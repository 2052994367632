import React, { useEffect, useState, ChangeEvent, KeyboardEvent } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/PurchaseOrdersAll.css";
import PurchaseOrdersSearchBar from "./PurchaseOrdersSearchBar";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import {
  BulkVendorPurchaseOrdersDataItem,
  ErrorMessageBody,
  FilterItem,
  PurchaseOrder,
  SearchParams,
  Vendor,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { Prev } from "react-bootstrap/esm/PageItem";
import ColorCard from "./ColorCard";
import { Filter, FilterSquareFill } from "react-bootstrap-icons";
import EscapeKeyListener from "../EscapeKeyListener";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import { AxiosError } from "axios";
import jsonexport from "jsonexport";
import { toast } from "react-toastify";

function PurchaseOrdersAll() {
  const history = useHistory();
  const location = useLocation();
  const purchaseOrdersNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [allPurchaseOrders, setAllPurchaseOrders] = useState<PurchaseOrder[]>(
    []
  );
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);
  const [poNumberSearchQueryLocal, setPoNumberSearchQueryLocal] =
    useState<string>("");
  const [poNumberSearchQueryGlobal, setPoNumberSearchQueryGlobal] =
    useState<string>("");
  const [checkedCount, setCheckedCount] = useState<number>(0);
  const [vendorIdFilterCheckedCountLocal, setVendorIdFilterCheckedCountLocal] =
    useState<number>(0);
  const [
    vendorIdFilterCheckedCountGlobal,
    setVendorIdFilterCheckedCountGlobal,
  ] = useState<number>(0);
  const [
    vendorNameFilterCheckedCountLocal,
    setVendorNameFilterCheckedCountLocal,
  ] = useState<number>(0);
  const [
    vendorNameFilterCheckedCountGlobal,
    setVendorNameFilterCheckedCountGlobal,
  ] = useState<number>(0);

  const [vendorIdFilterList, setVendorIdFilterList] = useState<FilterItem[]>(
    []
  );
  const [vendorNameFilterList, setVendorNameFilterList] = useState<
    FilterItem[]
  >([]);

  const [isPoNumberFilterVisible, setIsPoNumberFilterVisible] = useState(false); // State for filter visibility
  const [isVendorIdFilterVisible, setIsVendorIdFilterVisible] = useState(false);
  const [isVendorNameFilterVisible, setIsVendorNameFilterVisible] =
    useState(false); // State for filter visibility

  const [finalVendorNamesList, setFinalVendorNamesList] = useState<string[]>(
    []
  );
  const [finalVendorIdsList, setFinalVendorIdsList] = useState<number[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const getPurchaseOrdersPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean,
    requestBody: Record<string, any>
  ) => {
    try {
      const response =
        await purchaseOrdersNetworkUtil.getPurchaseOrdersPaginated_v2(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          100,
          requestBody
        );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllPurchaseOrders((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in fetching vendor listings by vendor id :", error);
      setShowLoadingPage(false);
      setShowBottomLoader(false);
    }
  };

  const handleSearch = (query: string) => {
    setAllPurchaseOrders([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    const requestBody: Record<string, any> = {};
    getPurchaseOrdersPaginated(query, 1, true, requestBody);
  };

  const loadMore = () => {
    setShowBottomLoader(true);
    const requestBody: Record<string, any> = {};
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = finalVendorNamesList;
    getPurchaseOrdersPaginated(
      "",
      searchParams.current_page_num + 1,
      false,
      requestBody
    );
  };

  const handleSearchWithPoNumber = (query: string) => {
    const requestBody: Record<string, any> = {};

    requestBody["poNumber"] = query;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = finalVendorNamesList;

    setAllPurchaseOrders([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    setCheckedCount(0);
    setIsCheckedSelectAll(false);
    getPurchaseOrdersPaginated("", 1, false, requestBody);
  };

  const applyPoNumberFilter = () => {
    setPoNumberSearchQueryGlobal(poNumberSearchQueryLocal);
    handleSearchWithPoNumber(poNumberSearchQueryLocal);
    setIsPoNumberFilterVisible(false);
  };

  const resetPoNumberFilter = () => {
    setIsPoNumberFilterVisible(false);
    setPoNumberSearchQueryLocal("");
    setPoNumberSearchQueryGlobal("");
    handleSearchWithPoNumber("");
  };

  const clearPoNumberFilter = () => {
    setIsPoNumberFilterVisible(false);
    setPoNumberSearchQueryLocal(poNumberSearchQueryGlobal);
  };

  const handlePoNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPoNumberSearchQueryLocal(e.target.value);
  };

  const handlePoNumberKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setPoNumberSearchQueryGlobal(poNumberSearchQueryLocal);
      handleSearchWithPoNumber(poNumberSearchQueryLocal);
      setIsPoNumberFilterVisible(false);
    }
  };

  const convertVendorsToVendorIdFilterList = (localVendors: Vendor[]) => {
    return localVendors.map((item) => ({
      filter_id: item.id,
      filter_string: item.id.toString(),
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  const convertVendorsToVendorNamesFilterList = (localVendors: Vendor[]) => {
    return localVendors.map((item) => ({
      filter_id: 0,
      filter_string: item.vendor_name,
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  useEffect(() => {
    const fetchAllVendors = async () => {
      try {
        const response = await vendorsNetworkUtil.getAllVendors();
        setVendorIdFilterList(
          convertVendorsToVendorIdFilterList(response.data)
        );
        setVendorNameFilterList(
          convertVendorsToVendorNamesFilterList(response.data)
        );
      } catch (error) {
        console.error("Error in fetching all active companies", error);
      }
    };
    setShowLoadingPage(true);
    fetchAllVendors();
    const requestBody: Record<string, any> = {};
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = finalVendorNamesList;
    setCheckedCount(0);
    setIsCheckedSelectAll(false);
    getPurchaseOrdersPaginated("", 1, false, requestBody);
  }, []);

  const clearVendorIdsFilter = () => {
    setVendorIdFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsVendorIdFilterVisible(false); // Close the dropdown
    setVendorIdFilterCheckedCountLocal(vendorIdFilterCheckedCountGlobal);
  };

  const clearVendorNamesFilter = () => {
    setVendorNameFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsVendorNameFilterVisible(false); // Close the dropdown
    setVendorNameFilterCheckedCountLocal(vendorNameFilterCheckedCountGlobal);
  };

  const resetVendorIdFilter = () => {
    setIsVendorIdFilterVisible(false);
    setVendorIdFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setVendorIdFilterCheckedCountLocal(0);
    setVendorIdFilterCheckedCountGlobal(0);
    setFinalVendorIdsList([]);
    handleSearchWithVendorIdFilters(searchParams.search_string, []);
  };

  const resetVendorNameFilter = () => {
    setIsVendorNameFilterVisible(false);
    setVendorNameFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setVendorNameFilterCheckedCountGlobal(0);
    setVendorNameFilterCheckedCountLocal(0);
    setFinalVendorNamesList([]);
    handleSearchWithVendorNameFilters(searchParams.search_string, []);
  };

  const applyVendorIdFilter = () => {
    const vendorIds = vendorIdFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_id);
    setFinalVendorIdsList(vendorIds);

    setVendorIdFilterCheckedCountGlobal(vendorIdFilterCheckedCountLocal);
    setVendorIdFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithVendorIdFilters(searchParams.search_string, vendorIds);
    setIsVendorIdFilterVisible(false); // Close the dropdown
  };

  const applyVendorNamesFilter = () => {
    const vendorNames = vendorNameFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_string);

    setFinalVendorNamesList(vendorNames);
    setVendorNameFilterCheckedCountGlobal(vendorNameFilterCheckedCountLocal);
    setVendorNameFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithVendorNameFilters(searchParams.search_string, vendorNames);
    setIsVendorNameFilterVisible(false); // Close the dropdown
  };

  const handleSearchWithVendorIdFilters = (
    query: string,
    vendorIds: number[]
  ) => {
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorIds"] = vendorIds;
    requestBody["vendorNames"] = finalVendorNamesList;

    setAllPurchaseOrders([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    setCheckedCount(0);
    setIsCheckedSelectAll(false);
    getPurchaseOrdersPaginated("", 1, false, requestBody);
  };

  const handleSearchWithVendorNameFilters = (
    query: string,
    localVendorNames: string[]
  ) => {
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = localVendorNames;

    setAllPurchaseOrders([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    setCheckedCount(0);
    setIsCheckedSelectAll(false);
    getPurchaseOrdersPaginated("", 1, false, requestBody);
  };

  const navigateToPurchaseOrderDetailsPage = (po: PurchaseOrder) => {
    // let url = "";
    // if (po.status == "PENDING" || po.status == "MARKED_MODIFICATION") {
    //   url = `/purchase-orders/add/addItems/${po.po_id}`;
    // } else {
    //   url = `/purchase-orders/published/${po.po_id}`;
    // }
    // history.push(url);
    // const win = window.open(url, "_blank");
    // if (win) {
    //   win.focus(); // Focus on the new tab if it opened successfully
    // } else {
    //   console.error("Popup blocked. Please allow popups for this website.");
    // }
  };

  const handleCheckBoxChangeForAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedSelectAll(event.target.checked); // Update state based on the checkbox value
    if (event.target.checked) {
      setCheckedCount(allPurchaseOrders.length);
    } else {
      setCheckedCount(0);
    }

    setAllPurchaseOrders((prevItems) =>
      prevItems.map((item, i) => ({
        ...item,
        ["is_selected"]: event.target.checked,
      }))
    );
  };

  const handleCheckBoxChangeAtIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) setCheckedCount(checkedCount + 1);
    else {
      setCheckedCount(checkedCount - 1);
      if (checkedCount - 1 == 0 && isCheckedSelectAll) {
        setIsCheckedSelectAll(false);
      }
    }

    setAllPurchaseOrders((prevItems) =>
      prevItems.map((item, i) =>
        i == index ? { ...item, ["is_selected"]: !item.is_selected } : item
      )
    );
  };

  const handleCheckBoxChangeAtVendorNameFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setVendorNameFilterCheckedCountLocal(
        vendorNameFilterCheckedCountLocal + 1
      );
    else {
      setVendorNameFilterCheckedCountGlobal(
        vendorNameFilterCheckedCountLocal - 1
      );
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setVendorNameFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const handleCheckBoxChangeAtVendorIdFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setVendorIdFilterCheckedCountLocal(vendorIdFilterCheckedCountLocal + 1);
    else {
      setVendorIdFilterCheckedCountLocal(vendorIdFilterCheckedCountLocal - 1);
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setVendorIdFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const getPurchaseOrderStatus = (poStatus: string) => {
    if (poStatus == "PENDING") return "PENDING";
    else if (poStatus == "PENDING_PUBLISHED") return "PUBLISHED";
    else if (poStatus == "MARKED_MODIFICATION")
      return "MARKED FOR MODIFICATION";
    else if (poStatus == "MARKED_RECEIVING") return "MARKED RECIEVING";
    else if (poStatus == "MARKED_PARTIALLY_RECEIVED")
      return "MARKED PARTIALLY RECIEVED";
    else if (poStatus == "MARKED_COMPLETED") return "MARKED COMPLETED";
    else if (poStatus == "MARKED_CANCELLED") return "MARKED CANCELLED";
    return "NA";
  };

  const getClassNameForNumberOfGrns = (grn_count: number) => {
    if (grn_count > 0) return "color-green";
    else return "color-red";
  };

  const getBulkVendorPurchaseOrdersData = async (poIds: any[]) => {
    try {
      const response =
        await purchaseOrdersNetworkUtil.getBulkVendorPurchaseOrdersData(poIds);

      setShowLoadingPage(false);
      return response.data;
    } catch (error) {
      console.error(
        "Error in getting bulk data for vendor purchase orders. : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);

      return [];
    }
  };

  const prepareAndDownloadBulkPurchaseOrderData = async () => {
    setShowLoadingPage(true);
    //check if invoice numbers were assigned for all
    const poIds = allPurchaseOrders
      .filter((item) => item.is_selected === true)
      .map((item) => item.po_id);

    const bulkPurchaseOrderDataItems: BulkVendorPurchaseOrdersDataItem[] =
      await getBulkVendorPurchaseOrdersData(poIds);

    const transformedItems = bulkPurchaseOrderDataItems.map((singleItem) => {
      return {
        po_id: singleItem.po_id,
        sku_id: singleItem.sku_id,
        ordered_quantity: singleItem.required_quantity,
        total_invoice_quantity: singleItem.invoice_quantity,
        total_accepted_quantity: singleItem.accepted_quantity,
        total_rejected_quantity: singleItem.rejected_quantity,
        total_shortage_quantity:
          singleItem.invoice_quantity -
          singleItem.accepted_quantity -
          singleItem.rejected_quantity,
        avg_price_excl_gst: singleItem.avg_price,
        vendor_id: singleItem.vendor_id,
        vendor_name: singleItem.vendor_name,
        po_status: singleItem.status,
        date_published: DateTimeUtils.formatDateOnly(singleItem.date_published),
        expected_date: DateTimeUtils.formatDateOnly(singleItem.expected_date),
      };
    });

    jsonexport(transformedItems, (err: Error | null, csv: string) => {
      if (err) {
        console.error(err);
        return;
      }
      // Create a Blob object with the CSV data
      const blob = new Blob([csv], { type: "text/csv" });
      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "bulk_vendor_purchase_order_data";

      // Simulate a click to trigger the download
      link.click();
    });
  };
  return (
    <div className="purchase-orders-container">
      {/* <div className="purchase-orders-search-bar-container">
        <div className="colors-overview-container">
          <ColorCard color="red" />
          <ColorCard color="orange" />          
        </div>        
      </div> */}

      <div className="catalogues-search-bar-container">
        {checkedCount > 0 && (
          <div>
            <Button
              className="mr-2 filters-button"
              onClick={prepareAndDownloadBulkPurchaseOrderData}
              variant="outline-primary"
            >
              Download Purchase Orders Data
            </Button>
          </div>
        )}
        <div className="colors-overview-container">
          <ColorCard color="red" />
          <ColorCard color="orange" />
          {/* <ColorCard color="green" /> */}
        </div>
      </div>

      <div className="vendor-purchase-orders-table-container">
        <div className="vendor-purchase-orders-table">
          <Table
            bordered
            hover
            size="sm"
            className="table-auto-width data-table"
          >
            <thead>
              <tr>
                <th>
                  {" "}
                  <input
                    type="checkbox"
                    checked={isCheckedSelectAll}
                    onChange={handleCheckBoxChangeForAll}
                  />
                </th>
                <th>
                  PO Id
                  {poNumberSearchQueryGlobal.length == 0 ? (
                    <Filter
                      className="filter-option ml-1"
                      onClick={() => setIsPoNumberFilterVisible(true)}
                    ></Filter>
                  ) : (
                    <FilterSquareFill
                      className="filter-option ml-1"
                      style={{ color: "green" }}
                      onClick={() => setIsPoNumberFilterVisible(true)}
                    ></FilterSquareFill>
                  )}
                  {isPoNumberFilterVisible && (
                    <EscapeKeyListener onEscape={clearPoNumberFilter}>
                      <div className="filter-dropdown">
                        <div className="empty-space"></div>
                        <div className="filter-list-main ml-2 mr-2">
                          <input
                            type="text"
                            placeholder="Search PO Number..."
                            value={poNumberSearchQueryLocal}
                            onChange={handlePoNumberInputChange}
                            onKeyPress={handlePoNumberKeyPress}
                          />
                        </div>
                        <div className="filter-buttons-section mt-2 ml-2 mr-2">
                          <Button
                            variant="outline-primary"
                            className="filters-button"
                            onClick={resetPoNumberFilter}
                          >
                            Reset
                          </Button>
                          <Button
                            variant="outline-primary"
                            className="filters-button"
                            onClick={clearPoNumberFilter}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            className="filters-button"
                            disabled={poNumberSearchQueryLocal.length == 0}
                            onClick={applyPoNumberFilter}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </EscapeKeyListener>
                  )}
                </th>
                <th>
                  Vendor Id
                  {vendorIdFilterCheckedCountGlobal == 0 ? (
                    <Filter
                      className="filter-option ml-1"
                      onClick={() => setIsVendorIdFilterVisible(true)}
                    ></Filter>
                  ) : (
                    <FilterSquareFill
                      className="filter-option ml-1"
                      style={{ color: "green" }}
                      onClick={() => setIsVendorIdFilterVisible(true)}
                    ></FilterSquareFill>
                  )}
                  {isVendorIdFilterVisible && (
                    <EscapeKeyListener onEscape={clearVendorIdsFilter}>
                      <div className="filter-dropdown">
                        <div className="empty-space"></div>
                        <div className="filter-list-main">
                          {vendorIdFilterList.map((filterListItem, index) => (
                            <div key={index} className="filter-item">
                              <input
                                className="filter-check-box mr-1"
                                type="checkbox"
                                checked={filterListItem.is_selected_local}
                                onChange={(e) =>
                                  handleCheckBoxChangeAtVendorIdFilterIndex(
                                    e,
                                    index
                                  )
                                }
                              />
                              {filterListItem.filter_string}
                            </div>
                          ))}
                        </div>
                        <div className="filter-buttons-section">
                          {/* {poTypeFilterCheckedCount > 0} */}
                          {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                          <Button
                            variant="outline-primary"
                            className="filters-button"
                            onClick={resetVendorIdFilter}
                          >
                            Reset
                          </Button>
                          <Button
                            variant="outline-primary"
                            className="filters-button"
                            onClick={clearVendorIdsFilter}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            className="filters-button"
                            disabled={vendorIdFilterCheckedCountLocal == 0}
                            onClick={applyVendorIdFilter}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </EscapeKeyListener>
                  )}
                </th>
                <th>
                  Vendor Name
                  {vendorNameFilterCheckedCountGlobal == 0 ? (
                    <Filter
                      className="filter-option ml-1"
                      onClick={() => setIsVendorNameFilterVisible(true)}
                    ></Filter>
                  ) : (
                    <FilterSquareFill
                      className="filter-option ml-1"
                      style={{ color: "green" }}
                      onClick={() => setIsVendorNameFilterVisible(true)}
                    ></FilterSquareFill>
                  )}
                  {isVendorNameFilterVisible && (
                    <EscapeKeyListener onEscape={clearVendorNamesFilter}>
                      <div className="filter-dropdown">
                        <div className="empty-space"></div>
                        <div className="filter-list-main">
                          {vendorNameFilterList.map((filterListItem, index) => (
                            <div key={index} className="filter-item">
                              <input
                                className="filter-check-box mr-1"
                                type="checkbox"
                                checked={filterListItem.is_selected_local}
                                onChange={(e) =>
                                  handleCheckBoxChangeAtVendorNameFilterIndex(
                                    e,
                                    index
                                  )
                                }
                              />
                              {filterListItem.filter_string}
                            </div>
                          ))}
                        </div>
                        <div className="filter-buttons-section">
                          {/* {poTypeFilterCheckedCount > 0} */}
                          {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                          <Button
                            variant="outline-primary"
                            className="filters-button"
                            onClick={resetVendorNameFilter}
                          >
                            Reset
                          </Button>
                          <Button
                            variant="outline-primary"
                            className="filters-button"
                            onClick={clearVendorNamesFilter}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            className="filters-button"
                            disabled={vendorNameFilterCheckedCountLocal == 0}
                            onClick={applyVendorNamesFilter}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </EscapeKeyListener>
                  )}
                </th>
                <th>PO status</th>
                <th>Sku Count</th>
                <th>Total Quantity</th>
                <th>Was GRN Done ?</th>
                <th>Number Of Grns</th>
                <th>Total Invoice Quantity</th>
                <th>Total Accepted Quantity</th>
                <th>Total Rejected Quantity</th>
                <th>Sku Fill Rate %</th>
                <th>Quantity Fill Rate %</th>
                <th>Po Remarks</th>
                <th>Date Created</th>
                <th>Date Published</th>
                <th>Expiry Date</th>
                <th>Last Updated</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {allPurchaseOrders.map((po, index) => {
                const dateStatus = DateTimeUtils.getDateStatus(
                  po.expected_date
                );
                return (
                  <tr
                    className="purchase-orders-search-bar-row"
                    key={index}
                    onClick={() => navigateToPurchaseOrderDetailsPage(po)}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={po.is_selected}
                        onChange={(e) => handleCheckBoxChangeAtIndex(e, index)}
                      />
                    </td>
                    <td className="po-id-link">
                      <a
                        href={`/vendor-hub/all-purchase-orders/details/${po.po_id}`}
                      >
                        {po.po_id}
                      </a>
                    </td>
                    <td className="po-id-link">
                      <a
                        href={`/vendor-hub/all-vendors/${po.vendor_id}/details`}
                      >
                        {po.vendor_id}
                      </a>
                    </td>
                    <td>{po.vendor_name}</td>
                    <td>{getPurchaseOrderStatus(po.status)}</td>
                    <td>{po.sku_count}</td>
                    <td>{po.total_quantity}</td>
                    <td
                      className={getClassNameForNumberOfGrns(po.number_of_grns)}
                    >
                      {po.number_of_grns > 0 ? "YES" : "NO"}
                    </td>
                    <td>{po.number_of_grns}</td>
                    <td>{po.total_invoice_quantity}</td>
                    <td>{po.total_accepted_quantity}</td>
                    <td>{po.total_rejected_quantity}</td>
                    <td>{po.sku_fill_rate}%</td>
                    <td>{po.quantity_fill_rate}%</td>
                    <td>{po.po_remarks}</td>
                    <td>{DateTimeUtils.formatDateTime(po.created_at)}</td>
                    <td>
                      {po.status == "PENDING"
                        ? ""
                        : DateTimeUtils.formatDateTime(po.date_published)}
                    </td>
                    <td
                      className={
                        `expiry-date ` +
                        (dateStatus < 0
                          ? "red"
                          : dateStatus <= 5
                          ? "orange"
                          : "green")
                      }
                    >
                      {DateTimeUtils.formatDateOnly(po.expected_date)}
                    </td>
                    <td>{DateTimeUtils.formatDateTime(po.updated_at)}</td>
                    <td>{po.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {!showBottomLoader && allPurchaseOrders.length == 0 && (
            <div className="nothing-found-container">
              No Purchase Orders were found.
            </div>
          )}
          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count &&
            !showBottomLoader && (
              <div className="load-more-catalogues-button-container">
                <Button
                  variant="outline-primary"
                  className="load-more-consignments-button"
                  onClick={() => loadMore()}
                >
                  Load More..
                </Button>
              </div>
            )}
          {showBottomLoader && (
            <div className="bottom-progress-container">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          )}
        </div>
      </div>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}
    </div>
  );
}

export default PurchaseOrdersAll;
