import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./CSS/PurchaseOrdersVendorListingsView.css";
import {
  CostPriceTimelineItem,
  ImageHashMapState,
  Listing,
  ListingPage,
  PurchaseOrderItemWithLisiting,
  PurchaseOrderItemWithLisitingAndGrnData,
  Vendor,
  VendorListing,
  VendorPurchaseOrderGrn,
} from "../../atomic_components/types";
import { Spinner, Table } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import {
  ImageHashMapVendorPurchaseOrder__Action,
  ImageHashMap__Action,
} from "../../state/action-creators";
import {
  ImageHashMapActionTypes,
  VendorPurchaseOrderImageHashMapActionTypes,
} from "../../state/action-types";
import { VisibilityOptions } from "./VendorDetails";

interface ListingImageProps {
  images: string[]; // Define the type of the images prop as an array of strings
}

const ListingImage: React.FC<ListingImageProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Use useMemo to find the first valid image
  const firstValidImageIndex = useMemo(() => {
    for (let i = 0; i < images.length; i++) {
      if (images[i] && images[i].trim().length > 10) {
        return i;
      }
    }
    return -1;
  }, [images]); // Recomputes only if images array changes

  // Set initial valid image index
  useEffect(() => {
    setCurrentImageIndex(firstValidImageIndex);
  }, [firstValidImageIndex]);

  // useCallback to prevent function recreation on every render
  const handleImageError = useCallback(() => {
    setCurrentImageIndex((prevIndex) => {
      let nextIndex = prevIndex + 1;
      while (nextIndex < images.length) {
        if (images[nextIndex] && images[nextIndex].trim().length >= 10) {
          return nextIndex;
        }
        nextIndex++;
      }
      return -1; // No valid image found
    });
  }, [images]); // Runs only if images array changes

  return (
    <div className="listings-img-container">
      {currentImageIndex === -1 ? (
        <img
          src="https://tech.intellozene.com/Media/na.png"
          alt="Product Image"
        />
      ) : (
        <img
          src={images[currentImageIndex]}
          alt="Product Image"
          onError={handleImageError}
        />
      )}
    </div>
  );
};

const ListingCard: React.FC<{
  purchaseOrderListing: PurchaseOrderItemWithLisitingAndGrnData;
  handleCardClick: (listing: PurchaseOrderItemWithLisitingAndGrnData) => void;
}> = ({ purchaseOrderListing, handleCardClick }) => {
  const images = useMemo(
    () => [
      purchaseOrderListing.listing.img_wdim,
      purchaseOrderListing.listing.img_hd,
      purchaseOrderListing.listing.img_white,
      purchaseOrderListing.listing.img_link1,
      purchaseOrderListing.listing.img_link2,
    ],
    [purchaseOrderListing]
  ); // Only recompute if vendorListing changes
  return (
    <div
      className={`listings-view-card vendor-listing-card-grn ${
        purchaseOrderListing.is_selected ? "blue" : "white"
      }`}
      onClick={() => handleCardClick(purchaseOrderListing)}
    >
      <div className="listings-img-container">
        <ListingImage images={images} />
      </div>

      <div className="listings-view-card-title-grn">
        <h3
          className={`sku-id-title 
          `}
        >
          {purchaseOrderListing.listing.sku_id}
        </h3>
      </div>
    </div>
  );
};

const ListingCardSmall: React.FC<{
  purchaseOrderListing: PurchaseOrderItemWithLisitingAndGrnData;
  handleCardClick: (listing: PurchaseOrderItemWithLisitingAndGrnData) => void;
}> = ({ purchaseOrderListing, handleCardClick }) => {
  const images = useMemo(
    () => [
      purchaseOrderListing.listing.img_wdim,
      purchaseOrderListing.listing.img_hd,
      purchaseOrderListing.listing.img_white,
      purchaseOrderListing.listing.img_link1,
      purchaseOrderListing.listing.img_link2,
    ],
    [purchaseOrderListing]
  ); // Only recompute if vendorListing changes

  return (
    <div
      className={`vendor-listing-card-small-grn ${
        purchaseOrderListing.is_selected ? "blue" : "white"
      }`}
      onClick={() => handleCardClick(purchaseOrderListing)}
    >
      <div className="vendor-listings-img-container-small">
        <ListingImage images={images} />
      </div>
      <div className="vendor-listings-view-card-title-small-grn">
        <p className={`sku-id-title`}>{purchaseOrderListing.listing.sku_id}</p>
      </div>
    </div>
  );
};

type ListingsViewProps = {
  currentGrn: VendorPurchaseOrderGrn;
  publishedListings: PurchaseOrderItemWithLisitingAndGrnData[];
  onCardClick: (
    selectedListing: PurchaseOrderItemWithLisitingAndGrnData
  ) => void;
  selectedVisibilityOption: VisibilityOptions;
};

export default function PurchaseOrderGrnItemsView({
  currentGrn,
  publishedListings,
  onCardClick,
  selectedVisibilityOption,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();

  const handleCardClick = async (
    listing: PurchaseOrderItemWithLisitingAndGrnData
  ) => {
    onCardClick(listing);
  };

  return (
    <div>
      {(selectedVisibilityOption == VisibilityOptions.GRID_LARGE ||
        selectedVisibilityOption == VisibilityOptions.GRID_SMALL) && (
        <div className="grn-vendor-skus-container">
          <div className="listings-container purchase-order-grn-listings-container">
            {publishedListings &&
              publishedListings.length > 0 &&
              selectedVisibilityOption == VisibilityOptions.GRID_LARGE && (
                <div className="vendor-listings-card-container">
                  {publishedListings.map((publishedListing, index) => (
                    <ListingCard
                      key={index}
                      purchaseOrderListing={publishedListing}
                      handleCardClick={handleCardClick}
                    />
                  ))}
                </div>
              )}

            {publishedListings &&
              publishedListings.length > 0 &&
              selectedVisibilityOption == VisibilityOptions.GRID_SMALL && (
                <div className="vendor-listings-card-container-small">
                  {publishedListings.map((publishedListing, index) => (
                    <ListingCardSmall
                      key={index}
                      purchaseOrderListing={publishedListing}
                      handleCardClick={handleCardClick}
                    />
                  ))}
                </div>
              )}
            {publishedListings && publishedListings.length == 0 && (
              <div className="no-items-view">Nothing to Display</div>
            )}
          </div>
        </div>
      )}

      <div className="grn-vendor-skus-container-no-scroll">
        <div className="listings-container purchase-order-grn-listings-container">
          {publishedListings &&
            publishedListings.length > 0 &&
            selectedVisibilityOption == VisibilityOptions.LIST_VIEW && (
              <div className="vendor-listings-table-container">
                <div className="vendor-skus-table-container grn-table-container">
                  <div className="all-labels-master-data-table">
                    <Table
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width"
                    >
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>SKU ID</th>
                          <th>Ops Tag</th>
                          <th>Available Quantity</th>
                          <th>Total Demand</th>
                          <th>Total Invoice Quantity (Prev. GRNs)</th>
                          <th>Total Accepted Quantity (Prev. GRNs)</th>
                          <th>Total Rejected Quantity (Prev. GRNs)</th>
                          <th>Average Recieved Price (Prev. GRNs)</th>
                          <th>Fill Rate % (Prev. GRNs)</th>
                          <th className="current-grn-table-header">
                            Current GRN Invoice Quantity
                          </th>
                          <th className="current-grn-table-header">
                            Current GRN Accepted Quantity
                          </th>
                          <th className="current-grn-table-header">
                            Current GRN Rejected Quantity
                          </th>
                          <th className="current-grn-table-header">
                            Current GRN Short Quantity
                          </th>
                          <th className="current-grn-table-header">
                            Current GRN Received Price (excl. gst)
                          </th>
                          <th className="current-grn-table-header">
                            Current GRN Tax Rate
                          </th>
                          <th className="current-grn-table-header">
                            Current GRN Entry By
                          </th>
                          <th className="current-grn-table-header">
                            Current GRN Damage Images Count
                          </th>
                          <th className="current-grn-table-header">
                            Created At
                          </th>

                          {currentGrn.grn_status == "CLOSED" && (
                            <th className="current-grn-table-header-audit">
                              Audit Price (excl. gst)
                            </th>
                          )}

                          {currentGrn.grn_status == "CLOSED" && (
                            <th className="current-grn-table-header-audit">
                              Audited By
                            </th>
                          )}

                          {currentGrn.grn_status == "CLOSED" && (
                            <th className="current-grn-table-header-audit">
                              Last Audited At
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {publishedListings.map((publishedListing, index) => {
                          return (
                            <tr
                              className={`catalogues-search-bar-row ${
                                publishedListing.is_selected ? "blue" : "white"
                              }`}
                              onClick={() => handleCardClick(publishedListing)}
                            >
                              <td>{index + 1}</td>
                              <td>{publishedListing.sku_id}</td>
                              <td>{publishedListing.listing.ops_tag}</td>
                              <td>
                                {publishedListing.listing.available_quantity}
                              </td>
                              <td>{publishedListing.required_quantity}</td>
                              <td>{publishedListing.invoice_quantity}</td>
                              <td>{publishedListing.accepted_quantity}</td>
                              <td>{publishedListing.rejected_quantity}</td>
                              <td>{publishedListing.avg_price}</td>
                              <td>
                                {Math.round(
                                  (publishedListing.accepted_quantity /
                                    publishedListing.required_quantity) *
                                    100 *
                                    100
                                ) / 100}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .invoice_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .accepted_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .rejected_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .invoice_quantity -
                                    publishedListing.currentGrnItem
                                      .accepted_quantity -
                                    publishedListing.currentGrnItem
                                      .rejected_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .received_price
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem.tax_rate
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem.created_by
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {publishedListing.currentGrnItem ? 0 : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? DateTimeUtils.formatDateTime(
                                      publishedListing.currentGrnItem.created_at
                                    )
                                  : ""}
                              </td>
                              {currentGrn.grn_status == "CLOSED" && (
                                <td
                                  className={`${
                                    publishedListing.currentGrnItem &&
                                    publishedListing.currentGrnItem.audit_price
                                      ? "audit-background"
                                      : ""
                                  }`}
                                >
                                  {publishedListing.currentGrnItem
                                    ? publishedListing.currentGrnItem
                                        .audit_price
                                    : ""}
                                </td>
                              )}
                              {currentGrn.grn_status == "CLOSED" && (
                                <td
                                  className={`${
                                    publishedListing.currentGrnItem &&
                                    publishedListing.currentGrnItem.audit_price
                                      ? "audit-background"
                                      : ""
                                  }`}
                                >
                                  {publishedListing.currentGrnItem
                                    ? publishedListing.currentGrnItem.audited_by
                                    : ""}
                                </td>
                              )}
                              {currentGrn.grn_status == "CLOSED" && (
                                <td
                                  className={`${
                                    publishedListing.currentGrnItem &&
                                    publishedListing.currentGrnItem.audit_price
                                      ? "audit-background"
                                      : ""
                                  }`}
                                >
                                  {publishedListing.currentGrnItem
                                    ? DateTimeUtils.formatDateTime(
                                        publishedListing.currentGrnItem
                                          .audited_at
                                      )
                                    : ""}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            )}

          {publishedListings && publishedListings.length == 0 && (
            <div className="no-items-view">Nothing to Display</div>
          )}
        </div>
      </div>
    </div>
  );
}
