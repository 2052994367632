import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/CatalogueDetailsPage.css";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  PurchaseOrder,
  CatalogueItem,
  Catalogue,
  CatalogueTheme,
  ThemeControls,
  ImageHashMap,
  ImageHashMapState,
  CatalogueItemForSpreadsheetExport,
} from "../../atomic_components/types";

import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import DateTimeUtils from "../../utils/DateTimeUtils";
import Header from "../Header";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import CataloguesSearchBar from "./CataloguesSearchBar";
import CatalogueNetworkUtils from "./NetworkUtils/CatalogueNetworkUtils";
import CatalogueItemsView from "./CatalogueItemsView";
import CataloguePDF from "./CataloguePDF";
import CataloguePDF_v2 from "./CataloguePDF_v2";
import CataloguePDF_v3 from "./CataloguePDF_v3";
import FilteredDropdown from "../Vendors/FilteredDropdown";
import CatalogueThemesDropdown from "./CatalogueThemesDropdown";
import { ImageHashMapActionTypes } from "../../state/action-types";
import { ImageHashMap__Action } from "../../state/action-creators";
import CataloguePDF_v4 from "./CataloguePDF_v4";
import { image } from "html2canvas/dist/types/css/types/image";
import {
  fallBackFirstPage,
  fallBackSecondPage,
  fallBackThirdPage,
  fallbackTopDesign,
} from "./NetworkUtils/fallbackImage";
import jsonexport from "jsonexport";

interface RouteParams {
  catalogue_id: string;
}

interface ThemeImageProps {
  imageKey: string; // Define the type of the images prop as an array of strings
  currentTheme: CatalogueTheme;
}

const ThemeImage: React.FC<ThemeImageProps> = ({ imageKey, currentTheme }) => {
  const [imageLoading, setImageLoading] = useState(true); // State to track image loading progress
  const handleImageLoad = () => {
    setImageLoading(false); // Set imageLoading to false when the image is loaded
  };

  const handleImageError = () => {
    setImageLoading(false); // Set imageLoading to false if an error occurs while loading the image
  };
  return (
    <div className="grid-item-details-card">
      <div key={imageKey} className={`image-placeholder-catalogue`}>
        {imageLoading && (
          <div className="theme-image-spiner-container">
            <Spinner animation="grow" />
          </div>
        )}
        <div className="theme-image-container">
          <img
            src={currentTheme.theme_pages[imageKey].toString()}
            alt={`Image ${currentTheme.theme_name}`}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </div>

        <div className="theme-name mt-2">{imageKey}</div>
      </div>
    </div>
  );
};

function CatalogueDetailsPage() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { catalogue_id } = useParams<RouteParams>();
  const dispatch: AppDispatch = useDispatch();
  const [selectedImageKey, setSelectedImageKey] = useState<string>("any_image");
  const [selectedTransferPriceType, setSelectedTransferPriceType] =
    useState<string>("");
  const [selectedTransferPriceAction, setselectedTransferPriceAction] =
    useState<string>("increase");
  const [transferPriceByValueOption, setTransferPriceByValueOption] =
    useState<number>(0);
  const [catalogueItems, setcatalogueItems] = useState<CatalogueItem[]>([]);
  const [catalogueThemes, setCatalogueThemes] = useState<CatalogueTheme[]>([]);
  const [showCanvas, setShowCanvas] = useState<boolean>(false);
  const [providedSelectedValue, setProvidedSelectedValue] =
    useState<string>("");
  const [selectedCatalogueTheme, setSelectedCatalogueTheme] =
    useState<CatalogueTheme | null>(null);
  const [filteredcatalogueItems, setFilteredcatalogueItemss] = useState<
    CatalogueItem[]
  >([]);
  const [currentCatalogue, setCurrentCatalogue] = useState<Catalogue | null>(
    null
  );
  const [chunkedCatalogueItems, setChunkedCatalogueItems] = useState<
    CatalogueItem[][]
  >([]);

  const [generatePDF, setGeneratePDF] = useState<boolean>(false);
  const [imageDownloadStart, setImageDownloadStart] = useState<boolean>(false);
  const [themeDownloadStart, setThemeDownloadStart] = useState<boolean>(false);
  const [areImagesAlreadyDownloaded, setAreImagesAlreadyDownloaded] =
    useState<boolean>(false);
  const [areThemesAlreadyDownloaded, setAreThemesAlreadyDownloaded] =
    useState<boolean>(false);
  const [downloadsReady, setDownloadsReady] = useState<boolean>(false);
  const [isAddWaterMarkChecked, setWaterMarkChecked] = useState<boolean>(false);
  const [showCatalogueLoader, setShowCatalogueLoader] = useState(true);
  const [loadingThemeImage, setLoadingThemeImage] = useState(false);
  const [themeControls, setThemeControls] = useState<ThemeControls>({
    description: true,
    transferPrice: true,
    lwh: true,
  });

  const [imageSources, setImageSources] = useState<string[]>([]);
  const [themePages, setThemePages] = useState<string[]>([]);
  const [
    downloadSpreadsheetButtonClicked,
    setDownloadSpreadsheetButtonClicked,
  ] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (key: string) => {
    setThemeControls((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the checked status of the checkbox
    }));
  };

  const history = useHistory();
  const location = useLocation();

  const catalogueNetworkUtils = new CatalogueNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  // const hashMap: ImageHashMapState = useAppSelector(
  //   (state) => state.image_hashmap_store
  // );
  // console.log(hashMap);

  useEffect(() => {
    const fetchCatalogueById = async (catalogueId: string) => {
      console.log(catalogueId);
      try {
        const response = await catalogueNetworkUtils.getCatalogueById(
          catalogueId
        );
        console.log(response.data);
        setCurrentCatalogue(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    fetchCatalogueById(catalogue_id);
  }, [catalogue_id]);

  useEffect(() => {
    const getcatalogueItemsByCatalogueId = async (catalogueId: number) => {
      try {
        const response = await catalogueNetworkUtils.getCatalogueItems(
          catalogueId.toString()
        );

        // console.log(response.data);
        const localCatalogueItems: CatalogueItem[] = response.data;
        const updatedLocalCatalogueItems = localCatalogueItems.map(
          (catalogueItem) => ({
            ...catalogueItem,
            new_transfer_price: catalogueItem.listing.bulk_price,
            valid_image_index: 0,
          })
        );

        dispatch(
          ImageHashMap__Action(ImageHashMapActionTypes.INIT_MAP, {
            key: "",
            value: 0,
            catalogueItems: updatedLocalCatalogueItems,
          })
        );

        setcatalogueItems(updatedLocalCatalogueItems);
        setFilteredcatalogueItemss(updatedLocalCatalogueItems);

        setShowCatalogueLoader(false);
      } catch (error) {
        console.error(
          "Error in getting catalogue items by catalogueId : ",
          error
        );
        setShowCatalogueLoader(false);
      }
    };
    const getCatalogueThemes = async () => {
      try {
        const response = await catalogueNetworkUtils.getCatalogueThemes();
        // console.log(response.data);
        setCatalogueThemes(response.data);
      } catch (error) {
        console.error("Error in getting catalogue themes", error);
      }
    };

    if (currentCatalogue) {
      getcatalogueItemsByCatalogueId(currentCatalogue.id);
      getCatalogueThemes();
    }
  }, [currentCatalogue]);

  useEffect(() => {
    setChunkedCatalogueItems(chunkArray(catalogueItems, 3));
  }, [catalogueItems]);

  useEffect(() => {
    if (catalogueThemes && catalogueThemes.length > 0) {
      setSelectedCatalogueTheme(catalogueThemes[0]);
      setProvidedSelectedValue(catalogueThemes[0].theme_name);
    }
  }, [catalogueThemes]);

  const handleSearch = (query: string) => {
    console.log("purchase order item searched ", query);
    if (query.length == 0)
      setFilteredcatalogueItemss(_.cloneDeep(catalogueItems));
    else setFilteredcatalogueItemss(filtercatalogueItemss(query));
  };

  const filtercatalogueItemss = (query: string) => {
    query = query.toLowerCase();
    return catalogueItems.filter((catalogue) => {
      const skuMatches = catalogue.listing.sku_id.toLowerCase().includes(query);

      const opsTagMatches = catalogue.listing.ops_tag
        .toLowerCase()
        .includes(query);

      return skuMatches || opsTagMatches;
    });
  };

  const chunkArray = (
    array: CatalogueItem[],
    size: number
  ): CatalogueItem[][] => {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
      array.slice(index * size, index * size + size)
    );
  };

  const onCardClick = (currentItem: CatalogueItem) => {
    console.log("card clicked: ", currentItem);
    // setModalShow(true);
    // setSelectedListing(currentListing);
  };

  const handleshowCanvas = () => {
    setShowCanvas(false);
  };

  const handleCatalogueThemeFromDropdownSelected = (value: CatalogueTheme) => {
    console.log("Selected Value:", value);
    setLoadingThemeImage(true);
    setSelectedCatalogueTheme(value);
  };

  useEffect(() => {
    if (loadingThemeImage) {
      // Wait for 2 seconds before resetting loading state
      const timeoutId = setTimeout(() => {
        setLoadingThemeImage(false);
      }, 1000);

      // Clear timeout on component unmount or when selectedCatalogueTheme changes
      return () => clearTimeout(timeoutId);
    }
  }, [loadingThemeImage, selectedCatalogueTheme]);

  const handleImageError = (imageKey: string) => {
    if (selectedCatalogueTheme) {
      const updatedCatalogueTheme = { ...selectedCatalogueTheme };
      updatedCatalogueTheme.theme_pages[imageKey] =
        "http://tech.intellozene.com/Media/na.png";
      setSelectedCatalogueTheme(updatedCatalogueTheme);
    }
  };

  const handleImageClick = (imageUrl: string) => {
    // setSelectedImage(imageUrl);
    console.log(imageUrl, "clicked");
  };

  const handleImageTypeRadioChange = (imageKey: string) => {
    setSelectedImageKey(imageKey);
  };

  const handleTransferPriceRadioChange = (type: string) => {
    setSelectedTransferPriceType(type);
  };

  const handleTransferPriceActionChange = (type: string) => {
    setselectedTransferPriceAction(type);
  };

  const changeTransferPriceByValue = () => {
    if (selectedTransferPriceAction == "increase") {
      const updatedCatalogueItems = catalogueItems.map((catalogueItem) => ({
        ...catalogueItem,
        new_transfer_price:
          catalogueItem.listing.bulk_price + transferPriceByValueOption,
      }));
      const updatedFilteredCatalogueItems = filteredcatalogueItems.map(
        (catalogueItem) => ({
          ...catalogueItem,
          new_transfer_price:
            catalogueItem.listing.bulk_price + transferPriceByValueOption,
        })
      );

      setcatalogueItems(updatedCatalogueItems);
      setFilteredcatalogueItemss(updatedFilteredCatalogueItems);
    } else {
      const updatedCatalogueItems = catalogueItems.map((catalogueItem) => ({
        ...catalogueItem,
        new_transfer_price:
          catalogueItem.listing.bulk_price - transferPriceByValueOption,
      }));
      const updatedFilteredCatalogueItems = filteredcatalogueItems.map(
        (catalogueItem) => ({
          ...catalogueItem,
          new_transfer_price:
            catalogueItem.listing.bulk_price - transferPriceByValueOption,
        })
      );

      setcatalogueItems(updatedCatalogueItems);
      setFilteredcatalogueItemss(updatedFilteredCatalogueItems);
    }
  };
  const changeTransferPriceByPercent = () => {
    if (selectedTransferPriceAction == "increase") {
      const updatedCatalogueItems = catalogueItems.map((catalogueItem) => ({
        ...catalogueItem,
        new_transfer_price: Math.round(
          catalogueItem.listing.bulk_price +
            (catalogueItem.listing.bulk_price * transferPriceByValueOption) /
              100
        ),
      }));
      const updatedFilteredCatalogueItems = filteredcatalogueItems.map(
        (catalogueItem) => ({
          ...catalogueItem,
          new_transfer_price: Math.round(
            catalogueItem.listing.bulk_price +
              (catalogueItem.listing.bulk_price * transferPriceByValueOption) /
                100
          ),
        })
      );

      setcatalogueItems(updatedCatalogueItems);
      setFilteredcatalogueItemss(updatedFilteredCatalogueItems);
    } else {
      const updatedCatalogueItems = catalogueItems.map((catalogueItem) => ({
        ...catalogueItem,
        new_transfer_price: Math.round(
          catalogueItem.listing.bulk_price -
            (catalogueItem.listing.bulk_price * transferPriceByValueOption) /
              100
        ),
      }));
      const updatedFilteredCatalogueItems = filteredcatalogueItems.map(
        (catalogueItem) => ({
          ...catalogueItem,
          new_transfer_price: Math.round(
            catalogueItem.listing.bulk_price -
              (catalogueItem.listing.bulk_price * transferPriceByValueOption) /
                100
          ),
        })
      );

      setcatalogueItems(updatedCatalogueItems);
      setFilteredcatalogueItemss(updatedFilteredCatalogueItems);
    }
  };

  const handleClose = () => {
    setGeneratePDF(false);
  };

  const hashMap: ImageHashMapState = useAppSelector(
    (state) => state.image_hashmap_store
  );

  const handleImageIndexChangeMain = (
    listing: CatalogueItem,
    index: number
  ) => {
    const sku = listing.listing.sku_id;
    let url = "";

    if (index == 0) url = listing.listing.img_wdim;
    else if (index == 1) url = listing.listing.img_hd;
    else if (index == 2) url = listing.listing.img_white;
    else if (index == 3) url = listing.listing.img_link1;
    else if (index == 4) url = listing.listing.img_link2;
    // console.log(sku, url);

    if (url != "") {
      // const updatedHashmap = {
      //   ...imageHashmap, // Create a copy of the existing hashmap
      //   [sku]: url,
      // };
      // console.log(updatedHashmap);
      // setImageHashmap(updatedHashmap);
      // dispatch(
      //   ImageHashMap__Action(ImageHashMapActionTypes.ADD_TO_MAP, {
      //     key: sku,
      //     value: url,
      //   })
      // );
    }
  };

  // // console.log(imageSources);
  // useEffect(() => {
  //   // if (imageSources.length == catalogueItems.length) {
  //   //   setGeneratePDF(true);
  //   //   setDownloadsReady(true);
  //   // }
  // }, [imageSources]);
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const downloadSingleTheme = async (theme_name: string, page_name: string) => {
    let resourceToAdd =
      await catalogueNetworkUtils.downloadThemeImagesWithRetry(
        theme_name.substring(theme_name.lastIndexOf("/") + 1),
        page_name
      );
    return resourceToAdd.image;
  };

  const downloadThemesAndImages = async () => {
    await downloadAllThemes();
    await downloadAllImages();
  };

  const downloadAllThemes = async () => {
    if (!areThemesAlreadyDownloaded) {
      setThemeDownloadStart(true);

      if (selectedCatalogueTheme) {
        let firstPageUrl = selectedCatalogueTheme.theme_pages.first_page;
        let firstPageTheme = await downloadSingleTheme(
          firstPageUrl,
          "first_page"
        );

        let secondPageUrl = selectedCatalogueTheme.theme_pages.second_page;
        let secondPageTheme = await downloadSingleTheme(
          secondPageUrl,
          "second_page"
        );

        let thirdPageUrl = selectedCatalogueTheme.theme_pages.third_page;
        let thirdPageTheme = await downloadSingleTheme(
          thirdPageUrl,
          "third_page"
        );

        let themePageUrl = selectedCatalogueTheme.theme_pages.theme_page;
        let themePage = await downloadSingleTheme(themePageUrl, "theme_page");

        setThemePages((prevThemePages) => [
          ...prevThemePages,
          firstPageTheme,
          secondPageTheme,
          thirdPageTheme,
          themePage,
        ]);
      } else {
        setThemePages((prevThemePages) => [
          ...prevThemePages,
          fallBackFirstPage.image,
          fallBackSecondPage.image,
          fallBackThirdPage.image,
          fallbackTopDesign.image,
        ]);
      }

      setAreThemesAlreadyDownloaded(true);
    }
    setThemeDownloadStart(false);
  };

  const downloadAllImages = async () => {
    if (!areImagesAlreadyDownloaded) {
      setImageDownloadStart(true);
      let i = 0;
      let url = "";
      for (i = 0; i < catalogueItems.length; i++) {
        if (selectedImageKey == "img_hd")
          url = catalogueItems[i].listing.img_hd;
        else if (selectedImageKey == "img_wdim")
          url = catalogueItems[i].listing.img_wdim;
        else if (selectedImageKey == "img_white")
          url = catalogueItems[i].listing.img_white;
        else if (selectedImageKey == "img_link1")
          url = catalogueItems[i].listing.img_link1;
        else if (selectedImageKey == "img_link2")
          url = catalogueItems[i].listing.img_link2;
        else if (selectedImageKey == "any_image") {
          const sku_id = catalogueItems[i].listing.sku_id;
          const img_index = hashMap.imageHashmap[sku_id];
          if (img_index > 4) {
            url = "images/not-available.jpg";
          } else {
            let images = [
              catalogueItems[i].listing.img_wdim,
              catalogueItems[i].listing.img_hd,
              catalogueItems[i].listing.img_white,
              catalogueItems[i].listing.img_link1,
              catalogueItems[i].listing.img_link2,
            ];
            url = images[img_index];
          }
        }
        // let resourceToAdd = await catalogueNetworkUtils.downloadImageWithRetry(
        //   url.substring(url.lastIndexOf("/") + 1)
        // );

        let resourceToAdd =
          await catalogueNetworkUtils.downloadImageWithRetry_v2(
            url.substring(url.lastIndexOf("/") + 1)
          );

        setImageSources((prevImageSources) => [
          ...prevImageSources,
          resourceToAdd.image,
        ]);
        // await delay(500);
      }
      setAreImagesAlreadyDownloaded(true);
    }
    // console.log(imageSources.length);
    // console.log(imageSources[0]);
    // console.log("code shift");
    setGeneratePDF(true);
    setDownloadsReady(true);
    setImageDownloadStart(false);
  };

  const handleDownload = async () => {
    try {
      setDownloadSpreadsheetButtonClicked(true);
      let itemsToExport: CatalogueItemForSpreadsheetExport[] = [];
      // itemsToExport.push()

      const transformedItems = catalogueItems.map((singleItem) => {
        const img_index = hashMap.imageHashmap[singleItem.sku_id];
        let url = "";
        if (img_index > 4) {
          url = "https://www.tech.intellozene.com/Media/not-available.jpg";
        } else {
          let images = [
            singleItem.listing.img_wdim,
            singleItem.listing.img_hd,
            singleItem.listing.img_white,
            singleItem.listing.img_link1,
            singleItem.listing.img_link2,
          ];
          url = images[img_index];
        }
        return {
          sku_id: singleItem.sku_id,
          image_url: singleItem.listing.img_wdim,
          new_transfer_price: singleItem.new_transfer_price,
          available_quantity: singleItem.listing.available_quantity,
          ops_tag: singleItem.listing.ops_tag,
        };
      });

      setDownloadSpreadsheetButtonClicked(false);
      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = currentCatalogue
          ? currentCatalogue.id + "_details.csv"
          : "catalogue_details.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log(
        "Printing error from ListingBulkOperations download inventory snapshot"
      );
      console.error("Error:", error);
    }
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        <div className="main-container">
          <div className="vendor-action-container">
            {catalogueItems.length > 0 && (
              <div className="vendor-basics vendors-info-card">
                <div className="vendors-view-card-title">
                  <div className="vendor-details-button-container">
                    <Button onClick={() => setShowCanvas(true)}>
                      Generate Catalogue Document
                    </Button>
                  </div>
                </div>
                <div className="grid-1-cells">
                  {catalogueItems.length != 0 && (
                    <div className="grid-1-cells">
                      <Alert variant="light" className="vendor-info">
                        <div>
                          <b>Total SKUs : </b>{" "}
                          {catalogueItems.length.toString()}
                        </div>
                      </Alert>
                    </div>
                  )}
                </div>
                <div className="grid-1-cells">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Image Options</h4>
                  </div>
                  <div className="grid-item-details-card">
                    <Form>
                      {[
                        "any_image",
                        "img_hd",
                        "img_white",
                        "img_wdim",
                        "img_link1",
                        "img_link2",
                      ].map((imageKey: string) => (
                        <Form.Check
                          type="radio"
                          key={imageKey}
                          label={imageKey}
                          name="group1"
                          checked={selectedImageKey === imageKey}
                          onChange={() => {
                            handleImageTypeRadioChange(imageKey);
                          }}
                        />
                      ))}
                    </Form>
                  </div>
                </div>

                <div className="grid-1-cells">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Pricing Options</h4>
                  </div>
                  <div className="grid-item-details-card">
                    <Form.Check
                      type="radio"
                      label="By Value"
                      checked={selectedTransferPriceType === "byValue"}
                      onChange={() => {
                        handleTransferPriceRadioChange("byValue");
                      }}
                    />
                    {selectedTransferPriceType === "byValue" && (
                      <div className="catalogue-pricing-inside-controls ml-3 mr-3 mb-3 mt-3">
                        <Form.Check
                          type="radio"
                          label="Increase"
                          checked={selectedTransferPriceAction === "increase"}
                          onChange={() =>
                            handleTransferPriceActionChange("increase")
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Decrease"
                          checked={selectedTransferPriceAction === "decrease"}
                          onChange={() =>
                            handleTransferPriceActionChange("decrease")
                          }
                        />
                        <Form.Control
                          type="number"
                          placeholder="Enter value"
                          value={transferPriceByValueOption}
                          onChange={(e) =>
                            setTransferPriceByValueOption(
                              parseInt(e.target.value)
                            )
                          }
                        />
                        <button
                          className="mt-2 catalogue-price-apply-button"
                          onClick={changeTransferPriceByValue}
                        >
                          {" "}
                          Apply{" "}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="grid-item-details-card">
                    <Form.Check
                      type="radio"
                      label="By Percentage"
                      checked={selectedTransferPriceType === "byPercent"}
                      onChange={() => {
                        handleTransferPriceRadioChange("byPercent");
                      }}
                    />
                    {selectedTransferPriceType === "byPercent" && (
                      <div className="catalogue-pricing-inside-controls ml-3 mr-3 mb-3 mt-3">
                        <Form.Check
                          type="radio"
                          label="Increase"
                          checked={selectedTransferPriceAction === "increase"}
                          onChange={() =>
                            handleTransferPriceActionChange("increase")
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Decrease"
                          checked={selectedTransferPriceAction === "decrease"}
                          onChange={() =>
                            handleTransferPriceActionChange("decrease")
                          }
                        />
                        <Form.Control
                          type="number"
                          placeholder="Enter value"
                          value={transferPriceByValueOption}
                          onChange={(e) =>
                            setTransferPriceByValueOption(
                              parseInt(e.target.value)
                            )
                          }
                        />
                        <button
                          className="mt-2 catalogue-price-apply-button"
                          onClick={changeTransferPriceByPercent}
                        >
                          {" "}
                          Apply{" "}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {currentCatalogue && (
              <div className="vendor-basics vendors-info-card">
                <div className="vendors-view-card-title">
                  <h4 className="vendor_id">Catalogue Details</h4>
                </div>
                <div className="grid-1-cells">
                  <div className="grid-item-details-card">
                    <label>Catalogue Id</label>
                    <input
                      type="text"
                      name="catalogue_id"
                      value={currentCatalogue.id}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Catalogue Name</label>
                    <input
                      type="text"
                      name="catalogue_name"
                      value={currentCatalogue.catalogue_name}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Catalogue Description</label>
                    <input
                      type="text"
                      name="catalogue_description"
                      value={currentCatalogue.catalogue_description}
                      disabled={true}
                    />
                  </div>
                  {/* <div className="grid-item-details-card">
                    <label>Min Price</label>
                    <input
                      type="text"
                      name="catalogue_min_price"
                      value={currentCatalogue.min_price}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Max Price</label>
                    <input
                      type="text"
                      name="catalogue_max_price"
                      value={currentCatalogue.max_price}
                      disabled={true}
                    />
                  </div> */}
                  <div className="grid-item-details-card">
                    <label>Created By</label>
                    <input
                      type="text"
                      name="catalogue_created_by"
                      value={currentCatalogue.created_by}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Date Created</label>
                    <input
                      type="text"
                      name="po_date_created"
                      value={DateTimeUtils.formatDateTime(
                        currentCatalogue.created_at
                      )}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="catalogue-items-container">
            <div className="vendor-listings-search-bar-container pl-2">
              <CataloguesSearchBar
                onSearch={handleSearch}
              ></CataloguesSearchBar>
            </div>

            <div className="listings-container pl-2">
              <CatalogueItemsView
                catalogueItems={filteredcatalogueItems}
                onCardClick={onCardClick}
                selectedImageKey={selectedImageKey}
                handleImageIndexChangeMain={handleImageIndexChangeMain}
              />
            </div>
          </div>
        </div>
      </div>

      {showCatalogueLoader && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, Please Wait..
        </div>
      )}

      <Offcanvas
        show={showCanvas}
        onHide={handleshowCanvas}
        // backdrop="static"
        placement="end"
        style={{ width: "70%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <p>Catalogue Generation</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container">
            {/* <Form>
              <Form.Check
                type="switch"
                label="Add ecraftIndia's watermark ?"
                checked={isAddWaterMarkChecked}
                onChange={() => {
                  setWaterMarkChecked(!isAddWaterMarkChecked);
                }}
              />
            </Form> */}
            <Container fluid className="mt-4 mb-4">
              <Row className="single-row pt-4 pl-4 pb-4">
                <Col>
                  <div className="download-snapshot-container">
                    <h6>Download as excel spreadsheet</h6>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={handleDownload}
                    >
                      Download
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>

            <div className="details-page-separator mt-2 mb-4">
              ------or------{" "}
            </div>

            <h5 className="mb-4">Download as PDF</h5>
            <CatalogueThemesDropdown
              options={catalogueThemes}
              onItemSelected={handleCatalogueThemeFromDropdownSelected}
              providedSelectedValue={providedSelectedValue}
            />
            {selectedCatalogueTheme && !loadingThemeImage && (
              <div className="grid-4-cells">
                {["first_page", "second_page", "third_page", "theme_page"].map(
                  (imageKey: string) => (
                    <ThemeImage
                      key={imageKey}
                      imageKey={imageKey}
                      currentTheme={selectedCatalogueTheme}
                    />
                  )
                )}
              </div>
            )}
            {loadingThemeImage && (
              <div className="grid-item-details-card">
                <div className="image-placeholder-catalogue-spinner">
                  <Spinner animation="grow" />
                </div>
              </div>
            )}

            <div className="theme-page-heading mt-3 mb-3">Display Options</div>
            <div className="grid-1-cells-theme-options mb-3">
              <div className="grid-item-theme">
                <Form.Check // prettier-ignore
                  type="checkbox"
                  label="Description"
                  checked={themeControls.description}
                  onChange={() => handleCheckboxChange("description")}
                />
              </div>
              <div className="grid-item-theme">
                <Form.Check // prettier-ignore
                  type="checkbox"
                  label="Transfer Price"
                  checked={themeControls.transferPrice}
                  onChange={() => handleCheckboxChange("transferPrice")}
                />
              </div>
              <div className="grid-item-theme">
                <Form.Check // prettier-ignore
                  type="checkbox"
                  label="L.W.H"
                  checked={themeControls.lwh}
                  onChange={() => handleCheckboxChange("lwh")}
                />
              </div>
            </div>

            {!generatePDF && (
              // <Button className="mt-4" onClick={() => setGeneratePDF(true)}>
              //   Generate PDF
              // </Button>
              <Button
                className="mt-4"
                onClick={() => {
                  downloadThemesAndImages();
                }}
              >
                Generate PDF
              </Button>
            )}

            {/* {generatePDF &&
              selectedCatalogueTheme &&
              chunkedCatalogueItems &&
              chunkedCatalogueItems.length > 0 && (
                <div className="mt-4">
                  <PDFDownloadLink
                    document={
                      <CataloguePDF_v3
                        chunkedCatalogueItems={chunkedCatalogueItems}
                        catalogue={currentCatalogue}
                        isAddWaterMarkChecked={isAddWaterMarkChecked}
                        selectedCatalogueTheme={selectedCatalogueTheme}
                        selectedImageKey={selectedImageKey}
                        themeControls={themeControls}
                      />
                    }
                    fileName="test"
                  >
                    {({ blob, url, loading, error }) => (
                      <button type="button" disabled={loading}>
                        {loading ? "Loading document..." : "Download PDF"}
                      </button>
                    )}
                  </PDFDownloadLink>
                </div>
              )} */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={generatePDF}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {generatePDF &&
            downloadsReady &&
            selectedCatalogueTheme &&
            chunkedCatalogueItems &&
            chunkedCatalogueItems.length > 0 && (
              <div className="mt-4">
                <PDFDownloadLink
                  document={
                    <CataloguePDF_v4
                      chunkedCatalogueItems={chunkedCatalogueItems}
                      catalogue={currentCatalogue}
                      isAddWaterMarkChecked={isAddWaterMarkChecked}
                      selectedCatalogueTheme={selectedCatalogueTheme}
                      selectedImageKey={selectedImageKey}
                      themeControls={themeControls}
                      hashMap={hashMap}
                      imageSources={imageSources}
                      themeSources={themePages}
                    />
                  }
                  fileName={
                    currentCatalogue ? currentCatalogue.id + "" : "catalogue"
                  }
                >
                  {({ blob, url, loading, error }) => (
                    <button
                      className="download-pdf-button"
                      type="button"
                      disabled={loading}
                    >
                      {loading ? "Loading document..." : "Download PDF"}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            )}
        </Modal.Body>
      </Modal>

      <Modal
        show={themeDownloadStart}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <span className="image-being-downloaded-spinner">
            <Spinner animation="grow" className="mr-4" />
            Please wait while the themes are being downloaded..
          </span>
        </Modal.Body>
      </Modal>

      <Modal
        show={imageDownloadStart}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <span className="image-being-downloaded-spinner">
            <Spinner animation="grow" className="mr-4" />
            Please wait while the images are being downloaded..
          </span>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CatalogueDetailsPage;
