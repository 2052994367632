// src/components/LoginPage.js
import React, { useState, useEffect, KeyboardEvent } from "react";
import { requestHeaders, requests } from "./../NetworkUtils/Requests";
import axios from "axios";
import "../page css components/LoginPage.css";
import { useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import { AppDispatch } from "../state";
import { useDispatch } from "react-redux";
import { User_details_Action } from "../state/action-creators";
import { toast } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import LoginPageHeader from "./LoginPageHeader";

const LoginPage = () => {
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    showPassword: false,
  });

  const [response, setresponse] = useState("");
  const history = useHistory();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const submitForm = async () => {
    const Data = {
      userId: formData.username,
      password: formData.password,
    };

    await axios
      .post(`${requestHeaders.rooturl}${requests.Login}`, Data, {
        withCredentials: true,
      })
      .then((res) => {
        // history.push("/listings");
        // if (history.length > 1)
        //   setTimeout(() => {
        //     history.goBack();
        //   }, 100);
        // else history.push("/listings/top");

        const returnUrl = new URLSearchParams(location.search).get("returnUrl");

        if (returnUrl) {
          // Redirect the user to the return URL after successful login
          history.push(returnUrl);
        } else {
          // Redirect to a default page if no return URL is available
          history.push("/listings/all");
        }

        // history.replace("listings/top");
        dispatch(User_details_Action(res.data));
        setShowLoadingPage(false);
      })
      .catch((error) => {
        toast.error("Invalid user credentials!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        console.log(error.response);
        setresponse(error.response.data.message);
        setShowLoadingPage(false);
      });
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = () => {
    setFormData((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handlePasswordKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setShowLoadingPage(true);
      submitForm();
    }
  };

  return (
    <div>
      <LoginPageHeader />

      <div className="login-page">
        <form>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type={formData.showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              onKeyPress={handlePasswordKeyPress}
              required
            />
          </div>
          <div className="checkbox-container">
            <label className="checkbox-label">
              <input
                className="show-password-checkbox"
                type="checkbox"
                checked={formData.showPassword}
                onChange={handleCheckboxChange}
              />
              Show Password
            </label>
          </div>
          <div className="login-button-container">
            <Button
              variant="outline-primary"
              onClick={() => {
                setShowLoadingPage(true);
                submitForm();
              }}
              className="vendor-action-button"
            >
              Login
            </Button>
          </div>
        </form>
      </div>
      {showLoadingPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
        </div>
      )}
    </div>
  );
};

export default LoginPage;
