import React, { useEffect, useState } from "react";
import {
  Listing,
  PacksAndCombos,
  PurchaseOrder,
  PurchaseOrderItemDetailed,
  Vendor,
  VendorAndCostPrice,
} from "../../atomic_components/types";
import "./CSS/ListingDetails.css";
import Header from "../Header";
import ListingBinGrid from "./ListingBinGrid";
import ListingNetworkUtil from "./NetworkUtils/ListingNetworkUtils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import {
  Listings__Action,
  TopListings__Action,
} from "../../state/action-creators";
import {
  ListingActionTypes,
  TopListingActionTypes,
} from "../../state/action-types";
import { round } from "@floating-ui/utils";
import { Badge, Button, Offcanvas, Spinner, Table } from "react-bootstrap";
import _ from "lodash";
import FilteredDropdown from "../Vendors/FilteredDropdown";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { useAppSelector } from "../../state/hooks";

interface ListingDetailsProps {
  listing: Listing; // Ensure the prop is correctly defined
}

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  sku: string;
}

// export default function ListingDetails({ listing }: ListingDetailsProps) {
export default function ListingDetails() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const { category, sku } = useParams<RouteParams>();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const [packsAndCombos, setPacksAndCombos] = useState<PacksAndCombos[]>([]);
  const [localPacksAndCombos, setLocalPacksAndCombos] =
    useState<PacksAndCombos[]>(packsAndCombos);
  const [selectedImage, setSelectedImage] = useState("img_hd");
  const [showAddNewItem, setShowAddNewItem] = useState(false);
  const [newItemSkuId, setNewItemSkuId] = useState<string>("");
  const [newItemSkuCount, setNewItemSkuCount] = useState<number>(0);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [analytics, setAnalytics] = useState({
    inward: {
      last_30_days: 0,
      last_60_days: 0,
      last_90_days: 0,
    },
    outward: {
      last_30_days: 0,
      last_60_days: 0,
      last_90_days: 0,
    },
  });

  const [editMode, setEditMode] = useState(false);
  const [allSkuIds, setAllSkuIds] = useState<string[]>([]);
  const [associatedVendors, setAllAssociatedVendors] = useState<
    VendorAndCostPrice[]
  >([]);
  const [packDetailsEditMode, setPackDetailsEditMode] = useState(false);
  const [inventoryEditMode, setInventoryEditMode] = useState(false);
  const [parentListing, setParentListing] = useState<Listing | null>(null);
  const [listing, setListing] = useState<Listing>({
    id: 0,
    sku_id: "",
    master_sku: "",
    inventory_sku_id: "",
    pack_combo_sku_id: "",
    sku_type: "",
    inventory_bypass_on: "",
    sales_rank: 0,
    ops_tag: "",
    threshold_inv: 0,
    category: "",
    description: "",
    meta_fields: "",
    img_hd: "",
    img_white: "",
    img_wdim: "",
    img_link1: "",
    img_link2: "",
    actual_weight: 0,
    sales_tag: "",
    adv_tag: "",
    gst: 0,
    hsn: "",
    dimension: "",
    box_size: "",
    length: 0,
    width: 0,
    height: 0,
    last_30_days_sales: 0,
    last_90_days_sales: 0,
    last_7_days_sales: 0,
    last_365_days_sales: 0,
    bins: [],
    available_quantity: 0,
    bulk_price: 0,
    isSelected: false,
    listingAnalytics: {
      inward: {
        last_30_days: 0,
        last_60_days: 0,
        last_90_days: 0,
      },
      outward: {
        last_30_days: 0,
        last_60_days: 0,
        last_90_days: 0,
      },
    },
  });
  const [localListing, setLocalListing] = useState<Listing>(
    _.cloneDeep(listing)
  );

  const [incomingPurchaseOrders, setIncomingPurchaseOrders] = useState<
    PurchaseOrderItemDetailed[]
  >([]);

  const [
    showIncomingPurchaseOrdersSpinner,
    setShowIncomingPurchaseOrdersSpinner,
  ] = useState<boolean>(true);

  useEffect(() => {
    // Make your API call here

    const fetchListingBySkuId = async (sku_id: string) => {
      try {
        const response = await listingNetworkUtil.getListingBySkuId(sku_id);
        console.log(response.data);
        setListing(response.data);
        setLocalListing(response.data);
      } catch (error) {
        console.error("Error in fetching listing :", error);
      }
    };

    const fetchAnalytics = async (sku_id: string) => {
      try {
        const response = await listingNetworkUtil.getAnalyticsBySkuId(sku_id);
        console.log(response.data);
        setAnalytics(response.data);
      } catch (error) {
        console.error("Error in fetching listing analytics :", error);
      }
    };

    const fetchPacksAndComboDetails = async (sku_id: string) => {
      try {
        const response = await listingNetworkUtil.getPacksAndComboDetails(
          sku_id
        );
        console.log(response.data);
        setPacksAndCombos(response.data);
        setLocalPacksAndCombos(response.data);
      } catch (error) {
        console.error("Error in updating listing :", error);
      }
    };
    const fetchAllSkuIds = async () => {
      try {
        const response = await listingNetworkUtil.getAllSkuIds();
        setAllSkuIds(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    const fetchAssociatedVendors = async (sku_id: string) => {
      try {
        const response = await vendorsNetworkUtil.fetchAssociatedVendors(
          sku_id
        );
        setAllAssociatedVendors(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error in fetching associated vendors", error);
      }
    };

    const fetchIncomingInventoryBySkuId = async (sku_id: string) => {
      try {
        const response = await listingNetworkUtil.fetchIncomingInventoryBySkuId(
          sku_id
        );
        // setAllAssociatedVendors(response.data);
        setIncomingPurchaseOrders(response.data);
        setShowIncomingPurchaseOrdersSpinner(false);
        console.log(response.data);
        setShowLoadingPage(false);
      } catch (error) {
        console.error("Error in fetching associated vendors", error);
        setShowIncomingPurchaseOrdersSpinner(false);
        setShowLoadingPage(false);
      }
    };

    fetchListingBySkuId(sku);
    fetchAnalytics(sku);
    fetchPacksAndComboDetails(sku);
    fetchAllSkuIds();
    fetchAssociatedVendors(sku);
    fetchIncomingInventoryBySkuId(sku);
  }, []);

  useEffect(() => {
    if (
      listing.sku_type == "SINGLE" &&
      listing.sku_id != listing.inventory_sku_id
    ) {
      const fetchParentListingBySkuId = async (sku_id: string) => {
        try {
          const response = await listingNetworkUtil.getListingBySkuId(sku_id);
          console.log(response.data);
          setParentListing(response.data);
        } catch (error) {
          console.error("Error in fetching listing :", error);
        }
      };

      fetchParentListingBySkuId(listing.inventory_sku_id);
    }
  }, [listing]);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleImageError = (imageKey: string) => {
    const updatedListing = { ...localListing };
    updatedListing[imageKey] = "http://tech.intellozene.com/Media/na.png";
    setLocalListing(updatedListing);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await listingNetworkUtil.updateListingBySkuId(
        listing.sku_id,
        localListing
      );
      dispatch(
        Listings__Action(
          ListingActionTypes.UPDATE_LISTING_DETAILS,
          [],
          listing.sku_id,
          [],
          localListing
        )
      );

      dispatch(
        TopListings__Action(
          TopListingActionTypes.UPDATE_TOP_LISTING_DETAILS,
          [],
          listing.sku_id,
          [],
          localListing
        )
      );

      console.log(response);
      toast.success("Successfully updated!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setEditMode(false);
      setListing(_.cloneDeep(localListing));
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      console.log(err.response && err.response.data);
      if (err.response && err.response.data && err.response.status == 422) {
        toast.error("Fields cannot be set empty!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log(name, value);
    setLocalListing({
      ...localListing,
      [name]: value,
    });
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setLocalListing({
      ...localListing,
      [name]: value,
    });
  };

  const handlePackDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const updatedPacksAndCombos = [...localPacksAndCombos];
    updatedPacksAndCombos[index] = {
      ...updatedPacksAndCombos[index],
      [name]: value,
    };
    setLocalPacksAndCombos(updatedPacksAndCombos);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleEditPackDetailsClick = () => {
    setPackDetailsEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setLocalListing(_.cloneDeep(listing));
  };

  const handleEditQuantityClick = () => {
    setInventoryEditMode(true);
  };

  const handleCancelQuantityClick = () => {
    setInventoryEditMode(false);
  };

  const handleAddPackDetailsClick = () => {
    setShowAddNewItem(true);
  };

  const handleCancelPackDetailsClick = () => {
    setPackDetailsEditMode(false);
    setLocalPacksAndCombos(packsAndCombos);
  };

  const deletePackMapping = (index: number) => {
    const updatedPacksAndCombos = [...localPacksAndCombos];
    updatedPacksAndCombos[index] = {
      ...updatedPacksAndCombos[index],
      ["marked_deleted"]: true,
    };
    setLocalPacksAndCombos(updatedPacksAndCombos);
  };

  const findEffectiveAvailableQuantity = (pAndC: PacksAndCombos[]): number => {
    var ans: number = 9007199254740992;
    pAndC.forEach((p) => {
      ans = Math.min(ans, round(p.listing.available_quantity / p.sku_count));
    });
    return ans;
  };

  const savePacksAndCombosMappingChanges = async () => {
    try {
      const response =
        await listingNetworkUtil.savePacksAndCombosMappingChanges(
          localPacksAndCombos,
          localListing.sku_id
        );
      console.log(response.data);
      setPacksAndCombos(response.data);
      setLocalPacksAndCombos(response.data);
      setPackDetailsEditMode(false);
      toast.success("Successfully saved!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in savePacksAndCombosMappingChanges :", error);
      toast.error("There was an error while saving the changes, try again!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };
  const handleCloseAddItem = () => {
    setShowAddNewItem(false);
  };
  const handleSkuFromDropdownSelected = (value: string) => {
    // Handle the selected value in the parent component
    console.log("Selected Value:", value);
    setNewItemSkuId(value);
  };

  const handleNewItemSkuCountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewItemSkuCount(parseInt(e.target.value));
  };

  const mapNewItem = async () => {
    if (newItemSkuId == null || newItemSkuId == "") {
      toast.error("SKU id cannot be NULL.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }
    if (newItemSkuCount <= 0) {
      toast.error("Error, Contribution count cannot be 0.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }
    try {
      const newItem: any = {
        pack_combo_sku_id: localListing.sku_id,
        inventory_sku_id: newItemSkuId,
        sku_count: newItemSkuCount,
      };
      const response = await listingNetworkUtil.mapNewItemToPack(
        newItem,
        localListing.sku_id
      );

      console.log(response);
      toast.success("Successfully added!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setPacksAndCombos([...packsAndCombos, response.data]);
      setLocalPacksAndCombos([...localPacksAndCombos, response.data]);
      setNewItemSkuCount(0);
      setNewItemSkuId("");
      handleCloseAddItem();
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      console.log(err.response && err.response.data);
      if (err.response && err.response.data && err.response.status == 400) {
        toast.error("Error, the sku you are trying to add is already mapped", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const getClassNameForNumberOfGrns = (grn_count: number) => {
    if (grn_count > 0) return "color-green";
    else return "color-red";
  };

  const onClickPackRow = (skuId: string) => {
    if (!packDetailsEditMode) window.open(skuId, "_blank");
  };

  const findAvgCostPrice = () => {
    if (associatedVendors.length == 0) return 0;
    const totalCostPrice: number = associatedVendors.reduce(
      (sum, av) => sum + av.cost_price,
      0
    );

    return Math.round(totalCostPrice / associatedVendors.length);
  };

  const navigateToVendorDetailsPage = (vendorId: number) => {
    if (!packDetailsEditMode)
      window.open(`/vendor-hub/all-vendors/${vendorId}/details`, "_blank");
  };

  return (
    <div className="overlay">
      <div className="sku-details">
        <Header />
        <div className="main-container">
          <div className="image-container">
            <div className="image-list">
              {[
                "img_hd",
                "img_white",
                "img_wdim",
                "img_link1",
                "img_link2",
              ].map((imageKey: string) => (
                <div
                  key={imageKey}
                  className={`image-placeholder ${
                    selectedImage === imageKey ? "active" : ""
                  }`}
                  onClick={() => handleImageClick(imageKey)}
                >
                  <img
                    src={localListing[imageKey].toString()}
                    alt={`Image ${imageKey}`}
                    onError={() => handleImageError(imageKey)}
                  />
                </div>
              ))}
            </div>

            <div className="zoomed-image">
              <img
                src={localListing[selectedImage].toString()}
                alt={`Image ${selectedImage}`}
                onError={() => handleImageError(selectedImage)}
              />
              <h5 className="mt-2">{selectedImage}</h5>
            </div>
          </div>
          <div className="details-container">
            <div className={`details-card ${editMode ? "editable" : ""}`}>
              <div className="details-first-row">
                <div className="sku-id">{localListing.sku_id}</div>
                <div>{localListing.sales_rank}</div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="description">
                  <input
                    type="text"
                    name="description"
                    value={localListing.description || ""}
                    onChange={handleChange}
                    disabled={!editMode}
                  />
                </div>

                <div className="grid-2-cells">
                  <div className="grid-item-details-card">
                    <label>Inventory SKU ID</label>
                    <input
                      type="text"
                      name="inventory_sku_id"
                      value={localListing.inventory_sku_id || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Category</label>
                    <input
                      type="text"
                      name="category"
                      value={localListing.category || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                </div>

                <div className="grid-4-cells">
                  <div className="grid-item-details-card">
                    <label>Ops Tag</label>
                    <input
                      type="text"
                      name="ops_tag"
                      value={localListing.ops_tag || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>Sales Tag</label>
                    <input
                      type="text"
                      name="sales_tag"
                      value={localListing.sales_tag || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>Advertisement Tag</label>
                    <input
                      type="text"
                      name="adv_tag"
                      value={localListing.adv_tag || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Bulk Transfer Price</label>
                    <input
                      type="text"
                      name="bulk_price"
                      value={localListing.bulk_price || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                </div>

                <div className="grid-4-cells">
                  <div className="grid-item-details-card">
                    <label>Sku Type</label>
                    <select
                      id="sku_type"
                      name="sku_type"
                      value={localListing.sku_type}
                      onChange={handleOptionChange}
                      disabled={!editMode}
                    >
                      <option value="SINGLE">SINGLE</option>
                      <option value="MULTI">MULTI</option>
                    </select>
                    {/* <input
                      type="text"
                      name="sku_type"
                      value={localListing.sku_type}
                      onChange={handleChange}
                      disabled={!editMode}
                    /> */}
                  </div>

                  <div className="grid-item-details-card">
                    <label>GST</label>
                    <input
                      type="text"
                      name="gst"
                      value={localListing.gst || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>HSN code</label>
                    <input
                      type="text"
                      name="hsn"
                      value={localListing.hsn || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>Box Size</label>
                    <input
                      type="text"
                      name="box_size"
                      value={localListing.box_size || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                </div>

                <div className="grid-4-cells">
                  <div className="grid-item-details-card">
                    <label>Actual weight</label>
                    <input
                      type="text"
                      name="actual_weight"
                      value={localListing.actual_weight || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Length</label>
                    <input
                      type="text"
                      name="length"
                      value={localListing.length || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>Width</label>
                    <input
                      type="text"
                      name="width"
                      value={localListing.width || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>Height</label>
                    <input
                      type="text"
                      name="height"
                      value={localListing.height || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                </div>

                <div className="details-button-container mt-2">
                  <button
                    type="button"
                    onClick={handleEditClick}
                    disabled={editMode}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelClick}
                    disabled={!editMode}
                  >
                    Cancel
                  </button>
                  {editMode && <button type="submit">Save</button>}
                </div>
              </form>
            </div>

            {listing.sku_type &&
              listing.sku_type == "SINGLE" &&
              listing.sku_id != listing.inventory_sku_id && (
                <div className="details-card">
                  <div className="details-first-row small">
                    <div>
                      You are currently viewing a child SKU. In order to update
                      inventory please make changes in the parent inventory sku
                      provided below :
                    </div>
                  </div>
                  {parentListing && (
                    <div className="packs-and-combos-container mt-4">
                      <div className="pack-and-combos-row mb-3">
                        <div className="image-placeholder-packs">
                          <img
                            src={parentListing.img_wdim}
                            alt={`Image`}
                            onError={() => handleImageError("img_wdim")}
                            onClick={() =>
                              onClickPackRow(parentListing.inventory_sku_id)
                            }
                          />
                        </div>
                        <div className="packs-info-container">
                          <div className="packs-info-sku-id">
                            {parentListing.sku_id}
                          </div>
                          <div className="packs-info-available-quantity">
                            <label>Available Quantity : </label>
                            <input
                              type="text"
                              name="description"
                              value={parentListing.available_quantity}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

            {listing.sku_type && listing.sku_type == "MULTI" && (
              <div className="details-card">
                <div className="details-first-row small">
                  <div>PACK DETAILS</div>
                  <div className="details-button-container">
                    <button
                      type="button"
                      onClick={handleAddPackDetailsClick}
                      disabled={packDetailsEditMode}
                    >
                      Add
                    </button>
                    {packsAndCombos.length > 0 && (
                      <button
                        type="button"
                        onClick={handleEditPackDetailsClick}
                        disabled={packDetailsEditMode}
                      >
                        Edit
                      </button>
                    )}
                    {packsAndCombos.length > 0 && (
                      <button
                        type="button"
                        onClick={handleCancelPackDetailsClick}
                        disabled={!packDetailsEditMode}
                      >
                        Cancel
                      </button>
                    )}
                    {packDetailsEditMode && (
                      <button
                        type="button"
                        onClick={savePacksAndCombosMappingChanges}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>

                {packsAndCombos.length > 0 && (
                  <div className="packs-and-combos-container mt-2">
                    {localPacksAndCombos.map((packAndCombo, index) => {
                      if (
                        packAndCombo.marked_deleted == null ||
                        packAndCombo.marked_deleted == false
                      )
                        return (
                          <div className="pack-and-combos-row mb-3">
                            <div className="image-placeholder-packs">
                              <img
                                src={packAndCombo.listing.img_wdim}
                                alt={`Image`}
                                onError={() => handleImageError("img_wdim")}
                                onClick={() =>
                                  onClickPackRow(packAndCombo.inventory_sku_id)
                                }
                              />
                            </div>
                            <div className="packs-info-container">
                              <div className="packs-info-sku-id">
                                {packAndCombo.listing.sku_id}
                              </div>
                              <div className="packs-info-available-quantity">
                                <label>Available Quantity : </label>
                                <input
                                  type="text"
                                  name="description"
                                  value={
                                    packAndCombo.listing.available_quantity
                                  }
                                  onChange={handleChange}
                                  disabled={true}
                                />
                              </div>
                              <div className="packs-info-unit-contribution">
                                <label>Units Contributed : </label>
                                <input
                                  type="text"
                                  name="sku_count"
                                  value={packAndCombo.sku_count}
                                  onChange={(e) =>
                                    handlePackDetailsChange(e, index)
                                  }
                                  disabled={!packDetailsEditMode}
                                />
                              </div>
                              {packDetailsEditMode && (
                                <div className="packs-info-delete-container">
                                  <Button
                                    variant="outline-danger"
                                    onClick={() => deletePackMapping(index)}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                    })}
                  </div>
                )}

                {packsAndCombos.length == 0 && (
                  <div className="packs-and-combos-not-available mt-2 mb-2">
                    No SKUs have been associated.
                  </div>
                )}
                {packsAndCombos.length > 0 && (
                  <div className="packs-and-combos-effective-available-quantity mt-2">
                    Effective available quantity :{" "}
                    {findEffectiveAvailableQuantity(packsAndCombos)}
                  </div>
                )}
              </div>
            )}

            <div className="details-card">
              <div className="details-first-row small">
                SALES & OUTWARD DATA
              </div>
              <div className="sales-grid">
                <div className="grid-item">
                  <div className="label">Last 7 Days Sales</div>
                  <div className="value">{localListing.last_7_days_sales}</div>
                </div>
                <div className="grid-item">
                  <div className="label">Last 30 Days Sales</div>
                  <div className="value">{localListing.last_30_days_sales}</div>
                </div>
                <div className="grid-item">
                  <div className="label">Last 90 Days Sales</div>
                  <div className="value">{localListing.last_90_days_sales}</div>
                </div>
                <div className="grid-item">
                  <div className="label">Last 365 Days Sales</div>
                  <div className="value">
                    {localListing.last_365_days_sales}
                  </div>
                </div>
              </div>

              <div className="sales-grid sub">
                <div className="grid-item">
                  <div className="label">Last 30 Days Outward</div>
                  <div className="value">{analytics.outward.last_30_days}</div>
                </div>
                <div className="grid-item">
                  <div className="label">Last 60 Days Outward</div>
                  <div className="value">{analytics.outward.last_60_days}</div>
                </div>
                <div className="grid-item">
                  <div className="label">Last 90 Days Outward</div>
                  <div className="value">{analytics.outward.last_90_days}</div>
                </div>
              </div>

              <div className="sales-grid sub">
                <div className="grid-item">
                  <div className="label">Last 30 Days Inward</div>
                  <div className="value">{analytics.inward.last_30_days}</div>
                </div>
                <div className="grid-item">
                  <div className="label">Last 60 Days Inward</div>
                  <div className="value">{analytics.inward.last_60_days}</div>
                </div>
                <div className="grid-item">
                  <div className="label">Last 90 Days Inward</div>
                  <div className="value">{analytics.inward.last_90_days}</div>
                </div>
              </div>
            </div>

            {/* {userDetailsFromStore.authorities.includes("AUTH_ADMIN") && ( */}
            <div className="details-card">
              <div className="details-first-row small">ASSOCIATED VENDORS</div>
              {associatedVendors.length > 0 ? (
                <div className="associated-vendors-bin-grid">
                  {associatedVendors.map((associatedVendor, index) => (
                    <div
                      className="associated-vendors-bin-item"
                      key={associatedVendor.id}
                      onClick={() =>
                        navigateToVendorDetailsPage(associatedVendor.vendor_id)
                      }
                    >
                      <div className="associated-vendors-vendor-id">
                        {associatedVendor.vendor_id}
                      </div>
                      <div className="associated-vendors-name">
                        {associatedVendor.vendor.vendor_name}
                      </div>
                      <div className="associated-vendors-cost-price">
                        Cost Price : {associatedVendor.cost_price}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="associated-vendors-not-available mt-2 mb-2">
                  No vendors have been associated.
                </div>
              )}
              {associatedVendors.length > 0 && (
                <div className="associated-vendors-avg-cost-price mt-2">
                  Avg Cost Price : {findAvgCostPrice()}
                </div>
              )}
            </div>
            {/*  )} */}

            {listing &&
              listing.sku_type == "SINGLE" &&
              listing.sku_id == listing.inventory_sku_id && (
                <ListingBinGrid listing={localListing} />
              )}

            <div className="details-card">
              <div className="details-first-row small">PURCHASE ORDERS</div>
              {incomingPurchaseOrders.length > 0 ? (
                <div className="listing-incoming-quantity-container mt-3">
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>PO Id</th>
                        <th>Vendor Id</th>
                        <th>PO Demand</th>
                        <th>PO Status</th>
                        <th>PO Release date</th>
                        <th>PO Expiry date</th>
                        <th>Was GRN done ?</th>
                        <th>Invoice Quantity</th>
                        <th>Accepted Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {incomingPurchaseOrders.map((po) => (
                        <tr>
                          <td className="po-id-link">
                            <a
                              href={`/vendor-hub/all-purchase-orders/details/${po.po_id}`}
                            >
                              {po.po_id}
                            </a>
                          </td>
                          <td className="po-id-link">
                            <a
                              href={`/vendor-hub/all-vendors/${po.vendor_id}/details`}
                            >
                              {po.vendor_id}
                            </a>
                          </td>
                          <td>{po.required_quantity}</td>
                          <td>
                            {po.status == "PENDING_PUBLISHED" && (
                              <Badge
                                className="mt-2 mr-2 ml-2 mb-2"
                                bg="primary"
                              >
                                PUBLISHED
                              </Badge>
                            )}
                            {po.status == "MARKED_MODIFICATION" && (
                              <Badge
                                className="mt-2 mr-2 ml-2 mb-2"
                                bg="warning"
                              >
                                MARKED FOR MODIFICATION
                              </Badge>
                            )}
                            {po.status == "MARKED_CANCELLED" && (
                              <Badge
                                className="mt-2 mr-2 ml-2 mb-2"
                                bg="warning"
                              >
                                MARKED CANCELLED
                              </Badge>
                            )}
                          </td>
                          <td>
                            {DateTimeUtils.formatDateOnly(po.date_published)}
                          </td>
                          <td>
                            {DateTimeUtils.formatDateOnly(po.expected_date)}
                          </td>
                          <td
                            className={getClassNameForNumberOfGrns(
                              po.total_grns
                            )}
                          >
                            {po.total_grns > 0 ? "YES" : "NO"}
                          </td>
                          <td>{po.total_invoice_quantity}</td>
                          <td>{po.total_invoice_quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : showIncomingPurchaseOrdersSpinner ? (
                <div className="associated-vendors-not-available mt-2 mb-2">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="associated-vendors-not-available mt-2 mb-2">
                  No purchase orders were found.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={showAddNewItem}
        onHide={handleCloseAddItem}
        // backdrop="static"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <p>Add new item to this pack</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container">
            <FilteredDropdown
              options={allSkuIds}
              onItemSelected={handleSkuFromDropdownSelected}
            />
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Contribution count for the pack</label>
                <input
                  type="number"
                  name="new_item_sku_count"
                  value={newItemSkuCount.toString()}
                  onChange={(e) => handleNewItemSkuCountChange(e)}
                />
              </div>
            </div>

            <div className="offcanvas-button-container">
              <button type="button" onClick={mapNewItem}>
                Save
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}
    </div>
  );
}
