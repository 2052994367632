import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/VendorPurchaseOrders.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  ConsignmentDetailed,
  BulkInvoiceDataItemParent,
  BulkVendorPurchaseOrdersDataItem,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import ColorCard from "./ColorCard";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  vendor_id: string;
  vendor_option: string;
}

function VendorPurchaseOrders() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const purchaseOrdersNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const { category, vendor_id, vendor_option } = useParams<RouteParams>();

  const [checkedCount, setCheckedCount] = useState<number>(0);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);

  const fetchAllPurchaseOrdersOfThisVendor = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean,
    requestBody: Record<string, any>
  ) => {
    requestBody["vendor_id"] = vendor_id;
    try {
      const response =
        await purchaseOrdersNetworkUtil.getPurchaseOrdersPaginated_v2(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          100,
          requestBody
        );

      setPurchaseOrders((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      // setCurrentConsignments((prevList) => [
      //   ...prevList,
      //   ...response.data["content"],
      // ]);

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in creating new Consignment : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    }
  };

  const handleCheckBoxChangeAtIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) setCheckedCount(checkedCount + 1);
    else {
      setCheckedCount(checkedCount - 1);
      if (checkedCount - 1 == 0 && isCheckedSelectAll) {
        setIsCheckedSelectAll(false);
      }
    }

    setPurchaseOrders((prevItems) =>
      prevItems.map((item, i) =>
        i == index ? { ...item, ["is_selected"]: !item.is_selected } : item
      )
    );
  };

  const handleCheckBoxChangeForAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedSelectAll(event.target.checked); // Update state based on the checkbox value
    if (event.target.checked) {
      setCheckedCount(purchaseOrders.length);
    } else {
      setCheckedCount(0);
    }

    setPurchaseOrders((prevItems) =>
      prevItems.map((item, i) => ({
        ...item,
        ["is_selected"]: event.target.checked,
      }))
    );
  };

  const getBulkVendorPurchaseOrdersData = async (poIds: any[]) => {
    try {
      const response =
        await purchaseOrdersNetworkUtil.getBulkVendorPurchaseOrdersData(poIds);

      setShowLoadingPage(false);
      return response.data;
    } catch (error) {
      console.error(
        "Error in getting bulk data for vendor purchase orders. : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);

      return [];
    }
  };

  const getPurchaseOrderStatus = (poStatus: string) => {
    if (poStatus == "PENDING") return "PENDING";
    else if (poStatus == "PENDING_PUBLISHED") return "PUBLISHED";
    else if (poStatus == "MARKED_MODIFICATION")
      return "MARKED FOR MODIFICATION";
    else if (poStatus == "MARKED_RECEIVING") return "MARKED RECIEVING";
    else if (poStatus == "MARKED_PARTIALLY_RECEIVED")
      return "MARKED PARTIALLY RECIEVED";
    else if (poStatus == "MARKED_COMPLETED") return "MARKED COMPLETED";
    else if (poStatus == "MARKED_CANCELLED") return "MARKED CANCELLED";
    return "NA";
  };

  const prepareAndDownloadBulkPurchaseOrderData = async () => {
    setShowLoadingPage(true);
    //check if invoice numbers were assigned for all
    const poIds = purchaseOrders
      .filter((item) => item.is_selected === true)
      .map((item) => item.po_id);

    const bulkPurchaseOrderDataItems: BulkVendorPurchaseOrdersDataItem[] =
      await getBulkVendorPurchaseOrdersData(poIds);

    const transformedItems = bulkPurchaseOrderDataItems.map((singleItem) => {
      return {
        po_id: singleItem.po_id,
        sku_id: singleItem.sku_id,
        ordered_quantity: singleItem.required_quantity,
        total_invoice_quantity: singleItem.invoice_quantity,
        total_accepted_quantity: singleItem.accepted_quantity,
        total_rejected_quantity: singleItem.rejected_quantity,
        total_shortage_quantity:
          singleItem.invoice_quantity -
          singleItem.accepted_quantity -
          singleItem.rejected_quantity,
        avg_price_excl_gst: singleItem.avg_price,
        vendor_id: singleItem.vendor_id,
        vendor_name: singleItem.vendor_name,
        po_status: singleItem.status,
        date_published: DateTimeUtils.formatDateOnly(singleItem.date_published),
        expected_date: DateTimeUtils.formatDateOnly(singleItem.expected_date),
      };
    });

    jsonexport(transformedItems, (err: Error | null, csv: string) => {
      if (err) {
        console.error(err);
        return;
      }
      // Create a Blob object with the CSV data
      const blob = new Blob([csv], { type: "text/csv" });
      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "bulk_vendor_purchase_order_data";

      // Simulate a click to trigger the download
      link.click();
    });
  };

  useEffect(() => {
    setShowLoadingPage(true);
    const requestBody: Record<string, any> = {};
    fetchAllPurchaseOrdersOfThisVendor("", 1, false, requestBody);
  }, []);

  const getClassNameForNumberOfGrns = (grn_count: number) => {
    if (grn_count > 0) return "color-green";
    else return "color-red";
  };

  const loadMore = () => {
    setShowBottomLoader(true);
    const requestBody: Record<string, any> = {};

    fetchAllPurchaseOrdersOfThisVendor(
      "",
      searchParams.current_page_num + 1,
      false,
      requestBody
    );
  };

  return (
    <div className="consignments-data-container">
      {purchaseOrders && (
        <div className="vendor-action-buttons-container">
          <div className="catalogues-search-bar-container">
            {checkedCount > 0 && (
              <div>
                <Button
                  className="mr-2 filters-button"
                  onClick={prepareAndDownloadBulkPurchaseOrderData}
                  variant="outline-primary"
                >
                  Download Purchase Orders Data
                </Button>
              </div>
            )}
            <div className="colors-overview-container">
              <ColorCard color="red" />
              <ColorCard color="orange" />
              {/* <ColorCard color="green" /> */}
            </div>
          </div>

          <div className="labels-master-data-table-container">
            <div className="all-labels-master-data-table">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>
                      {" "}
                      <input
                        type="checkbox"
                        checked={isCheckedSelectAll}
                        onChange={handleCheckBoxChangeForAll}
                      />
                    </th>
                    <th>PO Id</th>
                    <th>Vendor Id</th>
                    <th>Vendor Name</th>
                    <th>PO status</th>
                    <th>Sku Count</th>
                    <th>Total Quantity</th>
                    <th>Was GRN Done ?</th>
                    <th>Number Of Grns</th>
                    <th>Total Invoice Quantity</th>
                    <th>Total Accepted Quantity</th>
                    <th>Total Rejected Quantity</th>
                    <th>Sku Fill Rate %</th>
                    <th>Quantity Fill Rate %</th>
                    <th>Po Remarks</th>
                    <th>Date Created</th>
                    <th>Date Published</th>
                    <th>Expiry Date</th>
                    <th>Last Updated</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseOrders.map((po, index) => {
                    const dateStatus = DateTimeUtils.getDateStatus(
                      po.expected_date
                    );
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td>
                          <input
                            type="checkbox"
                            checked={po.is_selected}
                            onChange={(e) =>
                              handleCheckBoxChangeAtIndex(e, index)
                            }
                          />
                        </td>
                        <td className="po-id-link">
                          <a
                            href={`/vendor-hub/all-purchase-orders/details/${po.po_id}`}
                          >
                            {po.po_id}
                          </a>
                        </td>
                        <td className="po-id-link">
                          <a
                            href={`/vendor-hub/all-vendors/${po.vendor_id}/details`}
                          >
                            {po.vendor_id}
                          </a>
                        </td>
                        <td>{po.vendor_name}</td>
                        <td>{getPurchaseOrderStatus(po.status)}</td>
                        <td>{po.sku_count}</td>
                        <td>{po.total_quantity}</td>
                        <td
                          className={getClassNameForNumberOfGrns(
                            po.number_of_grns
                          )}
                        >
                          {po.number_of_grns > 0 ? "YES" : "NO"}
                        </td>
                        <td>{po.number_of_grns}</td>
                        <td>{po.total_invoice_quantity}</td>
                        <td>{po.total_accepted_quantity}</td>
                        <td>{po.total_rejected_quantity}</td>
                        <td>{po.sku_fill_rate}</td>
                        <td>{po.quantity_fill_rate}</td>
                        <td>{po.po_remarks}</td>
                        <td>{DateTimeUtils.formatDateTime(po.created_at)}</td>
                        <td>
                          {po.status == "PENDING"
                            ? ""
                            : DateTimeUtils.formatDateTime(po.date_published)}
                        </td>
                        <td
                          className={
                            `expiry-date ` +
                            (dateStatus < 0
                              ? "red"
                              : dateStatus <= 5
                              ? "orange"
                              : "green")
                          }
                        >
                          {DateTimeUtils.formatDateOnly(po.expected_date)}
                        </td>
                        <td>{DateTimeUtils.formatDateTime(po.updated_at)}</td>
                        <td>{po.created_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {!showBottomLoader && purchaseOrders.length == 0 && (
                <div className="nothing-found-container">
                  No Purchase Orders were found.
                </div>
              )}
              {searchParams.item_count > 0 &&
                searchParams.item_count < searchParams.expected_item_count &&
                !showBottomLoader && (
                  <div className="load-more-catalogues-button-container">
                    <Button
                      variant="outline-primary"
                      className="load-more-consignments-button"
                      onClick={() => loadMore()}
                    >
                      Load More..
                    </Button>
                  </div>
                )}
              {showBottomLoader && (
                <div className="bottom-progress-container">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}
    </div>
  );
}

export default VendorPurchaseOrders;
