import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./CSS/PurchaseOrdersVendorListingsView.css";
import {
  CostPriceTimelineItem,
  Listing,
  ListingPage,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import { Spinner, Table } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import { VisibilityOptions } from "./VendorDetails";

interface ListingImageProps {
  images: string[]; // Define the type of the images prop as an array of strings
}

const ListingImage: React.FC<ListingImageProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Use useMemo to find the first valid image
  const firstValidImageIndex = useMemo(() => {
    for (let i = 0; i < images.length; i++) {
      if (images[i] && images[i].trim().length > 10) {
        return i;
      }
    }
    return -1;
  }, [images]); // Recomputes only if images array changes

  // Set initial valid image index
  useEffect(() => {
    setCurrentImageIndex(firstValidImageIndex);
  }, [firstValidImageIndex]);

  // useCallback to prevent function recreation on every render
  const handleImageError = useCallback(() => {
    setCurrentImageIndex((prevIndex) => {
      let nextIndex = prevIndex + 1;
      while (nextIndex < images.length) {
        if (images[nextIndex] && images[nextIndex].trim().length >= 10) {
          return nextIndex;
        }
        nextIndex++;
      }
      return -1; // No valid image found
    });
  }, [images]); // Runs only if images array changes

  return (
    <div className="listings-img-container">
      {currentImageIndex === -1 ? (
        <img
          src="https://tech.intellozene.com/Media/na.png"
          alt="Product Image"
        />
      ) : (
        <img
          src={images[currentImageIndex]}
          alt="Product Image"
          onError={handleImageError}
        />
      )}
    </div>
  );
};

const ListingCard: React.FC<{
  vendorListing: VendorListing;
  handleCardClick: (listing: VendorListing) => void;
}> = ({ vendorListing, handleCardClick }) => {
  const images = useMemo(
    () => [
      vendorListing.listing.img_wdim,
      vendorListing.listing.img_hd,
      vendorListing.listing.img_white,
      vendorListing.listing.img_link1,
      vendorListing.listing.img_link2,
    ],
    [vendorListing]
  ); // Only recompute if vendorListing changes

  return (
    <div
      className="listings-view-card vendor-listing-card"
      onClick={() => handleCardClick(vendorListing)}
    >
      <div className="listings-img-container">
        <ListingImage images={images} />
      </div>
      <div className="listings-view-card-title">
        <h3
          className={`sku-id-title ${
            vendorListing.listing.sku_type == "MULTI"
              ? ""
              : vendorListing.listing.available_quantity === 0
              ? "not-available"
              : "available"
          }`}
        >
          {vendorListing.listing.sku_id}
        </h3>
      </div>
      <p>OPS Tag: {vendorListing.listing.ops_tag}</p>
      {vendorListing.listing.sku_type == "MULTI" ? (
        <p>MULTI</p>
      ) : (
        <p>Available Quantity: {vendorListing.listing.available_quantity}</p>
      )}

      <p>Cost Price : {vendorListing.cost_price.toString()}</p>

      <p className="last-modified">
        Modified At: {DateTimeUtils.formatDateTime(vendorListing.updated_at)}
      </p>
    </div>
  );
};

const ListingCardSmall: React.FC<{
  vendorListing: VendorListing;
  handleCardClick: (listing: VendorListing) => void;
}> = ({ vendorListing, handleCardClick }) => {
  const images = useMemo(
    () => [
      vendorListing.listing.img_wdim,
      vendorListing.listing.img_hd,
      vendorListing.listing.img_white,
      vendorListing.listing.img_link1,
      vendorListing.listing.img_link2,
    ],
    [vendorListing]
  ); // Only recompute if vendorListing changes

  return (
    <div
      className="vendor-listing-card-small"
      onClick={() => handleCardClick(vendorListing)}
    >
      <div className="vendor-listings-img-container-small">
        <ListingImage images={images} />
      </div>
      <div className="vendor-listings-view-card-title-small">
        <p
          className={`sku-id-title ${
            vendorListing.listing.sku_type == "MULTI"
              ? ""
              : vendorListing.listing.available_quantity === 0
              ? "not-available"
              : "available"
          }`}
        >
          {vendorListing.listing.sku_id}
        </p>
      </div>
      {vendorListing.listing.sku_type == "MULTI" ? (
        <p>MULTI</p>
      ) : (
        <p>Instock : {vendorListing.listing.available_quantity}</p>
      )}
      <p>Cost Price : {vendorListing.cost_price.toString()}</p>
    </div>
  );
};

type ListingsViewProps = {
  vendorListings: VendorListing[];
  onCardClick: (selectedListing: VendorListing) => void;
  selectedVisibilityOption: VisibilityOptions;
};

export default function PurchaseOrderVendorListingsView({
  vendorListings,
  onCardClick,
  selectedVisibilityOption,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handleCardClick = async (listing: VendorListing) => {
    onCardClick(listing);
  };

  return (
    <div>
      {vendorListings &&
        vendorListings.length > 0 &&
        selectedVisibilityOption == VisibilityOptions.GRID_LARGE && (
          <div className="vendor-listings-card-container">
            {vendorListings.map((vendorListing, index) => (
              <ListingCard
                key={index}
                vendorListing={vendorListing}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        )}

      {vendorListings &&
        vendorListings.length > 0 &&
        selectedVisibilityOption == VisibilityOptions.GRID_SMALL && (
          <div className="vendor-listings-card-container-small">
            {vendorListings.map((vendorListing, index) => (
              <ListingCardSmall
                key={index}
                vendorListing={vendorListing}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        )}

      {vendorListings &&
        vendorListings.length > 0 &&
        selectedVisibilityOption == VisibilityOptions.LIST_VIEW && (
          <div className="vendor-listings-table-container">
            <div className="vendor-skus-table-container">
              <div className="all-labels-master-data-table">
                <Table bordered hover size="sm" className="table-auto-width">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>SKU ID</th>
                      <th>Ops Tag</th>
                      <th>Available Quantity</th>
                      <th>Cost Price</th>
                      <th>Last Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorListings.map((vendorListing, index) => {
                      return (
                        <tr
                          className="catalogues-search-bar-row "
                          onClick={() => handleCardClick(vendorListing)}
                        >
                          <td>{index + 1}</td>
                          <td>{vendorListing.sku_id}</td>
                          <td>{vendorListing.listing.ops_tag}</td>
                          <td>{vendorListing.listing.available_quantity}</td>
                          <td>{vendorListing.cost_price}</td>

                          <td>
                            {DateTimeUtils.formatDateTime(
                              vendorListing.updated_at
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}

      {vendorListings && vendorListings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
