import React, { useEffect, useState } from "react";

import "./CSS/VendorsHome.css";

import { PurchaseOrder, SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PurchaseOrderPublished from "./PurchaseOrderPublished";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import { Spinner } from "react-bootstrap";
import PurchaseOrdersAddItem from "./PurchaseOrdersAddItem";

interface RouteParams {
  operation: string;
  po_id: string;
}

function PurchaseOrderDetailsSubHome() {
  const history = useHistory();
  const location = useLocation();
  const { operation, po_id } = useParams<RouteParams>();
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<PurchaseOrder | null>(null);
  const purchaseOrderNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handlePurchaseOrderChange = (purchaseOrder: PurchaseOrder) => {
    setCurrentPurchaseOrder(purchaseOrder);
  };

  useEffect(() => {
    const fetchPurchaseOrderByPOId = async (poId: string) => {
      console.log(poId);
      try {
        const response = await purchaseOrderNetworkUtil.getPurchaseOrderById(
          poId
        );
        console.log(response.data);
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    fetchPurchaseOrderByPOId(po_id);
  }, [po_id]);

  return (
    <div>
      {currentPurchaseOrder ? (
        currentPurchaseOrder.status == "PENDING" ||
        currentPurchaseOrder.status == "MARKED_MODIFICATION" ? (
          <PurchaseOrdersAddItem
            currentPurchaseOrder={currentPurchaseOrder}
            onPurchaseOrderChange={handlePurchaseOrderChange}
          />
        ) : (
          <PurchaseOrderPublished
            currentPurchaseOrder={currentPurchaseOrder}
            onPurchaseOrderChange={handlePurchaseOrderChange}
          />
        )
      ) : (
        <div className="loading-page">
          <Spinner animation="grow" />
        </div>
      )}
    </div>
  );
}

export default PurchaseOrderDetailsSubHome;
