import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/PurchaseOrdersAll.css";
import PurchaseOrdersSearchBar from "./PurchaseOrdersSearchBar";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import {
  ErrorMessageBody,
  PurchaseOrder,
  PurchaseOrderItemWithLisiting,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { Prev } from "react-bootstrap/esm/PageItem";
import ColorCard from "./ColorCard";
import PurchaseOrdersListingSearchBar from "./PurchaseOrdersListingSearchBar";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

function PurchaseOrdersListingView() {
  const history = useHistory();
  const location = useLocation();
  const purchaseOrdersNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [isShowImagesChecked, setIsShowImageChecked] = useState<boolean>(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);
  const [purchaseOrderItemsWithListing, setAllPurchaseOrderItemWithListing] =
    useState<PurchaseOrderItemWithLisiting[]>([]);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const getPurcahseOrdersWithLotWiseListingsAndSearchKeyword = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await purchaseOrdersNetworkUtil.getPurcahseOrdersWithLotWiseListingsAndSearchKeyword(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          100
        );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllPurchaseOrderItemWithListing((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in fetching lotwise listings :", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
      setShowBottomLoader(false);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowImageChecked(event.target.checked); // Update state based on the checkbox value
  };

  const handleSearch = (query: string) => {
    setAllPurchaseOrderItemWithListing([]);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    setShowBottomLoader(true);
    getPurcahseOrdersWithLotWiseListingsAndSearchKeyword(query, 1, true);
  };

  const loadMore = () => {
    setShowBottomLoader(true);
    getPurcahseOrdersWithLotWiseListingsAndSearchKeyword(
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  useEffect(() => {
    setShowLoadingPage(true);
    getPurcahseOrdersWithLotWiseListingsAndSearchKeyword("", 1, false);
  }, []);

  const getPurchaseOrderStatus = (poStatus: string) => {
    if (poStatus == "PENDING") return "PENDING";
    else if (poStatus == "PENDING_PUBLISHED") return "PUBLISHED";
    else if (poStatus == "MARKED_MODIFICATION")
      return "MARKED FOR MODIFICATION";
    else if (poStatus == "MARKED_RECEIVING") return "MARKED RECIEVING";
    else if (poStatus == "MARKED_PARTIALLY_RECEIVED")
      return "MARKED PARTIALLY RECIEVED";
    else if (poStatus == "MARKED_COMPLETED") return "MARKED COMPLETED";
    else if (poStatus == "MARKED_CANCELLED") return "MARKED CANCELLED";
    return "NA";
  };

  return (
    <div className="purchase-orders-container">
      <div className="purchase-orders-search-bar-container">
        <div className="colors-overview-container">
          {/* <ColorCard color="red" />
          <ColorCard color="orange" /> */}
          {/* <ColorCard color="green" /> */}
        </div>
        <PurchaseOrdersListingSearchBar onSearch={handleSearch} />
      </div>
      <div className=" purchase-orders-search-bar-container mb-2">
        <label>
          <input
            type="checkbox"
            checked={isShowImagesChecked}
            onChange={handleCheckboxChange}
          />
          Show Images ?
        </label>
      </div>

      <div className="vendor-purchase-orders-table-container">
        <div className="vendor-purchase-orders-table">
          <Table
            bordered
            hover
            size="sm"
            className="table-auto-width data-table"
          >
            <thead>
              <tr>
                {isShowImagesChecked && <th>Image</th>}
                <th>Sku Id</th>
                <th>Po Id</th>
                <th>PO status</th>
                <th>Vendor Id</th>
                <th>Vendor Name</th>
                <th>Order Placed Quantity</th>
                <th>Expiry Date</th>
                <th>Date Created</th>
                <th>Created by</th>
              </tr>
            </thead>
            <tbody>
              {purchaseOrderItemsWithListing.map((poItem, index) => {
                const dateStatus = DateTimeUtils.getDateStatus(
                  poItem.expected_date
                );
                return (
                  <tr className="purchase-orders-search-bar-row" key={index}>
                    {isShowImagesChecked && (
                      <td>
                        <img
                          style={{ height: "50px", width: "50px" }}
                          src={poItem.listing.img_wdim}
                          alt="Product Image"
                        />
                      </td>
                    )}
                    <td className="po-id-link">
                      <a href={`/listings/all/${poItem.sku_id}`}>
                        {poItem.sku_id}
                      </a>
                    </td>
                    <td className="po-id-link">
                      <a
                        href={`/vendor-hub/all-purchase-orders/details/${poItem.po_id}`}
                      >
                        {poItem.po_id}
                      </a>
                    </td>
                    <td>{getPurchaseOrderStatus(poItem.status)}</td>
                    <td className="po-id-link">
                      <a
                        href={`/vendor-hub/all-vendors/${poItem.vendor_id}/details`}
                      >
                        {poItem.vendor_id}
                      </a>
                    </td>
                    <td>{poItem.vendor_name}</td>
                    <td>{poItem.required_quantity}</td>
                    <td
                      className={
                        `expiry-date ` +
                        (dateStatus < 0
                          ? "red"
                          : dateStatus <= 5
                          ? "orange"
                          : "green")
                      }
                    >
                      {DateTimeUtils.formatDateOnly(poItem.expected_date)}
                    </td>
                    <td>{DateTimeUtils.formatDateTime(poItem.created_at)}</td>
                    <td>{poItem.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {!showBottomLoader && purchaseOrderItemsWithListing.length == 0 && (
            <div className="nothing-found-container">
              No Listings were found.
            </div>
          )}
          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count &&
            !showBottomLoader && (
              <div className="load-more-catalogues-button-container">
                <Button
                  variant="outline-primary"
                  className="load-more-consignments-button"
                  onClick={() => loadMore()}
                >
                  Load More..
                </Button>
              </div>
            )}
          {showBottomLoader && (
            <div className="bottom-progress-container">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          )}
        </div>
      </div>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}
    </div>
  );
}

export default PurchaseOrdersListingView;
