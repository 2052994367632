import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./CSS/PurchaseOrdersAddItem.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import {
  ErrorMessageBody,
  ImageHashMapState,
  PoItem,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemWithLisiting,
  PurchaseOrderItemWithLisitingAndGrnData,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";

import Header from "../Header";
import VendorListingsView from "./VendorListingsView";
import VendorsListingSearchBar from "./VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import PurchaseOrderPDF from "./PurchaseOrderPDF";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import PurchaseOrdersPublishedItemsView from "./PurchaseOrdersPublishedItemsView";
import PurchaseOrderPDF_v2 from "./PurchaseOrderPDF_v2";
import PurchaseOrderPDF_v3 from "./PurchaseOrderPDF_v3";
import PurchaseOrderPDF_v4 from "./PurchaseOrderPDF_v4";
import CatalogueNetworkUtils from "../Catalogues/NetworkUtils/CatalogueNetworkUtils";
import { ImageHashMapVendorPurchaseOrder__Action } from "../../state/action-creators";
import {
  ImageHashMapActionTypes,
  VendorPurchaseOrderImageHashMapActionTypes,
} from "../../state/action-types";
import { AppDispatch } from "../../state";
import { VisibilityOptions } from "./VendorDetails";
import {
  Grid3x3GapFill,
  GridFill,
  ListUl,
  PencilSquare,
} from "react-bootstrap-icons";
import CompanyPurchaseOrdersCustomDatePicker from "../CompanyPurchaseOrders/CompanyPurchaseOrdersCustomDatePicker";

interface RouteParams {
  po_id: string;
}

type PurchaseOrderPublishedProps = {
  currentPurchaseOrder: PurchaseOrder;
  onPurchaseOrderChange: (po: PurchaseOrder) => void;
};

function PurchaseOrderPublished({
  currentPurchaseOrder,
  onPurchaseOrderChange,
}: PurchaseOrderPublishedProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const chunkSize = 4;
  const { po_id } = useParams<RouteParams>();
  const dispatch: AppDispatch = useDispatch();

  const [purchaseOrderItemsWithListing, setPurchaseOrderItemsWithListing] =
    useState<PurchaseOrderItemWithLisitingAndGrnData[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [modifyPurchaseOrder, setModifyPurchaseOrder] = useState(false);
  const [cancelPurchaseOrder, setCancelPurchaseOrder] = useState(false);
  const [chunkedPoItems, setChunkedPoItems] = useState<
    PurchaseOrderItemWithLisitingAndGrnData[][]
  >([]);
  const [
    filteredPurchaseOrderItemsWithListing,
    setFilteredPurchaseOrderItemsWithListings,
  ] = useState<PurchaseOrderItemWithLisitingAndGrnData[]>([]);

  const [selectedVisibilityOption, setSelectedVisibilityOption] = useState(
    VisibilityOptions.LIST_VIEW
  );
  const [generatePDF, setGeneratePDF] = useState(false);
  const [downloadsReady, setDownloadsReady] = useState<boolean>(false);
  const [imageDownloadStart, setImageDownloadStart] = useState<boolean>(false);
  const [areImagesAlreadyDownloaded, setAreImagesAlreadyDownloaded] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModifyExpiryDateOption, setShowModifyExpiryDateOption] =
    useState<boolean>(false);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);
  const [imageSources, setImageSources] = useState<string[]>([]);
  const [currentVendor, setCurrentVendor] = useState<Vendor>({
    id: 0,
    vendor_name: "",
    vendor_address_line: "",
    vendor_city: "",
    vendor_state: "",
    vendor_postal_code: "",
    vendor_gstin: "",
    vendor_contact_number: "",
    created_by: "",
    modified_by: "",
    created_at: "",
    updated_at: "",
    specialties: [],
  });

  const history = useHistory();
  const location = useLocation();
  const catalogueNetworkUtils = new CatalogueNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const purchaseOrderNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    // }
  }, []);

  // useEffect(() => {
  //   const fetchPurchaseOrderByPOId = async (poId: string) => {
  //     console.log(poId);
  //     try {
  //       const response = await purchaseOrderNetworkUtil.getPurchaseOrderById(
  //         poId
  //       );
  //       console.log(response.data);
  //       onPurchaseOrderChange(response.data);
  //     } catch (error) {
  //       console.error(
  //         "Error in fetching vendor listings by vendor id :",
  //         error
  //       );
  //     }
  //   };

  //   fetchPurchaseOrderByPOId(po_id);
  // }, [po_id]);

  useEffect(() => {
    const fetchVendorByVendorId = async (vendor_id: string) => {
      try {
        const response = await vendorsNetworkUtil.getVendorByVendorId(
          vendor_id
        );
        console.log(response.data);
        setCurrentVendor(response.data);
      } catch (error) {
        console.error("Error in fetching vendor by vendor_id :", error);
      }
    };

    const getPurchaseOrderItemsWithListingByPurchaseOrderId = async (
      poId: number
    ) => {
      try {
        const response =
          await purchaseOrderNetworkUtil.getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId(
            poId.toString()
          );

        dispatch(
          ImageHashMapVendorPurchaseOrder__Action(
            VendorPurchaseOrderImageHashMapActionTypes.INIT_MAP,
            {
              key: "",
              value: 0,
              purchaseOrderItemWithListings: response.data,
            }
          )
        );
        setPurchaseOrderItemsWithListing(response.data);
        setFilteredPurchaseOrderItemsWithListings(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Error in getting purchase order items by poId : ",
          error
        );
        setIsLoading(false);
      }
    };

    if (currentPurchaseOrder) {
      if (
        currentPurchaseOrder.status == "PENDING" ||
        currentPurchaseOrder.status == "MARKED_MODIFICATION"
      ) {
        history.push(`/vendor-hub/all-purchase-orders/details/${po_id}`);
        return;
      }
      fetchVendorByVendorId(currentPurchaseOrder.vendor_id.toString());
      getPurchaseOrderItemsWithListingByPurchaseOrderId(
        currentPurchaseOrder.po_id
      );
    }
  }, [currentPurchaseOrder]);

  useEffect(() => {
    setChunkedPoItems(chunkArray(purchaseOrderItemsWithListing));
  }, [purchaseOrderItemsWithListing]);

  const chunkArray = (
    array: PurchaseOrderItemWithLisitingAndGrnData[]
  ): PurchaseOrderItemWithLisitingAndGrnData[][] => {
    return Array.from(
      { length: Math.ceil(array.length / chunkSize) },
      (_, index) =>
        array.slice(index * chunkSize, index * chunkSize + chunkSize)
    );
  };

  const handleSearch = (query: string) => {
    console.log("purchase order item searched ", query);
    if (query.length == 0)
      setFilteredPurchaseOrderItemsWithListings(
        _.cloneDeep(purchaseOrderItemsWithListing)
      );
    else
      setFilteredPurchaseOrderItemsWithListings(
        filterPurchaseOrderItemsWithListings(query)
      );
  };

  const filterPurchaseOrderItemsWithListings = (query: string) => {
    query = query.toLowerCase();
    return purchaseOrderItemsWithListing.filter((poItem) => {
      const skuMatches = poItem.listing.sku_id.toLowerCase().includes(query);

      const opsTagMatches = poItem.listing.ops_tag
        .toLowerCase()
        .includes(query);

      return skuMatches || opsTagMatches;
    });
  };

  const onCardClick = (
    currentListing: PurchaseOrderItemWithLisitingAndGrnData
  ) => {
    console.log("card clicked: ", currentListing);
    setModalShow(true);
    // setSelectedListing(currentListing);
  };

  const markForModification = async () => {
    try {
      const response = await purchaseOrderNetworkUtil.markPoForModification(
        po_id
      );
      onPurchaseOrderChange(response.data);
      // window.location.reload();
      console.log(response.data);
    } catch (error) {
      console.error(
        "Error in marking purchase order for modification : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const markForCancellation = async () => {
    try {
      const response = await purchaseOrderNetworkUtil.markPoForCancellation(
        po_id
      );
      onPurchaseOrderChange(response.data);
      toast.success("Cancelled PO successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setCancelPurchaseOrder(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for cancellation : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const downloadThemesAndImages = async () => {
    await downloadAllImages();
  };

  const downloadAllImages = async () => {
    if (!areImagesAlreadyDownloaded) {
      setImageDownloadStart(true);
      let i = 0;
      let url = "";
      for (i = 0; i < purchaseOrderItemsWithListing.length; i++) {
        const sku_id = purchaseOrderItemsWithListing[i].listing.sku_id;
        const img_index = hashMap.imageHashmap[sku_id];
        if (img_index > 4) {
          url = "images/not-available.jpg";
        } else {
          let images = [
            purchaseOrderItemsWithListing[i].listing.img_wdim,
            purchaseOrderItemsWithListing[i].listing.img_hd,
            purchaseOrderItemsWithListing[i].listing.img_white,
            purchaseOrderItemsWithListing[i].listing.img_link1,
            purchaseOrderItemsWithListing[i].listing.img_link2,
          ];
          url = images[img_index];
        }

        // let resourceToAdd = await catalogueNetworkUtils.downloadImageWithRetry(
        //   url.substring(url.lastIndexOf("/") + 1)
        // );

        let resourceToAdd =
          await catalogueNetworkUtils.downloadImageWithRetry_v2(url.trim());

        setImageSources((prevImageSources) => [
          ...prevImageSources,
          resourceToAdd.image,
        ]);
        // await delay(500);
      }
      setAreImagesAlreadyDownloaded(true);
    }
    // console.log(imageSources.length);
    // console.log(imageSources[0]);
    // console.log("code shift");
    setGeneratePDF(true);
    setDownloadsReady(true);
    setImageDownloadStart(false);
  };

  const handleClose = () => {
    setGeneratePDF(false);
  };

  const hashMap: ImageHashMapState = useAppSelector(
    (state) => state.purchase_order_image_hashmap_store
  );

  const handleDateTimeChange = (newDateTime: Date | null) => {
    setSelectedDateTime(newDateTime);
  };

  const submitExpiryDateForModification = async () => {
    if (currentPurchaseOrder) {
      const poIssueDate = new Date(currentPurchaseOrder.created_at);
      if (!selectedDateTime) {
        toast.error("Error! PO Expiry date not selected.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        setSelectedDateTime(null);
        return;
      } else if (selectedDateTime <= poIssueDate) {
        toast.error(
          "Error! PO Expiry date must be greater than po release date.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        setSelectedDateTime(null);
        return;
      }
      setShowModifyExpiryDateOption(false);
      setShowLoadingPage(true);
      try {
        const response =
          await purchaseOrderNetworkUtil.submitVendorPoExpiryDateForModification(
            currentPurchaseOrder.po_id.toString(),
            DateTimeUtils.formatDateTimeForDb(selectedDateTime),
            userDetailsFromStore.user_details.userId
          );

        onPurchaseOrderChange(response.data);
        setShowLoadingPage(false);
        setSelectedDateTime(null);
        // createDocumentMap(response.data);
      } catch (error) {
        setShowLoadingPage(false);
        console.error("Error in modifying expiry date : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else if (
          err.response &&
          err.response.data &&
          err.response.status == 500
        ) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
        setSelectedDateTime(null);
      }
    }
  };

  return (
    <div>
      <div className="vendor-details">
        <div className="main-container">
          {purchaseOrderItemsWithListing.length > 0 && currentPurchaseOrder ? (
            <div className="vendor-action-container">
              {currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Purchase Order Actions</h4>
                  </div>

                  <div className="vendor-action-buttons-container">
                    {/* {generatePDF && downloadsReady
                      ? currentVendor &&
                        currentPurchaseOrder &&
                        purchaseOrderItemsWithListing.length > 0 && (
                          <div>
                            <PDFDownloadLink
                              document={
                                <PurchaseOrderPDF_v3
                                  chunkedPoItems={chunkedPoItems}
                                  purchaseOrder={currentPurchaseOrder}
                                  vendor={currentVendor}
                                  totalItems={
                                    purchaseOrderItemsWithListing.length
                                  }
                                  totalQuantity={purchaseOrderItemsWithListing.reduce(
                                    (total, poItem) =>
                                      total + poItem.required_quantity,
                                    0
                                  )}
                                />
                              }
                              fileName={currentPurchaseOrder.po_id.toString()}
                            >
                              {({ blob, url, loading, error }) => (
                                <Button
                                  className="vendor-action-button"
                                  disabled={loading}
                                >
                                  {loading
                                    ? "Loading document..."
                                    : "Download Purchase order PDF"}
                                </Button>
                              )}
                            </PDFDownloadLink>
                          </div>
                        )
                      : currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                        currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                          <Button
                            variant="outline-primary"
                            className="vendor-action-button"
                            onClick={() => setGeneratePDF(true)}
                          >
                            Generate Purchase Order PDF
                          </Button>
                        )} */}

                    {currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                      currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                          onClick={() => {
                            downloadThemesAndImages();
                          }}
                        >
                          Generate Purchase Order PDF
                        </Button>
                      )}

                    {currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                      currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                          onClick={() => setModifyPurchaseOrder(true)}
                        >
                          Modify Purchase Order
                        </Button>
                      )}
                    {currentPurchaseOrder.status != "MARKED_COMPLETED" &&
                      currentPurchaseOrder.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                          onClick={() => setCancelPurchaseOrder(true)}
                        >
                          Cancel Purchase Order
                        </Button>
                      )}
                  </div>
                </div>
              )}
              {purchaseOrderItemsWithListing.length > 0 &&
                currentPurchaseOrder && (
                  <div className="vendor-basics vendors-info-card">
                    <div className="purchase-order-summary-table-container">
                      <Table
                        bordered
                        hover
                        size="sm"
                        className="table-auto-width"
                      >
                        <tbody>
                          <tr className="catalogues-search-bar-row">
                            <td colSpan={2} className="centered-heading">
                              PO Summary
                            </td>
                          </tr>
                          <tr className="catalogues-search-bar-row">
                            <td className="nowrap">Number of SKUs</td>
                            <td className="centered">
                              {currentPurchaseOrder.sku_count}
                            </td>
                          </tr>
                          <tr className="catalogues-search-bar-row">
                            <td className="nowrap">Total Demand</td>
                            <td className="centered">
                              {currentPurchaseOrder.total_quantity}
                            </td>
                          </tr>
                          <tr className="catalogues-search-bar-row">
                            <td className="nowrap">No. Of GRNs</td>
                            <td className="centered">
                              {currentPurchaseOrder.number_of_grns}
                            </td>
                          </tr>
                          <tr className="catalogues-search-bar-row">
                            <td className="nowrap">Total Invoice Quantity</td>
                            <td className="centered">
                              {currentPurchaseOrder.total_invoice_quantity}
                            </td>
                          </tr>
                          <tr className="catalogues-search-bar-row">
                            <td className="nowrap">Total Accepted Quantity</td>
                            <td className="centered">
                              {currentPurchaseOrder.total_accepted_quantity}
                            </td>
                          </tr>
                          <tr className="catalogues-search-bar-row">
                            <td className="nowrap">Total Rejected Quantity</td>
                            <td className="centered">
                              {currentPurchaseOrder.total_rejected_quantity}
                            </td>
                          </tr>
                          <tr className="catalogues-search-bar-row">
                            <td className="nowrap">SKU Fill Rate %</td>
                            <td className="centered">
                              {currentPurchaseOrder.sku_fill_rate} %
                            </td>
                          </tr>
                          <tr className="catalogues-search-bar-row">
                            <td className="nowrap">Quantity Fill Rate %</td>
                            <td className="centered">
                              {currentPurchaseOrder.quantity_fill_rate} %
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                )}
              {currentPurchaseOrder && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Purchase Order Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>PO status</label>
                      {currentPurchaseOrder.status == "PENDING_PUBLISHED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="primary">
                          PUBLISHED
                        </Badge>
                      )}
                      {currentPurchaseOrder.status == "MARKED_CANCELLED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="dark">
                          MARKED CANCELLED
                        </Badge>
                      )}
                      {currentPurchaseOrder.status == "MARKED_COMPLETED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="success">
                          MARKED COMPLETED
                        </Badge>
                      )}
                    </div>

                    <div className="vendor-detail-row">
                      <b>Purchase Order Id : </b>
                      {currentPurchaseOrder.po_id}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Expiry Date : </b>
                      {DateTimeUtils.formatDateOnly(
                        currentPurchaseOrder.expected_date
                      )}
                      <div className="button_part">
                        <PencilSquare
                          size={14}
                          color="#007bff"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowModifyExpiryDateOption(true)}
                        ></PencilSquare>
                      </div>
                    </div>
                    <div className="vendor-detail-row">
                      <b>Purchase Order Remarks : </b>
                      {currentPurchaseOrder.po_remarks}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Created By : </b>
                      {currentPurchaseOrder.created_by}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Created At : </b>
                      {DateTimeUtils.formatDateTime(
                        currentPurchaseOrder.created_at
                      )}
                    </div>
                  </div>
                </div>
              )}
              {currentPurchaseOrder && currentVendor && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Vendor Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="vendor-detail-row">
                      <b>Vendor Id : </b>
                      {currentVendor.id}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Vendor Name : </b>
                      {currentVendor.vendor_name}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Vendor Address : </b>
                      {currentVendor.vendor_address_line},{" "}
                      {currentVendor.vendor_city}, {currentVendor.vendor_state},{" "}
                      {currentVendor.vendor_postal_code}
                    </div>
                    <div className="vendor-detail-row">
                      <b>GSTIN : </b>
                      {currentVendor.vendor_gstin}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Contact Number : </b>
                      {currentVendor.vendor_contact_number}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="vendor-action-container"></div>
          )}
          {isLoading ? (
            <div className="vendor-skus-spinner-container">
              {" "}
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="vendor-skus-container-main">
              {purchaseOrderItemsWithListing.length > 0 &&
                currentPurchaseOrder && (
                  <div className="vendor-listings-search-bar-container">
                    <VendorsListingSearchBar
                      onSearch={handleSearch}
                    ></VendorsListingSearchBar>
                    <div className="visibility-options">
                      <div
                        className={`visibility-option ${
                          selectedVisibilityOption ==
                          VisibilityOptions.GRID_SMALL
                            ? "selected"
                            : "not-selected"
                        }`}
                        onClick={() =>
                          setSelectedVisibilityOption(
                            VisibilityOptions.GRID_SMALL
                          )
                        }
                      >
                        <Grid3x3GapFill
                          size={18}
                          color="#16275c"
                          style={{ cursor: "pointer" }}
                        ></Grid3x3GapFill>
                      </div>
                      <div
                        className={`visibility-option ${
                          selectedVisibilityOption ==
                          VisibilityOptions.GRID_LARGE
                            ? "selected"
                            : "not-selected"
                        }`}
                        onClick={() =>
                          setSelectedVisibilityOption(
                            VisibilityOptions.GRID_LARGE
                          )
                        }
                      >
                        <GridFill
                          size={18}
                          color="#16275c"
                          style={{ cursor: "pointer" }}
                        ></GridFill>
                      </div>
                      <div
                        className={`visibility-option ${
                          selectedVisibilityOption ==
                          VisibilityOptions.LIST_VIEW
                            ? "selected"
                            : "not-selected"
                        }`}
                        onClick={() =>
                          setSelectedVisibilityOption(
                            VisibilityOptions.LIST_VIEW
                          )
                        }
                      >
                        <ListUl
                          size={18}
                          color="#16275c"
                          style={{ cursor: "pointer" }}
                        ></ListUl>
                      </div>
                    </div>
                  </div>
                )}
              <div className="vendor-skus-container">
                <div className="listings-container purchase-order-vendor-listings-container">
                  <PurchaseOrdersPublishedItemsView
                    publishedListings={filteredPurchaseOrderItemsWithListing}
                    onCardClick={onCardClick}
                    selectedVisibilityOption={selectedVisibilityOption}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={modifyPurchaseOrder}
        onHide={() => setModifyPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modify Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure about modification? Please ensure to inform vendor about
          the updated purchase order.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModifyPurchaseOrder(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => markForModification()}>
            Understood
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cancelPurchaseOrder}
        onHide={() => setCancelPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this Purchase Order? Please note that
          purchase orders whose status is "Published" and against which no
          quantities were received can only be marked "Cancelled".
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCancelPurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForCancellation()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={imageDownloadStart}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <span className="image-being-downloaded-spinner">
            <Spinner animation="grow" className="mr-4" />
            Please wait while the images are being downloaded..
          </span>
        </Modal.Body>
      </Modal>

      <Modal
        show={generatePDF}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {generatePDF &&
            downloadsReady &&
            currentVendor &&
            currentPurchaseOrder &&
            purchaseOrderItemsWithListing.length > 0 && (
              <div className="mt-4">
                <PDFDownloadLink
                  document={
                    <PurchaseOrderPDF_v4
                      chunkedPoItems={chunkedPoItems}
                      purchaseOrder={currentPurchaseOrder}
                      vendor={currentVendor}
                      totalItems={purchaseOrderItemsWithListing.length}
                      totalQuantity={purchaseOrderItemsWithListing.reduce(
                        (total, poItem) => total + poItem.required_quantity,
                        0
                      )}
                      hashMap={hashMap}
                      imageSources={imageSources}
                      chunkSize={chunkSize}
                    />
                  }
                  fileName={
                    currentPurchaseOrder.po_id.toString() +
                    "_" +
                    currentVendor.vendor_name
                  }
                >
                  {({ blob, url, loading, error }) => (
                    <button
                      className="download-pdf-button"
                      type="button"
                      disabled={loading}
                    >
                      {loading
                        ? "Loading document..."
                        : "Download Purchase Order PDF"}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showModifyExpiryDateOption}
        onHide={() => setShowModifyExpiryDateOption(false)}
        className="alert-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Modify Expiry Date</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dropdown-container pt-3">
            <CompanyPurchaseOrdersCustomDatePicker
              displayText="Select PO expiry date : "
              buttonText="Set PO expiry date"
              onDateTimeChange={handleDateTimeChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-footer-button"
            variant="secondary"
            onClick={() => setShowModifyExpiryDateOption(false)}
          >
            Cancel
          </Button>
          <Button
            className="modal-footer-button"
            variant="primary"
            onClick={() => {
              // setShowModifyExpiryDateOption(false);
              // setShowLoadingPage(true);

              submitExpiryDateForModification();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}
    </div>
  );
}

export default PurchaseOrderPublished;
