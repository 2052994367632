import React, { useEffect, useState, ChangeEvent, KeyboardEvent } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/PurchaseOrderAllGrns.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  ConsignmentDetailed,
  FilterItem,
  Company,
  VendorPurchaseOrderGrnDetailed,
  Vendor,
  VendorPurchaseOrderGrnWithGrnListings,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download, Filter, FilterSquareFill } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import EscapeKeyListener from "../EscapeKeyListener";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";

function PurchaseOrderGrnsPendingInvoiceCollections() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const purchaseOrderNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);

  const [createNewConsignment, setCreateNewConsignment] = useState(false);
  const [currentGrns, setCurrentGrns] = useState<
    VendorPurchaseOrderGrnDetailed[]
  >([]);

  const [selectedGrn, setSelectedGrn] =
    useState<VendorPurchaseOrderGrnDetailed | null>(null);

  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);
  const [checkedCount, setCheckedCount] = useState<number>(0);

  const [vendorIdFilterList, setVendorIdFilterList] = useState<FilterItem[]>(
    []
  );
  const [vendorNameFilterList, setVendorNameFilterList] = useState<
    FilterItem[]
  >([]);

  const [grnIdSearchQueryLocal, setGrnIdSearchQueryLocal] =
    useState<string>("");
  const [grnIdSearchQueryGlobal, setGrnIdSearchQueryGlobal] =
    useState<string>("");

  const [poNumberSearchQueryLocal, setPoNumberSearchQueryLocal] =
    useState<string>("");
  const [poNumberSearchQueryGlobal, setPoNumberSearchQueryGlobal] =
    useState<string>("");

  const [
    vendorInvoiceNumberSearchQueryLocal,
    setVendorInvoiceNumberSearchQueryLocal,
  ] = useState<string>("");
  const [
    vendorInvoiceNumberSearchQueryGlobal,
    setVendorInvoiceNumberSearchQueryGlobal,
  ] = useState<string>("");

  const [vendorIdFilterCheckedCountLocal, setVendorIdFilterCheckedCountLocal] =
    useState<number>(0);
  const [
    vendorIdFilterCheckedCountGlobal,
    setVendorIdFilterCheckedCountGlobal,
  ] = useState<number>(0);
  const [
    vendorNameFilterCheckedCountLocal,
    setVendorNameFilterCheckedCountLocal,
  ] = useState<number>(0);
  const [
    vendorNameFilterCheckedCountGlobal,
    setVendorNameFilterCheckedCountGlobal,
  ] = useState<number>(0);

  const [isGrnIdFilterVisible, setIsGrnIdFilterVisible] = useState(false); // State for filter visibility
  const [isPoNumberFilterVisible, setIsPoNumberFilterVisible] = useState(false); // State for filter visibility
  const [showReceiveInvoiceCanvas, setShowReceiveInvoiceCanvas] =
    useState(false); // State for filter visibility
  const [
    isVendorInvoiceNumberFilterVisible,
    setIsVendorInvoiceNumberFilterVisible,
  ] = useState(false); // State for filter visibility

  const [isVendorIdFilterVisible, setIsVendorIdFilterVisible] = useState(false);
  const [isVendorNameFilterVisible, setIsVendorNameFilterVisible] =
    useState(false); // State for filter visibility

  const [finalVendorNamesList, setFinalVendorNamesList] = useState<string[]>(
    []
  );
  const [finalVendorIdsList, setFinalVendorIdsList] = useState<number[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);

  const acknowledgeInvoiceReceipt = async () => {
    if (selectedGrn) {
      try {
        const response =
          await purchaseOrderNetworkUtil.acknowledgeInvoiceReceipt(
            userDetailsFromStore.user_details.userId,
            selectedGrn.grn_id.toString()
          );

        // setAllVendorPurchaseOrderGrns(response.data)
        setShowLoadingPage(false);

        setCurrentGrns((prevGrns) =>
          prevGrns.map((grn) =>
            grn.grn_id === selectedGrn.grn_id
              ? {
                  ...grn,
                  grn_invoice_collection_status: "CLOSED",
                  grn_invoice_collection_by:
                    userDetailsFromStore.user_details.userId,
                }
              : grn
          )
        );

        toast.success("Success!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } catch (error) {
        console.error("Error in fetching GRN details : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
        setShowLoadingPage(false);
      }
    }
  };

  const prepareAndDownloadFile = (
    bulkGrnData: VendorPurchaseOrderGrnWithGrnListings[]
  ) => {
    try {
      // itemsToExport.push()
      const transformedItems = bulkGrnData.map((currentGrn) => {
        const localTis = currentGrn.grn_listings.map((singleItem, index) => {
          return {
            vendor_invoice_number: currentGrn.vendor_invoice_number,
            financial_year: "",
            ref_no: "",
            sku_id: singleItem.sku_id,
            title: "",
            invoice_quantity: singleItem.invoice_quantity,
            received_price: singleItem.received_price,
            shipping_and_other_charges_nil_tax_rate: "",
            shipping_and_other_charges: "",
            shipping_tax_rate: "",
            state: "",
            tax_rate: singleItem.tax_rate,
            taxable_amount:
              singleItem.invoice_quantity * singleItem.received_price,
            igst:
              Math.round(
                ((singleItem.invoice_quantity *
                  singleItem.received_price *
                  singleItem.tax_rate) /
                  100) *
                  100
              ) / 100,
            sgst_csgst:
              Math.round(
                ((singleItem.invoice_quantity *
                  singleItem.received_price *
                  singleItem.tax_rate) /
                  200) *
                  100
              ) / 100,
            net_purhcase:
              Math.round(
                ((singleItem.invoice_quantity *
                  singleItem.received_price *
                  (100 + singleItem.tax_rate)) /
                  100) *
                  100
              ) / 100,
            vendor_name: currentGrn.vendor.vendor_name,
            vendor_gstin: "",
            goods_received_date: DateTimeUtils.formatDateOnly(
              currentGrn.created_at
            ),
            actual_bill_date: "",
            frieght: "",
            hsn: "",
            grn_id: currentGrn.grn_id,
          };
        });

        localTis.push({
          vendor_invoice_number: "",
          financial_year: "",
          ref_no: "",
          sku_id: "",
          title: "",
          invoice_quantity: 0,
          received_price: 0,
          shipping_and_other_charges_nil_tax_rate: "",
          shipping_and_other_charges: "",
          shipping_tax_rate: "",
          state: "",
          tax_rate: 0,
          taxable_amount: 0,
          igst: 0,
          sgst_csgst: 0,
          net_purhcase: 0,
          vendor_name: currentGrn.vendor.vendor_name,
          vendor_gstin: "",
          goods_received_date: "",
          actual_bill_date: "",
          frieght: "",
          hsn: "",
          grn_id: 0,
        });

        return localTis;
      });
      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "bulk_invoice_data";

        // Simulate a click to trigger the download
        link.click();
      });
      setShowDownloadPage(false);
    } catch (error) {
      console.log("Printing error from downloadInvoiceData ");
      console.error("Error:", error);
      setShowDownloadPage(false);
    }
  };

  const acknowledgeInvoiceReceiptInBulk = async () => {
    const grnIds = currentGrns
      .filter((item) => item.is_selected === true)
      .map((item) => item.grn_id.toString());

    try {
      const response =
        await purchaseOrderNetworkUtil.acknowledgeInvoiceReceiptInBulk(
          userDetailsFromStore.user_details.userId,
          grnIds
        );

      // setAllVendorPurchaseOrderGrns(response.data)
      setShowLoadingPage(false);
      setShowDownloadPage(true);

      prepareAndDownloadFile(response.data);
      setCurrentGrns((prevGrns) =>
        prevGrns.map((grn) =>
          grnIds.includes(grn.grn_id.toString())
            ? {
                ...grn,
                grn_invoice_collection_status: "CLOSED",
                grn_invoice_collection_by:
                  userDetailsFromStore.user_details.userId,
              }
            : grn
        )
      );

      toast.success("Success!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in fetching GRN details : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
    }
  };

  const fetchAllPendingForInvoiceCollectionPaginated = async (
    pageNumber: number,
    requestBody: Record<string, any>
  ) => {
    try {
      const response =
        await purchaseOrderNetworkUtil.fetchAllPendingForInvoiceCollectionPaginated(
          pageNumber,
          100,
          requestBody
        );

      // setCurrentConsignments(response.data);
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentGrns((prevList) => [...prevList, ...response.data["content"]]);

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in fetching GRNs : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    }
  };

  const handleGrnIdKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setGrnIdSearchQueryGlobal(grnIdSearchQueryLocal);
      handleSearchWithGrnId(grnIdSearchQueryLocal);
      setIsGrnIdFilterVisible(false);
    }
  };

  const handleVendorInvoiceNumberKeyPress = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setVendorInvoiceNumberSearchQueryGlobal(
        vendorInvoiceNumberSearchQueryLocal
      );
      handleSearchWithVendorInvoiceNumber(vendorInvoiceNumberSearchQueryLocal);
      setIsVendorInvoiceNumberFilterVisible(false);
    }
  };

  const handlePoNumberKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setPoNumberSearchQueryGlobal(poNumberSearchQueryLocal);
      handleSearchWithPoNumber(poNumberSearchQueryLocal);
      setIsPoNumberFilterVisible(false);
    }
  };

  const handleCheckBoxChangeAtVendorNameFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setVendorNameFilterCheckedCountLocal(
        vendorNameFilterCheckedCountLocal + 1
      );
    else {
      setVendorNameFilterCheckedCountGlobal(
        vendorNameFilterCheckedCountLocal - 1
      );
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setVendorNameFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const handleCheckBoxChangeAtVendorIdFilterIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked)
      setVendorIdFilterCheckedCountLocal(vendorIdFilterCheckedCountLocal + 1);
    else {
      setVendorIdFilterCheckedCountLocal(vendorIdFilterCheckedCountLocal - 1);
      // if (checkedCount - 1 == 0 && isCheckedSelectAll) {
      //   setIsCheckedSelectAll(false);
      // }
    }

    setVendorIdFilterList((prevItems) =>
      prevItems.map((item, i) =>
        i == index
          ? { ...item, ["is_selected_local"]: !item.is_selected_local }
          : item
      )
    );
  };

  const handleSearchWithVendorInvoiceNumber = (query: string) => {
    const requestBody: Record<string, any> = {};
    requestBody["grnId"] = grnIdSearchQueryGlobal;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorInvoiceNumber"] = query;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = finalVendorNamesList;

    setCurrentGrns([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllPendingForInvoiceCollectionPaginated(1, requestBody);
  };

  const handleGrnIdInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGrnIdSearchQueryLocal(e.target.value);
  };

  const handleVendorInvoiceNumberInputChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setVendorInvoiceNumberSearchQueryLocal(e.target.value);
  };

  const handlePoNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPoNumberSearchQueryLocal(e.target.value);
  };

  const handleSearchWithGrnId = (query: string) => {
    const requestBody: Record<string, any> = {};
    requestBody["grnId"] = query;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorInvoiceNumber"] = vendorInvoiceNumberSearchQueryGlobal;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = finalVendorNamesList;

    setCurrentGrns([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllPendingForInvoiceCollectionPaginated(1, requestBody);
  };

  const handleSearchWithPoNumber = (query: string) => {
    const requestBody: Record<string, any> = {};
    requestBody["grnId"] = grnIdSearchQueryGlobal;
    requestBody["poNumber"] = query;
    requestBody["vendorInvoiceNumber"] = vendorInvoiceNumberSearchQueryGlobal;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = finalVendorNamesList;

    setCurrentGrns([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllPendingForInvoiceCollectionPaginated(1, requestBody);
  };

  const handleSearchWithVendorIdFilters = (
    query: string,
    vendorIds: number[]
  ) => {
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["grnId"] = grnIdSearchQueryGlobal;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorInvoiceNumber"] = vendorInvoiceNumberSearchQueryGlobal;
    requestBody["vendorInvoiceNumber"] = vendorInvoiceNumberSearchQueryGlobal;
    requestBody["vendorIds"] = vendorIds;
    requestBody["vendorNames"] = finalVendorNamesList;

    setCurrentGrns([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllPendingForInvoiceCollectionPaginated(1, requestBody);
  };

  const handleSearchWithVendorNameFilters = (
    query: string,
    localVendorNames: string[]
  ) => {
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["grnId"] = grnIdSearchQueryGlobal;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorInvoiceNumber"] = vendorInvoiceNumberSearchQueryGlobal;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = localVendorNames;

    setCurrentGrns([]);
    setShowBottomLoader(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });

    fetchAllPendingForInvoiceCollectionPaginated(1, requestBody);
  };

  const convertVendorsToVendorIdFilterList = (localVendors: Vendor[]) => {
    return localVendors.map((item) => ({
      filter_id: item.id,
      filter_string: item.id.toString(),
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  const convertVendorsToVendorNamesFilterList = (localVendors: Vendor[]) => {
    return localVendors.map((item) => ({
      filter_id: 0,
      filter_string: item.vendor_name,
      is_selected_local: false,
      is_selected_global: false,
    }));
  };

  const clearVendorInvoiceNumberFilter = () => {
    setIsVendorInvoiceNumberFilterVisible(false);
    setVendorInvoiceNumberSearchQueryLocal(
      vendorInvoiceNumberSearchQueryGlobal
    );
  };

  const clearGrnIdFilter = () => {
    setIsGrnIdFilterVisible(false);
    setGrnIdSearchQueryLocal(grnIdSearchQueryGlobal);
  };

  const clearPoNumberFilter = () => {
    setIsPoNumberFilterVisible(false);
    setPoNumberSearchQueryLocal(poNumberSearchQueryGlobal);
  };

  const clearVendorIdsFilter = () => {
    setVendorIdFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsVendorIdFilterVisible(false); // Close the dropdown
    setVendorIdFilterCheckedCountLocal(vendorIdFilterCheckedCountGlobal);
  };

  const clearVendorNamesFilter = () => {
    setVendorNameFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setIsVendorNameFilterVisible(false); // Close the dropdown
    setVendorNameFilterCheckedCountLocal(vendorNameFilterCheckedCountGlobal);
  };

  const resetGrnIdFilter = () => {
    setIsGrnIdFilterVisible(false);
    setGrnIdSearchQueryLocal("");
    setGrnIdSearchQueryGlobal("");
    handleSearchWithGrnId("");
  };

  const resetVendorInvoiceNumberFilter = () => {
    setIsVendorInvoiceNumberFilterVisible(false);
    setVendorInvoiceNumberSearchQueryLocal("");
    setVendorInvoiceNumberSearchQueryGlobal("");
    handleSearchWithVendorInvoiceNumber("");
  };

  const resetPoNumberFilter = () => {
    setIsPoNumberFilterVisible(false);
    setPoNumberSearchQueryLocal("");
    setPoNumberSearchQueryGlobal("");
    handleSearchWithPoNumber("");
  };

  const resetVendorIdFilter = () => {
    setIsVendorIdFilterVisible(false);
    setVendorIdFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setVendorIdFilterCheckedCountLocal(0);
    setVendorIdFilterCheckedCountGlobal(0);
    setFinalVendorIdsList([]);
    handleSearchWithVendorIdFilters(searchParams.search_string, []);
  };

  const resetVendorNameFilter = () => {
    setIsVendorNameFilterVisible(false);
    setVendorNameFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_local: false, // Update `is_selected_local` with the value of `is_selected_global`
        is_selected_global: false, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    setVendorNameFilterCheckedCountGlobal(0);
    setVendorNameFilterCheckedCountLocal(0);
    setFinalVendorNamesList([]);
    handleSearchWithVendorNameFilters(searchParams.search_string, []);
  };

  const applyGrnIdFilter = () => {
    setGrnIdSearchQueryGlobal(grnIdSearchQueryLocal);
    handleSearchWithGrnId(grnIdSearchQueryLocal);
    setIsGrnIdFilterVisible(false);
  };

  const applyVendorInvoiceNumberFilter = () => {
    setVendorInvoiceNumberSearchQueryGlobal(
      vendorInvoiceNumberSearchQueryLocal
    );
    handleSearchWithVendorInvoiceNumber(vendorInvoiceNumberSearchQueryLocal);
    setIsVendorInvoiceNumberFilterVisible(false);
  };

  const applyPoNumberFilter = () => {
    setPoNumberSearchQueryGlobal(grnIdSearchQueryLocal);
    handleSearchWithPoNumber(grnIdSearchQueryLocal);
    setIsPoNumberFilterVisible(false);
  };

  const applyVendorIdFilter = () => {
    const vendorIds = vendorIdFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_id);
    setFinalVendorIdsList(vendorIds);

    setVendorIdFilterCheckedCountGlobal(vendorIdFilterCheckedCountLocal);
    setVendorIdFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithVendorIdFilters(searchParams.search_string, vendorIds);
    setIsVendorIdFilterVisible(false); // Close the dropdown
  };

  const applyVendorNamesFilter = () => {
    const vendorNames = vendorNameFilterList
      .filter((item) => item.is_selected_local)
      .map((item) => item.filter_string);

    setFinalVendorNamesList(vendorNames);
    setVendorNameFilterCheckedCountGlobal(vendorNameFilterCheckedCountLocal);
    setVendorNameFilterList((prevItems) =>
      prevItems.map((item) => ({
        ...item, // Spread the existing properties of the object
        is_selected_global: item.is_selected_local, // Update `is_selected_local` with the value of `is_selected_global`
      }))
    );

    handleSearchWithVendorNameFilters(searchParams.search_string, vendorNames);
    setIsVendorNameFilterVisible(false); // Close the dropdown
  };

  const handleCheckBoxChangeForAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedSelectAll(event.target.checked); // Update state based on the checkbox value
    if (event.target.checked) {
      setCheckedCount(currentGrns.length);
    } else {
      setCheckedCount(0);
    }

    setCurrentGrns((prevItems) =>
      prevItems.map((item, i) => ({
        ...item,
        ["is_selected"]: event.target.checked,
      }))
    );
  };

  const handleCheckBoxChangeAtIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) setCheckedCount(checkedCount + 1);
    else {
      setCheckedCount(checkedCount - 1);
      if (checkedCount - 1 == 0 && isCheckedSelectAll) {
        setIsCheckedSelectAll(false);
      }
    }

    setCurrentGrns((prevItems) =>
      prevItems.map((item, i) =>
        i == index ? { ...item, ["is_selected"]: !item.is_selected } : item
      )
    );
  };

  const getClassNameForGRN = (grn_status: string) => {
    if (grn_status == "OPEN") return "color-green";
    else if (grn_status == "CLOSED") return "color-pink";
    return "";
  };

  const getClassNameForInvoiceNumberStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    else if (invoice_number_status == "ASSIGNED") return "color-green";
    return "";
  };
  const getClassNameForInvoiceUploadStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-UPLOADED") return "color-red";
    else if (invoice_number_status == "UPLOADED") return "color-green";
    return "";
  };

  const loadMore = () => {
    setShowBottomLoader(true);
    const requestBody: Record<string, any> = {};
    // requestBody["expiryDates"] = finalExpiryDatesList;
    // requestBody["poStatuses"] = finalPoStatusList;
    // requestBody["poTypes"] = finalPOTypesList;
    requestBody["grnId"] = grnIdSearchQueryGlobal;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorInvoiceNumber"] = vendorInvoiceNumberSearchQueryGlobal;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = finalVendorNamesList;
    fetchAllPendingForInvoiceCollectionPaginated(
      searchParams.current_page_num + 1,
      requestBody
    );

    // setSearchParams((prev) => {
    //   return {
    //     ...prev,
    //     current_page_num: prev.current_page_num + 1,
    //   };
    // });
  };

  useEffect(() => {
    const fetchAllVendors = async () => {
      try {
        const response = await vendorsNetworkUtil.getAllVendors();
        setVendorIdFilterList(
          convertVendorsToVendorIdFilterList(response.data)
        );
        setVendorNameFilterList(
          convertVendorsToVendorNamesFilterList(response.data)
        );
      } catch (error) {
        console.error("Error in fetching all active companies", error);
      }
    };

    setShowLoadingPage(true);
    fetchAllVendors();
    // fetchAllUniqueDeliveryLocations();
    const requestBody: Record<string, any> = {};
    requestBody["grnId"] = grnIdSearchQueryGlobal;
    requestBody["poNumber"] = poNumberSearchQueryGlobal;
    requestBody["vendorInvoiceNumber"] = vendorInvoiceNumberSearchQueryGlobal;
    requestBody["vendorIds"] = finalVendorIdsList;
    requestBody["vendorNames"] = finalVendorNamesList;
    fetchAllPendingForInvoiceCollectionPaginated(1, requestBody);
  }, []);

  const verifyDataForInvoiceRecieval = () => {
    if (
      currentGrns.filter((item, ind) => {
        return (
          item.is_selected && item.grn_invoice_collection_status == "CLOSED"
        );
      }).length == 0
    ) {
      setShowReceiveInvoiceCanvas(true);
    } else {
      toast.error(
        "Invoice Collection status of one of the selected invoices has already been closed."
      );
    }
  };

  return (
    <div className="consignments-data-container">
      {currentGrns && (
        <div className="vendor-action-buttons-container">
          <div className="buttons-and-search-results-container pb-2 pt-2">
            <div className="search-results-count-container">
              Showing {searchParams.item_count} of{" "}
              {searchParams.expected_item_count} Invoices(s).
            </div>

            {checkedCount > 0 && (
              <div className="mr-1">
                <Button
                  className="filters-button"
                  // onClick={prepareAndDownloadPendencyPDF}
                  onClick={() => {
                    if (
                      userDetailsFromStore.authorities.includes("AUTH_ACCOUNTS")
                    ) {
                      // setSelectedGrn(grn);
                      // setShowReceiveInvoiceCanvas(true);
                      verifyDataForInvoiceRecieval();
                    } else {
                      toast.error(
                        "Only Accounts team can acknowledge receipt of physical invoices."
                      );
                      return;
                    }
                  }}
                  variant="outline-primary"
                >
                  Mark As Recieved In Bulk
                </Button>
              </div>
            )}
          </div>

          <div className="labels-master-data-table-container">
            <div className="all-labels-master-data-table">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>
                      {" "}
                      <input
                        type="checkbox"
                        checked={isCheckedSelectAll}
                        onChange={handleCheckBoxChangeForAll}
                      />
                    </th>
                    <th>
                      GRN Id
                      {grnIdSearchQueryGlobal.length == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() => setIsGrnIdFilterVisible(true)}
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() => setIsGrnIdFilterVisible(true)}
                        ></FilterSquareFill>
                      )}
                      {isGrnIdFilterVisible && (
                        <EscapeKeyListener onEscape={clearGrnIdFilter}>
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main ml-2 mr-2">
                              <input
                                type="text"
                                placeholder="Search GRN Id..."
                                value={grnIdSearchQueryLocal}
                                onChange={handleGrnIdInputChange}
                                onKeyPress={handleGrnIdKeyPress}
                              />
                            </div>
                            <div className="filter-buttons-section mt-2 ml-2 mr-2">
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetGrnIdFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearGrnIdFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={grnIdSearchQueryLocal.length == 0}
                                onClick={applyGrnIdFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>
                      PO Number
                      {poNumberSearchQueryGlobal.length == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() => setIsPoNumberFilterVisible(true)}
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() => setIsPoNumberFilterVisible(true)}
                        ></FilterSquareFill>
                      )}
                      {isPoNumberFilterVisible && (
                        <EscapeKeyListener onEscape={clearPoNumberFilter}>
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main ml-2 mr-2">
                              <input
                                type="text"
                                placeholder="Search PO Number..."
                                value={poNumberSearchQueryLocal}
                                onChange={handlePoNumberInputChange}
                                onKeyPress={handlePoNumberKeyPress}
                              />
                            </div>
                            <div className="filter-buttons-section mt-2 ml-2 mr-2">
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetPoNumberFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearPoNumberFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={poNumberSearchQueryLocal.length == 0}
                                onClick={applyPoNumberFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>GRN Status</th>
                    <th>GRN Audit Status</th>
                    <th>Invoice Collection Status</th>
                    <th>GRN SKU Count</th>
                    <th>GRN Invoice Quantity</th>
                    <th>GRN Accepted Quantity</th>
                    <th>GRN Rejected Quantity</th>
                    <th>GRN Shortage Quantity</th>

                    <th>
                      Vendor Invoice Number
                      {vendorInvoiceNumberSearchQueryLocal.length == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() =>
                            setIsVendorInvoiceNumberFilterVisible(true)
                          }
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() =>
                            setIsVendorInvoiceNumberFilterVisible(true)
                          }
                        ></FilterSquareFill>
                      )}
                      {isVendorInvoiceNumberFilterVisible && (
                        <EscapeKeyListener
                          onEscape={clearVendorInvoiceNumberFilter}
                        >
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main ml-2 mr-2">
                              <input
                                type="text"
                                placeholder="Search Vendor Invoice Number..."
                                value={vendorInvoiceNumberSearchQueryLocal}
                                onChange={handleVendorInvoiceNumberInputChange}
                                onKeyPress={handleVendorInvoiceNumberKeyPress}
                              />
                            </div>
                            <div className="filter-buttons-section mt-2 ml-2 mr-2">
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetVendorInvoiceNumberFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearVendorInvoiceNumberFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={
                                  vendorInvoiceNumberSearchQueryLocal.length ==
                                  0
                                }
                                onClick={applyVendorInvoiceNumberFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>
                      Vendor Id
                      {vendorIdFilterCheckedCountGlobal == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() => setIsVendorIdFilterVisible(true)}
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() => setIsVendorIdFilterVisible(true)}
                        ></FilterSquareFill>
                      )}
                      {isVendorIdFilterVisible && (
                        <EscapeKeyListener onEscape={clearVendorIdsFilter}>
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main">
                              {vendorIdFilterList.map(
                                (filterListItem, index) => (
                                  <div key={index} className="filter-item">
                                    <input
                                      className="filter-check-box mr-1"
                                      type="checkbox"
                                      checked={filterListItem.is_selected_local}
                                      onChange={(e) =>
                                        handleCheckBoxChangeAtVendorIdFilterIndex(
                                          e,
                                          index
                                        )
                                      }
                                    />
                                    {filterListItem.filter_string}
                                  </div>
                                )
                              )}
                            </div>
                            <div className="filter-buttons-section">
                              {/* {poTypeFilterCheckedCount > 0} */}
                              {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetVendorIdFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearVendorIdsFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={vendorIdFilterCheckedCountLocal == 0}
                                onClick={applyVendorIdFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>
                    <th>
                      Vendor Name
                      {vendorNameFilterCheckedCountGlobal == 0 ? (
                        <Filter
                          className="filter-option ml-1"
                          onClick={() => setIsVendorNameFilterVisible(true)}
                        ></Filter>
                      ) : (
                        <FilterSquareFill
                          className="filter-option ml-1"
                          style={{ color: "green" }}
                          onClick={() => setIsVendorNameFilterVisible(true)}
                        ></FilterSquareFill>
                      )}
                      {isVendorNameFilterVisible && (
                        <EscapeKeyListener onEscape={clearVendorNamesFilter}>
                          <div className="filter-dropdown">
                            <div className="empty-space"></div>
                            <div className="filter-list-main">
                              {vendorNameFilterList.map(
                                (filterListItem, index) => (
                                  <div key={index} className="filter-item">
                                    <input
                                      className="filter-check-box mr-1"
                                      type="checkbox"
                                      checked={filterListItem.is_selected_local}
                                      onChange={(e) =>
                                        handleCheckBoxChangeAtVendorNameFilterIndex(
                                          e,
                                          index
                                        )
                                      }
                                    />
                                    {filterListItem.filter_string}
                                  </div>
                                )
                              )}
                            </div>
                            <div className="filter-buttons-section">
                              {/* {poTypeFilterCheckedCount > 0} */}
                              {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={resetVendorNameFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="filters-button"
                                onClick={clearVendorNamesFilter}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="filters-button"
                                disabled={
                                  vendorNameFilterCheckedCountLocal == 0
                                }
                                onClick={applyVendorNamesFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </EscapeKeyListener>
                      )}
                    </th>

                    <th>GRN Audited by</th>
                    <th>Invoice Collection by</th>
                    <th>GRN Opened by</th>
                    <th>GRN Opened at</th>
                  </tr>
                </thead>
                <tbody>
                  {currentGrns.map((grn, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td>
                          <input
                            type="checkbox"
                            checked={grn.is_selected}
                            onChange={(e) =>
                              handleCheckBoxChangeAtIndex(e, index)
                            }
                          />
                        </td>
                        <td className="po-id-link">
                          <a
                            href={`/vendor-hub/all-purchase-orders/grn/details/${grn.grn_id}`}
                          >
                            {grn.grn_id}
                          </a>
                        </td>
                        <td className="po-id-link">
                          <a
                            href={`/vendor-hub/all-purchase-orders/details/${grn.po_id}`}
                          >
                            {grn.po_id}
                          </a>
                        </td>
                        <td className={getClassNameForGRN(grn.grn_status)}>
                          {grn.grn_status}
                        </td>
                        <td
                          className={getClassNameForGRN(grn.grn_audit_status)}
                        >
                          {grn.grn_audit_status == "OPEN" ||
                          grn.grn_audit_status == "CLOSED"
                            ? grn.grn_audit_status
                            : ""}
                        </td>
                        <td
                          className={getClassNameForGRN(
                            grn.grn_invoice_collection_status
                          )}
                        >
                          {grn.grn_invoice_collection_status == "OPEN" ||
                          grn.grn_invoice_collection_status == "CLOSED"
                            ? grn.grn_invoice_collection_status
                            : ""}
                        </td>
                        <td>{grn.grn_sku_count}</td>
                        <td>{grn.grn_invoice_quantity}</td>
                        <td>{grn.grn_accepted_quantity}</td>
                        <td>{grn.grn_rejected_quantity}</td>
                        <td>{grn.grn_shortage_quantity}</td>
                        <td>{grn.vendor_invoice_number}</td>
                        <td>{grn.vendor_id}</td>
                        <td>{grn.vendor_name}</td>
                        <td>{grn.grn_audit_by}</td>
                        <td>{grn.grn_invoice_collection_by}</td>
                        <td>{grn.created_by}</td>
                        <td>{DateTimeUtils.formatDateTime(grn.created_at)}</td>

                        {/* <td>
                          {grn.grn_invoice_collection_status == "OPEN" && (
                            <Button
                              variant="outline-primary"
                              className="grn-action-button"
                              onClick={() => {
                                if (
                                  userDetailsFromStore.authorities.includes(
                                    "AUTH_ACCOUNTS"
                                  )
                                ) {
                                  setSelectedGrn(grn);
                                  setShowReceiveInvoiceCanvas(true);
                                } else {
                                  toast.error(
                                    "Only Accounts team can acknowledge receipt of physical invoices."
                                  );
                                  return;
                                }
                              }}
                            >
                              {" "}
                              Mark as Received
                            </Button>
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {!showBottomLoader && currentGrns.length == 0 && (
                <div className="nothing-found-container">
                  No Invoices were found.
                </div>
              )}
              {searchParams.item_count > 0 &&
                searchParams.item_count < searchParams.expected_item_count &&
                !showBottomLoader && (
                  <div className="load-more-catalogues-button-container">
                    <Button
                      variant="outline-primary"
                      className="load-more-consignments-button"
                      onClick={() => loadMore()}
                    >
                      Load More..
                    </Button>
                  </div>
                )}
              {showBottomLoader && (
                <div className="bottom-progress-container">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}

      <Modal
        show={showReceiveInvoiceCanvas}
        onHide={() => setShowReceiveInvoiceCanvas(false)}
        className="alert-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Confirm Physical Invoice Receipt</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2">
            Are you sure you have received matching physical copy of selected
            invoices ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-footer-button"
            variant="secondary"
            onClick={() => setShowReceiveInvoiceCanvas(false)}
          >
            Cancel
          </Button>
          <Button
            className="modal-footer-button"
            variant="primary"
            onClick={() => {
              setShowLoadingPage(true);
              setShowReceiveInvoiceCanvas(false);
              // acknowledgeInvoiceReceipt();
              acknowledgeInvoiceReceiptInBulk();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PurchaseOrderGrnsPendingInvoiceCollections;
