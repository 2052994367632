import React, { useEffect, useRef, useState, KeyboardEvent } from "react";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Form,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/PurchaseOrderGrnDocuments.css";

import {
  ErrorMessageBody,
  IncomingPurchaseOrder,
  Consignment,
  ConsignmentInvoiceItem,
  InvoiceDataItem,
  BoxLevelItem,
  SKUPackingListItem,
  BoxInvoiceItem,
  GrnDebitCreditNoteItemDetailed,
  GrnItemWithListing,
  PurchaseOrderItemWithLisitingAndGrnData,
  GrnDocument,
  VendorPurchaseOrderGrnDetailed,
  PurchaseOrder,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import {
  ChevronDown,
  ChevronUp,
  Download,
  XCircleFill,
} from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _, { update } from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import Header from "../Header";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";

interface RouteParams {
  grn_id: string;
}

function PurchaseOrderGrnDocuments() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const purchaseOrderNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { grn_id } = useParams<RouteParams>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [selectedConsignment, setSelectedConsignment] = useState<Consignment>();
  const [selectedDebitCreditNoteItem, setSelectedDebitCreditNoteItem] =
    useState<GrnDebitCreditNoteItemDetailed>();
  const [debitCreditNoteItems, setdebitCreditNoteItems] = useState<
    GrnDebitCreditNoteItemDetailed[]
  >([]);
  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [showUploadDebitCreditNoteCanvas, setShowUploadDebitCreditNoteCanvas] =
    useState<boolean>(false);
  const [
    showUploadReverseDebitCreditNoteCanvas,
    setShowReverseUploadDebitCreditNoteCanvas,
  ] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [isExpandedDetailsLoading, setIsExpandedDetailsLoading] =
    useState<boolean>(false);
  const [showDebitCreditNumberCanvas, setShowDebitCreditNumberCanvas] =
    useState<boolean>(false);
  const [fyDebitCreditNoteNumber, setFyDebitCreditNoteNumber] =
    useState<string>("");
  const [otherDebitCreditNoteNumber, setOtherDebitCreditNoteNumber] =
    useState<string>("");
  const [reverseDebitCreditNoteNumber, setReverseDebitCreditNoteNumber] =
    useState<string>("");
  const [generatePackingListPDF, setGeneratePackingListPDF] =
    useState<boolean>(false);
  const [generateSKUPackingListPDF, setGenerateSKUPackingListPDF] =
    useState<boolean>(false);
  const [grnInvoiceDocuments, setGrnInvoiceDocuments] = useState<GrnDocument[]>(
    []
  );
  const [currentGrn, setCurrentGrn] =
    useState<VendorPurchaseOrderGrnDetailed | null>(null);

  const [files, setFiles] = useState<File[]>([]);
  const [boxLevelData, setBoxLevelData] = useState<BoxLevelItem[]>([]);
  const [skuLevelData, setSkuLevelData] = useState<SKUPackingListItem[]>([]);
  const [useOtherDebitCreditNoteeNumber, setUseOtherDebitCreditNoteeNumber] =
    useState<boolean>(false);

  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<PurchaseOrder | null>(null);

  const [grnItemsWithListing, setGrnItemsWithListing] = useState<
    GrnItemWithListing[]
  >([]);
  const [purchaseOrderItemsWithListing, setPurchaseOrderItemsWithListing] =
    useState<PurchaseOrderItemWithLisitingAndGrnData[]>([]);
  const [shortageDebitNotesListing, setShortageDebitNotesListing] = useState<
    PurchaseOrderItemWithLisitingAndGrnData[]
  >([]);
  const [damagesDebitNotesListing, setDamagesDebitNotesListing] = useState<
    PurchaseOrderItemWithLisitingAndGrnData[]
  >([]);
  const [
    priceDifferenceDebitNotesWithListing,
    setPriceDifferenceDebitNotesWithListing,
  ] = useState<PurchaseOrderItemWithLisitingAndGrnData[]>([]);
  const [creditNotesWithListing, setCreditNotesWithListing] = useState<
    PurchaseOrderItemWithLisitingAndGrnData[]
  >([]);

  const handleCheckboxChange = () => {
    setUseOtherDebitCreditNoteeNumber(!useOtherDebitCreditNoteeNumber);
  };

  const submitDebitCreditNoteNumber = () => {
    let finalInvoiceNumber = "";
    if (useOtherDebitCreditNoteeNumber) {
      if (
        otherDebitCreditNoteNumber.length < 10 ||
        otherDebitCreditNoteNumber.length > 16
      ) {
        toast.error(
          "Invalid Credit/Debit Note Number. Credit/Debit Note number should have less than 16 and more than 10 characters.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );

        return;
      }

      finalInvoiceNumber = otherDebitCreditNoteNumber;
    } else {
      let invoiceNumber = "";
      if (/^\d{0,4}$/.test(fyDebitCreditNoteNumber)) {
        // Allow only up to 4 digits
        const numericValue = parseInt(fyDebitCreditNoteNumber, 10);

        if (numericValue >= 1 && numericValue <= 9999) {
          invoiceNumber = fyDebitCreditNoteNumber.padStart(2, "0");
        } else {
          toast.error("Credit/Debit Note Number must be between 01 and 9999.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          return;
        }
      } else {
        toast.error("Only numeric values up to 4 digits are allowed.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });

        return;
      }

      const created_invoice_number = getFinancialYear() + invoiceNumber;

      if (
        created_invoice_number.length < 10 ||
        created_invoice_number.length > 16
      ) {
        toast.error(
          "Invalid Credit/Debit Note Number. Credit/Debit Note number should have less than 16 and more than 10 characters.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );

        return;
      }

      finalInvoiceNumber = created_invoice_number;
    }

    submitDebitCreditNoteNumberToWeb(finalInvoiceNumber);
  };

  const submitDebitCreditNoteNumberToWeb = async (
    finalDebitCreditNoteNumber: string
  ) => {
    if (selectedDebitCreditNoteItem) {
      setShowLoadingPage(true);
      try {
        const response =
          await purchaseOrderNetworkUtil.submitDebitCreditNoteNumberToWeb(
            finalDebitCreditNoteNumber,
            grn_id,
            selectedDebitCreditNoteItem.credit_debit_note_type,
            userDetailsFromStore.user_details.userId,
            selectedDebitCreditNoteItem.po_id.toString()
          );
        setdebitCreditNoteItems(response.data);
        setShowLoadingPage(false);
        setShowDebitCreditNumberCanvas(false);
      } catch (error) {
        console.error("Error in submitting invoice number : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }

        setShowLoadingPage(false);
      }
    }
  };

  const downloadFileFromWeb = async (file_id: any, saved_file_name: string) => {
    setShowDownloadPage(true);
    try {
      const response =
        await purchaseOrderNetworkUtil.downloadVendorGrnFileFromWeb(file_id);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = saved_file_name; // Use the original file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setShowDownloadPage(false);
    } catch (error) {
      console.error("Error in downloading file : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
    }
  };

  const getGrnListings = async (
    grn_id: string
  ): Promise<GrnItemWithListing[]> => {
    try {
      const response = await purchaseOrderNetworkUtil.getGrnItemsWithListing(
        grn_id
      );
      // setdebitCreditNoteItems(response.data);
      return response.data;
    } catch (error) {
      console.error("Error in fetching invoice data : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      return [];
    }
  };

  const sanitize = (invoice_number: string) => {
    const match = invoice_number.match(/[\/\\](\d+)$/); // Match '/' or '\' followed by digits
    return match ? match[1] : null;
  };

  const downloadInvoiceData = async () => {
    if (currentGrn && currentGrn.grn_invoice_collection_status != "CLOSED") {
      toast.error(
        "Error! could not download invoice data unless invoice collection status is CLOSED.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      setShowDownloadPage(false);
      return;
    }

    const boxInvoicePackingListData: GrnItemWithListing[] =
      await getGrnListings(grn_id);

    if (currentGrn) {
      try {
        // itemsToExport.push()
        const transformedItems = boxInvoicePackingListData.map((singleItem) => {
          return {
            vendor_invoice_number: currentGrn.vendor_invoice_number,
            financial_year: "",
            ref_no: "",
            sku_id: singleItem.sku_id,
            title: "",
            invoice_quantity: singleItem.invoice_quantity,
            received_price: singleItem.received_price,
            shipping_and_other_charges_nil_tax_rate: "",
            shipping_and_other_charges: "",
            shipping_tax_rate: "",
            state: "",
            tax_rate: singleItem.tax_rate,
            taxable_amount:
              singleItem.invoice_quantity * singleItem.received_price,
            igst:
              Math.round(
                ((singleItem.invoice_quantity *
                  singleItem.received_price *
                  singleItem.tax_rate) /
                  100) *
                  100
              ) / 100,
            sgst_csgst:
              Math.round(
                ((singleItem.invoice_quantity *
                  singleItem.received_price *
                  singleItem.tax_rate) /
                  200) *
                  100
              ) / 100,
            net_purhcase:
              Math.round(
                ((singleItem.invoice_quantity *
                  singleItem.received_price *
                  (100 + singleItem.tax_rate)) /
                  100) *
                  100
              ) / 100,
            vendor_name: currentGrn.vendor_name,
            vendor_gstin: "",
            goods_received_date: DateTimeUtils.formatDateOnly(
              currentGrn.created_at
            ),
            actual_bill_date: "",
            frieght: "",
            hsn: "",
            grn_id: grn_id,
          };
        });
        jsonexport(transformedItems, (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = grn_id + "_invoice_data";

          // Simulate a click to trigger the download
          link.click();
        });
        setShowDownloadPage(false);
      } catch (error) {
        console.log("Printing error from downloadInvoiceData ");
        console.error("Error:", error);
        setShowDownloadPage(false);
      }
    }
  };

  const downloadDebitCreditNoteData = async (
    debitCreditNoteItem: GrnDebitCreditNoteItemDetailed
  ) => {
    // const invoiceData: InvoiceDataItem[] = await getGrnListings(
    //   consignmentInvoiceItem.consignment_id.toString()
    // );

    // console.log(invoiceData);

    if (debitCreditNoteItem.credit_debit_note_type == "SHORTAGES_DEBIT_NOTE") {
      try {
        const transformedItems = shortageDebitNotesListing.map((singleItem) => {
          return {
            credit_debit_note_number:
              debitCreditNoteItem.credit_debit_note_number,
            grn_id: grn_id,
            po_id: debitCreditNoteItem.po_id,
            vendor_id: debitCreditNoteItem.vendor_id,
            vendor_name: debitCreditNoteItem.vendor_name,
            sku_id: singleItem.sku_id,
            short_quantity: singleItem.currentGrnItem
              ? singleItem.currentGrnItem.invoice_quantity -
                (singleItem.currentGrnItem.accepted_quantity ?? 0) -
                (singleItem.currentGrnItem.rejected_quantity ?? 0)
              : "NA",
            price_excl_gst: singleItem.currentGrnItem
              ? singleItem.currentGrnItem.received_price
              : "NA",
            tax_rate: singleItem.currentGrnItem
              ? singleItem.currentGrnItem.tax_rate
              : "NA",
            credit_debit_note_type: debitCreditNoteItem.credit_debit_note_type,
          };
        });
        jsonexport(transformedItems, (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = grn_id + "_shortages_debit_note_data";

          // Simulate a click to trigger the download
          link.click();
        });
      } catch (error) {
        console.log("Printing error from downloadDebitCreditNoteData ");
        console.error("Error:", error);
      }
    } else if (
      debitCreditNoteItem.credit_debit_note_type == "DAMAGES_DEBIT_NOTE"
    ) {
      try {
        const transformedItems = damagesDebitNotesListing.map((singleItem) => {
          return {
            credit_debit_note_number:
              debitCreditNoteItem.credit_debit_note_number,
            grn_id: grn_id,
            po_id: debitCreditNoteItem.po_id,
            vendor_id: debitCreditNoteItem.vendor_id,
            vendor_name: debitCreditNoteItem.vendor_name,
            sku_id: singleItem.sku_id,
            rejected_quantity: singleItem.currentGrnItem
              ? singleItem.currentGrnItem.rejected_quantity
              : "NA",
            price_excl_gst: singleItem.currentGrnItem
              ? singleItem.currentGrnItem.received_price
              : "NA",
            tax_rate: singleItem.currentGrnItem
              ? singleItem.currentGrnItem.tax_rate
              : "NA",
            credit_debit_note_type: debitCreditNoteItem.credit_debit_note_type,
          };
        });
        jsonexport(transformedItems, (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = grn_id + "_damages_debit_note_data";

          // Simulate a click to trigger the download
          link.click();
        });
      } catch (error) {
        console.log("Printing error from downloadDebitCreditNoteData ");
        console.error("Error:", error);
      }
    } else if (
      debitCreditNoteItem.credit_debit_note_type ==
      "PRICE_DIFFERENCE_DEBIT_NOTE"
    ) {
      try {
        const transformedItems = priceDifferenceDebitNotesWithListing.map(
          (singleItem) => {
            return {
              credit_debit_note_number:
                debitCreditNoteItem.credit_debit_note_number,
              grn_id: grn_id,
              po_id: debitCreditNoteItem.po_id,
              vendor_id: debitCreditNoteItem.vendor_id,
              vendor_name: debitCreditNoteItem.vendor_name,
              sku_id: singleItem.sku_id,
              accepted_quantity: singleItem.currentGrnItem
                ? singleItem.currentGrnItem.accepted_quantity
                : "NA",
              received_price_excl_gst: singleItem.currentGrnItem
                ? singleItem.currentGrnItem.received_price
                : "NA",
              tax_rate: singleItem.currentGrnItem
                ? singleItem.currentGrnItem.tax_rate
                : "NA",
              audited_price_excl_gst: singleItem.currentGrnItem
                ? singleItem.currentGrnItem.audit_price
                : "NA",
              credit_debit_note_type:
                debitCreditNoteItem.credit_debit_note_type,
            };
          }
        );
        jsonexport(transformedItems, (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = grn_id + "_price_difference_debit_note_data";

          // Simulate a click to trigger the download
          link.click();
        });
      } catch (error) {
        console.log("Printing error from downloadDebitCreditNoteData ");
        console.error("Error:", error);
      }
    }
  };

  //   const getBoxLevelData = async (
  //     consignment_id: string
  //   ): Promise<BoxLevelItem[]> => {
  //     setShowDownloadPage(true);
  //     try {
  //       const response = await purchaseOrderNetworkUtil.getDataForBoxLabels(
  //         consignment_id
  //       );
  //       // setdebitCreditNoteItems(response.data);
  //       setShowDownloadPage(false);
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error in fetching invoice data : ", error);
  //       const err = error as AxiosError;
  //       if (err.response && err.response.data && err.response.status == 400) {
  //         console.log((err.response.data as ErrorMessageBody).message);
  //         toast.error((err.response.data as ErrorMessageBody).message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //           hideProgressBar: true,
  //         });
  //       } else {
  //         toast.error("Unexpected error", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           hideProgressBar: true,
  //         });
  //       }

  //       setShowDownloadPage(false);
  //       return [];
  //     }
  //   };

  //   const fetchPurchaseOrderDetails = async (purchase_order_id: any) => {
  //     try {
  //       const response = await purchaseOrderNetworkUtil.geturchaseOrderByPOid(
  //         purchase_order_id.toString()
  //       );
  //       // console.log(response.data);
  //       setCurrentPurchaseOrder(response.data);
  //       return response.data;
  //     } catch (error) {
  //       toast.error("Error! Could not find purchase order details.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         hideProgressBar: true,
  //       });
  //     }
  //   };

  //   const donwloadBoxLevelItems = async (
  //     consignmentInvoiceItem: ConsignmentInvoiceItem
  //   ) => {
  //     setShowDownloadPage(true);

  //     if (boxLevelData.length == 0) {
  //       const localBoxLevelData: BoxLevelItem[] = await getBoxLevelData(
  //         consignmentInvoiceItem.consignment_id.toString()
  //       );
  //       setBoxLevelData(localBoxLevelData);
  //     }

  //     if (!currentPurchaseOrder) {
  //       const localPurchaseOrder: IncomingPurchaseOrder =
  //         await fetchPurchaseOrderDetails(po_id);
  //       // setCurrentPurchaseOrder(localPurchaseOrder);
  //     }

  //     setShowDownloadPage(false);
  //     setGeneratePackingListPDF(true);
  //   };

  //   const downloadBoxLabels = async (
  //     consignmentInvoiceItem: ConsignmentInvoiceItem
  //   ) => {
  //     let localBoxLevelData: BoxLevelItem[] = boxLevelData;
  //     if (boxLevelData.length == 0) {
  //       localBoxLevelData = await getBoxLevelData(
  //         consignmentInvoiceItem.consignment_id.toString()
  //       );
  //       setBoxLevelData(localBoxLevelData);
  //     }

  //     const match = consignmentInvoiceItem.invoice_number.match(/[\/\\](\d+)$/); // Match '/' or '\' followed by digits
  //     const sanitized_invoice_number = match ? match[1] : "";

  //     CompanyPurchaseOrderLabelUtils.generateBoxLabels4by6_v2(
  //       localBoxLevelData,
  //       sanitized_invoice_number,
  //       consignmentInvoiceItem.consignment_id.toString()
  //     );
  //   };

  //   const donwloadBoxLevelItemsWithSKUpackingListPrep = async (
  //     consignmentInvoiceItem: ConsignmentInvoiceItem
  //   ) => {
  //     setShowDownloadPage(true);

  //     let localBoxLevelData: BoxLevelItem[] = boxLevelData;
  //     if (boxLevelData.length == 0) {
  //       localBoxLevelData = await getBoxLevelData(
  //         consignmentInvoiceItem.consignment_id.toString()
  //       );
  //       setBoxLevelData(localBoxLevelData);
  //     }

  //     if (!currentPurchaseOrder) {
  //       const localPurchaseOrder: IncomingPurchaseOrder =
  //         await fetchPurchaseOrderDetails(po_id);
  //       // setCurrentPurchaseOrder(localPurchaseOrder);
  //     }

  //     let localSkuLevelData: SKUPackingListItem[] = skuLevelData;
  //     if (skuLevelData.length == 0) {
  //       localSkuLevelData = await prepareSKUWiseDataForPackingList(
  //         localBoxLevelData
  //       );
  //       setSkuLevelData(localSkuLevelData);
  //     }

  //     setShowDownloadPage(false);
  //     setGenerateSKUPackingListPDF(true);
  //   };

  //   const prepareSKUWiseDataForPackingList = (
  //     boxLevelData: BoxLevelItem[]
  //   ): SKUPackingListItem[] => {
  //     const skuPackingMap = new Map<string, SKUPackingListItem>();
  //     boxLevelData.forEach((boxLevelItem) => {
  //       boxLevelItem.box_details.forEach((detail) => {
  //         const {
  //           company_code_primary,
  //           po_secondary_sku,
  //           box_number,
  //           box_quantity,
  //         } = detail;

  //         // Determine if the box contains multiple SKUs
  //         const is_multi = boxLevelItem.box_details.length > 1;
  //         const key = `${company_code_primary}-${po_secondary_sku}`;

  //         if (!skuPackingMap.has(key)) {
  //           // Add a new SKUPackingListItem
  //           skuPackingMap.set(key, {
  //             company_code_primary,
  //             po_secondary_sku,
  //             boxes: [],
  //           });
  //         }

  //         // Add the box to the existing SKUPackingListItem
  //         skuPackingMap.get(key)?.boxes.push({
  //           box_number,
  //           quantity: box_quantity,
  //           is_multi,
  //         });
  //       });
  //     });

  //     return Array.from(skuPackingMap.values());
  //   };

  const getDebitCreditNoteDetails = async (grn_id: string) => {
    setShowLoadingPage(true);
    try {
      const response = await purchaseOrderNetworkUtil.getDebitCreditNoteDetails(
        grn_id
      );

      setdebitCreditNoteItems(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in fetching debit/credit note details", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const toggleRow = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null); // collapse if already expanded
    } else {
      setExpandedRow(index); // expand selected row
      if (debitCreditNoteItems.length > 0) {
        setSelectedDebitCreditNoteItem(debitCreditNoteItems.at(index));
      }
      // setIsPackComboListingDetailsLoading(true);
      // getPackComboListingDetails(
      //   currentPackComboListings[index].pack_combo_sku_id
      // );
    }
  };

  const getClassNameForInvoiceNumberStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "ASSIGNED") return "color-green";
    else if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    return "";
  };

  const getClassNameForInvoiceStatus = (invoice_status: string) => {
    if (invoice_status == "ACTIVE") return "color-green";
    else if (invoice_status == "INACTIVE") return "color-red";
    return "";
  };

  const getClassNameForInvoiceUploadStatus = (invoice_status: string) => {
    if (invoice_status == "UPLOADED") return "color-green";
    else if (invoice_status == "NOT-UPLOADED") return "color-red";
    return "";
  };

  const handleFYDebitCreditNoteNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFyDebitCreditNoteNumber(e.target.value.trim());
  };

  const handleOtherDebitCreditNoteNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherDebitCreditNoteNumber(e.target.value.trim());
  };

  const handleReverseDebitCreditNoteNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReverseDebitCreditNoteNumber(e.target.value.trim());
  };

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPcolor = (wip_status: string) => {
    if (wip_status == "YES") return "marked-green";
    else return "marked-red";
  };

  function getFinancialYear(): string {
    if (selectedDebitCreditNoteItem) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed

      // Financial year starts in April
      const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
      const startYearHalf = startYear.toString().slice(-2);
      const endYear = (startYear + 1).toString().slice(-2);

      return `${startYearHalf}${endYear}/${selectedDebitCreditNoteItem.vendor_invoice_number}/PR/`;
    }

    return "";
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      const fileArray = Array.from(uploadedFiles);
      if (fileArray.length + files.length > 2) {
        alert("You can upload a maximum of 2 files.");
      } else {
        setFiles([...files, ...fileArray]);
      }
    }
  };

  const handleFileRemove = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getClassNameForGRN = (grn_status: string) => {
    if (grn_status == "OPEN") return "color-green";
    else if (grn_status == "CLOSED") return "color-pink";
    return "";
  };
  const getClassNameForCreditDebitNoteStatus = (debit_note_status: string) => {
    if (debit_note_status == "APPROVED") return "color-green";
    else if (debit_note_status == "NOT-APPROVED") return "color-red";
    return "";
  };

  const getClassNameForCreditDebitNoteUploadStatus = (
    upload_status: string
  ) => {
    if (upload_status == "UPLOADED") return "color-green";
    else if (upload_status == "NOT-UPLOADED") return "color-red";
    return "";
  };

  const getClassNameForCreditDebitNoteNumberAssignmentStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    else if (invoice_number_status == "ASSIGNED") return "color-green";
    return "";
  };

  const getDebitNoteType = (type: string) => {
    return type.replace(/_/g, " ");
  };

  const uploadDebitCreditNote = async (
    localDebitCreditNoteItem: GrnDebitCreditNoteItemDetailed
  ) => {
    if (files.length == 0) {
      toast.error("No File was selected");
      return;
    }
    if (debitCreditNoteItems.length > 0) {
      try {
        const response =
          await purchaseOrderNetworkUtil.uploadGrnDebitCreditNoteFiles(
            files,
            userDetailsFromStore.user_details.userId,
            grn_id,
            localDebitCreditNoteItem.credit_debit_note_type
          );
        console.log(response);
        toast.success("Files successfully uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        // updateTransferListing(response.data["paths"], transferListing);
        setShowUploadDebitCreditNoteCanvas(false);
        window.location.reload();
      } catch (error) {
        console.log(error);
        toast.error(
          "Unknown error while trying to upload files! Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        return;
      }
    }
  };

  const uploadReverseDebitCreditNoteWithNumber = async (
    localDebitCreditNoteItem: GrnDebitCreditNoteItemDetailed
  ) => {
    if (
      !reverseDebitCreditNoteNumber ||
      reverseDebitCreditNoteNumber.length < 2
    ) {
      toast.error("Invalid reverse debit/credit note number provided");
      return;
    }

    if (files.length == 0) {
      toast.error("No File was selected");
      return;
    }
    if (debitCreditNoteItems.length > 0) {
      try {
        const response =
          await purchaseOrderNetworkUtil.uploadGrnReverseDebitCreditNoteFiles(
            files,
            userDetailsFromStore.user_details.userId,
            grn_id,
            "REVERSE_" + localDebitCreditNoteItem.credit_debit_note_type,
            reverseDebitCreditNoteNumber
          );
        console.log(response);
        toast.success("Files successfully uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        // updateTransferListing(response.data["paths"], transferListing);
        setShowReverseUploadDebitCreditNoteCanvas(false);
        window.location.reload();
      } catch (error) {
        console.log(error);
        toast.error(
          "Unknown error while trying to upload files! Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        return;
      }
    }
  };

  const handleDebitCreditNoteKeyPress = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      submitDebitCreditNoteNumber();
    }
  };

  useEffect(() => {
    const fetchGrnByGrnId = async (grnId: string) => {
      setShowLoadingPage(true);
      try {
        const response = await purchaseOrderNetworkUtil.getGrnByGrnId(grnId);
        setCurrentGrn(response.data);
      } catch (error) {
        setShowLoadingPage(false);
        console.error("Error in fetching GRN by grn id :", error);
      }
    };

    fetchGrnByGrnId(grn_id);
  }, [grn_id]);

  useEffect(() => {
    const fetchPurchaseOrderByPOId = async (poId: number) => {
      setShowLoadingPage(true);
      try {
        const response = await purchaseOrderNetworkUtil.getPurchaseOrderById(
          poId.toString()
        );
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        setShowLoadingPage(false);
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    const fetchVendorGrnInvoiceFiles = async (grn_id: any) => {
      try {
        const response =
          await purchaseOrderNetworkUtil.fetchVendorGrnInvoiceFiles(grn_id);

        setGrnInvoiceDocuments(response.data);
        // createDocumentMap(response.data);
      } catch (error) {
        console.error("Error in getting grn documents : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    };

    if (currentGrn) {
      fetchPurchaseOrderByPOId(currentGrn.po_id);
      getDebitCreditNoteDetails(grn_id);
      fetchVendorGrnInvoiceFiles(grn_id);
    }
  }, [currentGrn]);

  useEffect(() => {
    const getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId =
      async (poId: number) => {
        setShowLoadingPage(true);
        try {
          const response =
            await purchaseOrderNetworkUtil.getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId(
              poId.toString()
            );

          setPurchaseOrderItemsWithListing(response.data);
          getGrnItemsWithListing(grn_id);
          setShowLoadingPage(false);
        } catch (error) {
          console.error(
            "Error in getting purchase order items by poId : ",
            error
          );
          setShowLoadingPage(false);
        }
      };

    const getGrnItemsWithListing = async (grnId: string) => {
      setShowLoadingPage(true);
      try {
        const response = await purchaseOrderNetworkUtil.getGrnItemsWithListing(
          grnId
        );

        setGrnItemsWithListing(response.data);
        // setPurchaseOrderItemsWithListing(response.data);
        // setFilteredPurchaseOrderItemsWithListings(response.data);
        // setIsLoading(false);
        setShowLoadingPage(false);
      } catch (error) {
        console.error(
          "Error in getting purchase order items by poId : ",
          error
        );
        setShowLoadingPage(false);
      }
    };

    if (debitCreditNoteItems && debitCreditNoteItems.length > 0) {
      getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId(
        debitCreditNoteItems[0].po_id
      );
      //   getGrnItemsWithListing(grn_id);
    }
  }, [debitCreditNoteItems]);

  useEffect(() => {
    if (
      !grnItemsWithListing ||
      grnItemsWithListing.length === 0 ||
      purchaseOrderItemsWithListing.length == 0
    ) {
      return;
    }
    setTimeout(() => {
      const skuMap = new Map<string, GrnItemWithListing>();

      grnItemsWithListing.forEach((item) => {
        skuMap.set(item.sku_id, item);
      });

      const updatedPurchaseOrderItems = purchaseOrderItemsWithListing.map(
        (item) => ({
          ...item,
          is_selected: skuMap.has(item.sku_id),
          currentGrnItem: skuMap.get(item.sku_id) || null,
        })
      );

      setPurchaseOrderItemsWithListing(updatedPurchaseOrderItems);
      prepareDebitAndCreditNoteList(updatedPurchaseOrderItems);
    }, 200); // Add a 500ms delay
  }, [grnItemsWithListing]);

  const prepareDebitAndCreditNoteList = (
    purchaseOrderItems: PurchaseOrderItemWithLisitingAndGrnData[]
  ) => {
    const shortageDebitNotes: PurchaseOrderItemWithLisitingAndGrnData[] = [];
    const damagesDebitNotes: PurchaseOrderItemWithLisitingAndGrnData[] = [];
    const priceDifferenceDebitNotes: PurchaseOrderItemWithLisitingAndGrnData[] =
      [];
    const creditNotes: PurchaseOrderItemWithLisitingAndGrnData[] = [];

    purchaseOrderItems.forEach((poItem) => {
      const grnItem = poItem.currentGrnItem;
      if (!grnItem) return;

      if (
        grnItem.accepted_quantity + grnItem.rejected_quantity <
        grnItem.invoice_quantity
      ) {
        shortageDebitNotes.push(poItem);
      }

      if (grnItem.rejected_quantity > 0) {
        damagesDebitNotes.push(poItem);
      }

      if (
        grnItem.audit_price != null &&
        grnItem.audit_price < grnItem.received_price
      ) {
        priceDifferenceDebitNotes.push(poItem);
      }

      if (grnItem.audit_price > grnItem.received_price) {
        creditNotes.push(poItem);
      }
    });

    setShortageDebitNotesListing(shortageDebitNotes);
    setDamagesDebitNotesListing(damagesDebitNotes);
    setPriceDifferenceDebitNotesWithListing(priceDifferenceDebitNotes);
    setCreditNotesWithListing(creditNotes);
  };

  useEffect(() => {
    if (files.length == 0) {
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the file input
      }
    }
  }, [files]);

  return (
    <div className="consignment-details-container catalogues-container">
      <div className="quicklinks-container">
        <div className="po-id-link">Quick Links ~</div>
        <div className="po-id-link">
          <a href="/vendor-hub/grn/all-grns">All GRNs</a>
        </div>
        <span> | </span>
        <div className="po-id-link">
          <a href="/vendor-hub/all-vendors">All Vendors</a>
        </div>
        <span> | </span>
        <div className="po-id-link">
          {currentPurchaseOrder && (
            <a
              href={`/vendor-hub/all-vendors/${currentPurchaseOrder.vendor_id}/vendor-po`}
            >
              Vendor Purchase Orders
            </a>
          )}
        </div>
        <span> | </span>
        <div className="po-id-link">
          {currentPurchaseOrder && (
            <a
              href={`/vendor-hub/all-purchase-orders/grn/${currentPurchaseOrder.po_id}`}
            >
              GRN Home
            </a>
          )}
        </div>
      </div>
      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          {/* Loading, please wait... */}
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}

      <div className="incoming-purchase-order-pack-task-summary-table-container mt-4">
        {currentGrn && (
          <div className="incoming-purchase-order-pack-task-summary-table-container-child">
            <Table bordered hover size="sm" className="table-auto-width">
              <tbody>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Number of SKUs in GRN</td>
                  <td className="centered">{currentGrn.grn_sku_count}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">GRN Invoice Quantity</td>
                  <td className="centered">
                    {currentGrn.grn_invoice_quantity}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">GRN Accepted Quantity</td>
                  <td className="centered">
                    {currentGrn.grn_accepted_quantity}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">GRN Rejected Quantity</td>
                  <td className="centered">
                    {currentGrn.grn_rejected_quantity}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">GRN Shortage Quantity</td>
                  <td className="centered">
                    {currentGrn.grn_shortage_quantity}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
        {currentGrn && (
          <div className="incoming-purchase-order-pack-task-summary-table-container-child">
            <Table bordered hover size="sm" className="table-auto-width">
              <tbody>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">PO Number</td>
                  <td className="centered">{currentGrn.po_id}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">GRN Number</td>
                  <td className="centered">{currentGrn.grn_id}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Vendor Invoice Number</td>
                  <td className="centered">
                    {currentGrn.vendor_invoice_number}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Invoice Collection Status</td>
                  <td
                    className={`centered ${getClassNameForGRN(
                      currentGrn.grn_invoice_collection_status
                    )}`}
                  >
                    {currentGrn.grn_invoice_collection_status == "OPEN" ||
                    currentGrn.grn_invoice_collection_status == "CLOSED" ? (
                      currentGrn.grn_invoice_collection_status
                    ) : (
                      <b>NOT COLLECTED</b>
                    )}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Invoice Collected By</td>
                  <td className="centered">
                    {currentGrn.grn_invoice_collection_by}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
        <div className="incoming-purchase-order-pack-task-summary-table-container-child"></div>
      </div>

      <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container ">
        <div className="po-document-section-heading">Vendor Invoice</div>
        {grnInvoiceDocuments.length === 0 ? (
          <div className="po-document-section-no-document mt-3">
            No Invoice was found
          </div>
        ) : (
          <div className="incoming-purchase-order-file-details-table-container pt-4 invoice-details-table">
            <div className="incoming-purchase-order-file-details-table-container">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>File ID</th>
                    <th>File Type</th>
                    <th>Uploaded At</th>
                    <th>Uploaded By</th>
                    <th>File Name</th>
                    <th>Download Uploaded Invoice File</th>
                    <th>Download Invoice Data</th>
                  </tr>
                </thead>
                <tbody>
                  {grnInvoiceDocuments.map((doc, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td className="nowrap">{doc.id}</td>
                        <td>{doc.file_type}</td>
                        <td>{DateTimeUtils.formatDateTime(doc.created_at)}</td>
                        <td>{doc.file_uploaded_by}</td>
                        <td>{doc.file_name}</td>
                        <td>
                          <Download
                            size={15}
                            color="#007bff"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowDownloadPage(true);
                              downloadFileFromWeb(doc.id, doc.saved_file_name);
                            }}
                          />
                        </td>
                        <td>
                          <Download
                            size={15}
                            color="#007bff"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowDownloadPage(true);
                              downloadInvoiceData();
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>

      <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container">
        <div className="po-document-section-heading">Debit/Credit Notes</div>
        {debitCreditNoteItems.length === 0 ? (
          <div className="po-document-section-no-document mt-3">
            No Debit/Credit Notes were found
          </div>
        ) : (
          <div className="incoming-purchase-order-file-details-table-container pt-4 invoice-details-table">
            <Table bordered hover size="sm" className="table-auto-width">
              <thead className="sticky-header">
                <tr>
                  <th>GRN ID</th>
                  <th>PO Number</th>
                  <th>GRN Status</th>
                  <th>GRN Audit Status</th>
                  <th>Vendor Invoice Number</th>
                  <th>Vendor Id</th>
                  <th>Vendor Name</th>
                  <th>Box Count in Invoice</th>
                  <th>Actual Box Count Recieved</th>
                  <th>GRN Audited by</th>
                  <th>Credit/Debit Note</th>
                  <th>Credit/Debit Note Status</th>
                  <th>Credit/Debit Note Number</th>
                  <th>Credit/Debit Note Number Assignment Status</th>
                  <th>Credit/Debit Note Upload Status</th>
                  <th>Credit/Debit Note Uploaded By</th>
                  <th>Reverse Credit/Debit Note Number</th>
                  <th>Reverse Credit/Debit Note Upload Status</th>
                  <th>Reverse Credit/Debit Note Uploaded By</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {debitCreditNoteItems.map((item, index) => {
                  const isExpanded = expandedRow === index;
                  return (
                    <React.Fragment key={index}>
                      <tr className="catalogues-search-bar-row">
                        <td className="po-id-link">{item.grn_id}</td>
                        <td className="po-id-link">
                          <a
                            href={`/vendor-hub/all-purchase-orders/details/${item.po_id}`}
                          >
                            {item.po_id}
                          </a>
                        </td>
                        <td className={getClassNameForGRN(item.grn_status)}>
                          {item.grn_status}
                        </td>
                        <td
                          className={getClassNameForGRN(item.grn_audit_status)}
                        >
                          {item.grn_audit_status == "OPEN" ||
                          item.grn_audit_status == "CLOSED"
                            ? item.grn_audit_status
                            : ""}
                        </td>
                        <td>{item.vendor_invoice_number}</td>
                        <td>{item.vendor_id}</td>
                        <td>{item.vendor_name}</td>
                        <td>{item.box_count_invoice}</td>
                        <td>{item.actual_box_count_recieved}</td>
                        <td>{item.grn_audit_by}</td>
                        <td>
                          <b>{getDebitNoteType(item.credit_debit_note_type)}</b>
                        </td>
                        <td
                          className={getClassNameForCreditDebitNoteStatus(
                            item.credit_debit_note_status
                          )}
                        >
                          {item.credit_debit_note_status}
                        </td>
                        <td>{item.credit_debit_note_number}</td>
                        <td
                          className={getClassNameForCreditDebitNoteNumberAssignmentStatus(
                            item.credit_debit_note_number_assignment_status
                          )}
                        >
                          {item.credit_debit_note_number_assignment_status}
                        </td>
                        <td
                          className={getClassNameForCreditDebitNoteUploadStatus(
                            item.credit_debit_note_upload_status
                          )}
                        >
                          {item.credit_debit_note_upload_status}
                        </td>
                        <td>{item.credit_debit_note_uploaded_by}</td>
                        <td>{item.reverse_credit_debit_note_number}</td>
                        <td
                          className={getClassNameForCreditDebitNoteUploadStatus(
                            item.reverse_credit_debit_note_upload_status
                          )}
                        >
                          {item.reverse_credit_debit_note_upload_status}
                        </td>
                        <td>{item.reverse_credit_debit_note_uploaded_by}</td>
                        <td>
                          {isExpanded ? (
                            <ChevronUp
                              size={15}
                              color="#007bff"
                              style={{ cursor: "pointer" }}
                              onClick={() => toggleRow(index)}
                            />
                          ) : (
                            <ChevronDown
                              size={15}
                              color="#007bff"
                              style={{ cursor: "pointer" }}
                              onClick={() => toggleRow(index)}
                            />
                          )}
                        </td>

                        {/* <td>
                        <Download
                          size={15}
                          color="#007bff"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            downloadFileFromWeb(doc.id, doc.saved_file_name)
                          }
                        />
                      </td> */}
                      </tr>

                      {isExpanded && (
                        <tr
                          className={`incoming-po-search-bar-row expandable-content-invoice 
                            
                            ${isExpanded ? "expanded" : "collapsed"}`}
                        >
                          <td colSpan={20} className="invoice-section-expanded">
                            {isExpandedDetailsLoading ? (
                              <div className="loading-item">
                                <Spinner animation="grow" />
                                Loading, please wait...
                              </div>
                            ) : (
                              <div className="expanded-content">
                                <div className="expanded-container-sub">
                                  <div className="actions-container">
                                    {userDetailsFromStore.authorities.includes(
                                      "AUTH_ACCOUNTS"
                                    ) && (
                                      <div className="purchase-order-action-buttons-container">
                                        {item.credit_debit_note_number_assignment_status ==
                                          "NOT-ASSIGNED" && (
                                          <Button
                                            variant="outline-primary"
                                            className="vendor-action-button"
                                            onClick={() => {
                                              if (
                                                item.credit_debit_note_status ==
                                                "APPROVED"
                                              ) {
                                                setShowDebitCreditNumberCanvas(
                                                  true
                                                );
                                              } else {
                                                toast.error(
                                                  "Cannot assign debit/credit note number to unapproved debit/credit note."
                                                );
                                              }
                                            }}
                                          >
                                            Assign Debit/Credit Note Number
                                          </Button>
                                        )}

                                        {item.credit_debit_note_upload_status ==
                                          "NOT-UPLOADED" &&
                                          item.credit_debit_note_number_assignment_status ==
                                            "ASSIGNED" && (
                                            <Button
                                              variant="outline-primary"
                                              className="vendor-action-button"
                                              onClick={() =>
                                                setShowUploadDebitCreditNoteCanvas(
                                                  true
                                                )
                                              }
                                            >
                                              Upload Debit/Credit Note
                                            </Button>
                                          )}
                                        {item.credit_debit_note_upload_status ==
                                          "UPLOADED" &&
                                          item.reverse_credit_debit_note_upload_status ==
                                            "NOT-UPLOADED" && (
                                            <Button
                                              variant="outline-primary"
                                              className="vendor-action-button"
                                              onClick={() =>
                                                setShowReverseUploadDebitCreditNoteCanvas(
                                                  true
                                                )
                                              }
                                            >
                                              Upload Reverse Debit/Credit Note
                                            </Button>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="downloadables-container">
                                    {(item.credit_debit_note_number_assignment_status ==
                                      "ASSIGNED" ||
                                      item.credit_debit_note_status ==
                                        "NOT-APPROVED") && (
                                      <div className="vendor-action-buttons-container incoming-purchase-order-invoice-details-table-container">
                                        <Table
                                          bordered
                                          hover
                                          size="sm"
                                          className="table-auto-width"
                                        >
                                          <tbody>
                                            <tr className="catalogues-search-bar-row">
                                              <td
                                                colSpan={2}
                                                className="centered-heading"
                                              >
                                                Prepared Downloadables
                                              </td>
                                            </tr>
                                            <tr className="catalogues-search-bar-row">
                                              <td className="nowrap">
                                                Debit/Credit Note Data (.csv)
                                              </td>
                                              <td className="centered">
                                                <Download
                                                  size={15}
                                                  color="#007bff"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    downloadDebitCreditNoteData(
                                                      item
                                                    )
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>
                                    )}

                                    {item.credit_debit_note_upload_status ==
                                      "UPLOADED" && (
                                      <div className="vendor-action-buttons-container incoming-purchase-order-invoice-details-table-container">
                                        <div className="po-document-section-heading">
                                          Debit/Credit Note Files
                                        </div>

                                        <div className="incoming-purchase-order-file-details-table-container mt-3">
                                          <Table
                                            bordered
                                            hover
                                            size="sm"
                                            className="table-auto-width"
                                          >
                                            <thead>
                                              <tr>
                                                <th>File ID</th>
                                                <th>File Type</th>
                                                <th>Uploaded At</th>
                                                <th>Uploaded By</th>
                                                <th>File Name</th>
                                                <th>Download</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {item.files.map((file, index) => (
                                                <tr
                                                  key={index}
                                                  className="catalogues-search-bar-row"
                                                >
                                                  <td className="nowrap">
                                                    {file.id}
                                                  </td>
                                                  <td>{file.file_type}</td>
                                                  <td>
                                                    {DateTimeUtils.formatDateTime(
                                                      file.updated_at
                                                    )}
                                                  </td>
                                                  <td>
                                                    {file.file_uploaded_by}
                                                  </td>
                                                  <td>{file.file_name}</td>
                                                  <td>
                                                    <Download
                                                      size={15}
                                                      color="#007bff"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        downloadFileFromWeb(
                                                          file.id,
                                                          file.saved_file_name
                                                        )
                                                      } // Add your download handler here
                                                    />
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      <Offcanvas
        show={showDebitCreditNumberCanvas}
        onHide={() => {
          setUseOtherDebitCreditNoteeNumber(false);
          setShowDebitCreditNumberCanvas(false);
        }}
        placement="end"
        className="dispatch-offcanvas"
        backdrop="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="dispatch-offcanvas-title">
              Assign Debit/Credit Note Number
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="invoice-details-container">
            <label>Add Debit/Credit Note Number</label>
            <div className="invoice-number mt-2">
              <div className="financial-year-text">{getFinancialYear()}</div>
              <input
                className="invoice-input"
                name="assigned_invoice_number"
                value={fyDebitCreditNoteNumber}
                onChange={handleFYDebitCreditNoteNumberChange}
                disabled={useOtherDebitCreditNoteeNumber}
                autoComplete="off"
                onKeyPress={handleDebitCreditNoteKeyPress}
              />
            </div>

            <div className="checkbox-container-invoice mt-2">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={useOtherDebitCreditNoteeNumber}
                  onChange={handleCheckboxChange}
                  className="use-other-invoice-number"
                />
                Use Other Debit/Credit ote Number
              </label>
            </div>

            {useOtherDebitCreditNoteeNumber && (
              <label className="mt-4">Add Other Debit/Credit Note Number</label>
            )}
            {useOtherDebitCreditNoteeNumber && (
              <div className="invoice-number mt-2">
                <input
                  className="invoice-input"
                  name="assigned_other_invoice_number"
                  value={otherDebitCreditNoteNumber}
                  onChange={handleOtherDebitCreditNoteNumberChange}
                  autoComplete="off"
                  onKeyPress={handleDebitCreditNoteKeyPress}
                />
              </div>
            )}

            <div className="offcanvas-button-container">
              <button type="button" onClick={submitDebitCreditNoteNumber}>
                Submit
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUploadDebitCreditNoteCanvas}
        onHide={() => {
          setFiles([]);
          setFyDebitCreditNoteNumber("");
          setUseOtherDebitCreditNoteeNumber(false);
          setShowUploadDebitCreditNoteCanvas(false);
        }}
        placement="end"
        className="dispatch-offcanvas"
        backdrop="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="dispatch-offcanvas-title">
              Upload Debit/Credit Note
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="invoice-details-container">
            <div className="upload-invoice-container">
              <span>
                Maximum 1 file is allowed. File size should not exceed 2MB.
                Please ensure, both e-invoice and e-way bill is present in the
                pdf
              </span>{" "}
              <input
                ref={fileInputRef}
                className="mt-4"
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                disabled={files.length == 1}
                // multiple
              />
            </div>

            {files.length > 0 && (
              <div className="upload-invoice-container">
                <div>Selected Files:</div>
                <ul>
                  {files.map((file, index) => (
                    <li key={index} style={{ padding: "4px" }}>
                      {file.name}
                      <XCircleFill
                        size={15}
                        color="#007bff"
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        onClick={() => handleFileRemove(index)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="offcanvas-button-container">
              {selectedDebitCreditNoteItem && (
                <button
                  type="button"
                  onClick={() =>
                    uploadDebitCreditNote(selectedDebitCreditNoteItem)
                  }
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUploadReverseDebitCreditNoteCanvas}
        onHide={() => {
          setReverseDebitCreditNoteNumber("");
          setFiles([]);
          setShowReverseUploadDebitCreditNoteCanvas(false);
        }}
        placement="end"
        className="dispatch-offcanvas"
        backdrop="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="dispatch-offcanvas-title">
              Upload Reverse Debit/Credit Note
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="invoice-details-container">
            <div className="upload-invoice-container">
              <label>Add Reverse Debit/Credit Note Number</label>
              <div className="invoice-number mt-2">
                <input
                  className="invoice-input"
                  name="assigned_other_invoice_number"
                  value={reverseDebitCreditNoteNumber}
                  onChange={handleReverseDebitCreditNoteNumberChange}
                  autoComplete="off"
                />
              </div>
              <div className="mt-4">
                <span>
                  Maximum 1 file is allowed. File size should not exceed 2MB.
                  Please ensure, both e-invoice and e-way bill is present in the
                  pdf
                </span>{" "}
                <input
                  ref={fileInputRef}
                  className="mt-4"
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  disabled={files.length == 1}
                  // multiple
                />
              </div>
            </div>

            {files.length > 0 && (
              <div className="upload-invoice-container">
                <div>Selected Files:</div>
                <ul>
                  {files.map((file, index) => (
                    <li key={index} style={{ padding: "4px" }}>
                      {file.name}
                      <XCircleFill
                        size={15}
                        color="#007bff"
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        onClick={() => handleFileRemove(index)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="offcanvas-button-container">
              {selectedDebitCreditNoteItem && (
                <button
                  type="button"
                  onClick={() =>
                    uploadReverseDebitCreditNoteWithNumber(
                      selectedDebitCreditNoteItem
                    )
                  }
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default PurchaseOrderGrnDocuments;
