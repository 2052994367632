import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./CSS/VendorListingsView.css";
import {
  CostPriceTimelineItem,
  Listing,
  ListingPage,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import { toast } from "react-toastify";
import _, { set } from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { VisibilityOptions } from "./VendorDetails";
import { Images } from "react-bootstrap-icons";

interface ListingImageProps {
  images: string[]; // Define the type of the images prop as an array of strings
}

const ListingImage: React.FC<ListingImageProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Use useMemo to find the first valid image
  const firstValidImageIndex = useMemo(() => {
    for (let i = 0; i < images.length; i++) {
      if (images[i] && images[i].trim().length > 10) {
        return i;
      }
    }
    return -1;
  }, [images]); // Recomputes only if images array changes

  // Set initial valid image index
  useEffect(() => {
    setCurrentImageIndex(firstValidImageIndex);
  }, [firstValidImageIndex]);

  // useCallback to prevent function recreation on every render
  const handleImageError = useCallback(() => {
    setCurrentImageIndex((prevIndex) => {
      let nextIndex = prevIndex + 1;
      while (nextIndex < images.length) {
        if (images[nextIndex] && images[nextIndex].trim().length >= 10) {
          return nextIndex;
        }
        nextIndex++;
      }
      return -1; // No valid image found
    });
  }, [images]); // Runs only if images array changes

  return (
    <div className="listings-img-container">
      {currentImageIndex === -1 ? (
        <img
          src="https://tech.intellozene.com/Media/na.png"
          alt="Product Image"
        />
      ) : (
        <img
          src={images[currentImageIndex]}
          alt="Product Image"
          onError={handleImageError}
        />
      )}
    </div>
  );
};

const ListingCard: React.FC<{
  vendorListing: VendorListing;
  handleCardClick: (listing: VendorListing) => void;
}> = ({ vendorListing, handleCardClick }) => {
  const images = useMemo(
    () => [
      vendorListing.listing.img_wdim,
      vendorListing.listing.img_hd,
      vendorListing.listing.img_white,
      vendorListing.listing.img_link1,
      vendorListing.listing.img_link2,
    ],
    [vendorListing]
  ); // Only recompute if vendorListing changes

  return (
    <div
      className="listings-view-card vendor-listing-card"
      onClick={() => handleCardClick(vendorListing)}
    >
      <div className="listings-img-container">
        <ListingImage images={images} />
      </div>
      <div className="listings-view-card-title">
        <h3
          className={`sku-id-title ${
            vendorListing.listing.sku_type == "MULTI"
              ? ""
              : vendorListing.listing.available_quantity === 0
              ? "not-available"
              : "available"
          }`}
        >
          {vendorListing.listing.sku_id}
        </h3>
      </div>
      <p>OPS Tag: {vendorListing.listing.ops_tag}</p>
      {vendorListing.listing.sku_type == "MULTI" ? (
        <p>MULTI</p>
      ) : (
        <p>Available Quantity: {vendorListing.listing.available_quantity}</p>
      )}

      <p>Cost Price : {vendorListing.cost_price.toString()}</p>

      <p className="last-modified">
        Modified At: {DateTimeUtils.formatDateTime(vendorListing.updated_at)}
      </p>
    </div>
  );
};
const ListingCardSmall: React.FC<{
  vendorListing: VendorListing;
  handleCardClick: (listing: VendorListing) => void;
}> = ({ vendorListing, handleCardClick }) => {
  const images = useMemo(
    () => [
      vendorListing.listing.img_wdim,
      vendorListing.listing.img_hd,
      vendorListing.listing.img_white,
      vendorListing.listing.img_link1,
      vendorListing.listing.img_link2,
    ],
    [vendorListing]
  ); // Only recompute if vendorListing changes

  return (
    <div
      className="vendor-listing-card-small"
      onClick={() => handleCardClick(vendorListing)}
    >
      <div className="vendor-listings-img-container-small">
        <ListingImage images={images} />
      </div>
      <div className="vendor-listings-view-card-title-small">
        <p
          className={`sku-id-title ${
            vendorListing.listing.sku_type == "MULTI"
              ? ""
              : vendorListing.listing.available_quantity === 0
              ? "not-available"
              : "available"
          }`}
        >
          {vendorListing.listing.sku_id}
        </p>
      </div>
      {vendorListing.listing.sku_type == "MULTI" ? (
        <p>MULTI</p>
      ) : (
        <p>Instock : {vendorListing.listing.available_quantity}</p>
      )}
      <p>Cost Price : {vendorListing.cost_price.toString()}</p>
    </div>
  );
};

type ListingsViewProps = {
  localVendorListings: VendorListing[];
  onCostPriceChange: (
    selectedListing: VendorListing,
    newCostPrice: number
  ) => void;
  onRemoveListing: (selectedListing: VendorListing) => void;
  selectedVisibilityOption: VisibilityOptions;
};

export default function VendorListingsView({
  localVendorListings,
  onCostPriceChange,
  onRemoveListing,
  selectedVisibilityOption,
}: ListingsViewProps) {
  const [selectedListing, setSelectedListing] = useState<VendorListing>();
  const [showSkuDetails, setShowSkuDetails] = useState(false);
  const [canRemoveVendorListing, setCanRemoveVendorListing] = useState(false);
  const [newCostPrice, setNewCostPrice] = useState<string>("0");
  const [costPriceTimeline, setCostPriceTimeline] = useState<
    CostPriceTimelineItem[]
  >([]);
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const [show, setShow] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handleClose = () => {
    setShow(false);
    setNewCostPrice("0");
  };

  const handleShow = () => setShow(true);

  const handleCardClick = async (listing: VendorListing) => {
    setSelectedListing(listing);
    handleShow();

    try {
      const response = await vendorsNetworkUtil.getCostPriceTimeLine(
        listing.vendor_id,
        listing.sku_id
      );

      console.log(response);
      setCostPriceTimeline(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCostPrice(event.target.value.toString());
  };

  const saveNewCostPrice = async () => {
    if (
      selectedListing &&
      selectedListing.cost_price.toString() == newCostPrice
    ) {
      toast.error("Current and new Cost price are same.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }
    try {
      if (selectedListing) {
        const tempListing: VendorListing = _.cloneDeep(selectedListing);
        tempListing.cost_price = parseFloat(newCostPrice);
        tempListing.modified_by = userDetailsFromStore.user_details.userId;
        const response =
          await vendorsNetworkUtil.updateCostPriceyByVendorIdAndSkuId(
            tempListing,
            selectedListing.vendor_id,
            selectedListing.sku_id
          );

        console.log(response);
        toast.success("Successfully updated!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        onCostPriceChange(selectedListing, parseFloat(newCostPrice));
        handleClose();
      }
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 422) {
        toast.error("Fields cannot be set empty!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unable to update cost price!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const navigateToListingDetailsPage = () => {
    if (selectedListing)
      window.open(`/listings/all/${selectedListing.sku_id}`, "_blank");
  };

  const navigateToRemoveListingModal = () => {
    if (selectedListing) setCanRemoveVendorListing(true);
  };

  const removeVendorListing = async () => {
    if (selectedListing) {
      try {
        const response = await vendorsNetworkUtil.removeItemFromVendor(
          selectedListing.vendor_id.toString(),
          selectedListing.sku_id
        );
        toast.success("Successfully deleted!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        onRemoveListing(selectedListing);
        setCanRemoveVendorListing(false);
        setShow(false);
      } catch (error) {
        setCanRemoveVendorListing(false);
        console.error("Error in fetching vendor by vendor_id :", error);
        toast.error("Error while deleting!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  return (
    <div>
      {localVendorListings &&
        localVendorListings.length > 0 &&
        selectedVisibilityOption == VisibilityOptions.GRID_LARGE && (
          <div className="vendor-listings-card-container">
            {localVendorListings.map((vendorListing, index) => (
              <ListingCard
                key={index}
                vendorListing={vendorListing}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        )}

      {localVendorListings &&
        localVendorListings.length > 0 &&
        selectedVisibilityOption == VisibilityOptions.GRID_SMALL && (
          <div className="vendor-listings-card-container-small">
            {localVendorListings.map((vendorListing, index) => (
              <ListingCardSmall
                key={index}
                vendorListing={vendorListing}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        )}

      {localVendorListings &&
        localVendorListings.length > 0 &&
        selectedVisibilityOption == VisibilityOptions.LIST_VIEW && (
          <div className="vendor-listings-table-container">
            <div className="vendor-skus-table-container">
              <div className="all-labels-master-data-table">
                <Table bordered hover size="sm" className="table-auto-width">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>SKU ID</th>
                      <th>Ops Tag</th>
                      <th>Available Quantity</th>
                      <th>Cost Price</th>
                      <th>Last Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    {localVendorListings.map((vendorListing, index) => {
                      return (
                        <tr
                          className="catalogues-search-bar-row"
                          onClick={() => handleCardClick(vendorListing)}
                        >
                          <td>{index + 1}</td>
                          <td>{vendorListing.sku_id}</td>
                          <td>{vendorListing.listing.ops_tag}</td>
                          <td>{vendorListing.listing.available_quantity}</td>
                          <td>{vendorListing.cost_price}</td>

                          <td>
                            {DateTimeUtils.formatDateTime(
                              vendorListing.updated_at
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}

      {localVendorListings && localVendorListings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}

      {/* {showSkuDetails && selectedListing && (
        <div className="overlay">
          <ListingDetails listing={selectedListing} />
        </div>
      )} */}

      <Offcanvas
        show={show}
        onHide={handleClose}
        // backdrop="static"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="offcanvas-title-and-navigator">
              <div className="offcanvas-title">
                {selectedListing && selectedListing.sku_id}
              </div>
              <div
                className="offcanvas-navigator mt-2"
                onClick={navigateToListingDetailsPage}
              >
                See details {`>`}
              </div>
              <div
                className="offcanvas-navigator red mt-1"
                onClick={navigateToRemoveListingModal}
              >
                Remove Mapping {">"}
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body">
            <div className="change-cost-price-container">
              <div className="grid-1-cells">
                <div className="grid-item-details-card change-cost-price-card">
                  <label>Change Cost Price</label>
                  <input
                    type="number"
                    name="new_cost_price"
                    value={newCostPrice}
                    onChange={handleChange}
                    // disabled={!vendorDetailsEditMode}
                  />
                </div>
              </div>
              <div className="offcanvas-button-container">
                <button type="button" onClick={saveNewCostPrice}>
                  Save
                </button>
              </div>
            </div>
            <div className="previous-cost-price-container">
              <label>Cost Price Timeline - </label>
              {/* <div className="grid-item-details-card">
                <div className="cost-price-timeline-item">
                  <div className="cp-ls">
                    <div className="cost-price">
                      {selectedListing && selectedListing.cost_price}
                    </div>
                    <div className="last-modified-by">
                      {selectedListing && selectedListing.modified_by}
                    </div>
                  </div>
                  <div className="last-modified">
                    {DateTimeUtils.formatDateTime(
                      selectedListing && selectedListing.updated_at
                    )}
                  </div>
                </div>
              </div> */}
              <div className="grid-1-cells">
                {costPriceTimeline.length == 0 && selectedListing ? (
                  <div className="grid-item-details-card">
                    <div className="cost-price-timeline-item">
                      <div className="cp-ls">
                        <div className="cost-price">
                          {selectedListing.cost_price}
                        </div>
                        <div className="last-modified-by">
                          <b>INIT PRICE</b>
                        </div>
                      </div>
                      <div className="last-modified"></div>
                    </div>
                  </div>
                ) : (
                  costPriceTimeline.map((cp, index) => (
                    <div className="grid-item-details-card" key={cp.id}>
                      <div className="cost-price-timeline-item">
                        <div className="cp-ls">
                          <div className="cost-price">{cp.cost_price}</div>
                          <div className="last-modified-by">
                            {cp.modified_by}
                          </div>
                        </div>
                        <div className="last-modified">
                          {DateTimeUtils.formatDateTime(cp.updated_at)}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={canRemoveVendorListing}
        onHide={() => setCanRemoveVendorListing(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modify Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure about listing removal ? Note that cost price history
          won't be deleted.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCanRemoveVendorListing(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => removeVendorListing()}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
