import React, { useEffect, useState } from "react";

import "./CSS/VendorsHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import VendorsAll from "./VendorsAll";
import VendorsAdd from "./VendorsAdd";
import PurchaseOrdersOpen from "./PurchaseOrdersAll";
import PurchaseOrdersListingView from "./PurchaseOrdersListingView";
import PurchaseOrdersAdd from "./PurchaseOrdersAdd";
import VendorDetails from "./VendorDetails";
import Header from "../Header";
import VendorPurchaseOrders from "./VendorPurchaseOrders";
import PurchaseOrderPublished from "./PurchaseOrderPublished";
import PurchaseOrderDetailsSubHome from "./PurchaseOrderDetailsSubHome";
import PurchaseOrderGrnHome from "./PurchaseOrderGrnHome";

interface RouteParams {
  operation: string;
  po_id: string;
}

function PurchaseOrderDetailsHome() {
  const dispatch: AppDispatch = useDispatch();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const history = useHistory();
  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    // }
  }, []);

  const { operation, po_id } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(operation);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.replace(`/vendor-hub/all-purchase-orders/${item}/${po_id}`);
  };

  useEffect(() => {
    setSelectedItem(operation);
  }, [operation]);

  return (
    <div className="overlay-purchase-order-details">
      <Header />
      <div>
        <div className="nav-container">
          <nav className="navbar-vendors">
            <ul className="navbar-menu">
              <li
                className={`navbar-item ${
                  selectedItem === "details" ? "active" : ""
                }`}
                onClick={() => handleItemClick("details")}
              >
                Purchase Order Details
              </li>
              <li
                className={`navbar-item ${
                  selectedItem === "grn" ? "active" : ""
                }`}
                onClick={() => handleItemClick("grn")}
              >
                GRN
              </li>
            </ul>
          </nav>
        </div>

        {selectedItem == "details" && <PurchaseOrderDetailsSubHome />}
        {selectedItem == "grn" && <PurchaseOrderGrnHome />}
      </div>
    </div>
  );
}

export default PurchaseOrderDetailsHome;
