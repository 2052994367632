import React, { useState, useEffect } from "react";
import "../page css components/Header.css";
import logo from "../Drawables/Logos/eCraft_logo_main.png";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { useHistory } from "react-router-dom";
import SideBarButton from "./SidebarButton";

function LoginPageHeader() {
  <link
    href="https://fonts.googleapis.com/css?family=Schoolbell&v1"
    rel="stylesheet"
  />;
  var img_url =
    "https://powerusers.microsoft.com/t5/image/serverpage/image-id/98171iCC9A58CAF1C9B5B9/image-size/large/is-moderation-mode/true?v=v2&px=999";

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  const navigate = () => {
    if ("search") {
      history.push("search");
    } else {
      alert("Route path not available for this item yet");
    }
  };

  const dispatch = useAppDispatch();

  const window_small = useAppSelector((state) => state.window_size);
  // const user_url =  useAppSelector((state) => state.UserProfileUrl)
  // const user_name =  useAppSelector((state) => state.LoginUserName)
  // const user_id =  useAppSelector((state) => state.LoginUserId)
  // const user_mobile = useAppSelector((state) => state.UserMobile)
  // const user_pass = useAppSelector((state) => state.UserPass)

  useEffect(() => {
    // Axios({
    //   method: "POST",
    //   url: `${requestHeaders.usersAndValidation}${requests.employeeLogin}`,
    //   headers: {
    //     "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    //   },
    //   data: qs.stringify({
    //     mobile_no: `${user_mobile}`,
    //     password: `${user_pass}`,
    //   }),
    // })
    //   .then((response) => {
    //     if(response.data.message== "You have been blocked."){
    //       dispatch(islogin(false));
    //       navigate("userlogin");
    //     }
    //   })
    //   .catch((error) => {
    //   });
  });

  // const Admin = useAppSelector((state)=> state.UserAdmin)
  // const Manufacturing_user = useAppSelector((state)=> state.UserManufacturing)
  // const Bulk_ops_user = useAppSelector((state)=> state.UserBulkOps)
  // const Regular_user = useAppSelector((state)=> state.UserRegular)

  var img_url =
    "https://powerusers.microsoft.com/t5/image/serverpage/image-id/98171iCC9A58CAF1C9B5B9/image-size/large/is-moderation-mode/true?v=v2&px=999";

  return (
    <div className="header">
      <div className="header__left">
        <div className="header__heading">
          {/* {window_small ? <img  className ={window_small ?"logo_small" :"logo"} src={logo} alt ="eCraftIndia Admin"/> :null } */}

          <div className="main_heading">eCraft Zap</div>
        </div>
      </div>
    </div>
  );
}

export default LoginPageHeader;
