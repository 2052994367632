import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./CSS/PurchaseOrderGrnDetails_v2.css";
import {
  Accordion,
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import {
  ErrorMessageBody,
  GrnDocument,
  GrnItemWithListing,
  ImageHashMapState,
  PoItem,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemWithLisiting,
  PurchaseOrderItemWithLisitingAndGrnData,
  Specialty,
  Vendor,
  VendorListing,
  VendorPurchaseOrderGrn,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _, { update } from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";

import Header from "../Header";
import VendorListingsView from "./VendorListingsView";
import VendorsListingSearchBar from "./VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import PurchaseOrderPDF from "./PurchaseOrderPDF";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import PurchaseOrdersPublishedItemsView from "./PurchaseOrdersPublishedItemsView";
import PurchaseOrderPDF_v2 from "./PurchaseOrderPDF_v2";
import PurchaseOrderPDF_v3 from "./PurchaseOrderPDF_v3";
import PurchaseOrderPDF_v4 from "./PurchaseOrderPDF_v4";
import CatalogueNetworkUtils from "../Catalogues/NetworkUtils/CatalogueNetworkUtils";
import { ImageHashMapVendorPurchaseOrder__Action } from "../../state/action-creators";
import {
  ImageHashMapActionTypes,
  VendorPurchaseOrderImageHashMapActionTypes,
} from "../../state/action-types";
import { AppDispatch } from "../../state";
import { VisibilityOptions } from "./VendorDetails";
import {
  Download,
  Grid3x3GapFill,
  GridFill,
  ListUl,
  XCircleFill,
} from "react-bootstrap-icons";
import PurchaseOrderGrnItemsView from "./PurchaseOrderGrnItemsView";
import { current } from "@reduxjs/toolkit";
import PurchaseOrderGrnItemsAddedView from "./PurchaseOrderGrnItemsAddedView";

interface RouteParams {
  grn_id: string;
}

function PurchaseOrderGrnDetails_v2() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const chunkSize = 4;
  const { grn_id } = useParams<RouteParams>();
  const dispatch: AppDispatch = useDispatch();

  const invoiceInputQuantityRef = useRef<HTMLInputElement>(null);

  const [currentGrn, setCurrentGrn] = useState<VendorPurchaseOrderGrn | null>(
    null
  );

  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<PurchaseOrder | null>(null);

  const [purchaseOrderItemsWithListing, setPurchaseOrderItemsWithListing] =
    useState<PurchaseOrderItemWithLisitingAndGrnData[]>([]);
  const [shortageDebitNotesListing, setShortageDebitNotesListing] = useState<
    PurchaseOrderItemWithLisitingAndGrnData[]
  >([]);
  const [damagesDebitNotesListing, setDamagesDebitNotesListing] = useState<
    PurchaseOrderItemWithLisitingAndGrnData[]
  >([]);
  const [
    priceDifferenceDebitNotesWithListing,
    setPriceDifferenceDebitNotesWithListing,
  ] = useState<PurchaseOrderItemWithLisitingAndGrnData[]>([]);
  const [creditNotesWithListing, setCreditNotesWithListing] = useState<
    PurchaseOrderItemWithLisitingAndGrnData[]
  >([]);

  const [
    filteredPurchaseOrderItemsWithListing,
    setFilteredPurchaseOrderItemsWithListings,
  ] = useState<PurchaseOrderItemWithLisitingAndGrnData[]>([]);

  const [selectedVisibilityOption, setSelectedVisibilityOption] = useState(
    VisibilityOptions.LIST_VIEW
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);

  const [selectedImage, setSelectedImage] = useState("img_hd");
  const [selectedListing, setSelectedListing] =
    useState<PurchaseOrderItemWithLisitingAndGrnData>();
  const [showGrnInputCanvas, setShowGrnInputCanvas] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [quantityInInvoice, setQuantityInInvoice] = useState<
    number | undefined
  >(undefined);
  const [acceptedQuantity, setAcceptedQuantity] = useState<number | undefined>(
    undefined
  );
  const [rejectedQuantity, setRejectedQuantity] = useState<number | undefined>(
    undefined
  );
  const [productPrice, setProductPrice] = useState<number | undefined>(
    undefined
  );
  const [taxRate, setTaxRate] = useState<number | undefined>(undefined);
  const [auditProductPrice, setAuditProductPrice] = useState<
    number | undefined
  >(undefined);

  const [showCloseGrnCanvas, setShowCloseGrnCanvas] = useState<boolean>(false);
  const [showAuditGrnCanvas, setShowAuditGrnCanvas] = useState<boolean>(false);
  const [grnDocuments, setGrnDocuments] = useState<GrnDocument[]>([]);

  const [grnItemsWithListing, setGrnItemsWithListing] = useState<
    GrnItemWithListing[]
  >([]);
  const [
    showShortQuantityConfirmationDialog,
    setShowShortQuantityConfirmationDialog,
  ] = useState<boolean>(false);
  const [isShortQuantityAcknowledged, setShortQuantityAcknowledged] =
    useState<boolean>(false);

  const [isShortQuantityDebitNoteApproved, setShortQuantityDebitNoteApproved] =
    useState<boolean>(false);
  const [
    isDamagedQuantityDebitNoteApproved,
    setDamagedQuantityDebitNoteApproved,
  ] = useState<boolean>(false);
  const [
    isPriceDifferenceDebitNoteApproved,
    setPriceDifferenceDebitNoteApproved,
  ] = useState<boolean>(false);
  const [isCreditNoteApproved, setIsCreditNoteApproved] =
    useState<boolean>(false);
  const [
    showAuditClosureConfirmationDialogue,
    setShowAuditClosureConfirmationDialogue,
  ] = useState<boolean>(false);

  const [currentVendor, setCurrentVendor] = useState<Vendor>({
    id: 0,
    vendor_name: "",
    vendor_address_line: "",
    vendor_city: "",
    vendor_state: "",
    vendor_postal_code: "",
    vendor_gstin: "",
    vendor_contact_number: "",
    created_by: "",
    modified_by: "",
    created_at: "",
    updated_at: "",
    specialties: [],
  });

  const history = useHistory();
  const location = useLocation();
  const catalogueNetworkUtils = new CatalogueNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const purchaseOrderNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [files, setFiles] = useState<File[]>([]);
  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      const fileArray = Array.from(uploadedFiles);
      if (fileArray.length + files.length > 2) {
        alert("You can upload a maximum of 2 files.");
      } else {
        setFiles([...files, ...fileArray]);
      }
    }
  };

  const handleFileRemove = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const fetchGrnByGrnId = async (grnId: string) => {
      try {
        const response = await purchaseOrderNetworkUtil.getGrnByGrnId(grnId);
        setCurrentGrn(response.data);
      } catch (error) {
        console.error("Error in fetching GRN by grn id :", error);
      }
    };

    fetchGrnByGrnId(grn_id);
  }, [grn_id]);

  useEffect(() => {
    const fetchPurchaseOrderByPOId = async (poId: number) => {
      try {
        const response = await purchaseOrderNetworkUtil.getPurchaseOrderById(
          poId.toString()
        );
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    const fetchVendorGrnInvoiceFiles = async (grn_id: any) => {
      try {
        const response =
          await purchaseOrderNetworkUtil.fetchVendorGrnInvoiceFiles(grn_id);

        setGrnDocuments(response.data);
        // createDocumentMap(response.data);
      } catch (error) {
        console.error("Error in getting grn documents : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    };

    if (currentGrn) {
      fetchPurchaseOrderByPOId(currentGrn.po_id);
      if (currentGrn.grn_status == "CLOSED") {
        fetchVendorGrnInvoiceFiles(grn_id);
      }
    }
  }, [currentGrn]);

  useEffect(() => {
    const fetchVendorByVendorId = async (vendor_id: string) => {
      try {
        const response = await vendorsNetworkUtil.getVendorByVendorId(
          vendor_id
        );
        console.log(response.data);
        setCurrentVendor(response.data);
      } catch (error) {
        console.error("Error in fetching vendor by vendor_id :", error);
      }
    };

    const getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId =
      async (poId: number, grn_id: string) => {
        try {
          const response =
            await purchaseOrderNetworkUtil.getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId(
              poId.toString()
            );

          dispatch(
            ImageHashMapVendorPurchaseOrder__Action(
              VendorPurchaseOrderImageHashMapActionTypes.INIT_MAP,
              {
                key: "",
                value: 0,
                purchaseOrderItemWithListings: response.data,
              }
            )
          );
          setPurchaseOrderItemsWithListing(response.data);
          setFilteredPurchaseOrderItemsWithListings(response.data);
          getGrnItemsWithListing(grn_id);
          setIsLoading(false);
        } catch (error) {
          toast.error(
            "Error while fetching purchase order items with listing.!",
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
            }
          );
          console.error(
            "Error in getting purchase order items by poId : ",
            error
          );
          setShowLoadingPage(false);
        }
      };

    const getGrnItemsWithListing = async (grnId: string) => {
      try {
        const response = await purchaseOrderNetworkUtil.getGrnItemsWithListing(
          grnId
        );

        setGrnItemsWithListing(response.data);
        // setPurchaseOrderItemsWithListing(response.data);
        // setFilteredPurchaseOrderItemsWithListings(response.data);
        setShowLoadingPage(false);
      } catch (error) {
        console.error(
          "Error in getting purchase order items by poId : ",
          error
        );
        setShowLoadingPage(false);
      }
    };

    if (currentPurchaseOrder) {
      if (
        currentPurchaseOrder.status == "PENDING" ||
        currentPurchaseOrder.status == "MARKED_MODIFICATION"
      ) {
        history.push(
          `/vendor-hub/all-purchase-orders/details/${currentPurchaseOrder.po_id}`
        );
        toast.error(
          "Cannot proceed with GRN, purchase order is not published",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        return;
      }

      fetchVendorByVendorId(currentPurchaseOrder.vendor_id.toString());
      getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId(
        currentPurchaseOrder.po_id,
        grn_id
      );
      // getGrnItemsWithListing(grn_id);
    }
  }, [currentPurchaseOrder]);

  useEffect(() => {
    console.log(grnItemsWithListing);
    if (!grnItemsWithListing || purchaseOrderItemsWithListing.length == 0) {
      return;
    }
    setTimeout(() => {
      console.log("here inside");
      const skuMap = new Map<string, GrnItemWithListing>();

      grnItemsWithListing.forEach((item) => {
        skuMap.set(item.sku_id, item);
      });

      const updatedFilteredPurchaseOrderItems =
        filteredPurchaseOrderItemsWithListing.map((item) => ({
          ...item,
          is_selected: skuMap.has(item.sku_id),
          currentGrnItem: skuMap.get(item.sku_id) || null,
        }));

      const updatedPurchaseOrderItems = purchaseOrderItemsWithListing.map(
        (item) => ({
          ...item,
          is_selected: skuMap.has(item.sku_id),
          currentGrnItem: skuMap.get(item.sku_id) || null,
        })
      );

      setFilteredPurchaseOrderItemsWithListings(
        updatedFilteredPurchaseOrderItems
      );
      setPurchaseOrderItemsWithListing(updatedPurchaseOrderItems);
    }, 200); // Add a 500ms delay
  }, [grnItemsWithListing]);

  const handleSearch = (query: string) => {
    console.log("purchase order item searched ", query);
    if (query.length == 0)
      setFilteredPurchaseOrderItemsWithListings(
        _.cloneDeep(purchaseOrderItemsWithListing)
      );
    else
      setFilteredPurchaseOrderItemsWithListings(
        filterPurchaseOrderItemsWithListings(query)
      );
  };

  const filterPurchaseOrderItemsWithListings = (query: string) => {
    query = query.toLowerCase();
    return purchaseOrderItemsWithListing.filter((poItem) => {
      const skuMatches = poItem.listing.sku_id.toLowerCase().includes(query);

      const opsTagMatches = poItem.listing.ops_tag
        .toLowerCase()
        .includes(query);

      return skuMatches || opsTagMatches;
    });
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleImageError = (imageKey: string) => {
    if (selectedListing) {
      const updatedListing = {
        ...selectedListing,
        listing: {
          ...selectedListing.listing, // Create a new object for `listing`
          [imageKey]: "http://tech.intellozene.com/Media/na.png",
        },
      };

      setPurchaseOrderItemsWithListing((prevItems) =>
        prevItems.map((item) =>
          item.sku_id === updatedListing.sku_id ? updatedListing : item
        )
      );

      setFilteredPurchaseOrderItemsWithListings((prevItems) =>
        prevItems.map((item) =>
          item.sku_id === updatedListing.sku_id ? updatedListing : item
        )
      );

      setSelectedListing(updatedListing);
    }
  };

  const onCardClick = (listing: PurchaseOrderItemWithLisitingAndGrnData) => {
    setSelectedListing(listing);
    setShowGrnInputCanvas(true);
  };

  useEffect(() => {
    if (showGrnInputCanvas) {
      setTimeout(() => {
        if (invoiceInputQuantityRef.current) {
          invoiceInputQuantityRef.current.focus();
          if (selectedListing && selectedListing.currentGrnItem) {
            setQuantityInInvoice(
              selectedListing.currentGrnItem.invoice_quantity
            );
            setAcceptedQuantity(
              selectedListing.currentGrnItem.accepted_quantity
            );
            setRejectedQuantity(
              selectedListing.currentGrnItem.rejected_quantity
            );
            setTaxRate(selectedListing.currentGrnItem.tax_rate);
            setProductPrice(selectedListing.currentGrnItem.received_price);
            setAuditProductPrice(selectedListing.currentGrnItem.audit_price);
          }
        }
      }, 100);
    } else {
      setQuantityInInvoice(undefined);
      setAcceptedQuantity(undefined);
      setRejectedQuantity(undefined);
      setProductPrice(undefined);
      setTaxRate(undefined);
    }
  }, [showGrnInputCanvas, selectedListing]);

  const hashMap: ImageHashMapState = useAppSelector(
    (state) => state.purchase_order_image_hashmap_store
  );

  const submitGrnItemToWeb = async (
    quantityInInvoice: number | undefined,
    acceptedQuantity: number | undefined,
    rejectedQuantity: number | undefined,
    productPrice: number | undefined,
    taxRate: number | undefined,
    submittedListing: PurchaseOrderItemWithLisiting
  ) => {
    const queryParams: Record<string, any> = {
      quantity_in_invoice: quantityInInvoice, // New page value
      accepted_quantity: acceptedQuantity, // New count value
      rejected_quantity: rejectedQuantity,
      sku_id: submittedListing.sku_id,
      product_price: productPrice,
      tax_rate: taxRate,
      actual_demand: submittedListing.required_quantity, //passing for concurrency check
    };

    try {
      const response = await purchaseOrderNetworkUtil.submitGrnItemToWeb(
        queryParams,
        userDetailsFromStore.user_details.userId,
        grn_id
      );

      // setAllVendorPurchaseOrderGrns(response.data)
      setGrnItemsWithListing([...grnItemsWithListing, response.data]);
      setShowShortQuantityConfirmationDialog(false);
      setShortQuantityAcknowledged(false);
      setSelectedImage("img_hd");
      setShowLoadingPage(false);

      toast.success("Success!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in fetching GRN details : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const updateGrnItemOnWeb = async (
    quantityInInvoice: number | undefined,
    acceptedQuantity: number | undefined,
    rejectedQuantity: number | undefined,
    productPrice: number | undefined,
    taxRate: number | undefined,
    submittedListing: PurchaseOrderItemWithLisiting
  ) => {
    const queryParams: Record<string, any> = {
      quantity_in_invoice: quantityInInvoice, // New page value
      accepted_quantity: acceptedQuantity, // New count value
      rejected_quantity: rejectedQuantity,
      sku_id: submittedListing.sku_id,
      product_price: productPrice,
      tax_rate: taxRate,
      actual_demand: submittedListing.required_quantity, //passing for concurrency check
    };

    try {
      const response = await purchaseOrderNetworkUtil.updateGrnItemOnWeb(
        queryParams,
        userDetailsFromStore.user_details.userId,
        grn_id
      );

      // setAllVendorPurchaseOrderGrns(response.data)
      setGrnItemsWithListing([...grnItemsWithListing, response.data]);
      setShowShortQuantityConfirmationDialog(false);
      setShortQuantityAcknowledged(false);
      setSelectedImage("img_hd");
      setShowLoadingPage(false);
      toast.success("Success!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in fetching GRN details : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const deleteGrnItemOnWeb = async (
    submittedListing: PurchaseOrderItemWithLisiting
  ) => {
    const queryParams: Record<string, any> = {
      sku_id: submittedListing.sku_id,
    };

    try {
      const response = await purchaseOrderNetworkUtil.deleteGrnItemOnWeb(
        queryParams,
        userDetailsFromStore.user_details.userId,
        grn_id
      );

      // setAllVendorPurchaseOrderGrns(response.data)
      // setGrnItemsWithListing([...grnItemsWithListing, response.data]);
      // setGrnItemsWithListing((prevGrns) =>
      //   prevGrns.filter((grn) => grn.sku_id !== submittedListing.sku_id)
      // );

      setGrnItemsWithListing((prevGrns) => [
        ...prevGrns.filter((grn) => grn.sku_id !== submittedListing.sku_id),
      ]);

      setShowLoadingPage(false);
      toast.success("Success!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in fetching GRN details : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const updateAuditedPriceOnWeb = async (
    auditProductPrice: number | undefined,
    submittedListing: PurchaseOrderItemWithLisiting
  ) => {
    // setShowLoadingPage(true);
    const queryParams: Record<string, any> = {
      audit_price: auditProductPrice,
      sku_id: submittedListing.sku_id,
      actual_demand: submittedListing.required_quantity, //passing for concurrency check
    };

    try {
      const response = await purchaseOrderNetworkUtil.updateAuditedPriceOnWeb(
        queryParams,
        userDetailsFromStore.user_details.userId,
        grn_id
      );

      // setAllVendorPurchaseOrderGrns(response.data)
      setGrnItemsWithListing([...grnItemsWithListing, response.data]);
      setShowLoadingPage(false);
      setAuditProductPrice(undefined);
      toast.success("Success!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in fetching GRN details : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setAuditProductPrice(undefined);
    }
  };

  const checkSubmittedListing = (
    submittedListing: PurchaseOrderItemWithLisiting
  ) => {
    if (
      quantityInInvoice === undefined ||
      acceptedQuantity === undefined ||
      rejectedQuantity === undefined ||
      productPrice === undefined ||
      taxRate === undefined
    ) {
      toast.error("All fields are required!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return false;
    }

    if (acceptedQuantity + rejectedQuantity > quantityInInvoice) {
      toast.error(
        "Accepted + Rejected Quantity cannot be greater than Invoice Quantity!",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      return false;
    }

    if (
      quantityInInvoice <= 0 ||
      acceptedQuantity < 0 ||
      rejectedQuantity < 0 ||
      productPrice <= 0
    ) {
      toast.error(
        "Invalid values! Please check the quantities and product price.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      return false;
    }

    if (isNaN(Number(taxRate))) {
      toast.error("Tax Rate Must Be a Number.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });

      return false;
    } else if (
      Number(taxRate) != 0 &&
      Number(taxRate) != 3 &&
      Number(taxRate) != 5 &&
      Number(taxRate) != 12 &&
      Number(taxRate) != 18 &&
      Number(taxRate) != 28
    ) {
      toast.error("invalid tax_rate, it must be either 3, 5, 12, 18, 28", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });

      return false;
    }

    if (
      acceptedQuantity + rejectedQuantity != quantityInInvoice &&
      !isShortQuantityAcknowledged
    ) {
      setShowShortQuantityConfirmationDialog(true);
      toast.error("Please acknowledge the Received short quantity.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return false;
    }

    return true;
  };

  const checkAndSubmitGrnItem = (
    submittedListing: PurchaseOrderItemWithLisiting
  ) => {
    setShowLoadingPage(true);
    if (checkSubmittedListing(submittedListing)) {
      setShowGrnInputCanvas(false);

      submitGrnItemToWeb(
        quantityInInvoice,
        acceptedQuantity,
        rejectedQuantity,
        productPrice,
        taxRate,
        submittedListing
      );
    } else {
      setShowLoadingPage(false);
    }
  };

  const deleteGrnItem = (submittedListing: PurchaseOrderItemWithLisiting) => {
    setShowLoadingPage(true);
    setShowGrnInputCanvas(false);
    deleteGrnItemOnWeb(submittedListing);
  };

  const checkAndUpdateGrnItem = (
    submittedListing: PurchaseOrderItemWithLisiting
  ) => {
    setShowLoadingPage(true);
    if (checkSubmittedListing(submittedListing)) {
      setShowGrnInputCanvas(false);
      updateGrnItemOnWeb(
        quantityInInvoice,
        acceptedQuantity,
        rejectedQuantity,
        productPrice,
        taxRate,
        submittedListing
      );
    } else {
      setShowLoadingPage(false);
    }
  };

  const checkAndUpdateAuditedPrice = (
    submittedListing: PurchaseOrderItemWithLisiting
  ) => {
    setShowLoadingPage(true);
    if (selectedListing && selectedListing.currentGrnItem) {
      if (auditProductPrice === undefined) {
        toast.error("Audit Price cannot be undefined!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        setShowLoadingPage(false);
        return;
      }

      if (auditProductPrice <= 0) {
        toast.error("Invalid audit price! Please check again.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        setShowLoadingPage(false);
        return;
      }

      if (auditProductPrice == selectedListing.currentGrnItem.received_price) {
        toast.error(
          "Invalid audit price! Audited price and received price cannot be same.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        setShowLoadingPage(false);
        return;
      }

      setShowGrnInputCanvas(false);

      updateAuditedPriceOnWeb(auditProductPrice, submittedListing);
    }
  };

  const uploadFilesAndCloseGrn = async () => {
    if (files.length === 0) {
      toast.error("Error! No invoice file was selected.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowLoadingPage(false);
      return;
    }

    try {
      //uploading files first;
      try {
        const response = await purchaseOrderNetworkUtil.uploadGrnInvoiceFiles(
          files,
          userDetailsFromStore.user_details.userId,
          grn_id
        );
        toast.success("Files successfully uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        setShowLoadingPage(false);
        // updateTransferListing(response.data["paths"], transferListing);
      } catch (error) {
        console.log(error);
        toast.error(
          "Unknown error while trying to upload files! Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        setShowLoadingPage(false);
        return;
      }
      // await delay(60000);

      const response = await purchaseOrderNetworkUtil.closeVendorGrn(
        userDetailsFromStore.user_details.userId,
        grn_id
      );

      // const addedPurchaseOrder: IncomingPurchaseOrder = response.data;
      // history.push(
      //   `/incomingPos/addPo/addPoDetails/${addedPurchaseOrder.po_number}`
      // );
      window.location.reload();

      toast.success("GRN closed successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowCloseGrnCanvas(false);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        if (err.response.data && err.response.status == 400) {
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Error! Ensure no fields are left empty.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    }

    setShowLoadingPage(false);
  };

  const downloadFileFromWeb = async (file_id: any, saved_file_name: string) => {
    try {
      const response =
        await purchaseOrderNetworkUtil.downloadVendorGrnFileFromWeb(file_id);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = saved_file_name; // Use the original file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setShowDownloadPage(false);
    } catch (error) {
      console.error("Error in downloading file : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
    }
  };

  const prepareDebitAndCreditNoteList = (
    purchaseOrderItems: PurchaseOrderItemWithLisitingAndGrnData[]
  ) => {
    const shortageDebitNotes: PurchaseOrderItemWithLisitingAndGrnData[] = [];
    const damagesDebitNotes: PurchaseOrderItemWithLisitingAndGrnData[] = [];
    const priceDifferenceDebitNotes: PurchaseOrderItemWithLisitingAndGrnData[] =
      [];
    const creditNotes: PurchaseOrderItemWithLisitingAndGrnData[] = [];

    purchaseOrderItems.forEach((poItem) => {
      const grnItem = poItem.currentGrnItem;
      if (!grnItem) return;

      if (
        grnItem.accepted_quantity + grnItem.rejected_quantity <
        grnItem.invoice_quantity
      ) {
        shortageDebitNotes.push(poItem);
      }

      if (grnItem.rejected_quantity > 0) {
        damagesDebitNotes.push(poItem);
      }

      if (
        grnItem.audit_price != null &&
        grnItem.audit_price < grnItem.received_price
      ) {
        priceDifferenceDebitNotes.push(poItem);
      }

      if (grnItem.audit_price > grnItem.received_price) {
        creditNotes.push(poItem);
      }
    });

    setShortageDebitNotesListing(shortageDebitNotes);
    setDamagesDebitNotesListing(damagesDebitNotes);
    setPriceDifferenceDebitNotesWithListing(priceDifferenceDebitNotes);
    setCreditNotesWithListing(creditNotes);
  };

  const submitForAuditClosure = async () => {
    //submit all the debit and credit notes on web.
    const queryParams: Record<string, any> = {};

    if (shortageDebitNotesListing.length > 0) {
      queryParams["shortage_debit_note"] = isShortQuantityDebitNoteApproved
        ? "approved"
        : "not-approved";
    }

    if (damagesDebitNotesListing.length > 0) {
      queryParams["damages_debit_note"] = isDamagedQuantityDebitNoteApproved
        ? "approved"
        : "not-approved";
    }

    if (priceDifferenceDebitNotesWithListing.length > 0) {
      queryParams["price_difference_debit_note"] =
        isPriceDifferenceDebitNoteApproved ? "approved" : "not-approved";
    }

    if (creditNotesWithListing.length > 0) {
      queryParams["credit_note"] = isCreditNoteApproved
        ? "approved"
        : "not-approved";
    }

    // setShowLoadingPage(true);
    try {
      const response = await purchaseOrderNetworkUtil.submitForAuditClosure(
        queryParams,
        userDetailsFromStore.user_details.userId,
        grn_id
      );

      // setAllVendorPurchaseOrderGrns(response.data)
      setGrnItemsWithListing([...grnItemsWithListing, response.data]);
      setShowGrnInputCanvas(false);
      setShowLoadingPage(false);
      setAuditProductPrice(undefined);
      toast.success("Success!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      window.location.reload();
    } catch (error) {
      console.error("Error in fetching GRN details : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setAuditProductPrice(undefined);
    }
  };

  return (
    <div>
      <div className="vendor-details">
        <div className="main-container-grn-details-page">
          <div className="quicklinks-container">
            <div className="po-id-link">Quick Links ~</div>
            <div className="po-id-link">
              <a href="/vendor-hub/grn/all-grns">All GRNs</a>
            </div>
            <span> | </span>
            <div className="po-id-link">
              <a href="/vendor-hub/all-vendors">All Vendors</a>
            </div>
            <span> | </span>
            <div className="po-id-link">
              {currentPurchaseOrder && (
                <a
                  href={`/vendor-hub/all-vendors/${currentPurchaseOrder.vendor_id}/vendor-po`}
                >
                  Vendor Purchase Orders
                </a>
              )}
            </div>
            <span> | </span>
            <div className="po-id-link">
              {currentPurchaseOrder && (
                <a
                  href={`/vendor-hub/all-purchase-orders/grn/${currentPurchaseOrder.po_id}`}
                >
                  GRN Home
                </a>
              )}
            </div>
          </div>

          {purchaseOrderItemsWithListing.length > 0 &&
            currentPurchaseOrder &&
            currentVendor &&
            currentGrn && (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {" "}
                    <Badge bg="secondary" className="po_status_pill mr-1">
                      PO Number : {currentPurchaseOrder.po_id}
                    </Badge>
                    <Badge bg="secondary" className="po_status_pill mr-1">
                      GRN Id : {currentGrn.grn_id}
                    </Badge>
                    <Badge bg="secondary" className="po_status_pill mr-1">
                      Vendor Invoice Number : {currentGrn.vendor_invoice_number}
                    </Badge>
                    {currentGrn.grn_status == "OPEN" && (
                      <Badge bg="warning" className="po_status_pill mr-1">
                        Grn Status : OPEN
                      </Badge>
                    )}
                    {currentGrn.grn_status == "CLOSED" && (
                      <Badge bg="success" className="po_status_pill mr-1">
                        Grn Status : CLOSED
                      </Badge>
                    )}
                    {/* {currentPurchaseOrder.status == "PENDING_PUBLISHED" && (
                      <Badge bg="primary" className="po_status_pill mr-1">
                        PO Status : PUBLISHED
                      </Badge>
                    )}
                    {currentPurchaseOrder.status == "MARKED_CANCELLED" && (
                      <Badge bg="dark" className="po_status_pill mr-1">
                        PO Status : MARKED CANCELLED
                      </Badge>
                    )}
                    {currentPurchaseOrder.status == "PENDING" && (
                      <Badge bg="danger" className="po_status_pill mr-1">
                        PO Status : PENDING
                      </Badge>
                    )}
                    {currentPurchaseOrder.status == "MARKED_MODIFICATION" && (
                      <Badge bg="warning" className="po_status_pill mr-1">
                        PO Status : MARKED FOR MODIFICATION
                      </Badge>
                    )} */}
                    {currentGrn.grn_status == "CLOSED" &&
                      currentGrn.grn_audit_status == "OPEN" && (
                        <Badge bg="warning" className="po_status_pill mr-1">
                          Grn Audit Status : OPEN
                        </Badge>
                      )}
                    {currentGrn.grn_status == "CLOSED" &&
                      currentGrn.grn_audit_status == "CLOSED" && (
                        <Badge bg="success" className="po_status_pill mr-1">
                          Grn Audit Status : CLOSED
                        </Badge>
                      )}
                    {currentGrn.grn_status == "CLOSED" &&
                      currentGrn.grn_invoice_collection_status == "OPEN" && (
                        <Badge bg="warning" className="po_status_pill mr-1">
                          Grn Invoice Collection Status : OPEN
                        </Badge>
                      )}
                    {currentGrn.grn_status == "CLOSED" &&
                      currentGrn.grn_invoice_collection_status == "CLOSED" && (
                        <Badge bg="success" className="po_status_pill mr-1">
                          Grn Invoice Collection Status : CLOSED
                        </Badge>
                      )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="grn-summary-table-container">
                      {currentVendor && (
                        <div className="incoming-purchase-order-pack-task-summary-table-container-child">
                          <Table
                            bordered
                            hover
                            size="sm"
                            className="table-auto-width"
                          >
                            <tbody>
                              <tr className="catalogues-search-bar-row">
                                <td
                                  colSpan={2}
                                  className="centered-heading grn"
                                >
                                  Vendor Details
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">Vendor Id</td>
                                <td className="centered po-id-link">
                                  <a
                                    href={`/vendor-hub/all-vendors/${currentPurchaseOrder.vendor_id}/details`}
                                  >
                                    {currentVendor.id}
                                  </a>
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">Vendor Name</td>
                                <td className="centered">
                                  {currentVendor.vendor_name}
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">Vendor Address</td>
                                <td className="centered">
                                  {currentVendor.vendor_address_line},{" "}
                                  {currentVendor.vendor_city},{" "}
                                  {currentVendor.vendor_state},{" "}
                                  {currentVendor.vendor_postal_code}
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">GSTIN</td>
                                <td className="centered">
                                  {currentVendor.vendor_gstin}
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">Contact Number</td>
                                <td className="centered">
                                  {currentVendor.vendor_contact_number}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      )}

                      {currentPurchaseOrder && (
                        <div className="incoming-purchase-order-pack-task-summary-table-container-child">
                          <Table
                            bordered
                            hover
                            size="sm"
                            className="table-auto-width"
                          >
                            <tbody>
                              <tr className="catalogues-search-bar-row">
                                <td
                                  colSpan={2}
                                  className="centered-heading grn"
                                >
                                  PO Details
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">PO Number</td>
                                <td className="centered po-id-link">
                                  <a
                                    href={`/vendor-hub/all-purchase-orders/details/${currentPurchaseOrder.po_id}`}
                                  >
                                    {currentPurchaseOrder.po_id}
                                  </a>
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">Total PO Demand</td>
                                <td className="centered">
                                  {currentPurchaseOrder.total_quantity}
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">PO Release Date</td>
                                <td className="centered">
                                  {DateTimeUtils.formatDateOnly(
                                    currentPurchaseOrder.date_published
                                  )}
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">PO Expiry Date</td>
                                <td className="centered">
                                  {DateTimeUtils.formatDateOnly(
                                    currentPurchaseOrder.expected_date
                                  )}
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">PO Created By</td>
                                <td className="centered">
                                  {currentPurchaseOrder.created_by}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      )}

                      {currentGrn && (
                        <div className="incoming-purchase-order-pack-task-summary-table-container-child">
                          <Table
                            bordered
                            hover
                            size="sm"
                            className="table-auto-width"
                          >
                            <tbody>
                              <tr className="catalogues-search-bar-row">
                                <td
                                  colSpan={2}
                                  className="centered-heading grn"
                                >
                                  GRN Details
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">
                                  Boxes Mentioned In Invoice
                                </td>
                                <td className="centered">
                                  {currentGrn.box_count_invoice}
                                </td>
                              </tr>
                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">
                                  Actual Box Count Received
                                </td>
                                <td className="centered">
                                  {currentGrn.actual_box_count_recieved}
                                </td>
                              </tr>

                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">Created At</td>
                                <td className="centered">
                                  {DateTimeUtils.formatDateTime(
                                    currentGrn.created_at
                                  )}
                                </td>
                              </tr>

                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">Updated At</td>
                                <td className="centered">
                                  {DateTimeUtils.formatDateTime(
                                    currentGrn.updated_at
                                  )}
                                </td>
                              </tr>

                              <tr className="catalogues-search-bar-row">
                                <td className="nowrap">GRN Opened By</td>
                                <td className="centered">
                                  {currentGrn.created_by}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>

                    {currentGrn && (
                      <div className="grn-buttons-and-file-container mt-4">
                        <div className="grn-buttons-container-main">
                          {currentGrn.grn_status == "OPEN" && (
                            <Button
                              variant="outline-primary"
                              className="grn-action-button"
                              onClick={() => {
                                if (
                                  purchaseOrderItemsWithListing.filter(
                                    (item) =>
                                      item.currentGrnItem !== null &&
                                      item.currentGrnItem !== undefined
                                  ).length == 0
                                ) {
                                  toast.error("No Item Was Added To GRN.");
                                } else {
                                  setShowCloseGrnCanvas(true);
                                }
                              }}
                            >
                              {" "}
                              Close GRN
                            </Button>
                          )}

                          {currentGrn.grn_status == "CLOSED" &&
                            currentGrn.grn_audit_status == "OPEN" && (
                              <Button
                                variant="outline-primary"
                                className="grn-action-button"
                                onClick={() => {
                                  if (
                                    userDetailsFromStore.authorities.includes(
                                      "AUTH_ADMIN"
                                    )
                                  ) {
                                    setShowAuditGrnCanvas(true);
                                  } else {
                                    toast.error("Only ADMINs can close Audit.");
                                  }
                                }}
                              >
                                {" "}
                                Close Audit
                              </Button>
                            )}
                        </div>
                        <div className="incoming-purchase-order-details-table-container-for-grn">
                          {currentGrn.grn_status == "CLOSED" && (
                            <div className="incoming-purchase-order-file-details-table-container">
                              <Table
                                bordered
                                hover
                                size="sm"
                                className="table-auto-width"
                              >
                                <thead>
                                  <tr>
                                    <th className="centered-heading grn">
                                      File ID
                                    </th>
                                    <th className="centered-heading grn">
                                      File Type
                                    </th>
                                    <th className="centered-heading grn">
                                      Uploaded At
                                    </th>
                                    <th className="centered-heading grn">
                                      Uploaded By
                                    </th>
                                    <th className="centered-heading grn">
                                      File Name
                                    </th>
                                    <th className="centered-heading grn">
                                      Download
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {grnDocuments.map((doc, index) => {
                                    return (
                                      <tr className="catalogues-search-bar-row">
                                        <td className="nowrap">{doc.id}</td>
                                        <td>{doc.file_type}</td>
                                        <td>
                                          {DateTimeUtils.formatDateTime(
                                            doc.created_at
                                          )}
                                        </td>
                                        <td>{doc.file_uploaded_by}</td>
                                        <td>{doc.file_name}</td>
                                        <td>
                                          <Download
                                            size={15}
                                            color="#007bff"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setShowDownloadPage(true);

                                              downloadFileFromWeb(
                                                doc.id,
                                                doc.saved_file_name
                                              );
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

          <div className="grn-skus-container-main-v2">
            {purchaseOrderItemsWithListing.length > 0 &&
              currentPurchaseOrder && (
                <div className="grn-listings-search-bar-container">
                  <VendorsListingSearchBar
                    onSearch={handleSearch}
                  ></VendorsListingSearchBar>
                  <div className="visibility-options">
                    <div
                      className={`visibility-option ${
                        selectedVisibilityOption == VisibilityOptions.GRID_SMALL
                          ? "selected"
                          : "not-selected"
                      }`}
                      onClick={() =>
                        setSelectedVisibilityOption(
                          VisibilityOptions.GRID_SMALL
                        )
                      }
                    >
                      <Grid3x3GapFill
                        size={18}
                        color="#16275c"
                        style={{ cursor: "pointer" }}
                      ></Grid3x3GapFill>
                    </div>
                    <div
                      className={`visibility-option ${
                        selectedVisibilityOption == VisibilityOptions.GRID_LARGE
                          ? "selected"
                          : "not-selected"
                      }`}
                      onClick={() =>
                        setSelectedVisibilityOption(
                          VisibilityOptions.GRID_LARGE
                        )
                      }
                    >
                      <GridFill
                        size={18}
                        color="#16275c"
                        style={{ cursor: "pointer" }}
                      ></GridFill>
                    </div>
                    <div
                      className={`visibility-option ${
                        selectedVisibilityOption == VisibilityOptions.LIST_VIEW
                          ? "selected"
                          : "not-selected"
                      }`}
                      onClick={() =>
                        setSelectedVisibilityOption(VisibilityOptions.LIST_VIEW)
                      }
                    >
                      <ListUl
                        size={18}
                        color="#16275c"
                        style={{ cursor: "pointer" }}
                      ></ListUl>
                    </div>
                  </div>
                </div>
              )}

            {currentGrn && (
              <PurchaseOrderGrnItemsView
                currentGrn={currentGrn}
                publishedListings={filteredPurchaseOrderItemsWithListing}
                onCardClick={onCardClick}
                selectedVisibilityOption={selectedVisibilityOption}
              />
            )}
          </div>
        </div>
      </div>

      <Offcanvas
        show={showGrnInputCanvas}
        onHide={() => {
          setShowGrnInputCanvas(false);
          setShowShortQuantityConfirmationDialog(false);
          setShortQuantityAcknowledged(false);
          setSelectedImage("img_hd");
        }}
        // backdrop="static"
        placement="end"
        style={{ width: "50%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <p>GRN Section</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {selectedListing && (
            <div className="grn-playground">
              <div className="grn-listing-section">
                <div className="grn-image-container">
                  <div className="grn-image-list">
                    {[
                      "img_hd",
                      "img_white",
                      "img_wdim",
                      "img_link1",
                      "img_link2",
                    ].map((imageKey: string) => (
                      <div
                        key={imageKey}
                        className={`grn-image-placeholder ${
                          selectedImage === imageKey ? "active" : ""
                        }`}
                        onClick={() => handleImageClick(imageKey)}
                      >
                        <img
                          src={selectedListing.listing[imageKey].toString()}
                          alt={`Image ${imageKey}`}
                          onError={() => handleImageError(imageKey)}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="grn-zoomed-image">
                    <img
                      src={selectedListing.listing[selectedImage].toString()}
                      alt={`Image ${selectedImage}`}
                      onError={() => handleImageError(selectedImage)}
                    />
                    <h5 className="mt-2">{selectedImage}</h5>
                  </div>
                </div>
                <div className="grn-listing-details-container">
                  <div className="grn-listing-details-header">
                    <b>DETAILS</b>
                  </div>
                  <div className="grid-1-cells ml-2 mr-2">
                    <div className="vendor-detail-row">
                      <b>SKU Id : </b>
                      {selectedListing.listing.sku_id}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Title : </b>
                      {selectedListing.listing.title}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Ops Tag : </b>
                      {selectedListing.listing.ops_tag}
                    </div>
                    <div className="vendor-detail-row">
                      <b>Warehouse Quantity : </b>
                      {selectedListing.listing.available_quantity}
                    </div>
                  </div>
                </div>
              </div>
              {currentPurchaseOrder && currentVendor && currentGrn && (
                <div className="incoming-purchase-order-details-table-container-for-grn mt-4">
                  <Table
                    bordered
                    hover
                    size="sm"
                    className="table-auto-width center-text"
                  >
                    <tbody>
                      {/* <tr className="catalogues-search-bar-row">
                        <td className="nowrap">PO Number</td>
                        <td>{currentPurchaseOrder.po_id}</td>

                        <td className="nowrap">GRN Id</td>
                        <td>{currentGrn.grn_id}</td>
                      </tr>
                      <tr className="catalogues-search-bar-row">
                        <td className="nowrap">Vendor Id</td>
                        <td>{currentPurchaseOrder.vendor_id}</td>
                        <td className="nowrap">Vendor Name</td>
                        <td>{currentVendor.vendor_name}</td>
                      </tr> */}

                      <tr className="catalogues-search-bar-row">
                        <td className="nowrap">Total Original Demand</td>
                        <td>{selectedListing.required_quantity}</td>
                        <td className="nowrap">Total Accepted Quantity</td>
                        <td>{selectedListing.accepted_quantity}</td>
                      </tr>

                      <tr className="catalogues-search-bar-row">
                        <td className="nowrap">Total Rejected Quantity</td>
                        <td>{selectedListing.rejected_quantity}</td>
                        <td className="nowrap">Total Pending Quantity</td>
                        <td>0</td>
                      </tr>

                      <tr className="catalogues-search-bar-row">
                        <td className="nowrap">Total Invoice Quantity</td>
                        <td>{selectedListing.invoice_quantity}</td>
                        <td className="nowrap">Fill Rate %</td>
                        <td>
                          {Math.round(
                            (selectedListing.accepted_quantity /
                              selectedListing.required_quantity) *
                              100 *
                              100
                          ) / 100}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              {currentPurchaseOrder &&
                currentVendor &&
                currentGrn &&
                currentGrn.grn_status == "OPEN" && (
                  <div className="incoming-purchase-order-details-table-container-for-grn mt-4">
                    <Table
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width center-text table-no-hover"
                    >
                      <thead>
                        <tr>
                          <th colSpan={6} className="table-heading">
                            GRN INPUT
                          </th>
                        </tr>
                        <tr>
                          <th className="table-sub-heading">
                            Quantity in Invoice
                          </th>
                          <th className="table-sub-heading">
                            Accepted Quantity
                          </th>
                          <th className="table-sub-heading">
                            Rejected Quantity
                          </th>
                          <th className="table-sub-heading">Short Quantity</th>
                          <th className="table-sub-heading">
                            Product Price(excl. Taxes)
                          </th>
                          <th className="table-sub-heading">Tax Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="catalogues-search-bar-row">
                          <td>
                            <input
                              ref={invoiceInputQuantityRef}
                              type="number"
                              className="grn-input-data purple-input-text"
                              value={quantityInInvoice}
                              onChange={(e) => {
                                if (e.target.value == "")
                                  setQuantityInInvoice(undefined);
                                else if (Number(e.target.value) >= 0)
                                  setQuantityInInvoice(Number(e.target.value));
                              }}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="grn-input-data green-input-text"
                              value={acceptedQuantity}
                              onChange={(e) => {
                                if (e.target.value == "")
                                  setAcceptedQuantity(undefined);
                                else if (Number(e.target.value) >= 0)
                                  setAcceptedQuantity(Number(e.target.value));
                              }}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="grn-input-data red-input-text"
                              value={rejectedQuantity}
                              onChange={(e) => {
                                if (e.target.value == "")
                                  setRejectedQuantity(undefined);
                                else if (Number(e.target.value) >= 0)
                                  setRejectedQuantity(Number(e.target.value));
                              }}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="grn-input-data red-input-text"
                              value={
                                quantityInInvoice
                                  ? quantityInInvoice -
                                    (acceptedQuantity ?? 0) -
                                    (rejectedQuantity ?? 0)
                                  : 0
                              }
                              disabled={true}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.01"
                              className="grn-input-data"
                              value={productPrice ?? ""}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (!isNaN(value) && value >= 0) {
                                  setProductPrice(value);
                                } else {
                                  setProductPrice(undefined);
                                }
                              }}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.5"
                              className="grn-input-data"
                              value={taxRate ?? ""}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (!isNaN(value) && value >= 0) {
                                  setTaxRate(value);
                                } else {
                                  setTaxRate(undefined);
                                }
                              }}
                              autoComplete="off"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}

              {currentPurchaseOrder &&
                currentVendor &&
                selectedListing.currentGrnItem &&
                currentGrn &&
                currentGrn.grn_status == "CLOSED" && (
                  <div className="incoming-purchase-order-details-table-container-for-grn mt-4">
                    <Table
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width center-text table-no-hover"
                    >
                      <thead>
                        <tr>
                          <th colSpan={7} className="table-heading">
                            GRN INPUT
                          </th>
                        </tr>
                        <tr>
                          <th className="table-sub-heading">
                            Quantity in Invoice
                          </th>
                          <th className="table-sub-heading">
                            Accepted Quantity
                          </th>
                          <th className="table-sub-heading">
                            Rejected Quantity
                          </th>
                          <th className="table-sub-heading">Short Quantity</th>
                          <th className="table-sub-heading">
                            Product Price(excl. Taxes)
                          </th>
                          <th className="table-sub-heading">Tax Rate</th>
                          <th className="table-sub-heading-audit audit-background">
                            Audited Price(excl. Taxes)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="catalogues-search-bar-row">
                          <td>
                            <input
                              ref={invoiceInputQuantityRef}
                              type="number"
                              className="grn-input-data purple-input-text"
                              value={
                                selectedListing.currentGrnItem.invoice_quantity
                              }
                              disabled={true}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="grn-input-data green-input-text"
                              value={
                                selectedListing.currentGrnItem.accepted_quantity
                              }
                              disabled={true}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="grn-input-data red-input-text"
                              value={
                                selectedListing.currentGrnItem.rejected_quantity
                              }
                              disabled={true}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="grn-input-data red-input-text"
                              value={
                                selectedListing.currentGrnItem
                                  .invoice_quantity -
                                (selectedListing.currentGrnItem
                                  .accepted_quantity ?? 0) -
                                (selectedListing.currentGrnItem
                                  .rejected_quantity ?? 0)
                              }
                              disabled={true}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="grn-input-data"
                              value={
                                selectedListing.currentGrnItem.received_price
                              }
                              disabled={true}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="grn-input-data"
                              value={selectedListing.currentGrnItem.tax_rate}
                              disabled={true}
                            />
                          </td>
                          <td className="audit-background">
                            <input
                              type="number"
                              step="0.01"
                              className="grn-input-data"
                              value={auditProductPrice ?? ""}
                              disabled={
                                currentGrn.grn_audit_status === "CLOSED"
                              }
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (!isNaN(value) && value >= 0) {
                                  setAuditProductPrice(value);
                                } else {
                                  setAuditProductPrice(undefined);
                                }
                              }}
                              autoComplete="off"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}

              {currentGrn &&
                currentGrn.grn_status == "OPEN" &&
                showShortQuantityConfirmationDialog && (
                  <div className="checkbox-container-invoice mt-2">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={isShortQuantityAcknowledged}
                        onChange={() => {
                          setShortQuantityAcknowledged(
                            !isShortQuantityAcknowledged
                          );
                        }}
                        className="use-other-invoice-number"
                      />
                      I hereby acknowledge that short quantity was Received
                      against this product. I have checked all the boxes.
                    </label>
                  </div>
                )}

              {currentGrn &&
                currentGrn.grn_status == "OPEN" &&
                (selectedListing.currentGrnItem ? (
                  <div className="offcanvas-button-container">
                    <button
                      type="button"
                      onClick={() => {
                        checkAndUpdateGrnItem(selectedListing);
                      }}
                    >
                      Update
                    </button>
                    <button
                      className="mr-2"
                      type="button"
                      onClick={() => {
                        deleteGrnItem(selectedListing);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <div className="offcanvas-button-container">
                    <button
                      type="button"
                      onClick={() => {
                        checkAndSubmitGrnItem(selectedListing);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                ))}

              {currentGrn &&
                selectedListing.currentGrnItem &&
                currentGrn.grn_status == "CLOSED" &&
                currentGrn.grn_audit_status == "OPEN" && (
                  <div className="offcanvas-button-container">
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          userDetailsFromStore.authorities.includes(
                            "AUTH_ADMIN"
                          )
                        ) {
                          checkAndUpdateAuditedPrice(selectedListing);
                        } else {
                          toast.error("Only ADMINs can set audit price.");
                        }
                      }}
                    >
                      Update Audit Price
                    </button>
                  </div>
                )}
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={showCloseGrnCanvas}
        onHide={() => setShowCloseGrnCanvas(false)}
        // onShow={() => {
        //   fetchLabelsDataForCurrentPurchaseOrder();
        // }}
        backdrop="static"
        className="alert-dialog"
        // keyboard={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Close GRN</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <div className="close-grn-container">
            <div className="vendor-listings-table-container">
              <div className="po-file-upload-container-main mt-3">
                <div className="date-time-picker-selected-date-container ml-1 mb-3">
                  <span>
                    <b>Upload Scanned Invoice files : </b>
                  </span>{" "}
                  <br></br>
                  <span>
                    (Maximum 2 files are allowed. File size should not exceed
                    4MB. Upload spreadsheet version of invoice here, if
                    available.){" "}
                  </span>{" "}
                  &nbsp;
                </div>
                <input
                  type="file"
                  accept=".xls,.xlsx,.csv,.pdf"
                  onChange={handleFileChange}
                  multiple
                />

                {files.length > 0 && (
                  <div>
                    <div>Selected Files:</div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index} style={{ padding: "4px" }}>
                          {file.name}
                          <XCircleFill
                            size={15}
                            color="#007bff"
                            style={{ cursor: "pointer", marginLeft: "8px" }}
                            onClick={() => handleFileRemove(index)}
                          />
                        </li>
                      ))}
                    </ul>

                    <Button
                      variant="outline-primary"
                      className="vendor-action-button mt-4"
                      onClick={() => {
                        setShowLoadingPage(true);
                        uploadFilesAndCloseGrn();
                      }}
                    >
                      {" "}
                      Upload Files & Close GRN
                    </Button>
                  </div>
                )}
              </div>
              <div className="mt-3">
                <h3>Grn Items</h3>
              </div>
              <div className="vendor-skus-table-container grn-table-container">
                <div className="all-labels-master-data-table">
                  <Table bordered hover size="sm" className="table-auto-width">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>SKU ID</th>
                        <th>Ops Tag</th>
                        <th>Available Quantity</th>
                        <th>Total Demand</th>
                        <th>Total Invoice Quantity (Prev. GRNs)</th>
                        <th>Total Accepted Quantity (Prev. GRNs)</th>
                        <th>Total Rejected Quantity (Prev. GRNs)</th>
                        <th>Average Received Price (Prev. GRNs)</th>
                        <th>Fill Rate % (Prev. GRNs)</th>
                        <th className="current-grn-table-header">
                          Current GRN Invoice Quantity
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Accepted Quantity
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Rejected Quantity
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Short Quantity
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Received Price (excl. gst)
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Tax Rate
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Entry By
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Damage Images Count
                        </th>
                        <th className="current-grn-table-header">
                          Last Modified
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseOrderItemsWithListing
                        .filter(
                          (item) =>
                            item.currentGrnItem !== null &&
                            item.currentGrnItem !== undefined
                        )
                        .map((publishedListing, index) => {
                          return (
                            <tr
                              className={`catalogues-search-bar-row 
                            }`}
                            >
                              <td>{index + 1}</td>
                              <td>{publishedListing.sku_id}</td>
                              <td>{publishedListing.listing.ops_tag}</td>
                              <td>
                                {publishedListing.listing.available_quantity}
                              </td>
                              <td>{publishedListing.required_quantity}</td>
                              <td>{publishedListing.invoice_quantity}</td>
                              <td>{publishedListing.accepted_quantity}</td>
                              <td>{publishedListing.rejected_quantity}</td>
                              <td>{publishedListing.avg_price}</td>
                              <td>
                                {Math.round(
                                  (publishedListing.accepted_quantity /
                                    publishedListing.required_quantity) *
                                    100 *
                                    100
                                ) / 100}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .invoice_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .accepted_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .rejected_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .invoice_quantity -
                                    publishedListing.currentGrnItem
                                      .accepted_quantity -
                                    publishedListing.currentGrnItem
                                      .rejected_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .received_price
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem.tax_rate
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem.created_by
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {publishedListing.currentGrnItem ? 0 : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? DateTimeUtils.formatDateTime(
                                      publishedListing.currentGrnItem.updated_at
                                    )
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>

            {showLoadingPage && (
              <div className="loading-page-download">
                <Spinner animation="grow" />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAuditGrnCanvas}
        onHide={() => setShowAuditGrnCanvas(false)}
        onShow={() => {
          prepareDebitAndCreditNoteList(purchaseOrderItemsWithListing);
        }}
        backdrop="static"
        className="alert-dialog"
        // keyboard={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Close Audit & Approve GRN</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="close-grn-container">
            {damagesDebitNotesListing.length > 0 && (
              <div className="debit-credit-accordion-container mb-3">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="debit-credit-note-heading">
                        Damages Debit Note Details
                      </div>
                      {isDamagedQuantityDebitNoteApproved ? (
                        <div className="debit-credit-note-heading-green ml-4">
                          [Approved]
                        </div>
                      ) : (
                        <div className="debit-credit-note-heading-red ml-4">
                          [Not Approved]
                        </div>
                      )}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="all-labels-master-data-table">
                        <Table
                          bordered
                          hover
                          size="sm"
                          className="table-auto-width"
                        >
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>SKU ID</th>
                              <th>Ops Tag</th>
                              <th>Available Quantity</th>
                              <th>Total Demand</th>
                              <th>Total Invoice Quantity (Prev. GRNs)</th>
                              <th>Total Accepted Quantity (Prev. GRNs)</th>
                              <th>Total Rejected Quantity (Prev. GRNs)</th>
                              <th>Average Received Price (Prev. GRNs)</th>
                              <th>Fill Rate % (Prev. GRNs)</th>
                              <th>Current GRN Invoice Quantity</th>
                              <th>Current GRN Accepted Quantity</th>
                              <th>Current GRN Rejected Quantity</th>
                              <th>Current GRN Short Quantity</th>
                              <th>Current GRN Received Price (excl. gst)</th>
                              <th>Current GRN Tax Rate</th>
                              <th>Current GRN Entry By</th>
                              <th>Current GRN Damage Images Count</th>
                              <th>Created At</th>
                              <th>Audit Price (excl. gst)</th>
                              <th>Audited By</th>
                              <th>Last Audited At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {damagesDebitNotesListing
                              .filter(
                                (item) =>
                                  item.currentGrnItem !== null &&
                                  item.currentGrnItem !== undefined
                              )
                              .map((publishedListing, index) => {
                                return (
                                  <tr
                                    className={`catalogues-search-bar-row 
                            }`}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{publishedListing.sku_id}</td>
                                    <td>{publishedListing.listing.ops_tag}</td>
                                    <td>
                                      {
                                        publishedListing.listing
                                          .available_quantity
                                      }
                                    </td>
                                    <td>
                                      {publishedListing.required_quantity}
                                    </td>
                                    <td>{publishedListing.invoice_quantity}</td>
                                    <td>
                                      {publishedListing.accepted_quantity}
                                    </td>
                                    <td>
                                      {publishedListing.rejected_quantity}
                                    </td>
                                    <td>{publishedListing.avg_price}</td>
                                    <td>
                                      {Math.round(
                                        (publishedListing.accepted_quantity /
                                          publishedListing.required_quantity) *
                                          100 *
                                          100
                                      ) / 100}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .invoice_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .accepted_quantity
                                        : ""}
                                    </td>
                                    <td className="highlighter-background">
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .rejected_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .invoice_quantity -
                                          publishedListing.currentGrnItem
                                            .accepted_quantity -
                                          publishedListing.currentGrnItem
                                            .rejected_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .received_price
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .tax_rate
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .created_by
                                        : ""}
                                    </td>
                                    <td>
                                      {" "}
                                      {publishedListing.currentGrnItem ? 0 : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? DateTimeUtils.formatDateTime(
                                            publishedListing.currentGrnItem
                                              .updated_at
                                          )
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .audit_price
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .audited_by
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? DateTimeUtils.formatDateTime(
                                            publishedListing.currentGrnItem
                                              .audited_at
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>

                      <div className="checkbox-container-invoice mt-2">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            checked={isDamagedQuantityDebitNoteApproved}
                            onChange={() => {
                              setDamagedQuantityDebitNoteApproved(
                                !isDamagedQuantityDebitNoteApproved
                              );
                            }}
                            className="use-other-invoice-number"
                          />
                          I approve this debit note.
                        </label>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}

            {shortageDebitNotesListing.length > 0 && (
              <div className="debit-credit-accordion-container mb-3">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="debit-credit-note-heading">
                        Shortage Debit Note Details
                      </div>
                      {isShortQuantityDebitNoteApproved ? (
                        <div className="debit-credit-note-heading-green ml-4">
                          [Approved]
                        </div>
                      ) : (
                        <div className="debit-credit-note-heading-red ml-4">
                          [Not Approved]
                        </div>
                      )}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="all-labels-master-data-table">
                        <Table
                          bordered
                          hover
                          size="sm"
                          className="table-auto-width"
                        >
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>SKU ID</th>
                              <th>Ops Tag</th>
                              <th>Available Quantity</th>
                              <th>Total Demand</th>
                              <th>Total Invoice Quantity (Prev. GRNs)</th>
                              <th>Total Accepted Quantity (Prev. GRNs)</th>
                              <th>Total Rejected Quantity (Prev. GRNs)</th>
                              <th>Average Received Price (Prev. GRNs)</th>
                              <th>Fill Rate % (Prev. GRNs)</th>
                              <th>Current GRN Invoice Quantity</th>
                              <th>Current GRN Accepted Quantity</th>
                              <th>Current GRN Rejected Quantity</th>
                              <th>Current GRN Short Quantity</th>
                              <th>Current GRN Received Price (excl. gst)</th>
                              <th>Current GRN Tax Rate</th>
                              <th>Current GRN Entry By</th>
                              <th>Current GRN Damage Images Count</th>
                              <th>Created At</th>
                              <th>Audit Price (excl. gst)</th>
                              <th>Audited By</th>
                              <th>Last Audited At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shortageDebitNotesListing
                              .filter(
                                (item) =>
                                  item.currentGrnItem !== null &&
                                  item.currentGrnItem !== undefined
                              )
                              .map((publishedListing, index) => {
                                return (
                                  <tr
                                    className={`catalogues-search-bar-row 
                            }`}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{publishedListing.sku_id}</td>
                                    <td>{publishedListing.listing.ops_tag}</td>
                                    <td>
                                      {
                                        publishedListing.listing
                                          .available_quantity
                                      }
                                    </td>
                                    <td>
                                      {publishedListing.required_quantity}
                                    </td>
                                    <td>{publishedListing.invoice_quantity}</td>
                                    <td>
                                      {publishedListing.accepted_quantity}
                                    </td>
                                    <td>
                                      {publishedListing.rejected_quantity}
                                    </td>
                                    <td>{publishedListing.avg_price}</td>
                                    <td>
                                      {Math.round(
                                        (publishedListing.accepted_quantity /
                                          publishedListing.required_quantity) *
                                          100 *
                                          100
                                      ) / 100}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .invoice_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .accepted_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .rejected_quantity
                                        : ""}
                                    </td>
                                    <td className="highlighter-background">
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .invoice_quantity -
                                          publishedListing.currentGrnItem
                                            .accepted_quantity -
                                          publishedListing.currentGrnItem
                                            .rejected_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .received_price
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .tax_rate
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .created_by
                                        : ""}
                                    </td>
                                    <td>
                                      {" "}
                                      {publishedListing.currentGrnItem ? 0 : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? DateTimeUtils.formatDateTime(
                                            publishedListing.currentGrnItem
                                              .updated_at
                                          )
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .audit_price
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .audited_by
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? DateTimeUtils.formatDateTime(
                                            publishedListing.currentGrnItem
                                              .audited_at
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="checkbox-container-invoice mt-2">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            checked={isShortQuantityDebitNoteApproved}
                            onChange={() => {
                              setShortQuantityDebitNoteApproved(
                                !isShortQuantityDebitNoteApproved
                              );
                            }}
                            className="use-other-invoice-number"
                          />
                          I approve this debit note.
                        </label>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}

            {priceDifferenceDebitNotesWithListing.length > 0 && (
              <div className="debit-credit-accordion-container mb-3">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="debit-credit-note-heading">
                        Price Difference Debit Note Details
                      </div>
                      {isPriceDifferenceDebitNoteApproved ? (
                        <div className="debit-credit-note-heading-green ml-4">
                          [Approved]
                        </div>
                      ) : (
                        <div className="debit-credit-note-heading-red ml-4">
                          [Not Approved]
                        </div>
                      )}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="all-labels-master-data-table">
                        <Table
                          bordered
                          hover
                          size="sm"
                          className="table-auto-width"
                        >
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>SKU ID</th>
                              <th>Ops Tag</th>
                              <th>Available Quantity</th>
                              <th>Total Demand</th>
                              <th>Total Invoice Quantity (Prev. GRNs)</th>
                              <th>Total Accepted Quantity (Prev. GRNs)</th>
                              <th>Total Rejected Quantity (Prev. GRNs)</th>
                              <th>Average Received Price (Prev. GRNs)</th>
                              <th>Fill Rate % (Prev. GRNs)</th>
                              <th>Current GRN Invoice Quantity</th>
                              <th>Current GRN Accepted Quantity</th>
                              <th>Current GRN Rejected Quantity</th>
                              <th>Current GRN Short Quantity</th>
                              <th>Current GRN Received Price (excl. gst)</th>
                              <th>Current GRN Tax Rate</th>
                              <th>Current GRN Entry By</th>
                              <th>Current GRN Damage Images Count</th>
                              <th>Created At</th>
                              <th>Audit Price (excl. gst)</th>
                              <th>Audited By</th>
                              <th>Last Audited At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {priceDifferenceDebitNotesWithListing
                              .filter(
                                (item) =>
                                  item.currentGrnItem !== null &&
                                  item.currentGrnItem !== undefined
                              )
                              .map((publishedListing, index) => {
                                return (
                                  <tr
                                    className={`catalogues-search-bar-row 
                            }`}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{publishedListing.sku_id}</td>
                                    <td>{publishedListing.listing.ops_tag}</td>
                                    <td>
                                      {
                                        publishedListing.listing
                                          .available_quantity
                                      }
                                    </td>
                                    <td>
                                      {publishedListing.required_quantity}
                                    </td>
                                    <td>{publishedListing.invoice_quantity}</td>
                                    <td>
                                      {publishedListing.accepted_quantity}
                                    </td>
                                    <td>
                                      {publishedListing.rejected_quantity}
                                    </td>
                                    <td>{publishedListing.avg_price}</td>
                                    <td>
                                      {Math.round(
                                        (publishedListing.accepted_quantity /
                                          publishedListing.required_quantity) *
                                          100 *
                                          100
                                      ) / 100}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .invoice_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .accepted_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .rejected_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .invoice_quantity -
                                          publishedListing.currentGrnItem
                                            .accepted_quantity -
                                          publishedListing.currentGrnItem
                                            .rejected_quantity
                                        : ""}
                                    </td>
                                    <td className="highlighter-background">
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .received_price
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .tax_rate
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .created_by
                                        : ""}
                                    </td>
                                    <td>
                                      {" "}
                                      {publishedListing.currentGrnItem ? 0 : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? DateTimeUtils.formatDateTime(
                                            publishedListing.currentGrnItem
                                              .updated_at
                                          )
                                        : ""}
                                    </td>

                                    <td className="highlighter-background">
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .audit_price
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .audited_by
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? DateTimeUtils.formatDateTime(
                                            publishedListing.currentGrnItem
                                              .audited_at
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>

                        <div className="checkbox-container-invoice mt-2">
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              checked={isPriceDifferenceDebitNoteApproved}
                              onChange={() => {
                                setPriceDifferenceDebitNoteApproved(
                                  !isPriceDifferenceDebitNoteApproved
                                );
                              }}
                              className="use-other-invoice-number"
                            />
                            I approve this debit note.
                          </label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}

            {creditNotesWithListing.length > 0 && (
              <div className="debit-credit-accordion-container mb-3">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="debit-credit-note-heading">
                        Credit Note Details
                      </div>
                      {isCreditNoteApproved ? (
                        <div className="debit-credit-note-heading-green ml-4">
                          [Approved]
                        </div>
                      ) : (
                        <div className="debit-credit-note-heading-red ml-4">
                          [Not Approved]
                        </div>
                      )}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="all-labels-master-data-table">
                        <Table
                          bordered
                          hover
                          size="sm"
                          className="table-auto-width"
                        >
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>SKU ID</th>
                              <th>Ops Tag</th>
                              <th>Available Quantity</th>
                              <th>Total Demand</th>
                              <th>Total Invoice Quantity (Prev. GRNs)</th>
                              <th>Total Accepted Quantity (Prev. GRNs)</th>
                              <th>Total Rejected Quantity (Prev. GRNs)</th>
                              <th>Average Received Price (Prev. GRNs)</th>
                              <th>Fill Rate % (Prev. GRNs)</th>
                              <th>Current GRN Invoice Quantity</th>
                              <th>Current GRN Accepted Quantity</th>
                              <th>Current GRN Rejected Quantity</th>
                              <th>Current GRN Short Quantity</th>
                              <th>Current GRN Received Price (excl. gst)</th>
                              <th>Current GRN Tax Rate</th>
                              <th>Current GRN Entry By</th>
                              <th>Current GRN Damage Images Count</th>
                              <th>Created At</th>
                              <th>Audit Price (excl. gst)</th>
                              <th>Audited By</th>
                              <th>Last Audited At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {creditNotesWithListing
                              .filter(
                                (item) =>
                                  item.currentGrnItem !== null &&
                                  item.currentGrnItem !== undefined
                              )
                              .map((publishedListing, index) => {
                                return (
                                  <tr
                                    className={`catalogues-search-bar-row 
                            }`}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{publishedListing.sku_id}</td>
                                    <td>{publishedListing.listing.ops_tag}</td>
                                    <td>
                                      {
                                        publishedListing.listing
                                          .available_quantity
                                      }
                                    </td>
                                    <td>
                                      {publishedListing.required_quantity}
                                    </td>
                                    <td>{publishedListing.invoice_quantity}</td>
                                    <td>
                                      {publishedListing.accepted_quantity}
                                    </td>
                                    <td>
                                      {publishedListing.rejected_quantity}
                                    </td>
                                    <td>{publishedListing.avg_price}</td>
                                    <td>
                                      {Math.round(
                                        (publishedListing.accepted_quantity /
                                          publishedListing.required_quantity) *
                                          100 *
                                          100
                                      ) / 100}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .invoice_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .accepted_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .rejected_quantity
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .invoice_quantity -
                                          publishedListing.currentGrnItem
                                            .accepted_quantity -
                                          publishedListing.currentGrnItem
                                            .rejected_quantity
                                        : ""}
                                    </td>
                                    <td className="highlighter-background">
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .received_price
                                        : ""}
                                    </td>
                                    <td className="highlighter-background">
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .tax_rate
                                        : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .created_by
                                        : ""}
                                    </td>
                                    <td>
                                      {" "}
                                      {publishedListing.currentGrnItem ? 0 : ""}
                                    </td>
                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? DateTimeUtils.formatDateTime(
                                            publishedListing.currentGrnItem
                                              .updated_at
                                          )
                                        : ""}
                                    </td>

                                    <td className="highlighter-background">
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .audit_price
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? publishedListing.currentGrnItem
                                            .audited_by
                                        : ""}
                                    </td>

                                    <td>
                                      {publishedListing.currentGrnItem
                                        ? DateTimeUtils.formatDateTime(
                                            publishedListing.currentGrnItem
                                              .audited_at
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                        <div className="checkbox-container-invoice mt-2">
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              checked={isCreditNoteApproved}
                              onChange={() => {
                                setIsCreditNoteApproved(!isCreditNoteApproved);
                              }}
                              className="use-other-invoice-number"
                            />
                            I approve this credit note.
                          </label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}

            <div className="vendor-listings-table-container">
              <div
                className="mt-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h3>All Grn Items</h3>{" "}
                <Button
                  variant="outline-primary"
                  className="grn-action-button close-audit-button-main ml-4"
                  onClick={() => {
                    if (
                      userDetailsFromStore.authorities.includes("AUTH_ADMIN")
                    ) {
                      setShowAuditGrnCanvas(false);
                      setShowAuditClosureConfirmationDialogue(true);
                    } else {
                      toast.error("Only ADMINs can close Audit.");
                    }
                  }}
                >
                  {" "}
                  Close Audit
                </Button>
              </div>
              <div className="vendor-skus-table-container grn-table-container">
                <div className="all-labels-master-data-table">
                  <Table bordered hover size="sm" className="table-auto-width">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>SKU ID</th>
                        <th>Ops Tag</th>
                        <th>Available Quantity</th>
                        <th>Total Demand</th>
                        <th>Total Invoice Quantity (Prev. GRNs)</th>
                        <th>Total Accepted Quantity (Prev. GRNs)</th>
                        <th>Total Rejected Quantity (Prev. GRNs)</th>
                        <th>Average Received Price (Prev. GRNs)</th>
                        <th>Fill Rate % (Prev. GRNs)</th>
                        <th className="current-grn-table-header">
                          Current GRN Invoice Quantity
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Accepted Quantity
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Rejected Quantity
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Short Quantity
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Received Price (excl. gst)
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Tax Rate
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Entry By
                        </th>
                        <th className="current-grn-table-header">
                          Current GRN Damage Images Count
                        </th>
                        <th className="current-grn-table-header">Created At</th>

                        <th className="current-grn-table-header-audit">
                          Audit Price (excl. gst)
                        </th>

                        <th className="current-grn-table-header-audit">
                          Audited By
                        </th>

                        <th className="current-grn-table-header-audit">
                          Last Audited At
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseOrderItemsWithListing
                        .filter(
                          (item) =>
                            item.currentGrnItem !== null &&
                            item.currentGrnItem !== undefined
                        )
                        .map((publishedListing, index) => {
                          return (
                            <tr
                              className={`catalogues-search-bar-row 
                            }`}
                            >
                              <td>{index + 1}</td>
                              <td>{publishedListing.sku_id}</td>
                              <td>{publishedListing.listing.ops_tag}</td>
                              <td>
                                {publishedListing.listing.available_quantity}
                              </td>
                              <td>{publishedListing.required_quantity}</td>
                              <td>{publishedListing.invoice_quantity}</td>
                              <td>{publishedListing.accepted_quantity}</td>
                              <td>{publishedListing.rejected_quantity}</td>
                              <td>{publishedListing.avg_price}</td>
                              <td>
                                {Math.round(
                                  (publishedListing.accepted_quantity /
                                    publishedListing.required_quantity) *
                                    100 *
                                    100
                                ) / 100}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .invoice_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .accepted_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .rejected_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .invoice_quantity -
                                    publishedListing.currentGrnItem
                                      .accepted_quantity -
                                    publishedListing.currentGrnItem
                                      .rejected_quantity
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem
                                      .received_price
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem.tax_rate
                                  : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem.created_by
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {publishedListing.currentGrnItem ? 0 : ""}
                              </td>
                              <td>
                                {publishedListing.currentGrnItem
                                  ? DateTimeUtils.formatDateTime(
                                      publishedListing.currentGrnItem.updated_at
                                    )
                                  : ""}
                              </td>

                              <td
                                className={`${
                                  publishedListing.currentGrnItem &&
                                  publishedListing.currentGrnItem.audit_price
                                    ? "audit-background"
                                    : ""
                                }`}
                              >
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem.audit_price
                                  : ""}
                              </td>

                              <td
                                className={`${
                                  publishedListing.currentGrnItem &&
                                  publishedListing.currentGrnItem.audit_price
                                    ? "audit-background"
                                    : ""
                                }`}
                              >
                                {publishedListing.currentGrnItem
                                  ? publishedListing.currentGrnItem.audited_by
                                  : ""}
                              </td>

                              <td
                                className={`${
                                  publishedListing.currentGrnItem &&
                                  publishedListing.currentGrnItem.audit_price
                                    ? "audit-background"
                                    : ""
                                }`}
                              >
                                {publishedListing.currentGrnItem
                                  ? DateTimeUtils.formatDateTime(
                                      publishedListing.currentGrnItem.audited_at
                                    )
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>

            {showLoadingPage && (
              <div className="loading-page-download">
                <Spinner animation="grow" />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAuditClosureConfirmationDialogue}
        onHide={() => setShowAuditClosureConfirmationDialogue(false)}
        className="alert-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Close Audit</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {shortageDebitNotesListing.length > 0 && (
            <div
              className={
                isShortQuantityDebitNoteApproved ? "approved" : "not-approved"
              }
            >
              Shortage Debit Note -{" "}
              {isShortQuantityDebitNoteApproved ? "Approved" : "Not Approved"}
            </div>
          )}
          {damagesDebitNotesListing.length > 0 && (
            <div
              className={
                isDamagedQuantityDebitNoteApproved ? "approved" : "not-approved"
              }
            >
              Damaged Quantity Debit Note -{" "}
              {isDamagedQuantityDebitNoteApproved ? "Approved" : "Not Approved"}
            </div>
          )}
          {priceDifferenceDebitNotesWithListing.length > 0 && (
            <div
              className={
                isPriceDifferenceDebitNoteApproved ? "approved" : "not-approved"
              }
            >
              Price Difference Debit Note -{" "}
              {isPriceDifferenceDebitNoteApproved ? "Approved" : "Not Approved"}
            </div>
          )}
          {creditNotesWithListing.length > 0 && (
            <div className={isCreditNoteApproved ? "approved" : "not-approved"}>
              Credit Note - {isCreditNoteApproved ? "Approved" : "Not Approved"}
            </div>
          )}

          {shortageDebitNotesListing.length == 0 &&
            damagesDebitNotesListing.length == 0 &&
            priceDifferenceDebitNotesWithListing.length == 0 &&
            creditNotesWithListing.length == 0 && (
              <div> No Credit/Debit Note was found.</div>
            )}

          <div className="mt-2">
            Are you sure you want to proceed with audit closure ?{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-footer-button"
            variant="secondary"
            onClick={() => setShowAuditClosureConfirmationDialogue(false)}
          >
            Cancel
          </Button>
          <Button
            className="modal-footer-button"
            variant="primary"
            onClick={() => {
              setShowLoadingPage(true);
              setShowAuditClosureConfirmationDialogue(false);
              submitForAuditClosure();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {showLoadingPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}
    </div>
  );
}

export default PurchaseOrderGrnDetails_v2;
// #f8f9f9
