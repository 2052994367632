import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/CompanyPurchaseOrderConsignmentDetails.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  Transporter,
  ShipmentData,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download, PenFill } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import Header from "../Header";
import { updateDecorator } from "typescript";

interface RouteParams {
  po_id: string;
  consignment_id: string;
}

function CompanyPurchaseOrdersConsignmentDetails() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { po_id, consignment_id } = useParams<RouteParams>();

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [transporters, setTransporters] = useState<Transporter[]>([]);

  const shipmentModes: string[] = [
    "Roadways",
    "Airways",
    "Railways",
    "Shipways",
  ];

  const [showConsignmentOptions, setShowConsignmentOptions] =
    useState<boolean>(false);
  const [showRemovalConfirmationDialog, setShowRemovalConfirmationDialog] =
    useState<boolean>(false);
  const [showUploadConsignmentItemsModal, setShowUploadConsignmentItemsModal] =
    useState(false);
  const [showSubmitConsignmentItemsModal, setShowSubmitConsignmentItemsModal] =
    useState(false);
  const [
    showCancellationConfirmationItemModal,
    setShowCancellationConfirmationItemModal,
  ] = useState(false);

  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);
  const [checkedCount, setCheckedCount] = useState<number>(0);

  const [selectedShipmentMode, setSelectedShipmentMode] = useState<
    string | null
  >(null);
  const [selectedTransporter, setSelectedTransporter] =
    useState<Transporter | null>(null);
  const [poDocuments, setPoDocuments] = useState<PoDocument[]>([]);
  const [missingBoxesMessage, setMissingBoxesMessage] = useState<string>("");
  const [ConsignmentViewKey, setConsignmentViewKey] = useState("default");
  const [selectedConsignment, setSelectedConsignment] = useState<Consignment>();
  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [showDispatchCanvas, setShowDispatchCanvas] = useState<boolean>(false);
  const [showFinalRTDDialog, setShowFinalRTDDialog] = useState<boolean>(false);
  const [originalConsignmentItems, setOriginalConsignmentItems] = useState<
    ConsignmentItems[]
  >([]);
  const [originalConsignmentBoxViewItems, setOriginalConsignmentBoxViewItems] =
    useState<ConsignmentItemsBoxView[]>([]);
  const [originalConsignmentSKUViewItems, setOriginalConsignmentSKUViewItems] =
    useState<ConsignmentItemsSKUView[]>([]);
  const [originalConsignmentPOViewItems, setOriginalConsignmentPOViewItems] =
    useState<ConsignmentItemsPOView[]>([]);
  const [purchaseOrderItems, setPurchaseOrderItems] = useState<
    IncomingPurchaseOrderListingDetailsDataObject[]
  >([]);

  const [mergedConsignmentItems, setMergedConsignmentItems] = useState<
    ConsignmentItems[]
  >([]);

  const [uploadedConsignmentItems, setUploadedConsignmentItems] = useState<
    ConsignmentItems[]
  >([]);
  const [finalUploadedConsignmentItems, setFinalUploadedConsignmentItems] =
    useState<ConsignmentItems[]>([]);

  const [errorConsignmentItems, setErrorConsignmentItems] = useState<
    ConsignmentItems[]
  >([]);

  const [vehicleNumber, setVehicleNumber] = useState("");
  const [docketNumber, setDocketNumber] = useState("");
  const [shipmentData, setShipmentData] = useState<ShipmentData>();

  const [
    currenConsignmentAnalyticsObject,
    setCurrentConsignmentAnalyticsObject,
  ] = useState<ConsignmentAnalyticsObject>();
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<IncomingPurchaseOrder>();
  const [uploadButtonActive, setUploadButtonActive] = useState(false);

  const handleDispatchCanvasClose = () => {
    setShowDispatchCanvas(false);
  };

  const fetchPurchaseOrderDetails = async (purchase_order_id: any) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.geturchaseOrderByPOid(
          purchase_order_id.toString()
        );
      // console.log(response.data);
      setCurrentPurchaseOrder(response.data);
    } catch (error) {
      toast.error("Error! Could not find purchase order details.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const fetchPurchaseOrderDetailFiles = async (purchase_order_id: any) => {
    try {
      const response = await companyPurchaseOrderNetworkUtil.fetchPODetailFiles(
        purchase_order_id.toString()
      );

      setPoDocuments(response.data);
      // createDocumentMap(response.data);
    } catch (error) {
      console.error("Error in getting po_documents : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const downloadFileFromWeb = async (file_id: any, saved_file_name: string) => {
    setShowDownloadPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.downloadPoFileFromWeb(file_id);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = saved_file_name; // Use the original file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setShowDownloadPage(false);
    } catch (error) {
      console.error("Error in downloading file : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
    }
  };

  const fetchPurchaseOrderListingDetails = async (purchase_order_id: any) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersListingPaginated(
          purchase_order_id.toString(),
          "",
          1,
          10000
        );

      return response.data["content"];
    } catch (error) {
      console.log("Error! Could not fetch purchase order details.");
    }
    return [];
  };

  const fetchPurchaseOrderDemandObjectDetails = async (
    purchase_order_id: any
  ): Promise<PurchaseOrderDemandObject[]> => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.fetchPurchaseOrderDemandObjectDetails(
          purchase_order_id.toString()
        );

      return response.data;
    } catch (error) {
      console.log("Error! Could not fetch purchase order demand details.");
    }

    return [];
  };

  const fetchConsignmentItemsByConsignmentId = async (consignment_id: any) => {
    try {
      let purchaseOrderlocalItems = [];
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersListingPaginated(
            po_id,
            "",
            1,
            10000
          );

        purchaseOrderlocalItems = response.data["content"];
        setPurchaseOrderItems(response.data["content"]);
      } catch (error) {
        console.log("Error! Could not fetch purchase order details.");
      }

      const response =
        await companyPurchaseOrderNetworkUtil.fetchConsignmentItemsByConsignmentId(
          consignment_id
        );

      setUpConsignmentViews(response.data, purchaseOrderlocalItems);
      // setMergedConsignmentItems(response.data);
      // console.log(response.data);
    } catch (error) {
      console.log("Error! Could not fetch purchase order details.");
      toast.error("Error fetching Consignment items.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const setUpConsignmentViews = async (
    ConsignmentItems: ConsignmentItems[],
    purchaseOrderlocalItems: IncomingPurchaseOrderListingDetailsDataObject[]
  ) => {
    //#### Default View ####
    setOriginalConsignmentItems(
      ConsignmentItems.sort((a, b) => a.box_number - b.box_number)
    );
    // setOriginalConsignmentItems(ConsignmentItems);

    //#### Box Wise View ####
    const boxViewMap = new Map<number, ConsignmentItemsBoxView>();

    ConsignmentItems.forEach((item) => {
      const existingBoxView = boxViewMap.get(item.box_number);
      if (existingBoxView) {
        // Update existing box view
        existingBoxView.total_quantity += item.box_quantity;

        // Add unique SKUs and primary codes to strings
        if (
          !existingBoxView.po_secondary_sku_string.includes(
            item.po_secondary_sku
          )
        ) {
          existingBoxView.po_secondary_sku_string += `, ${item.po_secondary_sku}`;
        }

        if (
          !existingBoxView.company_code_primary_string.includes(
            item.company_code_primary
          )
        ) {
          existingBoxView.company_code_primary_string += `, ${item.company_code_primary}`;
        }
      } else {
        // Create a new box view entry
        boxViewMap.set(item.box_number, {
          box_number: String(item.box_number),
          total_quantity: item.box_quantity,
          po_secondary_sku_string: item.po_secondary_sku,
          company_code_primary_string: item.company_code_primary,
        });
      }
    });

    setOriginalConsignmentBoxViewItems(
      Array.from(boxViewMap.values()).sort(
        (a, b) => parseInt(a.box_number, 10) - parseInt(b.box_number, 10)
      )
    );

    //#### SKU wise view ####
    const skuViewMap = new Map<string, ConsignmentItemsSKUView>();

    ConsignmentItems.forEach((item) => {
      const key = `${item.po_secondary_sku}_${item.company_code_primary}`; // Unique key for SKU + Company
      const existingSKUView = skuViewMap.get(key);

      if (existingSKUView) {
        // Update existing SKU view
        existingSKUView.total_quantity += item.box_quantity;

        // Add unique box numbers to string
        if (
          !existingSKUView.box_numbers_string.includes(
            item.box_number.toString()
          )
        ) {
          existingSKUView.box_numbers_string += `, ${item.box_number}`;
        }
      } else {
        // Create a new SKU view entry
        skuViewMap.set(key, {
          po_secondary_sku: item.po_secondary_sku,
          company_code_primary: item.company_code_primary,
          total_quantity: item.box_quantity,
          box_numbers_string: item.box_number.toString(),
        });
      }
    });

    // Convert the map to an array of ConsignmentItemsSKUView and return it
    setOriginalConsignmentSKUViewItems(Array.from(skuViewMap.values()));

    //### PO wise view ####

    // Group ConsignmentItems by po_secondary_sku
    const groupedConsignmentItems = new Map<string, ConsignmentItems[]>();
    ConsignmentItems.forEach((item) => {
      if (!groupedConsignmentItems.has(item.po_secondary_sku)) {
        groupedConsignmentItems.set(item.po_secondary_sku, []);
      }
      groupedConsignmentItems.get(item.po_secondary_sku)?.push(item);
    });

    // Create ConsignmentItemsPOView list
    const poViewList: ConsignmentItemsPOView[] = [];

    purchaseOrderlocalItems.forEach((incomingPO) => {
      // Get the Consignment items for this PO item
      const items =
        groupedConsignmentItems.get(incomingPO.po_secondary_sku) || [];

      // Aggregate data
      const ConsignmentQuantity = items.reduce(
        (sum, item) => sum + item.box_quantity,
        0
      );
      const boxNumbersString = items
        .map((item) => item.box_number)
        .sort((a, b) => a - b) // Sort box numbers
        .join(", "); // Join as comma-separated string

      // Push the PO view object
      poViewList.push({
        po_number: incomingPO.po_number,
        po_secondary_sku: incomingPO.po_secondary_sku,
        master_sku: incomingPO.master_sku,
        inventory_sku_id: incomingPO.inventory_sku_id,
        pack_combo_sku_id: incomingPO.pack_combo_sku_id,
        sku_type: incomingPO.sku_type,
        company_code_primary: incomingPO.company_code_primary,
        company_code_secondary: incomingPO.company_code_secondary,
        title: incomingPO.title,
        mrp: incomingPO.mrp,
        rate_without_tax: incomingPO.rate_without_tax,
        tax_rate: incomingPO.tax_rate,
        original_demand: incomingPO.demand, // Assuming demand is the original demand
        dispatched_quantity: incomingPO.dispatched_quantity, // Default to 0 if dispatched_quantity is not available
        consignment_quantity: ConsignmentQuantity,
        box_numbers_string: boxNumbersString,
        fill_rate:
          Math.round((ConsignmentQuantity / incomingPO.demand) * 100 * 100) /
          100,
      });
    });

    setOriginalConsignmentPOViewItems(poViewList);
  };

  const getClassNameForConsignment = (consignment_status: string) => {
    if (consignment_status == "OPEN") return "color-green";
    else if (consignment_status == "CLOSED") return "color-red";
    else if (consignment_status == "MARKED_RTD") return "color-pink";
    return "";
  };

  const prepareConsignmentAnalyticsObject = () => {
    const skuSet = new Set<string>();
    const boxSet = new Set<number>();
    let totalPackedSkus = 0;
    let totalPackedQuantity = 0;
    let totalDispatchedQuantity = 0;
    let totalDispatchedSkus = 0;
    let totalDemand = 0;
    let totalSkus = 0;

    originalConsignmentItems.forEach((item) => {
      skuSet.add(item.po_secondary_sku); // Track unique SKUs
      boxSet.add(item.box_number);
      totalPackedQuantity += item.box_quantity; // Add the packed quantity
    });

    totalPackedSkus = skuSet.size;
    purchaseOrderItems.forEach((item) => {
      if (item.dispatched_quantity > 0) {
        totalDispatchedSkus++;
        totalDispatchedQuantity += item.dispatched_quantity;
        skuSet.add(item.po_secondary_sku);
      }
      totalDemand += item.demand;
      totalSkus += 1;
    });

    const localConsignmentAnalyticsObject: ConsignmentAnalyticsObject = {
      total_consignment_boxes: boxSet.size,
      total_consignment_quantity: totalPackedQuantity,
      total_consignment_skus: totalPackedSkus,
      total_po_demand: totalDemand,
      total_po_skus: totalSkus,
      po_skus_dispatched: totalDispatchedSkus,
      po_quantity_dispatched: totalDispatchedQuantity,
      po_sku_fill_rate:
        Math.round((totalDispatchedSkus / totalSkus) * 100 * 100) / 100,
      po_quantity_fill_rate:
        Math.round((totalDispatchedQuantity / totalDemand) * 100 * 100) / 100,
      consignment_sku_fill_rate:
        Math.round((totalPackedSkus / totalSkus) * 100 * 100) / 100,
      consignment_quantity_fill_rate:
        Math.round((totalPackedQuantity / totalDemand) * 100 * 100) / 100,
      sku_fill_rate_will_increase_to:
        Math.round((skuSet.size / totalSkus) * 100 * 100) / 100,
      quantity_fill_rate_will_increase_to:
        Math.round(
          ((totalPackedQuantity + totalDispatchedQuantity) / totalDemand) *
            100 *
            100
        ) / 100,
    };

    setCurrentConsignmentAnalyticsObject(localConsignmentAnalyticsObject);
  };

  const uploadAndCheckConsignmentItems = async () => {
    //phase 1 check
    let error_count = 0;
    setShowLoadingPage(true);
    // const purchaseOrderItems: IncomingPurchaseOrderListingDetailsDataObject[] =
    //   await fetchPurchaseOrderListingDetails(po_id);

    const purchaseOrderDemandItems: PurchaseOrderDemandObject[] =
      await fetchPurchaseOrderDemandObjectDetails(po_id);

    console.log(purchaseOrderDemandItems);

    const detailMap = new Map<string, boolean>();
    const demandMap = new Map<string, PurchaseOrderDemandObject>();

    // Populate the hashmap with keys as 'po_secondary_sku|company_code_primary'
    purchaseOrderDemandItems.forEach((purchaseOrderItem) => {
      const key = `${purchaseOrderItem.po_secondary_sku}|${purchaseOrderItem.company_code_primary}`;
      detailMap.set(key, true);
      demandMap.set(purchaseOrderItem.po_secondary_sku, purchaseOrderItem);
    });

    uploadedConsignmentItems.forEach((item, index) => {
      const key = `${item.po_secondary_sku}|${item.company_code_primary}`;
      if (!detailMap.has(key)) {
        error_count++;
        item.hasError = true;
        item.errorMessage =
          ">) Invalid po_secondary_sku & company_code_primary mapping provided. Does not exist in PO.";
      }

      const isBoxNumberValid = item.box_number > 0;
      const isQuantityValid = item.box_quantity > 0;

      if (!isBoxNumberValid) {
        error_count++;
        item.hasError = true;
        item.errorMessage =
          item.errorMessage +
          ">) Invalid Box Number provided. Box number must be a Number greater than 0.";
      }
      if (!isQuantityValid) {
        error_count++;
        item.hasError = true;
        item.errorMessage =
          item.errorMessage +
          ">) Invalid Box Quantity provided. Quantity in a box must be a Number greater than 0.";
      }
    });

    if (error_count > 0) {
      setErrorConsignmentItems(_.cloneDeep(uploadedConsignmentItems));
      setShowLoadingPage(false);
      return;
    }

    //phase 2 check
    setFinalUploadedConsignmentItems(_.cloneDeep(uploadedConsignmentItems));

    //mergeFinalListWithOriginalConsignmentItems
    const mergedListTemp = [
      ...originalConsignmentItems,
      ...uploadedConsignmentItems,
    ];
    const mergedListMain = mergedListTemp.sort(
      (a, b) => a.box_number - b.box_number
    );

    // check mergedList
    const skuBoxMap = new Map<string, boolean>();
    const uploadedQuantityMap = new Map<string, number>();
    uploadedConsignmentItems.forEach((item) => {
      const existingQuantity =
        Number(uploadedQuantityMap.get(item.po_secondary_sku)) || 0; // Use 0 if the key doesn't exist
      uploadedQuantityMap.set(
        item.po_secondary_sku,
        existingQuantity + Number(item.box_quantity)
      );
    });

    // console.log(uploadedQuantityMap);

    for (const item of mergedListMain) {
      // Create a unique key for the SKU-box mapping
      const skuBoxKey = `${item.po_secondary_sku}_${item.box_number}`;

      if (skuBoxMap.has(skuBoxKey) && item.isNew) {
        // Mark the item as having an error if the mapping already exists
        item.hasError = true;
        item.errorMessage = `Duplicate mapping found for SKU: ${item.po_secondary_sku} and Box Number: ${item.box_number} .`;
      } else {
        // Add the mapping to the Map
        skuBoxMap.set(skuBoxKey, true);
        item.hasError = false; // Ensure no error for valid items
        item.errorMessage = "";
      }

      if (item.isNew) {
        const demandObject = demandMap.get(item.po_secondary_sku);
        const uploadedQuantity =
          uploadedQuantityMap.get(item.po_secondary_sku) || 0;

        if (demandObject) {
          const pendingCount =
            demandObject.original_demand -
            (demandObject.dispatched_quantity +
              demandObject.reserved_quantity) -
            uploadedQuantity;

          if (pendingCount < 0) {
            item.hasError = true;
            item.errorMessage += ` OverSupply!! demand is < (dispatched + reserved + uploaded qty) ${uploadedQuantity}`;
          }
        }
      }
    }

    // Sort the merged list by box_number
    setMergedConsignmentItems(
      mergedListMain.sort((a, b) => a.box_number - b.box_number)
    );

    setShowLoadingPage(false);
  };

  const mergedErrorsAreZero = (): boolean => {
    let result: boolean = true;
    mergedConsignmentItems.forEach((item) => {
      if (item.hasError) result = false;
    });

    return result;
  };

  const mapToUploadConsignmentFileFormat = async (data: any[]) => {
    const ConsignmentItems: ConsignmentItems[] = [];

    if (selectedConsignment) {
      data.forEach((item) => {
        ConsignmentItems.push({
          consignment_id: selectedConsignment.consignment_id,
          po_secondary_sku: item.po_secondary_sku,
          company_code_primary: item.company_code_primary,
          box_number: item.box_number,
          box_quantity: item.box_quantity,
          submitted_from: "WEB",
          created_by: userDetailsFromStore.user_details.userId,
          created_at: "",
          updated_at: "",
          hasError: false,
          errorMessage: "",
          isNew: true,
          isSelected: false,
        });
      });
    }

    setUploadedConsignmentItems(ConsignmentItems);
  };

  const handleConsignmentFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const expectedHeaders = [
      "po_secondary_sku",
      "company_code_primary",
      "demand_quantity",
      "dispatched_quantity",
      "reserved_quantity",
      "pending_quantity",
      "box_number",
      "box_quantity",
    ];

    setUploadButtonActive(false);

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      // let jsonPayload: any[] | null = null;
      if (selectedFile) {
        Papa.parse(selectedFile, {
          complete: (result) => {
            const fileHeaders = result.meta.fields;
            if (fileHeaders) {
              const headersMatch =
                expectedHeaders.every((header) =>
                  fileHeaders.includes(header)
                ) &&
                fileHeaders.every((header) => expectedHeaders.includes(header));

              if (!headersMatch) {
                toast.error(
                  "Error: The CSV file headers are incorrect. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return; // Stop further processing
              }

              const validRows = result.data.filter((row: any) => {
                // Check if the row is not empty
                return Object.values(row).some(
                  (value) => value !== null && value !== ""
                );
              });

              if (validRows.length === 0) {
                toast.error(
                  "Error: The CSV file contains no valid rows. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return;
              }

              // If headers match, process the data
              setUploadButtonActive(true);
              mapToUploadConsignmentFileFormat(validRows);
            } else {
              toast.error(
                "Error: The CSV file headers are missing. Please upload a valid file.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                }
              );
            }
          },
          header: true, // Treat the first row as header,
          skipEmptyLines: true,
        });
      }
    }
  };

  const handleVehicleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.trim(); // Trim spaces
    setVehicleNumber(value.toUpperCase()); // Update the state
  };

  const handleDocketNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim(); // Trim spaces
    setDocketNumber(value.toUpperCase()); // Update the state
  };

  const handleRTDMark = () => {
    if (
      !selectedTransporter ||
      selectedTransporter.transporter_name === "Select Transporter"
    ) {
      alert("Please select a valid transporter.");
      return;
    }
    if (
      !selectedShipmentMode ||
      selectedShipmentMode === "Select Shipment Mode"
    ) {
      alert("Please select a valid shipment mode.");
      return;
    }

    if (selectedShipmentMode) {
      if (selectedShipmentMode == "Roadways") {
        if (!vehicleNumber || /\s/.test(vehicleNumber)) {
          alert("Vehicle number cannot be empty or contain spaces.");
          return;
        }

        const finalData = {
          id: 0,
          transporter: selectedTransporter,
          shipmentMode: selectedShipmentMode,
          vehicleNumber: vehicleNumber.toUpperCase(),
          docketNumber: "",
        };
        setShipmentData(finalData);
      } else {
        if (!docketNumber || /\s/.test(docketNumber)) {
          alert("Docket number cannot be empty or contain spaces.");
          return;
        }

        const finalData = {
          id: 0,
          transporter: selectedTransporter,
          shipmentMode: selectedShipmentMode,
          vehicleNumber: "",
          docketNumber: docketNumber.toUpperCase(),
        };

        setShipmentData(finalData);
      }
    }

    // Final Data

    setShowDispatchCanvas(false);
    setShowFinalRTDDialog(true);
  };

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPcolor = (wip_status: string) => {
    if (wip_status == "YES") return "marked-green";
    else return "marked-red";
  };

  const sortByPendingQuantity = (
    array: IncomingPurchaseOrderListingDetailsDataObject[]
  ): IncomingPurchaseOrderListingDetailsDataObject[] => {
    const sortedArray = [...array].sort((a, b) => {
      const diffA = a.demand - a.reserved_quantity - a.dispatched_quantity; //a.pending;
      const diffB = b.demand - b.reserved_quantity - b.dispatched_quantity; //b.pending;
      return diffB - diffA; // descending order
    });

    return sortedArray;
  };

  const handleConsignmentTemplateFileDownload = async () => {
    setShowDownloadPage(true);
    const templateItems: IncomingPurchaseOrderListingDetailsDataObject[] =
      sortByPendingQuantity(await fetchPurchaseOrderListingDetails(po_id));

    try {
      // itemsToExport.push()
      const transformedItems = templateItems
        .filter(
          (singleItem) =>
            singleItem.demand -
              singleItem.dispatched_quantity -
              singleItem.reserved_quantity >
            0
        )
        .map((singleItem) => ({
          po_secondary_sku: singleItem.po_secondary_sku,
          company_code_primary: singleItem.company_code_primary,
          demand_quantity: singleItem.demand,
          dispatched_quantity: singleItem.dispatched_quantity,
          reserved_quantity: singleItem.reserved_quantity,
          pending_quantity:
            singleItem.demand -
            singleItem.dispatched_quantity -
            singleItem.reserved_quantity,
          box_number: 0,
          box_quantity: 0,
        }));

      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = currentPurchaseOrder
          ? selectedConsignment?.consignment_id + "_consignment_template.csv"
          : "po_detailed_report.csv";
        // Simulate a click to trigger the download
        link.click();
      });

      setShowDownloadPage(false);
    } catch (error) {
      console.log(
        "Printing error from CompanyPurchaseOrderConsignmentDetails -> Download Consignment template.   "
      );
      console.error("Error:", error);
      setShowDownloadPage(false);
    }
  };

  const submitConsignmentItems = async () => {
    if (selectedConsignment) {
      setShowLoadingPage(true);
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.submitConsignmentItems(
            selectedConsignment.consignment_id.toString(),
            finalUploadedConsignmentItems,
            userDetailsFromStore.user_details.userId
          );

        //setOriginalConsignmentItems(response.data);
        setUpConsignmentViews(response.data, purchaseOrderItems);
        setMergedConsignmentItems([]);
        setUploadedConsignmentItems([]);
        setFinalUploadedConsignmentItems([]);
        setErrorConsignmentItems([]);
        setShowLoadingPage(false);
      } catch (error) {
        console.log("Error! Could not fetch purchase order details.");
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error(
            (err.response.data as ErrorMessageBody).message +
              " Please Refresh.",
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
            }
          );
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
        setShowLoadingPage(false);
      }
    }
  };

  const detectMissingBoxNumbers = async () => {
    if (originalConsignmentItems.length == 0) {
      setMissingBoxesMessage("");
      return;
    }

    const boxNumbers = originalConsignmentItems.map((item) => item.box_number);
    const boxNumbersSet = new Set(boxNumbers);
    const maxBoxNumber = Math.max(...boxNumbers);
    const completeRange = Array.from(
      { length: maxBoxNumber },
      (_, index) => index + 1
    );

    const missingBoxNumbers = completeRange.filter(
      (number) => !boxNumbersSet.has(number)
    );

    if (missingBoxNumbers.length == 0) setMissingBoxesMessage("");
    else
      setMissingBoxesMessage(
        `Alert! Follwowing box numbers are missing : ${missingBoxNumbers.join(
          ","
        )}`
      );
  };

  const fetchConsignmentByConsignmentId = async (consignment_id: string) => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.fetchConsignmentByConsignmentId(
          consignment_id
        );

      setSelectedConsignment(response.data);
      setShowConsignmentOptions(true);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in fetching Consignment by consignment id: ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const handleTransporterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selected = transporters.find(
      (transporter) => transporter.id.toString() === selectedId
    );
    setSelectedTransporter(selected || null);
  };

  const handleShipmentModeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedShipmentMode(e.target.value);
  };

  const getAllTransporters = async () => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getAllTransporterDetails();
      setTransporters(response.data);
    } catch (error) {
      console.error("Error in fetching transporters: ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const markForRTD = async () => {
    try {
      const response = await companyPurchaseOrderNetworkUtil.markForRTD(
        po_id,
        consignment_id,
        userDetailsFromStore.user_details.userId,
        shipmentData
      );
      toast.success("RTD marked successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      window.location.reload();
    } catch (error) {
      console.error("Error in fetching transporters: ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const handleCheckBoxChangeForAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedSelectAll(event.target.checked); // Update state based on the checkbox value
    if (event.target.checked) {
      setCheckedCount(originalConsignmentItems.length);
    } else {
      setCheckedCount(0);
    }

    setOriginalConsignmentItems((prevItems) =>
      prevItems.map((item, i) => ({
        ...item,
        ["isSelected"]: event.target.checked,
      }))
    );
  };

  const handleCheckBoxChangeAtIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) {
      setCheckedCount(checkedCount + 1);
    } else {
      setCheckedCount(checkedCount - 1);
      if (checkedCount - 1 == 0 && isCheckedSelectAll) {
        setIsCheckedSelectAll(false);
      }
    }

    setOriginalConsignmentItems((prevItems) =>
      prevItems.map((item, i) =>
        i == index ? { ...item, ["isSelected"]: !item.isSelected } : item
      )
    );
  };

  const prepareAndRemoveSelectedItems = async () => {
    const removalList = originalConsignmentItems.filter(
      (item) => item.isSelected === true
    ); // Use strict equality and ensure the condition is returned

    try {
      const response =
        await companyPurchaseOrderNetworkUtil.deleteItemsFromPickList(
          removalList,
          userDetailsFromStore.user_details.userId,
          consignment_id
        );
      window.location.reload();
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in prepareAndRemoveSelectedItemss : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else if (
        err.response &&
        err.response.data &&
        err.response.status == 500
      ) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
    }
  };

  const resetAllChecks = async () => {
    setCheckedCount(0);
    setIsCheckedSelectAll(false);
    setOriginalConsignmentItems((prevItems) =>
      prevItems.map((item, i) => ({
        ...item,
        ["isSelected"]: false,
      }))
    );
  };

  const downloadDefaultViewAsSpreadsheet = () => {
    try {
      const transformedItems = originalConsignmentItems.map((singleItem) => {
        return {
          po_number: po_id,
          po_secondary_sku: singleItem.po_secondary_sku,
          company_code_primary: singleItem.company_code_primary,
          box_number: singleItem.box_number,
          box_quantity: singleItem.box_quantity,
          submitted_from: singleItem.submitted_from,
          created_by: singleItem.created_by,
          created_at: DateTimeUtils.formatDateTime(singleItem.created_at),
          updated_at: DateTimeUtils.formatDateTime(singleItem.updated_at),
        };
      });

      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = po_id + "_default_view_packing_list.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from downloadDefaultViewAsSpreadsheet.");
      console.error("Error:", error);
    }
  };
  const downloadBoxViewAsSpreadsheet = () => {
    try {
      const transformedItems = originalConsignmentBoxViewItems.map(
        (singleItem) => {
          return {
            po_number: po_id,
            box_number: singleItem.box_number,
            total_quantity: singleItem.total_quantity,
            po_secondary_sku_string: singleItem.po_secondary_sku_string,
            company_code_primary_string: singleItem.company_code_primary_string,
          };
        }
      );

      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = po_id + "_box_view_packing_list.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from downloadBoxViewAsSpreadsheet.");
      console.error("Error:", error);
    }
  };

  const downloadSKUViewAsSpreadsheet = () => {
    try {
      const transformedItems = originalConsignmentSKUViewItems.map(
        (singleItem) => {
          return {
            po_number: po_id,
            po_secondary_sku: singleItem.po_secondary_sku,
            company_code_primary: singleItem.company_code_primary,
            total_quantity: singleItem.total_quantity,
            box_numbers: singleItem.box_numbers_string,
          };
        }
      );

      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = po_id + "_sku_view_packing_list.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from downloadSKUViewAsSpreadsheet.");
      console.error("Error:", error);
    }
  };

  const downloadPOViewAsSpreadsheet = () => {
    try {
      const transformedItems = originalConsignmentPOViewItems.map(
        (singleItem) => {
          return {
            po_number: po_id,
            po_secondary_sku: singleItem.po_secondary_sku,
            company_code_primary: singleItem.company_code_primary,
            company_code_secondary: singleItem.company_code_secondary,
            mrp: singleItem.mrp,
            rate_without_tax: singleItem.rate_without_tax,
            tax_rate: singleItem.tax_rate,
            original_demand: singleItem.original_demand,
            dispatched_quantity: singleItem.dispatched_quantity,
            consignment_quantity: singleItem.consignment_quantity,
            box_numbers: singleItem.box_numbers_string,
            overall_fill_rate: singleItem.fill_rate,
          };
        }
      );

      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = po_id + "_po_view_packing_list.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from downloadSKUViewAsSpreadsheet.");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchConsignmentByConsignmentId(consignment_id);
    getAllTransporters();
  }, []);

  useEffect(() => {
    if (showConsignmentOptions && selectedConsignment) {
      fetchPurchaseOrderDetails(po_id);
      fetchPurchaseOrderDetailFiles(po_id);
      // fetchPurchaseOrderListingDetails(po_id);
      fetchConsignmentItemsByConsignmentId(selectedConsignment.consignment_id);
    } else {
      setUploadedConsignmentItems([]);
      setFinalUploadedConsignmentItems([]);
    }
  }, [showConsignmentOptions]);

  useEffect(() => {
    if (purchaseOrderItems.length > 0) {
      prepareConsignmentAnalyticsObject();
      detectMissingBoxNumbers();
    }
  }, [originalConsignmentItems, purchaseOrderItems]);

  return (
    <div className="consignment-details-container catalogues-container">
      {/* <Header /> */}

      {selectedConsignment && (
        <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container">
          <div className="po-document-section-heading">
            <Table bordered hover size="sm" className="table-auto-width mt-2">
              <tbody>
                <tr className="consignment-heading">
                  <td className="nowrap">
                    Consignment - {selectedConsignment.consignment_id}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}

      <div className="purchase-order-pack-task-details-container-main">
        {poDocuments.length > 0 && (
          <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container">
            <div className="po-document-section-heading">
              Purchase Order Files For Reference
            </div>

            <div className="incoming-purchase-order-file-details-table-container mt-3">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>File ID</th>
                    <th>Uploaded At</th>
                    <th>Uploaded By</th>
                    <th>File Name</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {poDocuments.map((doc, index) => {
                    return (
                      <tr className="catalogues-search-bar-row" key={index}>
                        <td className="nowrap">{doc.id}</td>
                        <td>{DateTimeUtils.formatDateTime(doc.created_at)}</td>
                        <td>{doc.file_uploaded_by}</td>
                        <td>{doc.file_name}</td>
                        <td>
                          <Download
                            size={15}
                            color="#007bff"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              downloadFileFromWeb(doc.id, doc.saved_file_name)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {currentPurchaseOrder && (
          <div className="incoming-purchase-order-pack-task-details-table-container">
            <Table bordered hover size="sm" className="table-auto-width">
              <tbody>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">PO Status</td>
                  <td
                    className={getCalculatedPoStatusColor(
                      currentPurchaseOrder.calculated_po_status
                    )}
                  >
                    {currentPurchaseOrder.calculated_po_status}
                  </td>
                </tr>

                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Marked WIP ?</td>
                  <td className={getWIPcolor(currentPurchaseOrder.is_wip)}>
                    {currentPurchaseOrder.is_wip}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Buyer Company Name</td>
                  <td>{currentPurchaseOrder.company.company_name}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">PO Number</td>
                  <td>{currentPurchaseOrder.po_number}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">PO Type</td>
                  <td>{currentPurchaseOrder.po_type}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Sold Via</td>
                  <td>{currentPurchaseOrder.sold_via}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Reference Location</td>
                  <td>{currentPurchaseOrder.delivery_city}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Delivery Address</td>
                  <td>{currentPurchaseOrder.delivery_address}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Billing Address</td>
                  <td>{currentPurchaseOrder.billing_address}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Buyer GSTIN</td>
                  <td>{currentPurchaseOrder.buyer_gstin}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">PO Release Date</td>
                  <td>
                    {DateTimeUtils.formatDateOnly(
                      currentPurchaseOrder.po_issue_date
                    )}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Expiry Date</td>
                  <td>
                    {DateTimeUtils.formatDateOnly(
                      currentPurchaseOrder.expiry_date
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}

        <div className="incoming-purchase-order-pack-task-summary-table-container">
          {currenConsignmentAnalyticsObject && (
            <div className="incoming-purchase-order-pack-task-summary-table-container-child">
              <Table bordered hover size="sm" className="table-auto-width">
                <tbody>
                  <tr className="catalogues-search-bar-row">
                    <td colSpan={2} className="centered-heading">
                      PO Summary
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Number of SKUs</td>
                    <td className="centered">
                      {currenConsignmentAnalyticsObject.total_po_skus}
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Total Demand</td>
                    <td className="centered">
                      {currenConsignmentAnalyticsObject.total_po_demand}
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Total SKUs dispatched</td>
                    <td className="centered">
                      {currenConsignmentAnalyticsObject.po_skus_dispatched}
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Total Quantity dispatched</td>
                    <td className="centered">
                      {currenConsignmentAnalyticsObject.po_quantity_dispatched}
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">SKU fill rate</td>
                    <td className="centered">
                      {currenConsignmentAnalyticsObject.po_sku_fill_rate}%
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Quantity fill rate</td>
                    <td className="centered">
                      {currenConsignmentAnalyticsObject.po_quantity_fill_rate}%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {currenConsignmentAnalyticsObject && (
            <div className="incoming-purchase-order-pack-task-summary-table-container-child">
              <Table bordered hover size="sm" className="table-auto-width">
                <tbody>
                  <tr className="catalogues-search-bar-row">
                    <td colSpan={2} className="centered-heading">
                      Current Consignment Summary
                    </td>
                  </tr>
                  {selectedConsignment && (
                    <tr className="catalogues-search-bar-row">
                      <td className="nowrap">Consignment Status</td>
                      <td
                        className={`centered ${getClassNameForConsignment(
                          selectedConsignment.consignment_status
                        )}`}
                      >
                        {selectedConsignment.consignment_status}
                      </td>
                    </tr>
                  )}
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Number of SKUs</td>
                    <td className="centered">
                      {currenConsignmentAnalyticsObject.total_consignment_skus}
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Total Boxes</td>
                    <td className="centered">
                      {currenConsignmentAnalyticsObject.total_consignment_boxes}
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Total Quantity</td>
                    <td className="centered">
                      {
                        currenConsignmentAnalyticsObject.total_consignment_quantity
                      }
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">Contribution to SKU fill rate</td>
                    <td className="centered">
                      {
                        currenConsignmentAnalyticsObject.consignment_sku_fill_rate
                      }
                      %
                    </td>
                  </tr>
                  <tr className="catalogues-search-bar-row">
                    <td className="nowrap">
                      Contribution to Quantity fill rate
                    </td>
                    <td className="centered">
                      {
                        currenConsignmentAnalyticsObject.consignment_quantity_fill_rate
                      }
                      %
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {currenConsignmentAnalyticsObject &&
            selectedConsignment &&
            (selectedConsignment.consignment_status == "OPEN" &&
            originalConsignmentItems.length > 0 ? (
              <div className="incoming-purchase-order-pack-task-summary-table-container-child">
                <Table bordered hover size="sm" className="table-auto-width">
                  <tbody>
                    <tr className="catalogues-search-bar-row">
                      <td colSpan={2} className="centered-heading">
                        After Dispatch Analysis
                      </td>
                    </tr>
                    <tr className="catalogues-search-bar-row success-row">
                      <td className="nowrap">
                        SKU fill rate will increase to :{" "}
                      </td>
                      <td className="centered">
                        {
                          currenConsignmentAnalyticsObject.sku_fill_rate_will_increase_to
                        }
                        %
                      </td>
                    </tr>
                    <tr className="catalogues-search-bar-row success-row">
                      <td className="nowrap">
                        Quantity fill rate will increase to :{" "}
                      </td>
                      <td className="centered">
                        {
                          currenConsignmentAnalyticsObject.quantity_fill_rate_will_increase_to
                        }
                        %
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="incoming-purchase-order-pack-task-summary-table-container-child"></div>
            ))}
        </div>

        <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container pt-4">
          <div className="po-document-section-heading">Consignment Items</div>
          {originalConsignmentItems.length === 0 ? (
            <div className="po-document-section-no-document mt-3">
              No items were found
            </div>
          ) : (
            <div>
              <Tabs
                id="controlled-tab-example"
                activeKey={ConsignmentViewKey}
                onSelect={(k) => {
                  if (k) {
                    setConsignmentViewKey(k);
                  }
                }}
                className="mb-3 mt-4 custom-tabs"
                justify
              >
                <Tab eventKey="default" title="Default View">
                  <div className="buttons-container-row">
                    <div className="buttons-container-sub">
                      {checkedCount > 0 && (
                        <Button
                          className="filters-button"
                          onClick={() => setShowRemovalConfirmationDialog(true)}
                        >
                          Remove Selected Items from Picklist.
                        </Button>
                      )}

                      <Button
                        className="filters-button"
                        onClick={downloadDefaultViewAsSpreadsheet}
                        variant="outline-primary"
                      >
                        Download Current View
                      </Button>
                    </div>
                  </div>
                  <div className="incoming-purchase-order-file-details-table-container pt-4">
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width"
                    >
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>PO Secondary SKU</th>
                          <th>Company Code Primary</th>
                          <th>Box Number</th>
                          <th>Box Quantity</th>
                          <th>Submitted From</th>
                          <th>Created By</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          {selectedConsignment &&
                            selectedConsignment.consignment_status !=
                              "MARKED_RTD" && (
                              <th>
                                {" "}
                                <input
                                  type="checkbox"
                                  checked={isCheckedSelectAll}
                                  onChange={handleCheckBoxChangeForAll}
                                />
                              </th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {originalConsignmentItems.map(
                          (ConsignmentItem, index) => {
                            return (
                              <tr
                                className="catalogues-search-bar-row"
                                key={index}
                              >
                                <td className="nowrap">{index + 1}</td>
                                <td>{ConsignmentItem.po_secondary_sku}</td>
                                <td>{ConsignmentItem.company_code_primary}</td>
                                <td>{ConsignmentItem.box_number}</td>
                                <td>{ConsignmentItem.box_quantity}</td>
                                <td>{ConsignmentItem.submitted_from}</td>
                                <td>{ConsignmentItem.created_by}</td>
                                <td>
                                  {DateTimeUtils.formatDateTime(
                                    ConsignmentItem.created_at
                                  )}
                                </td>
                                <td>
                                  {DateTimeUtils.formatDateTime(
                                    ConsignmentItem.updated_at
                                  )}
                                </td>
                                {selectedConsignment &&
                                  selectedConsignment.consignment_status !=
                                    "MARKED_RTD" && (
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={ConsignmentItem.isSelected}
                                        onChange={(e) =>
                                          handleCheckBoxChangeAtIndex(e, index)
                                        }
                                      />
                                    </td>
                                  )}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab eventKey="boxview" title="Box Wise View">
                  <div className="buttons-container-row">
                    <div className="buttons-container-sub">
                      <Button
                        className="filters-button"
                        onClick={downloadBoxViewAsSpreadsheet}
                        variant="outline-primary"
                      >
                        Download Current View
                      </Button>
                    </div>
                  </div>
                  <div className="incoming-purchase-order-file-details-table-container pt-4">
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width"
                    >
                      <thead>
                        <tr>
                          <th>Box Number</th>
                          <th>Total Box Quantity</th>
                          <th>PO Secondary SKUs in Box</th>
                          <th>Company Codes Primary in Box</th>
                        </tr>
                      </thead>
                      <tbody>
                        {originalConsignmentBoxViewItems.map(
                          (ConsignmentBoxItem, index) => {
                            return (
                              <tr
                                className="catalogues-search-bar-row"
                                key={index}
                              >
                                <td className="nowrap">
                                  {ConsignmentBoxItem.box_number}
                                </td>
                                <td>{ConsignmentBoxItem.total_quantity}</td>
                                <td>
                                  {ConsignmentBoxItem.po_secondary_sku_string}
                                </td>
                                <td>
                                  {
                                    ConsignmentBoxItem.company_code_primary_string
                                  }
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab eventKey="skuview" title="SKU Wise View">
                  <div className="buttons-container-row">
                    <div className="buttons-container-sub">
                      <Button
                        className="filters-button"
                        onClick={downloadSKUViewAsSpreadsheet}
                        variant="outline-primary"
                      >
                        Download Current View
                      </Button>
                    </div>
                  </div>
                  <div className="incoming-purchase-order-file-details-table-container pt-4">
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width"
                    >
                      <thead>
                        <tr>
                          <th>PO Secondary SKU</th>
                          <th>Company Code Primary</th>
                          <th>Total Quantity</th>
                          <th>Box Numbers</th>
                        </tr>
                      </thead>
                      <tbody>
                        {originalConsignmentSKUViewItems.map(
                          (ConsignmentSKUItem, index) => {
                            return (
                              <tr
                                className="catalogues-search-bar-row"
                                key={index}
                              >
                                <td className="nowrap">
                                  {ConsignmentSKUItem.po_secondary_sku}
                                </td>
                                <td className="nowrap">
                                  {ConsignmentSKUItem.company_code_primary}
                                </td>
                                <td>{ConsignmentSKUItem.total_quantity}</td>
                                <td>{ConsignmentSKUItem.box_numbers_string}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab eventKey="poview" title="PO Wise View">
                  <div className="buttons-container-row">
                    <div className="buttons-container-sub">
                      <Button
                        className="filters-button"
                        onClick={downloadPOViewAsSpreadsheet}
                        variant="outline-primary"
                      >
                        Download Current View
                      </Button>
                    </div>
                  </div>
                  <div className="incoming-purchase-order-file-details-table-container pt-4">
                    <Table
                      bordered
                      hover
                      size="sm"
                      className="table-auto-width"
                    >
                      <thead>
                        <tr>
                          <th>PO Secondary SKU</th>
                          <th>Company Code Primary</th>
                          <th>Company Code Secondary</th>
                          <th>MRP</th>
                          <th>Original Demand</th>
                          <th>Dispatched Quantity</th>
                          <th>Consignment Quantity</th>
                          <th>Box Numbers</th>
                          <th>Overall Fill Rate %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {originalConsignmentPOViewItems.map(
                          (ConsignmentPOItem, index) => {
                            return (
                              <tr
                                className={`catalogues-search-bar-row ${
                                  ConsignmentPOItem.consignment_quantity > 0
                                    ? " part-row"
                                    : ""
                                }`}
                                key={index}
                              >
                                <td className="nowrap">
                                  {ConsignmentPOItem.po_secondary_sku}
                                </td>

                                <td>
                                  {ConsignmentPOItem.company_code_primary}
                                </td>
                                <td>
                                  {ConsignmentPOItem.company_code_secondary}
                                </td>
                                <td>{ConsignmentPOItem.mrp}</td>

                                <td>{ConsignmentPOItem.original_demand}</td>
                                <td>{ConsignmentPOItem.dispatched_quantity}</td>
                                <td>
                                  {ConsignmentPOItem.consignment_quantity}
                                </td>
                                <td>{ConsignmentPOItem.box_numbers_string}</td>
                                <td>{ConsignmentPOItem.fill_rate}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
              </Tabs>
            </div>
          )}
          <div className="missing-boxes-message">{missingBoxesMessage}</div>
        </div>

        {mergedConsignmentItems.length > 0 && (
          <div className="vendor-action-buttons-container incoming-purchase-order-merged-pack-task-details-table-container pt-4">
            <div className="po-document-section-heading">
              Merged Consignment Items
            </div>
            {mergedConsignmentItems.length === 0 ? (
              <div className="po-document-section-no-document mt-3">
                No items were found
              </div>
            ) : (
              <div className="incoming-purchase-order-file-details-table-container pt-4">
                <Table bordered hover size="sm" className="table-auto-width">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>PO Secondary SKU</th>
                      <th>Company Code Primary</th>
                      <th>Box Number</th>
                      <th>Box Quantity</th>
                      <th>Submitted From</th>
                      <th>Created By</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Is New ?</th>
                      <th>Has Error ?</th>
                      <th>Error Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mergedConsignmentItems.map((ConsignmentItem, index) => {
                      return (
                        <tr
                          className={`catalogues-search-bar-row ${
                            ConsignmentItem.hasError
                              ? " error-row"
                              : ConsignmentItem.isNew
                              ? " success-row"
                              : ""
                          }`}
                          key={index}
                        >
                          <td className="nowrap">{index + 1}</td>
                          <td>{ConsignmentItem.po_secondary_sku}</td>
                          <td>{ConsignmentItem.company_code_primary}</td>
                          <td>{ConsignmentItem.box_number}</td>
                          <td>{ConsignmentItem.box_quantity}</td>
                          <td>{ConsignmentItem.submitted_from}</td>
                          <td>{ConsignmentItem.created_by}</td>
                          <td>
                            {DateTimeUtils.formatDateTime(
                              ConsignmentItem.created_at
                            )}
                          </td>
                          <td>
                            {DateTimeUtils.formatDateTime(
                              ConsignmentItem.updated_at
                            )}
                          </td>
                          <td>{ConsignmentItem.isNew ? "Yes" : ""}</td>
                          <td>{ConsignmentItem.hasError ? "Yes" : ""}</td>
                          <td>{ConsignmentItem.errorMessage}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        )}

        {errorConsignmentItems && errorConsignmentItems.length > 0 && (
          <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container pt-4">
            <div className="po-document-section-heading">
              Uploaded Consignment Items
            </div>

            <div className="incoming-purchase-order-file-details-table-container pt-4">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>PO Secondary SKU</th>
                    <th>Company Code Primary</th>
                    <th>Box Number</th>
                    <th>Box Quantity</th>
                    <th>Submitted From</th>
                    <th>Created By</th>
                    <th>Has Error ?</th>
                    <th>Error Message</th>
                  </tr>
                </thead>
                <tbody>
                  {errorConsignmentItems.map((ConsignmentItem, index) => {
                    return (
                      <tr
                        className={`catalogues-search-bar-row ${
                          ConsignmentItem.hasError ? " error-row" : ""
                        }`}
                        key={index}
                      >
                        <td className="nowrap">{index + 1}</td>
                        <td>{ConsignmentItem.po_secondary_sku}</td>
                        <td>{ConsignmentItem.company_code_primary}</td>
                        <td>{ConsignmentItem.box_number}</td>
                        <td>{ConsignmentItem.box_quantity}</td>
                        <td>{ConsignmentItem.submitted_from}</td>
                        <td>{ConsignmentItem.created_by}</td>
                        <td>{ConsignmentItem.hasError ? "Yes" : ""}</td>
                        <td>{ConsignmentItem.errorMessage}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        <div className="pack-tasks-button-container-main">
          {(errorConsignmentItems.length > 0 ||
            mergedConsignmentItems.length > 0 ||
            finalUploadedConsignmentItems.length > 0) && (
            <div className="pack-task-buttons-container pt-4">
              <Button
                variant="outline-primary"
                className="vendor-action-button"
                onClick={() => {
                  setShowCancellationConfirmationItemModal(true);
                }}
              >
                Cancel
              </Button>
            </div>
          )}

          {selectedConsignment &&
            selectedConsignment.consignment_status == "OPEN" &&
            mergedConsignmentItems.length == 0 &&
            uploadedConsignmentItems.length == 0 && (
              <div className="pack-task-buttons-container pt-4">
                <Button
                  variant="outline-primary"
                  className="vendor-action-button"
                  onClick={() => setShowUploadConsignmentItemsModal(true)}
                >
                  Add Items to this Consignment
                </Button>
              </div>
            )}

          {selectedConsignment &&
            selectedConsignment.consignment_status == "OPEN" &&
            mergedConsignmentItems.length == 0 &&
            uploadedConsignmentItems.length == 0 &&
            originalConsignmentItems.length > 0 && (
              <div className="pack-task-buttons-container pt-4">
                <Button
                  variant="outline-primary"
                  className="vendor-action-button"
                  onClick={() => {
                    if (missingBoxesMessage.length > 0) {
                      toast.error(
                        "Error! Missing box numbers. Fix them to continue.",
                        {
                          position: toast.POSITION.TOP_RIGHT,
                          hideProgressBar: true,
                        }
                      );
                      return;
                    }
                    setShowDispatchCanvas(true);
                  }}
                >
                  Mark For Dispatch
                </Button>
              </div>
            )}

          {mergedConsignmentItems.length > 0 && mergedErrorsAreZero() && (
            <div className="pack-task-buttons-container pt-4">
              <Button
                variant="outline-primary"
                className="vendor-action-button"
                onClick={() => setShowSubmitConsignmentItemsModal(true)}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={showUploadConsignmentItemsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowUploadConsignmentItemsModal(false)}
      >
        <div>
          <Modal.Body>
            <div className="upload-incoming-po-container">
              <div className="upload-incoming-po-container-insider mb-4 pt-4 pb-4">
                <div className="upload-snapshot-container">
                  <div className="upload-snapshot-heading-text">
                    Upload Consignment Items
                  </div>
                  <div>
                    {showDownloadPage ? (
                      <div className="mt-2">
                        <div className="pack-task-template-download-message">
                          Downloading Consignment Template...
                        </div>
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <p
                        className="download-sample-link"
                        onClick={handleConsignmentTemplateFileDownload}
                      >
                        [Download Template File]
                      </p>
                    )}
                  </div>
                  {!showDownloadPage && (
                    <div className="upload-controls">
                      <input
                        className="upload-snapshot-button"
                        type="file"
                        accept=".csv"
                        id="fileInput"
                        onChange={handleConsignmentFileChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="add-item-quantity-card-button-container">
              <Button
                className="mr-2"
                onClick={() => setShowUploadConsignmentItemsModal(false)}
              >
                Cancel
              </Button>

              <Button
                className="ml-2"
                onClick={() => {
                  setShowUploadConsignmentItemsModal(false);
                  uploadAndCheckConsignmentItems();
                }}
                disabled={!uploadButtonActive}
              >
                Upload
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={showCancellationConfirmationItemModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowCancellationConfirmationItemModal(false)}
      >
        <div>
          <Modal.Body>
            This will remove the uploaded items, are you sure ?
          </Modal.Body>
          <Modal.Footer>
            <div className="add-item-quantity-card-button-container">
              <Button
                className="mr-2"
                onClick={() => setShowCancellationConfirmationItemModal(false)}
              >
                Cancel
              </Button>

              <Button
                className="ml-2"
                onClick={() => {
                  setMergedConsignmentItems([]);
                  setUploadedConsignmentItems([]);
                  setFinalUploadedConsignmentItems([]);
                  setErrorConsignmentItems([]);
                  setShowCancellationConfirmationItemModal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={showSubmitConsignmentItemsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowSubmitConsignmentItemsModal(false)}
      >
        <div>
          <Modal.Body>
            Are you sure you want to submit these Consignment items ?
          </Modal.Body>
          <Modal.Footer>
            <div className="add-item-quantity-card-button-container">
              <Button
                className="mr-2"
                onClick={() => setShowSubmitConsignmentItemsModal(false)}
              >
                Cancel
              </Button>

              <Button
                className="ml-2"
                onClick={() => {
                  setShowSubmitConsignmentItemsModal(false);
                  submitConsignmentItems();
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={showFinalRTDDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowFinalRTDDialog(false)}
      >
        <div>
          <Modal.Body>
            Are you sure you want to mark this consignment RTD ?
          </Modal.Body>
          <Modal.Footer>
            <div className="add-item-quantity-card-button-container">
              <Button
                className="mr-2"
                onClick={() => setShowFinalRTDDialog(false)}
              >
                Cancel
              </Button>

              <Button
                className="ml-2"
                onClick={() => {
                  setShowFinalRTDDialog(false);
                  markForRTD();
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={showRemovalConfirmationDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setShowRemovalConfirmationDialog(false);
          resetAllChecks();
        }}
      >
        <div>
          <Modal.Body>
            Are you sure you want to remove the selected items from picklist ?
          </Modal.Body>
          <Modal.Footer>
            <div className="add-item-quantity-card-button-container">
              <Button
                className="mr-2"
                onClick={() => {
                  setShowRemovalConfirmationDialog(false);
                  resetAllChecks();
                }}
              >
                Cancel
              </Button>

              <Button
                className="ml-2"
                onClick={() => {
                  prepareAndRemoveSelectedItems();
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      {showDispatchCanvas && (
        <div
          className="custom-backdrop"
          onClick={handleDispatchCanvasClose} // Allow closing on backdrop click
        ></div>
      )}

      <Offcanvas
        show={showDispatchCanvas}
        onHide={handleDispatchCanvasClose}
        placement="end"
        className="dispatch-offcanvas"
        backdrop="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="dispatch-offcanvas-title">Mark For Dispatch</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="dispatch-details-container">
            <div className="select-container">
              <select
                className="transporter-picker-select"
                onChange={handleTransporterChange}
                value={selectedTransporter ? selectedTransporter.id : ""}
              >
                <option value="">Select Transporter</option>
                {transporters.map((transporter) => (
                  <option key={transporter.id} value={transporter.id}>
                    {transporter.transporter_name}
                  </option>
                ))}
              </select>
            </div>

            {selectedTransporter && (
              <div className="selected-transporter-info">
                <p>
                  Transporter ID : {selectedTransporter.legal_transporter_id}
                </p>
              </div>
            )}

            <div className="select-container">
              <select
                className="transporter-picker-select"
                onChange={handleShipmentModeChange}
                value={selectedShipmentMode ? selectedShipmentMode : ""}
              >
                <option value="">Select Shipment Mode</option>
                {shipmentModes.map((shipmentMode, index) => (
                  <option key={index} value={shipmentMode}>
                    {shipmentMode}
                  </option>
                ))}
              </select>
            </div>

            {selectedTransporter && selectedShipmentMode && (
              <div>
                {selectedShipmentMode === "Roadways" ? (
                  <div className="vehicle-number mt-4">
                    <label>Add Vehicle Number</label>
                    <input
                      name="transporter_vehicle_number"
                      value={vehicleNumber}
                      onChange={handleVehicleNumberChange}
                    />
                  </div>
                ) : (
                  <div className="vehicle-number mt-4">
                    <label>Add Docket Number</label>
                    <input
                      name="transporter_docket_number"
                      value={docketNumber}
                      onChange={handleDocketNumberChange}
                    />
                  </div>
                )}

                <div className="offcanvas-button-container">
                  <button type="button" onClick={handleRTDMark}>
                    Continue
                  </button>
                </div>
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrdersConsignmentDetails;
