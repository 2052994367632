import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { PurchaseOrder, Specialty, Vendor, VendorListing } from "../../../atomic_components/types";


class PurchaseOrderNetworkUtils {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location:any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async createNewPurchaseOrder(newPurchaseOrder: any) {
    try {
      let url = `${requestHeaders.rooturl}${requests.createNewPurchaseOrder}`;
      const response = await this.api.post(url, newPurchaseOrder, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from createNewPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurchaseOrderById(poId : string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getPurchaseOrderById}${poId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurchaseOrderById");
      console.error("Error:", error);
      throw error;
    }
  }

  async addItemToPurchaseOrder(purchaseOrderItem : any, poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.addItemToPurchaseOrder}${poId}`;
      const response = await this.api.put(url, purchaseOrderItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from addItemToPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async deleteItemFromPurchaseOrder(purchaseOrderItem : any, poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.deleteItemFromPurchaseOrder}${poId}`;
      const response = await this.api.post(url, purchaseOrderItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from deleteItemFromPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async getPurcahseOrderItems(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.getAddedItemsOfAPurchaseOrder}${poId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurcahseOrderItems");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurcahseOrderItemsWithListing(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.getAddedItemsOfAPurchaseOrderWithListing}${poId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurcahseOrderItemsWithListing");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId (po_id : string){
    try {
      let url = `${requestHeaders.rooturl}${requests.getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId}${po_id}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from addNewVendor");
      console.error("Error:", error);
      throw error;
    }
  }

  async getGrnItemsWithListing(grnId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.getGrnItemsWithListing}${grnId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getGrnItemsWithListing");
      console.error("Error:", error);
      throw error;
    }
  }

  async publishPo(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.publishPo}${poId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from publishPo");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async republishPo(poId: string, userId : string){
    try {
      // const queryParams: Record<string, any> = {
      //   userId: userId, // New page value
      // };
      let url = `${requestHeaders.rooturl}${requests.republishPo}${poId}?userId=${userId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from republishPo");
      console.error("Error:", error);
      throw error;
    }
  }



  async markPoForModification(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.markPoForModification}${poId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from markPoForModification");
      console.error("Error:", error);
      throw error;
    }
  }

  async markPoForCancellation(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.markPoForCancellation}${poId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from markPoForCancellation");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurchaseOrdersPaginated(
      searchKeyword: string,
      pageNumber: number,
      count: number
    ) {
      const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
      };
      try {
        let url = `${requestHeaders.rooturl}${requests.getPurchaseOrdersPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
          const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
          url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
      } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getPurchaseOrdersPaginated");
        console.error("Error:", error);
        throw error;
      }
  }

  async getPurchaseOrdersPaginated_v2(
    searchKeyword: string,
    pageNumber: number,
    count: number,
    filters: any,
  ) {
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
    };
    try {
      let url = `${requestHeaders.rooturl}${requests.getPurchaseOrdersPaginated_v2}?search_keyword=${searchKeyword}`;
      if (queryParams) {
        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");
        url += `&${queryString}`;
      }
      const response = await this.api.post(url, filters, { withCredentials: true });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurchaseOrdersPaginated");
      console.error("Error:", error);
      throw error;
    }
}

async getBulkVendorPurchaseOrdersData(
  poIds:any[]
){  
  try {
    let url = `${requestHeaders.rooturl}${requests.getBulkVendorPurchaseOrdersData}`;    
    const response = await this.api.post(url, poIds, { withCredentials: true });
    return response;
  } catch (error) {
    // Handle errors or return a default value as needed
    console.log("Printing error from getBulkVendorPurchaseOrdersData");
    console.error("Error:", error);
    throw error;
  } 
}

  async getPurcahseOrdersWithLotWiseListings(lastPoId: string,  count: string){
    const queryParams: Record<string, any> = {
      last_po_id: lastPoId,
      count: count
    };
    try {
      let url = `${requestHeaders.rooturl}${requests.getPurcahseOrdersWithLotWiseListings}?count=${count}`;
      const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
      url += `&${queryString}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurcahseOrdersWithLotWiseListings");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurcahseOrdersWithLotWiseListingsAndSearchKeyword(
      searchKeyword: string,
      pageNumber: number,
      count: number){
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, //
    };
    try {
      let url = `${requestHeaders.rooturl}${requests.getPurcahseOrdersWithLotWiseListingsAndSearchKeyword}?search_keyword=${searchKeyword}`;
      const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
      url += `&${queryString}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurcahseOrdersWithLotWiseListings");
      console.error("Error:", error);
      throw error;
    }
  }

  async openNewGrn(
    payload: any,
    userId: string,
    poId: string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.openNewGrn}${poId}?created_by=${userId}`;      
      const response = await this.api.post(url, payload,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from openNewGrn");
      console.error("Error:", error);
      throw error;
    }
  }

  async getGrnByGrnId (
    grnId: string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.getGrnByGrnId}${grnId}`;      
      const response = await this.api.get(url,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getGrnByGrnId");
      console.error("Error:", error);
      throw error;
    }
  }
 
  async fetchAllGrnsByPoId(    
    poId: string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.fetchAllGrnsByPoId}${poId}`;      
      const response = await this.api.get(url,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from fetchAllGrnsByPoId");
      console.error("Error:", error);
      throw error;
    }
  }

  async submitGrnItemToWeb(
    payload : any,
    userId : string,
    grnId : string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.submitGrnItemToWeb}${grnId}?created_by=${userId}`;      
      const response = await this.api.post(url,payload,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from submitGrnItemToWeb");
      console.error("Error:", error);
      throw error;
    }
  }  
  async deleteGrnItemOnWeb(
    payload : any,
    userId : string,
    grnId : string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.deleteGrnItemOnWeb}${grnId}?created_by=${userId}`;      
      const response = await this.api.post(url,payload,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from deleteGrnItemOnWeb");
      console.error("Error:", error);
      throw error;
    }
  }  

  async acknowledgeInvoiceReceipt(
    userId : string,
    grnId : string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.acknowledgeInvoiceReceipt}${grnId}?created_by=${userId}`;      
      const response = await this.api.put(url, {},{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from acknowledgeInvoiceReceipt");
      console.error("Error:", error);
      throw error;
    }
  }  

  async acknowledgeInvoiceReceiptInBulk(
    userId : string,
    grnIds : string[]
  ){
    try { 
      let url = `${requestHeaders.rooturl}${requests.acknowledgeInvoiceReceiptInBulk}?created_by=${userId}`;      
      const response = await this.api.put(url, grnIds,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from acknowledgeInvoiceReceiptInBulk");
      console.error("Error:", error);
      throw error;
    }
  }  


  async updateGrnItemOnWeb(
    payload : any,
    userId : string,
    grnId : string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.updateGrnItemOnWeb}${grnId}?created_by=${userId}`;      
      const response = await this.api.put(url,payload,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from updateGrnItemOnWeb");
      console.error("Error:", error);
      throw error;
    }
  }  
  
  async updateAuditedPriceOnWeb(
    payload : any,
    userId : string,
    grnId : string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.updateAuditedPriceOnWeb}${grnId}?created_by=${userId}`;      
      const response = await this.api.put(url,payload,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from updateAuditedPriceOnWeb");
      console.error("Error:", error);
      throw error;
    }
  }  

  async submitForAuditClosure(
    payload: any,
    userId : string,
    grnId : string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.submitForAuditClosure}${grnId}?created_by=${userId}`;      
      const response = await this.api.post(url,payload,{
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from submitForAuditClosure");
      console.error("Error:", error);
      throw error;
    }

  }

  async uploadGrnInvoiceFiles(
    files : any[], 
    created_by: string,
    grn_id: string
  ){
    const formData = new FormData();
    const queryParams: Record<string, any> = {
      created_by : created_by, // New page value        
      grn_id : grn_id
    };

    // Object.values(uploadedImages).forEach((image) => {
    //   formData.append("images[]", image);
    // });

    files.forEach((file) => {
      formData.append("files[]", file);
    });

    let url = `${requestHeaders.rooturl}${requests.uploadGrnInvoiceFiles}`;
    const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
    url += `?${queryString}`;

    try {
      const response = await this.api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response;
    } catch (error) {
      console.error("Error:", error);
      console.log("Printing error from uploadGrnInvoiceFiles");
      throw error;
    }
  }

  async uploadGrnDebitCreditNoteFiles(
    files : any[], 
    created_by: string,
    grn_id: string,
    file_type: string
  ){
    const formData = new FormData();
    const queryParams: Record<string, any> = {
      created_by : created_by, // New page value        
      grn_id : grn_id,
      file_type: file_type
    };

    // Object.values(uploadedImages).forEach((image) => {
    //   formData.append("images[]", image);
    // });

    files.forEach((file) => {
      formData.append("files[]", file);
    });

    let url = `${requestHeaders.rooturl}${requests.uploadGrnDebitCreditNoteFiles}`;
    const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
    url += `?${queryString}`;

    try {
      const response = await this.api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response;
    } catch (error) {
      console.error("Error:", error);
      console.log("Printing error from uploadGrnDebitCreditNoteFiles");
      throw error;
    }
  }

  async uploadGrnReverseDebitCreditNoteFiles(
    files : any[], 
    created_by: string,
    grn_id: string,
    file_type: string,
    reverse_debit_credit_note_number: string
  ){
    const formData = new FormData();
    const queryParams: Record<string, any> = {
      created_by : created_by, // New page value        
      grn_id : grn_id,
      file_type: file_type,
      reverse_debit_credit_note_number: reverse_debit_credit_note_number
    };

    // Object.values(uploadedImages).forEach((image) => {
    //   formData.append("images[]", image);
    // });

    files.forEach((file) => {
      formData.append("files[]", file);
    });

    let url = `${requestHeaders.rooturl}${requests.uploadGrnReverseDebitCreditNoteFiles}`;
    const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
    url += `?${queryString}`;

    try {
      const response = await this.api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response;
    } catch (error) {
      console.error("Error:", error);
      console.log("Printing error from uploadGrnDebitCreditNoteFiles");
      throw error;
    }
  }



  async closeVendorGrn(    
    userId : string,
    grnId : string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.closeVendorGrn}${grnId}?created_by=${userId}`;
      const response = await this.api.put(url, null, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from closeVendorGrn");
      console.error("Error:", error);
      throw error;
    }
  }  

  async fetchVendorGrnInvoiceFiles(
    grnId: string
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.getVendorGrnInvoiceFiles}${grnId}`; 
      const response = await this.api.get(url, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getVendorGrnInvoiceFiles");
      console.error("Error:", error);
      throw error;
      }
  }

  async downloadVendorGrnFileFromWeb(
    file_id:any
  ){
    try {
      let url = `${requestHeaders.rooturl}${requests.downloadVendorGrnFileFromWeb}${file_id}`; 
      const response = await this.api.get(url, { withCredentials: true, responseType:"blob" });        
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from downloadVendorGrnFileFromWeb");
      console.error("Error:", error);
      throw error;
      }
  }

  async fetchAllGrnsPaginated( 
    pageNumber: number,
    count: number,
    filters: Record<string, any>
  ){
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
      };
      try {
      let url = `${requestHeaders.rooturl}${requests.fetchAllGrnsPaginated}?search_keyword=`;        
      if (queryParams) {
        const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
        url += `&${queryString}`;
      }
      const response = await this.api.post(url, filters, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from fetchAllGrnsPaginated");
      console.error("Error:", error);
      throw error;
      }
  }

  async fetchAllPendingForAuditGrnsPaginated( 
    pageNumber: number,
    count: number,
    filters: Record<string, any>
  ){
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
      };
      try {
      let url = `${requestHeaders.rooturl}${requests.fetchAllPendingForAuditGrnsPaginated}?search_keyword=`;        
      if (queryParams) {
        const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
        url += `&${queryString}`;
      }
      const response = await this.api.post(url, filters, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from fetchAllPendingForAuditGrnsPaginated");
      console.error("Error:", error);
      throw error;
      }
  }

  async fetchAllPendingForInvoiceCollectionPaginated( 
    pageNumber: number,
    count: number,
    filters: Record<string, any>
  ){
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
      };
      try {
      let url = `${requestHeaders.rooturl}${requests.fetchAllPendingForInvoiceCollectionPaginated}?search_keyword=`;        
      if (queryParams) {
        const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
        url += `&${queryString}`;
      }
      const response = await this.api.post(url, filters, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from fetchAllPendingForAuditGrnsPaginated");
      console.error("Error:", error);
      throw error;
      }
  }

  async fetchAllDebitCreditNotesPaginated( 
    pageNumber: number,
    count: number,
    filters: Record<string, any>
  ){
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
      };
      try {
      let url = `${requestHeaders.rooturl}${requests.fetchAllDebitCreditNotesPaginated}?search_keyword=`;        
      if (queryParams) {
        const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
        url += `&${queryString}`;
      }
      const response = await this.api.post(url, filters, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from fetchAllDebitCreditNotesPaginated");
      console.error("Error:", error);
      throw error;
      }
  }

  async getDebitCreditNoteDetails(
    grn_id: string,
  ){
      try {
      let url = `${requestHeaders.rooturl}${requests.getDebitCreditNoteDetails}${grn_id}`;        
      const response = await this.api.get(url, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getDebitCreditNoteDetails");
      console.error("Error:", error);
      throw error;
      }
  }

  async submitDebitCreditNoteNumberToWeb(finalDebitCreditNoteNumber: string, grn_id: string, debit_note_type: string,  created_by: string, po_id : string){
    try {
      const jsonObject: Record<string, any> = {
        debit_credit_note_number : finalDebitCreditNoteNumber,
        grn_id : grn_id,
        debit_note_type: debit_note_type,
        created_by : created_by,
        po_id: po_id
      };
      let url = `${requestHeaders.rooturl}${requests.submitDebitCreditNoteNumberToWeb}`;
      
      const response = await this.api.post(url, jsonObject, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from submitInvoiceNumberToWeb");
      console.error("Error:", error);
      throw error;
    }
  }

  async submitVendorPoExpiryDateForModification(po_number: string, new_expiry_date: string | null, created_by: string){
    try {     
      const data: Record<string, any> = {
        expiry_date: new_expiry_date,
        created_by : created_by, // New page value              
      };
      let url = `${requestHeaders.rooturl}${requests.submitVendorPoExpiryDateForModification}${po_number}`;      
      const response = await this.api.put(url, data, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from submitVendorPoExpiryDateForModification");
      console.error("Error:", error);
      throw error;
    }
  }
}

export default PurchaseOrderNetworkUtils;
