import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/CompanyPurchaseOrderConsignmentDetails.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";

interface RouteParams {
  po_id: string;
}

function CompanyPurchaseOrdersPOConsignmentsHome() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { po_id } = useParams<RouteParams>();

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [createNewConsignment, setCreateNewConsignment] = useState(false);
  const [currentConsignments, setCurrentConsignments] = useState<Consignment[]>(
    []
  );

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<IncomingPurchaseOrder>();

  const createNewConsignmentFunc = async (purchase_order_id: any) => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.createNewConsignment(
          purchase_order_id.toString(),
          userDetailsFromStore.user_details.userId
        );

      toast.success("Consignment created successfully", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });

      setShowLoadingPage(false);
      setCreateNewConsignment(false);
      fetchConsignmentDetails(purchase_order_id);
    } catch (error) {
      console.error("Error in creating new Consignment : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setCreateNewConsignment(false);
      setShowLoadingPage(false);
    }
  };

  const fetchConsignmentDetails = async (purchase_order_id: any) => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.fetchConsignmentsByPoId(
          purchase_order_id.toString()
        );

      setCurrentConsignments(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in creating new Consignment : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const getClassNameForConsignment = (consignment_status: string) => {
    if (consignment_status == "OPEN") return "color-green";
    else if (consignment_status == "CLOSED") return "color-red";
    else if (consignment_status == "MARKED_RTD") return "color-pink";
    return "";
  };

  useEffect(() => {
    const fetchPurchaseOrderDetails = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.geturchaseOrderByPOid(
            purchase_order_id.toString()
          );
        // console.log(response.data);
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    fetchPurchaseOrderDetails(po_id);
    fetchConsignmentDetails(po_id);
  }, []);

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPcolor = (wip_status: string) => {
    if (wip_status == "YES") return "marked-green";
    else return "marked-red";
  };

  return (
    <div className="catalogues-container">
      {currentPurchaseOrder && (
        <div className="incoming-purchase-order-details-table-container mt-4">
          <Table bordered hover size="sm" className="table-auto-width">
            <tbody>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Status</td>
                <td
                  className={getCalculatedPoStatusColor(
                    currentPurchaseOrder.calculated_po_status
                  )}
                >
                  {currentPurchaseOrder.calculated_po_status}
                </td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Marked WIP ?</td>
                <td className={getWIPcolor(currentPurchaseOrder.is_wip)}>
                  {currentPurchaseOrder.is_wip}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer Company Name</td>
                <td>{currentPurchaseOrder.company.company_name}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Number</td>
                <td>{currentPurchaseOrder.po_number}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Type</td>
                <td>{currentPurchaseOrder.po_type}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Sold Via</td>
                <td>{currentPurchaseOrder.sold_via}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Reference Location</td>
                <td>{currentPurchaseOrder.delivery_city}</td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Release Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.po_issue_date
                  )}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Expiry Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.expiry_date
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      <div className="create-pack-task-container pt-4">
        <Button
          variant="outline-primary"
          className="vendor-action-button"
          onClick={() => setCreateNewConsignment(true)}
        >
          Create New Consignment
        </Button>
      </div>
      {currentConsignments && (
        <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-container">
          <div className="po-document-section-heading mt-4">
            All Consignments
          </div>
          {currentConsignments.length === 0 ? (
            <div className="po-document-section-no-document mt-3">
              No Consignment was found
            </div>
          ) : (
            <div className="incoming-purchase-order-file-details-table-container mt-3">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>Consignment ID</th>
                    <th>PO Number</th>
                    <th>Consignment Status</th>
                    <th>Boxes Count</th>
                    <th>Sku Count</th>
                    <th>Total Quantity</th>
                    <th>Created At</th>
                    <th>Consignment Staus Updated At</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {currentConsignments.map((Consignment, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td className="po-id-link">
                          <a
                            href={`/incomingPos/info/consignmentDetails/${Consignment.po_number}/${Consignment.consignment_id}/details`}
                          >
                            {Consignment.consignment_id}
                          </a>
                        </td>
                        <td className="nowrap">{Consignment.po_number}</td>
                        <td
                          className={getClassNameForConsignment(
                            Consignment.consignment_status
                          )}
                        >
                          {Consignment.consignment_status}
                        </td>
                        <td>{Consignment.total_boxes}</td>
                        <td>{Consignment.total_skus}</td>
                        <td>{Consignment.total_quantity}</td>
                        <td>
                          {DateTimeUtils.formatDateTime(Consignment.created_at)}
                        </td>
                        <td>
                          {DateTimeUtils.formatDateTime(Consignment.updated_at)}
                        </td>
                        <td>{Consignment.created_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      )}
      <Modal
        show={createNewConsignment}
        onHide={() => setCreateNewConsignment(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Consignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please note that a Consignment can only be created once a PO is marked
          as WIP.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCreateNewConsignment(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => createNewConsignmentFunc(po_id)}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrdersPOConsignmentsHome;
