export const requestHeaders = {
  // rooturl : "https://data.intellozene.com/public/api/",

  // rooturl: "https://web.intellozene.com/public/api/",
  // rooturl: "http://localhost:8000/api/",
  // rooturl: "https://test.intellozene.com/public/api/",
  rooturl: "https://web.eautomate.in/public/api/",
  rooturlAuthorization: "http://auth.intellozene.com/public/api/",
};

export const requests = {
  companiesList: "companies",
  addCompany: "companies",
  getCompanyById: "companies/",
  createNewGroup: "groups",
  createNewTeam: "teams",
  getAllGroups: "groups",
  getGroupByGroupId: "groups/",
  getAllTeamsOfaGroup: "groups/teams/",
  createPO: "po",
  getAllPos: "po",
  getAllUsersOfTeam: "teams/user/",
  AddUserToTeam: "teams/user",
  GetAllUsers: "users",
  GetUserById: "users/",
  AddSkusThroughExcel: "po/add_sku_sheet",
  GetTeambyId: "teams/",
  GetAllSkusPaginated: "po/listings/by_page/",
  GetAllLogsSku: "po/logs/",
  GetSkuFromSkuList: "sku_po/",
  GetPOSummary: "po/summary/",
  SubmitForm: "forms_response",
  Login: "login",
  AddNewSkuInPo: "po/add_sku",
  EditSkuQuantityPO: "editor/update/quantity/",
  ChangeSkuStatusPO: "editor/update/sku_status/",
  ListingsBySearchKeyword: "listings/by_page_v4",
  listingsBySkuId: "listings/sku/",
  topListings: "listings/topselling/500",
  updateListingBySkuId: "listings/sku/",
  analyticsBySkuId: "listings/analytics/sku/",
  updateInventoryMulti: "warehouse_inventory/update_inventory_multi/",
  getAllFormCategories: "forms/categories",
  getAllFormCategoriesByName: "forms/categories/",
  getFormBySubCategoryName: "forms/categories/",
  saveFormResponse: "forms/response/",
  getFormsDataByFormIdFilledToday: "forms/today/",
  getAllVendors: "vendors/all",
  getVendorByVendorId: "vendors/",
  updateVendorByVendorId: "vendors/",
  updateVendorSpecialtiesByVendorId: "vendors/specialty/",
  getVendorListingsByVendorId: "vendors/listings/",
  updateCostPriceyByVendorIdAndSkuId: "vendors/cost_price/",
  getCostPriceTimeline: "vendors/cost_price_timeline/",
  getAllSkuIds: "listings/sku/names",
  mapNewItemToVendor: "vendors/cost_price/",
  getCurrentListingsSnapshot: "export-listings",
  getFilesHistory: "filesHistory",
  downloadFile: "files/download/",
  uploadListingsUpdateFile: "files/upload/listing-update",
  getPacksAndComboDetails: "packs_combos/sku/",
  updatePacksAndComboMappings: "packs_combos/sku/",
  mapNewItemToPack: "packs_combos/add_item/sku/",
  fetchAssociatedVendors: "vendors/sku/",
  addNewVendor: "vendors",
  createNewPurchaseOrder: "purchase_orders/add",
  getPurchaseOrderById: "purchase_orders/",
  addItemToPurchaseOrder: "purchase_orders/addItem/",
  deleteItemFromPurchaseOrder: "purchase_orders/deleteItem/",
  getAddedItemsOfAPurchaseOrder: "purchase_orders/addedItems/",
  getPurchaseOrdersPaginated: "purchase_orders",
  getPurchaseOrdersPaginated_v2: "purchase_orders/with_filters",
  getBulkVendorPurchaseOrdersData: "purchase_orders/bulk_data",
  publishPo: "purchase_orders/publish/",
  republishPo: "purchase_orders/republish/",
  markPoForModification: "purchase_orders/mark_for_modification/",
  markPoForCancellation: "purchase_orders/mark_for_cancellation/",
  getAddedItemsOfAPurchaseOrderWithListing:
    "purchase_orders/addedItems/withListing/",
  getCataloguesPaginated: "catalogues",
  getStandardCataloguesPaginated: "catalogues/standard",
  getOnetimeCataloguesPaginated: "catalogues/onetime",
  createNewCatalogue: "catalogues/add",
  getCatalogueItemms: "catalogues/allItems/",
  getCatalogueById: "catalogues/",
  getCatalogueThemes: "catalogues/themes",
  removeItemFromVendor: "vendors/remove-listing/",
  fetchIncomingInventoryBySkuId: "listings/incoming-quantity/",
  getPurcahseOrdersWithLotWiseListings: "purchase_orders/lot-listings",
  getPurcahseOrdersWithLotWiseListingsAndSearchKeyword:
    "purchase_orders/lot-listings/with-search-keyword",
  addItemsToCatalogue: "catalogues/addItems/",
  publishCatalogue: "catalogues/publish/",
  addItemToCatalogueInBulk: "catalogues/addItems/bulk/",

  addNewIncomingPurchaseOrder: "incoming_purchase_orders/add",
  getIncomingPurchaseOrderById: "incoming_purchase_orders/",
  getAllCompanyNames: "companies/names",
  getAllCompanies: "companies",
  addNewIncomingPurchaseOrderData: "incoming_purchase_orders/add/addItems/",
  deletePartialPurchaseOrderData:
    "incoming_purchase_orders/delete/partialPurchaseOrder/",
  checkOpsAndMasterSkuMappingForPurchaseOrder:
    "incoming_purchase_orders/mapping_validation",
  getSkuMappingForPurchaseOrder:
    "incoming_purchase_orders/mapping_validation_and_extraction",
  getSecondaryMasterAndOtherSkuMappingWithMRPVerification:
    "incoming_purchase_orders/mapping_validation_and_extraction_v2",

  fetchAllIncomingPurchaseOrdersPaginated: "incoming_purchase_orders/all",
  fetchAllIncomingPurchaseOrdersPaginatedWithFilters:
    "incoming_purchase_orders/all/with_filters",
  fetchAllIncomingPartialPurchaseOrdersPaginated:
    "incoming_purchase_orders/partial",
  fetchIncomingPurchaseOrdersListingPaginated:
    "incoming_purchase_orders/listings/paginated/",
  fetchPurchaseOrderAnalyticsObject:
    "incoming_purchase_orders/analytics_object/",
  acknowledgePurchaseOrder: "incoming_purchase_orders/acknowledge/",
  markForWIP: "incoming_purchase_orders/mark_wip/",
  createConsignment: "incoming_purchase_orders/create_consignment/",
  fetchAllConsignmentsByPoId: "incoming_purchase_orders/consignments/by_po_id/",
  fetchSecondaryListingsBySearchKeywordPaginated:
    "inventory/secondary_listings/paginated",
  getSecondaryListingDetails: "inventory/secondary_listings/sku_wise_details",
  fetchAisListingsBySearchKeywordPaginated:
    "inventory/secondary_listings/ais_listings/paginated",
  addOrUpdateAISlistingsInBulk:
    "inventory/secondary_listings/ais_listings/add_or_update",
  addOrUpdateSecondarylistingsInBulk:
    "inventory/secondary_listings/add_or_update",
  fetchPacksAndCombosBySearchKeywordPaginated:
    "inventory/secondary_listings/packs_and_combos/paginated",
  fetchPacksAndCombosListingDetails:
    "inventory/secondary_listings/packs_and_combos/listing_details/",
  fetchDownloadablePacksAndCombosBySearchKeywordPaginated:
    "inventory/secondary_listings/packs_and_combos/paginated/downloadable",
  addOrUpdatePacksComboslistingsInBulk:
    "inventory/secondary_listings/packs_and_combos/add_or_update",
  uploadPurchaseOrderFiles: "incoming_purchase_orders/upload_po_files",
  uploadInvoiceFiles: "incoming_purchase_orders/upload_invoice_files",
  fetchPurchaseOrderDetailFiles:
    "incoming_purchase_orders/fetch_po_detail_files/",
  fetchPurchaseOrderFiles: "incoming_purchase_orders/fetch_po_files/",
  downloadPurchaseOrderFile: "incoming_purchase_orders/download_po_file/",
  fetchConsignmentItemsByConsignmentId:
    "incoming_purchase_orders/consignments/consignment_details/",

  uploadConsignmentItems:
    "incoming_purchase_orders/consignments/consignment_details/upload_items/",
  fetchPurchaseOrderDemandObjectDetails:
    "incoming_purchase_orders/demand_object/",

  fetchConsignmentByConsignmentId: "incoming_purchase_orders/consignments/",
  getAllTransporterDetails: "transporter_details",
  markForRTD: "incoming_purchase_orders/mark_rtd/",
  fetchAllConsignmentsPaginated:
    "incoming_purchase_orders/consignments/all/paginated",
  fetchConsignmentsWithPendingInvoicesPaginated:
    "incoming_purchase_orders/consignments/pending_invoices/paginated",
  fetchConsignmentInvoiceEntries:
    "incoming_purchase_orders/consignments/invoice_entries/",
  submitInvoiceNumberToWeb:
    "incoming_purchase_orders/consignments/invoice_entries/create",
  getDataForInvoice:
    "incoming_purchase_orders/consignments/invoice_entries/invoice_data/",
  getDataForBulkInvoices:
    "incoming_purchase_orders/consignments/invoice_entries/bulk_invoice_data",
  getBoxInvoiceData:
    "incoming_purchase_orders/consignments/invoice_entries/box_and_invoice_data/",
  getDataForBoxLabels:
    "incoming_purchase_orders/consignments/invoice_entries/box_labels_data/",
  markPurchaseOrderForCancellation:
    "incoming_purchase_orders/mark_for_cancellation/",
  removeSelectedPOsFromWIP: "incoming_purchase_orders/remove_from_wip",
  addSelectedPOsToWIP: "incoming_purchase_orders/add_to_wip",
  deleteItemsFromPickList:
    "incoming_purchase_orders/consignments/delete_items_from_picklist/",
  getAllConsignmentLogs: "incoming_purchase_orders/consignments/logs/",
  getAllPurchaseOrderLogs: "incoming_purchase_orders/logs/",
  getAllUniqueDeliveryLocations: "incoming_purchase_orders/delivery_locations",
  downloadDataForSKULevelReport:
    "incoming_purchase_orders/download_data_for_sku_level_report",
  fetchSecondarySkuAndCompanySkusPaginated:
    "labels/secondary_company_sku_relation",
  fetchLabelsMasterDataPaginated: "labels/labels_master_data",
  submitPoTypeForModification: "incoming_purchase_orders/modify_po_type/",
  submitExpiryDateForModification:
    "incoming_purchase_orders/modify_expiry_date/",
  submitPoLocationForModification:
    "incoming_purchase_orders/modify_po_location/",
  fetchLabelsDataForPurchaseOrder: "labels/purchase_order/",
  openNewGrn: "purchase_orders/grn/open/",
  getGrnByGrnId: "purchase_orders/grn/",
  fetchAllGrnsByPoId: "purchase_orders/grn/get_by_po_id/",
  submitGrnItemToWeb: "purchase_orders/grn/submit_item/",
  deleteGrnItemOnWeb: "purchase_orders/grn/delete_item/",
  acknowledgeInvoiceReceipt: "purchase_orders/grn/acknowledge_invoice_receipt/",
  acknowledgeInvoiceReceiptInBulk:
    "purchase_orders/grn/acknowledge_invoice_receipt/bulk",
  updateGrnItemOnWeb: "purchase_orders/grn/update_item/",
  updateAuditedPriceOnWeb: "purchase_orders/grn/update_audit_price/",
  submitForAuditClosure: "purchase_orders/grn/submit_for_audit_closure/",
  getGrnItemsWithListing: "purchase_orders/grn/items/withListing/",
  getPurchaseOrderItemsWithListingAndPendingQuantityByPurchaseOrderId:
    "purchase_orders/addedItems/withListing/withPendency/",
  uploadGrnInvoiceFiles: "purchase_orders/grn/upload_grn_invoice_files",
  uploadGrnDebitCreditNoteFiles:
    "purchase_orders/grn/upload_grn_debit_credit_note_files",
  uploadGrnReverseDebitCreditNoteFiles:
    "purchase_orders/grn/upload_grn_reverse_debit_credit_note_files",
  closeVendorGrn: "purchase_orders/grn/close_grn/",
  getVendorGrnInvoiceFiles: "purchase_orders/grn/invoice_files/",
  downloadVendorGrnFileFromWeb: "purchase_orders/grn/download_grn_file/",
  fetchAllGrnsPaginated: "purchase_orders/grn/all/paginated",
  fetchAllPendingForAuditGrnsPaginated:
    "purchase_orders/grn/pending_for_audit/paginated",
  fetchAllPendingForInvoiceCollectionPaginated:
    "purchase_orders/grn/pending_for_invoice_collection/paginated",
  fetchAllDebitCreditNotesPaginated:
    "purchase_orders/grn/debit_credit_notes/paginated",
  getDebitCreditNoteDetails: "purchase_orders/grn/debit_credit_notes/",
  submitDebitCreditNoteNumberToWeb:
    "purchase_orders/grn/debit_credit_notes/assign_number",
  submitVendorPoExpiryDateForModification:
    "purchase_orders/modify_expiry_date/",
};
