import React, { useEffect, useState } from "react";
import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import "./CSS/PurchaseOrderGrnDetailsHomeMain.css";
import Header from "../Header";
import { ArrowLeft } from "react-bootstrap-icons";
import PurchaseOrderAllGrns from "./PurchaseOrderAllGrns";
import PurchaseOrderGrnsPendingAudits from "./PurchaseOrderGrnsPendingAudits";
import PurchaseOrderGrnsPendingInvoiceCollections from "./PurchaseOrderGrnsPendingInvoiceCollections";
import PurchaseOrderGrnsDebitCreditNotes from "./PurchaseOrderGrnsDebitCreditNotes";

interface RouteParams {
  category: string;
}

function PurchaseOrderGrnDetailsHomeMain() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    //   return;
    // }
    // dispatch(ActiveTab__Action("incomingPos"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    if (item == "goBack") {
      history.goBack();
      // history.replace("/incomingPos/wipPos");
      // window.history.pushState(null, "", "/incomingPos/wipPos");
      // window.location.replace("/incomingPos/wipPos");

      // window.history.go(-(window.history.length - 1));
      return;
    }

    setSelectedItem(item);
    history.replace(`/vendor-hub/grn/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div className="overlay-purchase-order-details">
      <Header />
      <div>
        <div className="nav-container">
          <nav className="navbar-vendors">
            <ul className="navbar-menu">
              <li
                className={`navbar-item `}
                onClick={() => handleItemClick("goBack")}
              >
                <ArrowLeft
                  size={15}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </li>
              <li
                className={`navbar-item ${
                  selectedItem === "all-grns" ? "active" : ""
                }`}
                onClick={() => handleItemClick("all-grns")}
              >
                All GRNs
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "pending-audits" ? "active" : ""
                }`}
                onClick={() => handleItemClick("pending-audits")}
              >
                GRNs Pending For Audit
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "pending-invoice-collection" ? "active" : ""
                }`}
                onClick={() => handleItemClick("pending-invoice-collection")}
              >
                Pending Invoice Collection
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "pending-debit-credit-notes" ? "active" : ""
                }`}
                onClick={() => handleItemClick("pending-debit-credit-notes")}
              >
                Debit & Credit Notes
              </li>
            </ul>
          </nav>
        </div>

        {selectedItem === "all-grns" && <PurchaseOrderAllGrns />}
        {selectedItem === "pending-audits" && (
          <PurchaseOrderGrnsPendingAudits />
        )}
        {selectedItem === "pending-invoice-collection" && (
          <PurchaseOrderGrnsPendingInvoiceCollections />
        )}
        {selectedItem === "pending-debit-credit-notes" && (
          <PurchaseOrderGrnsDebitCreditNotes />
        )}
        {/*
        {selectedItem === "pendingInvoices" && (
          <CompanyPurchaseOrderConsignmentsPendingInvoices />
        )} */}
        {/* {selectedItem === "appointments" && (
          <CompanyPurchaseOrdersConsignmentDetailsHome />
        )} */}
      </div>
    </div>
  );
}

export default PurchaseOrderGrnDetailsHomeMain;
