import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import { BoxLevelItem, LabelData, LabelDataV2 } from "../../../atomic_components/types";
import { PDFDocument, rgb, degrees } from "pdf-lib";
import { logoBase64 } from "./Base64Provider";


class CompanyPurchaseOrderLabelUtils{
    
    static createPhase1BoxLabelsFor75by38(
        startingNumber: number,
        endingNumber: number,
        poNumber: string,
        deliveryCity: string,
        companyName: string
      ) {
        const width = 7.5; // Longer side of the label (parallel to the text)
        const height = 3.8; // Shorter side of the label
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "landscape", // Set landscape for the desired layout
        });
      
        const mainFontSize = 20; // Font size for main text
        const dateFontSize = 6; // Font size for the date
        const margin = 0.3; // General margin
        const barcodeHeight = 1.2; // Barcode height reduced to 65% of label height
        const textStartY = barcodeHeight + 0.5; // Text starts just below the barcode
        const textStartX = margin; // Starting X position for text
        const lineHeight = 0.5; // Height between text lines
      
        for (let i = startingNumber; i <= endingNumber; i++) {
          if (i > startingNumber) doc.addPage(); // Add a new page for each label after the first one


          doc.setFontSize(mainFontSize);                    
          // Line 1: BOX NO.
          doc.text(`BOX NO. - ${i}`, textStartX, margin+0.5);
          let textY = lineHeight + 0.6;      
      
          // Generate the barcode for the PO number
          const canvas = document.createElement("canvas");
          // JsBarcode(canvas, poNumber, { format: "CODE128", fontSize: 30});
          JsBarcode(canvas, poNumber, {
            format: "CODE128",
            height: 20, // Increased for better scanability
            margin: 0, // Removes extra white space
            displayValue: true, // Hides default text below the barcode
            fontSize:20
          });
          const barcodeImage = canvas.toDataURL("image/png");
      
          // Add the barcode spanning the entire width of the label
          doc.addImage(barcodeImage, "PNG", margin, textY, width - 2 * margin, barcodeHeight);
      
          // Add text content below the barcode          
          

          doc.setFontSize(14);
          textY += lineHeight + barcodeHeight + 0.1;      
          doc.text(`${companyName}, ${deliveryCity}`, textStartX, textY);          
      
          // Line 2: PO Number
        //   doc.text(`PO Number: ${poNumber}`, textStartX, textY);
        //   textY += lineHeight;
      
          // Line 3: Current Date and Time
          textY += lineHeight;      
          doc.setFontSize(dateFontSize); // Use smaller font size for the date
          doc.text(`Date: ${DateTimeUtils.getCurrentDateTime()}`, textStartX, textY);
        }
      
        // Save the PDF with a descriptive name
        doc.save(`phase1_${poNumber}(${startingNumber}-${endingNumber}).pdf`);
    }

    static createPhase1BoxLabelsFor70by40(
        startingNumber: number,
        endingNumber: number,
        poNumber: string,
        deliveryCity: string,
        companyName: string
      ) {
        console.log("inside 70 40")
        const width = 7.0; // Longer side of the label (parallel to the text)
        const height = 4.0; // Shorter side of the label
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "landscape", // Set landscape for the desired layout
        });
      
        const mainFontSize = 20; // Font size for main text
        const dateFontSize = 6; // Font size for the date
        const margin = 0.3; // General margin
        const barcodeHeight = 1.2; // Barcode height reduced to 65% of label height
        const textStartY = barcodeHeight + 0.5; // Text starts just below the barcode
        const textStartX = margin; // Starting X position for text
        const lineHeight = 0.5; // Height between text lines
      
        for (let i = startingNumber; i <= endingNumber; i++) {
          if (i > startingNumber) doc.addPage(); // Add a new page for each label after the first one


          doc.setFontSize(mainFontSize);                    
          // Line 1: BOX NO.
          doc.text(`BOX NO. - ${i}`, textStartX, margin+0.5);
          let textY = lineHeight + 0.6;      
      
          // Generate the barcode for the PO number
          const canvas = document.createElement("canvas");
          // JsBarcode(canvas, poNumber, { format: "CODE128", fontSize: 30});
          JsBarcode(canvas, poNumber, {
            format: "CODE128",
            height: 20, // Increased for better scanability
            margin: 0, // Removes extra white space
            displayValue: true, // Hides default text below the barcode
            fontSize:20
          });
          const barcodeImage = canvas.toDataURL("image/png");
      
          // Add the barcode spanning the entire width of the label
          doc.addImage(barcodeImage, "PNG", margin, textY, width - 2 * margin, barcodeHeight);
      
          // Add text content below the barcode          
          

          doc.setFontSize(14);
          textY += lineHeight + barcodeHeight + 0.1;      
          doc.text(`${companyName}, ${deliveryCity}`, textStartX, textY);          
      
          // Line 2: PO Number
        //   doc.text(`PO Number: ${poNumber}`, textStartX, textY);
        //   textY += lineHeight;
      
          // Line 3: Current Date and Time
          textY += lineHeight;      
          doc.setFontSize(dateFontSize); // Use smaller font size for the date
          doc.text(`Date: ${DateTimeUtils.getCurrentDateTime()}`, textStartX, textY);
        }
      
        // Save the PDF with a descriptive name
        doc.save(`phase1_${poNumber}(${startingNumber}-${endingNumber}).pdf`);

      }
      
      
    static generateMyntraLabels75By38_v1 = async (        
        labelData: LabelData,
        labelHeadings: LabelData,
        labelCount: number
      ) => {

        const width = 3.8;
        const height = 7.5;
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "portrait",
        });
    
        const fontSize = 5; // Further reduced font size for tighter fit
        doc.setFontSize(fontSize);
    
        // General margin and content settings
        const margin = 0.15; // Further reduced border margin
        const contentWidth = width - 2 * margin; // Available width within the border
        const textMargin = margin + 0.1; // Reduced margin between text elements
        const barcodeHeight = height / 7; // Adjusted barcode height for smaller label
        const cellHeight3Lines = 0.7; // Further reduced cell height to fit more content
        const cellHeight1Line = 0.3;
    
        for (let i = 0; i < labelCount; i++) {
          if (i > 0) doc.addPage(); // Add a new page for each label after the first one
    
          // Generate barcode
          const canvas = document.createElement("canvas");
          JsBarcode(canvas, labelData.barcode, { format: "CODE128" });
          const barcodeImage = canvas.toDataURL("image/png");
    
          // Add barcode image at the top (centered within the border)
          const barcodeWidth = contentWidth - 0.8; // Adjust width for alignment
          const barcodeWidthNew = contentWidth - 0.8;
          const barcodeX = (width - barcodeWidthNew) / 2; // Center barcode
          doc.addImage(
            barcodeImage,
            "PNG",
            barcodeX,
            margin,
            barcodeWidthNew,
            barcodeHeight
          );
    
          // Draw border around the entire label
          // doc.setLineWidth(0.05); // Reduced border thickness
          // doc.rect(margin, margin, width - 2 * margin, height - 2 * margin);
    
          // Add table data below the barcode
          const contentStartY = margin + barcodeHeight + 0.3; // Start just below the barcode
          const keys = Object.keys(labelData) as (keyof LabelData)[];
          const filteredKeys = keys.filter((key) => key !== "barcode");
    
          let y = contentStartY;
          filteredKeys.forEach((key, index) => {
            // Combine heading and value into a single wrapped text
            const fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
    
            // Add wrapped text within the border
            doc.text(fullText, textMargin, y, {
              maxWidth: contentWidth - margin, // Ensure text wraps within the border
            });
    
            if (
              key === "marketedBy" ||
              key === "manufacturedBy" ||
              key === "oneSetContains"
            ) {
              y += cellHeight3Lines;
            } else {
              y += cellHeight1Line;
            }
          });
        }
    
        // Save the PDF as a Uint8Array
        doc.save("labels.pdf");
        // const pdfBytes = doc.output("arraybuffer");
    
        // // Rotate the pages using PDF-Lib
        // await rotatePDFPages(pdfBytes);
      };
    

    ///IMPORTANT, rotated barcode.
    static ellipsize = (text: string, maxLength: number): string => {
        return text.length > maxLength
          ? text.substring(0, maxLength - 3) + "..."
          : text;
      };
    
    static generateMyntraLabels75By38_v2 = async (
        labelData: LabelData,
        labelHeadings: LabelData,
        labelCount: number
      ) => {
        const width = 3.8; // Total label width in cm
        const height = 7.5; // Total label height in cm
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "portrait",
        });
    
        const fontSize = 5; // Default font size for the text
        doc.setFontSize(fontSize);
    
        // Dimensions for the two parts
        const barcodePartWidth = 1; // Left part width for barcode
        const textWidth = width - barcodePartWidth; // Right part width for text
        const margin = 0.3; // General margin for content
    
        // Barcode dimensions (swap width and height due to rotation)
        const barcodeHeight = 1; // Height corresponds to the width
        const barcodeWidthFinal = 5.0; // Width corresponds to the height
        const barcodeX = margin; // Left margin for barcode
        const barcodeY = margin; // Top margin for the barcode
    
        // Text area start positions
        const textStartX = barcodePartWidth + margin; // Start text area after the barcode
        const textStartY = margin; // Start text from the top margin
        const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
    
        const cellHeight6Lines = 1.05; // Height for multi-line content
        const cellHeight5Lines = 0.9; // Height for multi-line content
        const cellHeight4Lines = 0.8; // Height for multi-line content
        const cellHeight1Line = 0.3; // Height for single-line content
        const cellHeight2Line = 0.5; // Height for single-line content
    
        for (let i = 0; i < labelCount; i++) {
          if (i > 0) doc.addPage(); // Add a new page for each label after the first
    
          // Generate the barcode image without the text
          const canvas = document.createElement("canvas");
          JsBarcode(canvas, labelData.barcode, {
            format: "CODE128",
            displayValue: true, // Ensure that no text is shown with the barcode
            fontSize: 24,
          });
          const barcodeImage = canvas.toDataURL("image/png");
    
          // Add the barcode image in the left section
          doc.addImage(
            barcodeImage,
            "PNG",
            barcodeX,
            -0.5, // Y position (top margin)
            barcodeWidthFinal,
            barcodeHeight,
            undefined,
            "FAST",
            -90 // Rotate the barcode to be aligned on the left
          );
    
          // Add text in the right part
          const keys = Object.keys(labelData) as (keyof LabelData)[];
          const filteredKeys = keys.filter((key) => key !== "barcode");
    
          let y = textStartY;
          filteredKeys.forEach((key) => {
            let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
            if (key === "title") {
              fullText = this.ellipsize(fullText, 128);
            } else if (key === "oneSetContains") {
              fullText = this.ellipsize(fullText, 52);
            }
    
            // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
            if (
              key === "marketedBy" ||
              key === "manufacturedBy" ||
              key === "title"
            ) {
              doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
            } else {
              doc.setFontSize(fontSize); // Restore default font size
            }
    
            // Add the text to the PDF
            doc.text(fullText, textStartX, y, {
              maxWidth: textContentWidth,
            });
    
            // Adjust the y position based on content type
            if (key === "marketedBy") {
              y += cellHeight6Lines;
            } else if (key === "manufacturedBy") {
              y += cellHeight5Lines;
            } else if (key === "title") {
              y += cellHeight4Lines;
            } else if (
              key === "productDimension" ||
              key === "modelNumber" ||
              key === "oneSetContains" ||
              key === "mrp" ||
              key === "styleId"
            ) {
              y += cellHeight2Line;
            } else {
              y += cellHeight1Line;
            }
          });
        }
    
        // Save the PDF
        // doc.save("labels_with_barcode_only_on_left.pdf");

        const pdfBytes = doc.output("arraybuffer");
    
        // Rotate the pages using PDF-Lib
        await this.rotatePDFPages(pdfBytes);
      };

    
    static generateMyntraLabels70By40_v2 = async (
      labelData: LabelData,
      labelHeadings: LabelData,
      labelCount: number
    ) => {
      const width = 4.0; // Total label width in cm
      const height = 7.0; // Total label height in cm
      const doc = new jsPDF({
        unit: "cm",
        format: [width, height],
        orientation: "portrait",
      });
  
      const fontSize = 5; // Default font size for the text
      doc.setFontSize(fontSize);
  
      // Dimensions for the two parts
      const barcodePartWidth = 1; // Left part width for barcode
      const textWidth = width - barcodePartWidth; // Right part width for text
      const margin = 0.3; // General margin for content
  
      // Barcode dimensions (swap width and height due to rotation)
      const barcodeHeight = 1; // Height corresponds to the width
      const barcodeWidthFinal = 5.0; // Width corresponds to the height
      const barcodeX = margin; // Left margin for barcode
      const barcodeY = margin; // Top margin for the barcode
  
      // Text area start positions
      const textStartX = barcodePartWidth + margin; // Start text area after the barcode
      const textStartY = margin; // Start text from the top margin
      const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
  
      const cellHeight6Lines = 1.05; // Height for multi-line content
      const cellHeight5Lines = 0.9; // Height for multi-line content
      const cellHeight4Lines = 0.8; // Height for multi-line content
      const cellHeight1Line = 0.3; // Height for single-line content
      const cellHeight2Line = 0.5; // Height for single-line content
  
      for (let i = 0; i < labelCount; i++) {
        if (i > 0) doc.addPage(); // Add a new page for each label after the first
  
        // Generate the barcode image without the text
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, labelData.barcode, {
          format: "CODE128",
          displayValue: true, // Ensure that no text is shown with the barcode
          fontSize: 24,
        });
        const barcodeImage = canvas.toDataURL("image/png");
  
        // Add the barcode image in the left section
        doc.addImage(
          barcodeImage,
          "PNG",
          barcodeX,
          -0.5, // Y position (top margin)
          barcodeWidthFinal,
          barcodeHeight,
          undefined,
          "FAST",
          -90 // Rotate the barcode to be aligned on the left
        );
  
        // Add text in the right part
        const keys = Object.keys(labelData) as (keyof LabelData)[];
        const filteredKeys = keys.filter((key) => key !== "barcode");
  
        let y = textStartY;
        filteredKeys.forEach((key) => {
          let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
          if (key === "title") {
            fullText = this.ellipsize(fullText, 128);
          } else if (key === "oneSetContains") {
            fullText = this.ellipsize(fullText, 52);
          }
  
          // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
          if (
            key === "marketedBy" ||
            key === "manufacturedBy" ||
            key === "title"
          ) {
            doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
          } else {
            doc.setFontSize(fontSize); // Restore default font size
          }
  
          // Add the text to the PDF
          doc.text(fullText, textStartX, y, {
            maxWidth: textContentWidth,
          });
  
          // Adjust the y position based on content type
          if (key === "marketedBy") {
            y += cellHeight6Lines;
          } else if (key === "manufacturedBy") {
            y += cellHeight5Lines;
          } else if (key === "title") {
            y += cellHeight4Lines;
          } else if (
            key === "productDimension" ||
            key === "modelNumber" ||
            key === "oneSetContains" ||              
            key === "styleId"
          ) {
            y += cellHeight2Line;
          } else {
            y += cellHeight1Line;
          }
        });
      }
  
      // Save the PDF
      // doc.save("labels_with_barcode_only_on_left.pdf");

      const pdfBytes = doc.output("arraybuffer");
  
      // Rotate the pages using PDF-Lib
      await this.rotatePDFPages(pdfBytes);
    };

    private static rotatePDFPages = async (pdfBytes: ArrayBuffer) => {
        // Load the PDF into PDF-Lib
        const pdfDoc = await PDFDocument.load(pdfBytes);
    
        // Rotate each page
        const pages = pdfDoc.getPages();
        pages.forEach((page) => {
          page.setRotation(degrees(90)); // Rotate 90 degrees clockwise
        });
    
        // Save the rotated PDF
        const rotatedPdfBytes = await pdfDoc.save();
    
        // Download the rotated PDF
        const blob = new Blob([rotatedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement("a");
        link.href = url;
        link.download = "rotated_labels.pdf";
        link.click();
    
        URL.revokeObjectURL(url);
      };


    static generateDmartLabels70By40_v2 = async (
        labelDataArray: LabelDataV2[],
        labelHeadings: LabelData,        
      ) => {
        const width = 4.0; // Total label width in cm
        const height = 7.0; // Total label height in cm
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "portrait",
        });
    
        const fontSize = 5; // Default font size for the text
        doc.setFontSize(fontSize);
    
        // Dimensions for the two parts
        const barcodePartWidth = 1; // Left part width for barcode
        const textWidth = width - barcodePartWidth; // Right part width for text
        const margin = 0.3; // General margin for content
    
        // Barcode dimensions (swap width and height due to rotation)
        const barcodeHeight = 1; // Height corresponds to the width
        const barcodeWidthFinal = 5.0; // Width corresponds to the height
        const barcodeX = margin; // Left margin for barcode
        const barcodeY = margin; // Top margin for the barcode
    
        // Text area start positions
        const textStartX = barcodePartWidth + margin; // Start text area after the barcode
        const textStartY = margin; // Start text from the top margin
        const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
    
        const cellHeight6Lines = 1.05; // Height for multi-line content
        const cellHeight5Lines = 0.9; // Height for multi-line content
        const cellHeight4Lines = 0.8; // Height for multi-line content
        const cellHeight1Line = 0.3; // Height for single-line content
        const cellHeight2Line = 0.5; // Height for single-line content
        
        const keys = Object.keys(labelHeadings) as (keyof LabelData)[];
        console.log(keys);
        const filteredKeys = keys.filter((key) => (key !== "barcode" && key !== "styleId") );
        console.log(filteredKeys);

        for(let j=0;j<labelDataArray.length;j++){          
          let labelData = labelDataArray[j];                    
          for (let i = 0; i < labelData.labelCount; i++) {            
            if (j+i > 0) doc.addPage(); // Add a new page for each label after the first
      
            // Generate the barcode image without the text
            const canvas = document.createElement("canvas");
            JsBarcode(canvas, labelData.barcode, {
              format: "CODE128",
              displayValue: true, // Ensure that no text is shown with the barcode
              fontSize: 24,
            });
            const barcodeImage = canvas.toDataURL("image/png");
      
            // Add the barcode image in the left section
            doc.addImage(
              barcodeImage,
              "PNG",
              barcodeX,
              -0.5, // Y position (top margin)
              barcodeWidthFinal,
              barcodeHeight,
              undefined,
              "FAST",
              -90 // Rotate the barcode to be aligned on the left
            );
      
            // Add text in the right part
           
      
            let y = textStartY;
            filteredKeys.forEach((key) => {
              let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
              if (key === "title") {
                fullText = this.ellipsize(fullText, 128);
              } else if (key === "oneSetContains" || key  === "productDimension") {
                fullText = this.ellipsize(fullText, 52);
              }
      
              // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
              if (
                key === "marketedBy" ||
                key === "manufacturedBy" ||
                key === "title"
              ) {
                doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
              } else {
                doc.setFontSize(fontSize); // Restore default font size
              }
      
              // Add the text to the PDF
              doc.text(fullText, textStartX, y, {
                maxWidth: textContentWidth,
              });
      
              // Adjust the y position based on content type
              if (key === "marketedBy") {
                y += cellHeight6Lines;
              } else if (key === "manufacturedBy") {
                y += cellHeight5Lines;
              } else if (key === "title") {
                y += cellHeight4Lines;
              } else if (
                key === "productDimension" ||
                key === "modelNumber" ||
                key === "oneSetContains" ||              
                key === "styleId"
              ) {
                y += cellHeight2Line;
              } else {
                y += cellHeight1Line;
              }
            });
          }
        }
    
        
    
        // Save the PDF
        // doc.save("labels_with_barcode_only_on_left.pdf");
  
        const pdfBytes = doc.output("arraybuffer");
    
        // Rotate the pages using PDF-Lib
        await this.rotatePDFPages(pdfBytes);
      };

    static generateDmartLabels70By40_v3 = async (
        labelDataArray: LabelDataV2[],
        labelHeadings: LabelData,
      ) => {
        
        const width = 4.0; // Total label width in cm
        const height = 7.0; // Total label height in cm

        
      
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "portrait",
        });
      
        const fontSize = 5; // Default font size for the text
        doc.setFontSize(fontSize);
      
        // Dimensions for the two parts
        const barcodePartWidth = 1; // Left part width for barcode
        const textWidth = width - barcodePartWidth; // Right part width for text
        const margin = 0.3; // General margin for content
      
        // Barcode dimensions (swap width and height due to rotation)
        const barcodeHeight = 1; // Height corresponds to the width
        const barcodeWidthFinal = 5.0; // Width corresponds to the height
        const barcodeX = margin; // Left margin for barcode
        const barcodeY = margin; // Top margin for the barcode
      
        // Text area start positions
        const textStartX = barcodePartWidth + margin; // Start text area after the barcode
        const textStartY = margin; // Start text from the top margin
        const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
      
        const cellHeight6Lines = 1.05; // Height for multi-line content
        const cellHeight5Lines = 0.9; // Height for multi-line content
        const cellHeight4Lines = 0.8; // Height for multi-line content
        const cellHeight1Line = 0.3; // Height for single-line content
        const cellHeight2Line = 0.5; // Height for single-line content
      
        const keys = Object.keys(labelHeadings) as (keyof LabelData)[];
        const filteredKeys = keys.filter((key) => key !== "barcode" && key !== "styleId");
      
        for (let j = 0; j < labelDataArray.length; j++) {
          let labelData = labelDataArray[j];
          for (let i = 0; i < labelData.labelCount; i++) {
            if (j + i > 0) doc.addPage(); // Add a new page for each label after the first
      
            // Generate the barcode image without the text
            const canvas = document.createElement("canvas");
            JsBarcode(canvas, labelData.barcode, {
              format: "CODE128",
              displayValue: true, // Ensure that no text is shown with the barcode
              fontSize: 24,
            });
            const barcodeImage = canvas.toDataURL("image/png");
      
            // Add the barcode image in the left section
            doc.addImage(
              barcodeImage,
              "PNG",
              barcodeX,
              -0.5, // Y position (top margin)
              barcodeWidthFinal,
              barcodeHeight,
              undefined,
              "FAST",
              -90 // Rotate the barcode to be aligned on the left
            );
      
            // Add text in the right part
            let y = textStartY;
            filteredKeys.forEach((key) => {
              let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
              if (key === "mrp") {
                fullText = `${labelHeadings[key]}: Rs. ${labelData[key] || ""}.00`; // Add Rupee symbol for MRP
              } else if (key === "title") {
                fullText = this.ellipsize(fullText, 128);
              } else if (key === "oneSetContains" || key === "productDimension") {
                fullText = this.ellipsize(fullText, 52);
              }
      
              // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
              if (
                key === "marketedBy" ||
                key === "manufacturedBy" ||
                key === "title" || 
                key === "material"
              ) {
                doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
              } else {
                doc.setFontSize(fontSize); // Restore default font size
              }
      
              // Add the text to the PDF
              doc.text(fullText, textStartX, y, {
                maxWidth: textContentWidth,
              });
      
              // Adjust the y position based on content type
              if (key === "marketedBy") {
                y += cellHeight6Lines;
              } else if (key === "manufacturedBy") {
                y += cellHeight5Lines;
              } else if (key === "title") {
                y += cellHeight4Lines;
              } else if (
                key === "productDimension" ||
                key === "modelNumber" ||
                key === "oneSetContains" ||
                key === "styleId"
              ) {
                y += cellHeight2Line;
              } else {
                y += cellHeight1Line;
              }
            });
          }
      


          // doc.addPage();
          // doc.setFontSize(30); // Larger font size for better visibility
          // doc.setTextColor(255, 0, 0); // Set text color to red for high visibility
          
          // // Draw a rectangle as a border for the separator
          // doc.setDrawColor(255, 0, 0); // Red border
          // // doc.setLineWidth(0.1); // Border thickness
          // // doc.rect(margin, margin, width - 2 * margin, height - 2 * margin);
          
          // // Add separator text
          // doc.text("*BREAK*", width / 2, height / 2 + 0.5, {
          //   align: "center",
          // });
          // doc.setFontSize(10);          
          // doc.setTextColor(0, 0, 0); // Reset text color to black
        }
      
        // Save the PDF
        const pdfBytes = doc.output("arraybuffer");
      
        // Rotate the pages using PDF-Lib
        await this.rotatePDFPages(pdfBytes);
    };

    static generateBoxLabels4by6 = async (boxLevelItems: BoxLevelItem[]) => {
      const width = 4.0; // Label width in inches
      const height = 6.0; // Label height in inches
      const doc = new jsPDF({
        unit: "in",
        format: [width, height],
        orientation: "portrait",
      });
    
      const margin = 0.1; // General margin
      const mainFontSize = 18; // Font size for main text
      const subFontSize = 10; // Font size for smaller text
      const lineHeight = 0.3; // Line height for text
      const boxNumberHeight = 1.0; // Height of the "BOX NO:" section
      const borderThickness = 0.01; // Border thickness for lines
      const leftSectionWidth = 2; // Width of the left section for "BOX NO:"
    
      boxLevelItems.forEach((boxItem, index) => {
        if (index > 0) doc.addPage(); // Add a new page for each label after the first one
    
        let currentY = margin;
    
        // Add the logo
        const logoWidth = 1; // Width of the logo in inches
        const logoHeight = 0.3; // Height of the logo in inches
        doc.addImage(
          logoBase64,
          "JPG", // Adjust type if using JPG
          margin,
          currentY,
          logoWidth,
          logoHeight
        );
    
        currentY += logoHeight + 0.2; // Move to the next section after the logo
    
        // Draw the top divider line
        const lineStartX = margin;
        const lineEndX = width - margin;
        doc.setLineWidth(borderThickness);
        doc.line(lineStartX, currentY, lineEndX, currentY);
    
        currentY += 0.2; // Add a small gap below the divider
    
        // BOX NO: Section
        doc.setFontSize(10);

        const rightSectionX = margin + leftSectionWidth;
        const rightSectionWidth = width - margin - rightSectionX;
        const rightSectionY = currentY;
    
        // Draw the left section ("BOX NO:")
        doc.text("Box Number :", margin + rightSectionWidth / 2 , currentY, { align: "center" } ); // Position slightly vertically centered

        doc.setFontSize(28); // Larger font for box number
        doc.text(
          `${boxItem.box_number + 150}`,
          margin + rightSectionWidth / 2, // Centered horizontally
          rightSectionY + boxNumberHeight / 2 + 0.1, // Centered vertically
          { align: "center" }
        );
              
        
        //right section
        let rightY = currentY
        doc.setFontSize(10);          
        doc.text("Invoice Number :", rightSectionX + rightSectionWidth / 2, rightY, { align: "center" }); // Position slightly vertically centered      
        doc.setFontSize(16); // Larger font for invoice number
        rightY += 0.3;
        doc.text(
          `${boxItem.box_number}`,
          rightSectionX + rightSectionWidth / 2, // Centered horizontally
          rightY,
          { align: "center" }
        );
        
        rightY += 0.3;
        doc.setFontSize(10);          
        doc.text("Delivery Location :", rightSectionX + rightSectionWidth / 2, rightY, { align: "center" }); // Position slightly vertically centered      
        doc.setFontSize(16); // Larger font for invoice number
        rightY += 0.3;
        doc.text(
          "Bangalore",
          rightSectionX + rightSectionWidth / 2, // Centered horizontally
          rightY,
          { align: "center" }
        );

    
        currentY = rightY + 0.2;      
        // Draw the bottom divider line
        doc.line(lineStartX, currentY, lineEndX, currentY);
    

        // PO Number Section
        currentY += 0.2; // Add a small gap below the divider
    
        // PO Number
        doc.setFontSize(subFontSize);
        doc.text(`PO Number :`, margin, currentY);
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, `${boxItem.po_number}`, { format: "CODE128", displayValue: false });
        const barcodeImage = canvas.toDataURL("image/png");
        currentY += 0.2;                
    
        // Add the barcode spanning the entire width of the label
        doc.addImage(
          barcodeImage,
          "PNG",
          (width - (width - 4 * margin)) / 2,
          currentY, // Y position (top margin)
          width - 4 * margin, // Full width minus margins
          0.5 // Adjusted height
        );

        currentY += 0.7;                
        doc.setFontSize(16);
        doc.text(
          `${boxItem.po_number}`,
          width / 2, // Center of the page
          currentY, // Current Y position
          { align: "center" } // Center alignment
        );  

        currentY += 0.2;                
        doc.line(lineStartX, currentY, lineEndX, currentY);
        currentY += 0.2;                
        
    
        // // Box Contents
        // doc.setFontSize(10);
        // doc.text("Box Contents:", margin, currentY);
        // currentY += lineHeight + 0.2;
    
        // boxItem.box_details.forEach((detail) => {
        //   doc.setFontSize(subFontSize);
        //   doc.text(
        //     `- SKU: ${detail.po_secondary_sku}, Qty: ${detail.box_quantity}`,
        //     margin + 0.2,
        //     currentY
        //   );
        //   currentY += lineHeight;
        // });
    
        // // Add extra space after contents if needed
        // currentY += 0.5;

        doc.setFontSize(10);
        doc.text("Box Contents:", margin, currentY);
        currentY +=  0.2; // Add space before the table
        


        // Table headers
        const tableHeaders = ["Buyer Code", "eCraft Code", "Qty"];
        const columnWidths = [(width - 2 * margin) * 0.4, (width - 2 * margin) * 0.4, (width - 2 * margin) * 0.2]; // Proportions for each column
        const headerHeight = 0.3;
        let headerX = margin;          
        doc.setFontSize(10); // Slightly larger font for headers
        tableHeaders.forEach((header, index) => {
          doc.text(header, headerX + columnWidths[index] / 2, currentY + headerHeight / 2, {
            align: "center",
          });
          headerX += columnWidths[index];
        });

        // Draw header divider
        currentY += 0.2;
        doc.line(margin, currentY, width - margin, currentY); // Horizontal line below headers
        
        

        // Table content
        currentY += 0.2; // Space after header divider
        doc.setFontSize(9); // Reset font size for content


        boxItem.box_details.forEach((detail) => {
          let contentX = margin;
        
          // Buyer Code
          // doc.text(`${detail.company_code_primary}`, contentX + columnWidths[0] / 2, currentY + 0.2, {
          //   align: "center",
          // });

          const canvas = document.createElement("canvas");
          JsBarcode(canvas, `${detail.company_code_primary}`, { format: "CODE128", width: 2, height: 40 }); // Adjust barcode properties as needed
          const barcodeImage = canvas.toDataURL("image/png");

          // Add Buyer Code barcode (centered in the first column)
          doc.addImage(
            barcodeImage,
            "PNG",
            contentX + columnWidths[0] / 2 - 0.8, // Center the barcode horizontally within the column
            currentY, // Current row's Y position
            1.6, // Barcode width
            0.4 // Barcode height
          );
          contentX += columnWidths[0];
        
          // eCraft Code
          doc.text(`${detail.po_secondary_sku}_akshit_akshit_akshit`, contentX + columnWidths[1] / 2, currentY + 0.2 , {
            align: "center",
            maxWidth: (width - 2 * margin) * 0.4 - 0.2
          });
          contentX += columnWidths[1];
        
          // Quantity
          doc.text(`${detail.box_quantity}`, contentX + columnWidths[2] / 2, currentY + 0.2, {
            align: "center",
          });
        
          // Move to the next row
          currentY += 0.45;
        });

        doc.line(margin, currentY, width - margin, currentY);
        currentY += 0.2; 

      });
    
      // Save the PDF with a descriptive name
      doc.save("box_labels.pdf");
    };

    static generateBoxLabels4by6_v2  = async (boxLevelItems: BoxLevelItem[], invoice_number:string, consignment_id: string) => {
      const width = 4.0; // Label width in inches
      const height = 6.0; // Label height in inches
      const doc = new jsPDF({
        unit: "in",
        format: [width, height],
        orientation: "portrait",
      });
    
      const margin = 0.1; // General margin
      const mainFontSize = 18; // Font size for main text
      const subFontSize = 10; // Font size for smaller text
      const lineHeight = 0.3; // Line height for text
      const boxNumberHeight = 1.0; // Height of the "BOX NO:" section
      const borderThickness = 0.01; // Border thickness for lines
      const leftSectionWidth = 2; // Width of the left section for "BOX NO:"
    
      boxLevelItems.forEach((boxItem, index) => {
        if (index > 0) doc.addPage(); // Add a new page for each label after the first one
    
        let currentY = margin;
    
        // Add the logo
        const logoWidth = 1.2; // Width of the logo in inches
        const logoHeight = 0.4; // Height of the logo in inches
        doc.addImage(
          logoBase64,
          "JPG", // Adjust type if using JPG
          margin,
          currentY,
          logoWidth,
          logoHeight
        );
    
        currentY += logoHeight // Move to the next section after the logo
    
        // Draw the top divider line
        const lineStartX = margin;
        const lineEndX = width - margin;
        doc.setLineWidth(borderThickness);
        doc.line(lineStartX, currentY, lineEndX, currentY);
    
        currentY += 0.2; // Add a small gap below the divider
    
        // BOX NO: Section
        doc.setFontSize(10);

        const rightSectionX = margin + leftSectionWidth;
        const rightSectionWidth = width - margin - rightSectionX;
        const rightSectionY = currentY;
    
        // Draw the left section ("BOX NO:")
        doc.text("Box Number :", margin + rightSectionWidth / 2 , currentY, { align: "center" } ); // Position slightly vertically centered

        doc.setFontSize(28); // Larger font for box number
        doc.text(
          `${boxItem.box_number}`,
          margin + rightSectionWidth / 2, // Centered horizontally
          rightSectionY + boxNumberHeight / 2 + 0.1, // Centered vertically
          { align: "center" }
        );
              
        
        //right section
        let rightY = currentY
        doc.setFontSize(10);          
        doc.text("Invoice Number :", rightSectionX + rightSectionWidth / 2, rightY, { align: "center" }); // Position slightly vertically centered      
        doc.setFontSize(16); // Larger font for invoice number
        rightY += 0.3;
        doc.text(
          `${invoice_number}`,
          rightSectionX + rightSectionWidth / 2, // Centered horizontally
          rightY,
          { align: "center" }
        );
        
        rightY += 0.3;
        doc.setFontSize(10);          
        doc.text("Delivery Location :", rightSectionX + rightSectionWidth / 2, rightY, { align: "center" }); // Position slightly vertically centered      
        doc.setFontSize(16); // Larger font for invoice number
        rightY += 0.3;
        doc.text(
          `${boxItem.delivery_city}`,
          rightSectionX + rightSectionWidth / 2, // Centered horizontally
          rightY,
          { align: "center" }
        );

    
        currentY = rightY + 0.2;      
        // Draw the bottom divider line
        doc.line(lineStartX, currentY, lineEndX, currentY);
    

        // PO Number Section
        currentY += 0.2; // Add a small gap below the divider
    
        // PO Number
        doc.setFontSize(subFontSize);
        doc.text(`PO Number :`, margin, currentY);
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, `${boxItem.po_number}`, { format: "CODE128", displayValue: false });
        const barcodeImage = canvas.toDataURL("image/png");
        currentY += 0.2;                
    
        // Add the barcode spanning the entire width of the label
        doc.addImage(
          barcodeImage,
          "PNG",
          (width - (width - 4 * margin)) / 2,
          currentY, // Y position (top margin)
          width - 4 * margin, // Full width minus margins
          0.5 // Adjusted height
        );

        currentY += 0.7;                
        doc.setFontSize(16);
        doc.text(
          `${boxItem.po_number}`,
          width / 2, // Center of the page
          currentY, // Current Y position
          { align: "center" } // Center alignment
        );  

        currentY += 0.2;                
        doc.line(lineStartX, currentY, lineEndX, currentY);
        currentY += 0.2;                
        
    
        // // Box Contents
        // doc.setFontSize(10);
        // doc.text("Box Contents:", margin, currentY);
        // currentY += lineHeight + 0.2;
    
        // boxItem.box_details.forEach((detail) => {
        //   doc.setFontSize(subFontSize);
        //   doc.text(
        //     `- SKU: ${detail.po_secondary_sku}, Qty: ${detail.box_quantity}`,
        //     margin + 0.2,
        //     currentY
        //   );
        //   currentY += lineHeight;
        // });
    
        // // Add extra space after contents if needed
        // currentY += 0.5;

        doc.setFontSize(10);
        doc.text("Box Contents:", margin, currentY);
        currentY +=  0.2; // Add space before the table
        


        // Table headers
        const tableHeaders = ["ITEM CODE", "ECRAFT SKU", "QTY"];
        // const columnWidths = [(width) * 0.5, (width - 2 * margin) * 0.35, (width - 2 * margin) * 0.15]; // Proportions for each column
        const columnWidths = [(width) * 0.4, (width ) * 0.35, (width ) * 0.25]; // Proportions for each column
        const headerHeight = 0.3;
        let headerX = margin;                  
        tableHeaders.forEach((header, index) => {
          doc.text(header, headerX + columnWidths[index] / 2, currentY + headerHeight / 2, {
            align: "center",
          });
          headerX += columnWidths[index];
        });

        // Draw header divider
        currentY += 0.2;
        // doc.line(margin, currentY, width - margin, currentY); // Horizontal line below headers
        
        

        // Table content
        currentY += 0.2; // Space after header divider
        doc.setFontSize(8); // Reset font size for content


        boxItem.box_details.forEach((detail) => {
          let contentX = margin;
        
          // Buyer Code
          // doc.text(`${detail.company_code_primary}`, contentX + columnWidths[0] / 2, currentY + 0.2, {
          //   align: "center",
          // });

          const canvas = document.createElement("canvas");
          JsBarcode(canvas, `${detail.company_code_primary}`, { format: "CODE128", width: 2, height: 30 , displayValue: false}); // Adjust barcode properties as needed
          const barcodeImage = canvas.toDataURL("image/png");

          // Add Buyer Code barcode (centered in the first column)
          doc.addImage(
            barcodeImage,
            "PNG",
            // columnWidths[0] / 2 - 0.9, // Center the barcode horizontally within the column
            margin*1.5,
            currentY, // Current row's Y position
            columnWidths[0]-margin,
            0.3 // Barcode height
          );
          doc.text(`${detail.company_code_primary}`,columnWidths[0] / 2 + margin*0.5, currentY + 0.35 , {
            align: "center",            
          });
          contentX += columnWidths[0];
        
          // eCraft Code
          doc.text(`${detail.po_secondary_sku}`, contentX + columnWidths[1] / 2, currentY + 0.2 , {
            align: "center",
            maxWidth: (width - 2 * margin) * 0.35 - 0.18
          });
          contentX += columnWidths[1];
        
          // Quantity
          doc.text(`${detail.box_quantity}`, contentX + columnWidths[2] / 2, currentY + 0.2, {
            align: "center",
          });
        
          // Move to the next row
          currentY += 0.45;
        });

        doc.line(margin, currentY, width - margin, currentY);
        currentY += 0.2; 

      });
    
      // Save the PDF with a descriptive name
      doc.save(`${consignment_id}_"box_labels.pdf`);
    };



    static generateGeneralLabels70By40_v3 = async (
      labelDataArray: LabelDataV2[],
      labelHeadings: LabelData,
    ) => {
      
      const width = 4.0; // Total label width in cm
      const height = 7.0; // Total label height in cm
          
      const doc = new jsPDF({
        unit: "cm",
        format: [width, height],
        orientation: "portrait",
      });
    
      const fontSize = 5; // Default font size for the text
      doc.setFontSize(fontSize);
    
      // Dimensions for the two parts
      const barcodePartWidth = 1; // Left part width for barcode
      const textWidth = width - barcodePartWidth; // Right part width for text
      const margin = 0.3; // General margin for content
    
      // Barcode dimensions (swap width and height due to rotation)
      const barcodeHeight = 1; // Height corresponds to the width
      const barcodeWidthFinal = 5.0; // Width corresponds to the height
      const barcodeX = margin; // Left margin for barcode
      const barcodeY = margin; // Top margin for the barcode
    
      // Text area start positions
      const textStartX = barcodePartWidth + margin; // Start text area after the barcode
      const textStartY = margin; // Start text from the top margin
      const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
    
      const cellHeight6Lines = 1.05; // Height for multi-line content
      const cellHeight5Lines = 0.9; // Height for multi-line content
      const cellHeight4Lines = 0.8; // Height for multi-line content
      const cellHeight1Line = 0.3; // Height for single-line content
      const cellHeight2Line = 0.5; // Height for single-line content
    
      const keys = Object.keys(labelHeadings) as (keyof LabelData)[];
      const filteredKeys = keys.filter((key) => key !== "barcode" && key !== "styleId");
    
      for (let j = 0; j < labelDataArray.length; j++) {
        let labelData = labelDataArray[j];
        for (let i = 0; i < labelData.labelCount; i++) {
          if (j + i > 0) doc.addPage(); // Add a new page for each label after the first
    
          // Generate the barcode image without the text
          const canvas = document.createElement("canvas");
          JsBarcode(canvas, labelData.barcode, {
            format: "CODE128",
            displayValue: true, // Ensure that no text is shown with the barcode
            fontSize: 24,
          });
          const barcodeImage = canvas.toDataURL("image/png");
    
          // Add the barcode image in the left section
          doc.addImage(
            barcodeImage,
            "PNG",
            barcodeX,
            -0.5, // Y position (top margin)
            barcodeWidthFinal,
            barcodeHeight,
            undefined,
            "FAST",
            -90 // Rotate the barcode to be aligned on the left
          );
    
          // Add text in the right part
          let y = textStartY;
          filteredKeys.forEach((key) => {
            let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
            if (key === "mrp") {
              fullText = `${labelHeadings[key]}: Rs. ${labelData[key] || ""}.00`; // Add Rupee symbol for MRP
            } else if (key === "title") {
              fullText = this.ellipsize(fullText, 128);
            } else if (key === "oneSetContains" || key === "productDimension") {
              fullText = this.ellipsize(fullText, 52);
            }
    
            // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
            if (
              key === "marketedBy" ||
              key === "manufacturedBy" ||
              key === "title" || 
              key === "material"
            ) {
              doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
            } else {
              doc.setFontSize(fontSize); // Restore default font size
            }
    
            // Add the text to the PDF
            doc.text(fullText, textStartX, y, {
              maxWidth: textContentWidth,
            });
    
            // Adjust the y position based on content type
            if (key === "marketedBy") {
              y += cellHeight6Lines;
            } else if (key === "manufacturedBy") {
              y += cellHeight5Lines;
            } else if (key === "title") {
              y += cellHeight4Lines;
            } else if (
              key === "productDimension" ||
              key === "modelNumber" ||
              key === "oneSetContains" ||
              key === "styleId"
            ) {
              y += cellHeight2Line;
            } else {
              y += cellHeight1Line;
            }
          });
        }
    
        doc.addPage();
        doc.setFontSize(30); // Larger font size for better visibility
        doc.setTextColor(255, 0, 0); // Set text color to red for high visibility
        
        // Draw a rectangle as a border for the separator
        doc.setDrawColor(255, 0, 0); // Red border
        // doc.setLineWidth(0.1); // Border thickness
        // doc.rect(margin, margin, width - 2 * margin, height - 2 * margin);
        
        // Add separator text
        doc.text("*BREAK*", width / 2, height / 2 + 0.5, {
          align: "center",
        });
        doc.setFontSize(10);          
        doc.setTextColor(0, 0, 0); // Reset text color to black
      }
    
      // Save the PDF
      const pdfBytes = doc.output("arraybuffer");
    
      // Rotate the pages using PDF-Lib
      await this.rotatePDFPages(pdfBytes);
  };

  static generateGeneralLabels70By40_v4_combined_address_Myntra = async (
    labelDataArray: LabelDataV2[],
    labelHeadings: LabelData,
  ) => {
    
    const width = 4.0; // Total label width in cm
    const height = 7.0; // Total label height in cm
        
    const doc = new jsPDF({
      unit: "cm",
      format: [width, height],
      orientation: "portrait",
    });
    doc.setFont("helvetica", "bold"); 
  
    const fontSize = 5.2; // Default font size for the text
    doc.setFontSize(fontSize);    
  
    // Dimensions for the two parts
    const barcodePartWidth = 1; // Left part width for barcode
    const textWidth = width - barcodePartWidth; // Right part width for text
    const margin = 0.3; // General margin for content
  
    // Barcode dimensions (swap width and height due to rotation)
    const barcodeHeight = 1; // Height corresponds to the width
    const barcodeWidthFinal = 5.0; // Width corresponds to the height
    const barcodeX = margin; // Left margin for barcode
    const barcodeY = margin; // Top margin for the barcode
  
    // Text area start positions
    const textStartX = barcodePartWidth + margin; // Start text area after the barcode
    const textStartY = margin; // Start text from the top margin
    const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
  
    const cellHeight6Lines = 1.75; // Height for multi-line content
    const cellHeight5Lines = 0.9; // Height for multi-line content
    const cellHeight4Lines = 0.8; // Height for multi-line content
    const cellHeight1Line = 0.3; // Height for single-line content
    const cellHeight2Line = 0.5; // Height for single-line content
  
    const keys = Object.keys(labelHeadings) as (keyof LabelData)[];
    const filteredKeys = keys.filter((key) => key !== "barcode" && key  !== "manufacturedBy");
  
    for (let j = 0; j < labelDataArray.length; j++) {
      let labelData = labelDataArray[j];
      for (let i = 0; i < labelData.labelCount; i++) {
        if (j + i > 0) doc.addPage(); // Add a new page for each label after the first
  
        // Generate the barcode image without the text
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, labelData.barcode, {
          format: "CODE128",
          displayValue: true, // Ensure that no text is shown with the barcode
          fontSize: 24,
        });
        const barcodeImage = canvas.toDataURL("image/png");
  
        // Add the barcode image in the left section
        doc.addImage(
          barcodeImage,
          "PNG",
          barcodeX,
          -0.5, // Y position (top margin)
          barcodeWidthFinal,
          barcodeHeight,
          undefined,
          "FAST",
          -90 // Rotate the barcode to be aligned on the left
        );
  
        // Add text in the right part
        let y = textStartY;
        filteredKeys.forEach((key) => {
          
            let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
          if (key === "mrp") {
            fullText = `${labelHeadings[key]}: Rs. ${labelData[key] || ""}.00`; // Add Rupee symbol for MRP
          } else if (key === "title") {
            fullText = this.ellipsize(fullText, 128);
          } else if (key === "oneSetContains" || key === "productDimension") {
            fullText = this.ellipsize(fullText, 52);
          }
  
          // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
          // if (
          //   key === "marketedBy" ||
          //   key === "manufacturedBy" ||
          //   key === "title" || 
          //   key === "material"
          // ) {
          //   doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
          // } else {
          //   doc.setFontSize(fontSize); // Restore default font size
          // }

          doc.setFontSize(fontSize); // Restore default font size
  
          // Add the text to the PDF
          doc.text(fullText, textStartX, y, {
            maxWidth: textContentWidth,
          });

          if(key === "mrp"){
            y+=0.15;
            doc.setFontSize(4);
            doc.text("(Incl. of all taxes)", textStartX, y, {
              maxWidth: textContentWidth,
            });            
          }

         
  
          // Adjust the y position based on content type
          if (key === "marketedBy") {
            y += cellHeight6Lines;
          }else if (key === "title") {
            y += cellHeight4Lines;
          } else if (
            key === "productDimension" ||
            key === "modelNumber" ||
            key === "oneSetContains" ||
            key === "styleId"
          ) {
            y += cellHeight2Line;
          } else {
            y += cellHeight1Line;
          }

         
          
          
        });
      }
  
      doc.addPage();
      doc.setFontSize(30); // Larger font size for better visibility
      doc.setTextColor(255, 0, 0); // Set text color to red for high visibility
      
      // Draw a rectangle as a border for the separator
      doc.setDrawColor(255, 0, 0); // Red border
      // doc.setLineWidth(0.1); // Border thickness
      // doc.rect(margin, margin, width - 2 * margin, height - 2 * margin);
      
      // Add separator text
      doc.text("*BREAK*", width / 2, height / 2 + 0.5, {
        align: "center",
      });
      doc.setFontSize(10);          
      doc.setTextColor(0, 0, 0); // Reset text color to black
    }
  
    // Save the PDF
    const pdfBytes = doc.output("arraybuffer");
  
    // Rotate the pages using PDF-Lib
    await this.rotatePDFPages(pdfBytes);
  };

  static generateGeneralLabels70By40_v5_combined_address_Myntra = async (
    labelDataArray: LabelDataV2[],
    labelHeadings: LabelData,
  ) => {
    
    const width = 4.0; // Total label width in cm
    const height = 7.0; // Total label height in cm
        
    const doc = new jsPDF({
      unit: "cm",
      format: [width, height],
      orientation: "portrait",
    });
    doc.setFont("helvetica", "bold"); 
  
    const fontSize = 5.2; // Default font size for the text
    doc.setFontSize(fontSize);    
  
    // Dimensions for the two parts
    const barcodePartWidth = 1; // Left part width for barcode
    const textWidth = width - barcodePartWidth; // Right part width for text
    const margin = 0.3; // General margin for content
  
    // Barcode dimensions (swap width and height due to rotation)
    const barcodeHeight = 1.1; // Height corresponds to the width
    const barcodeWidthFinal = 5.0; // Width corresponds to the height
    const barcodeX = margin; // Left margin for barcode
    const barcodeY = margin; // Top margin for the barcode
  
    // Text area start positions
    const textStartX = barcodePartWidth + margin; // Start text area after the barcode
    const textStartY = margin; // Start text from the top margin
    const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
  
    const cellHeight6Lines = 1.75; // Height for multi-line content
    const cellHeight5Lines = 0.9; // Height for multi-line content
    const cellHeight4Lines = 0.8; // Height for multi-line content
    const cellHeight1Line = 0.3; // Height for single-line content
    const cellHeight2Line = 0.5; // Height for single-line content
  
    const keys = Object.keys(labelHeadings) as (keyof LabelData)[];
    const filteredKeys = keys.filter((key) => key !== "barcode" && key  !== "manufacturedBy");
  
    for (let j = 0; j < labelDataArray.length; j++) {
      let labelData = labelDataArray[j];
      for (let i = 0; i < labelData.labelCount; i++) {
        if (j + i > 0) doc.addPage(); // Add a new page for each label after the first
  
        // Generate the barcode image without the text
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, labelData.barcode, {
          format: "CODE128",
          displayValue: true, // Ensure that no text is shown with the barcode
          fontSize: 26,
          textMargin: 5,                   
        });
        const barcodeImage = canvas.toDataURL("image/png");
  
        // Add the barcode image in the left section
        doc.addImage(
          barcodeImage,
          "PNG",
          barcodeX-0.1,
          -0.5, // Y position (top margin)
          barcodeWidthFinal,
          barcodeHeight,
          undefined,
          "FAST",
          -90 // Rotate the barcode to be aligned on the left
        );
        // doc.saveGraphicsState();
        // doc.setFontSize(10); // Adjust font size
        // doc.setTextColor(0, 0, 0); // Black text
        // doc.text(labelData.barcode, barcodeX + barcodeHeight + 0.5, barcodeY + barcodeWidthFinal / 2, {
        //   angle: -90, // Rotate text to match barcode
        //   align: "center",
        // });
        // doc.restoreGraphicsState();
  
        // Add text in the right part
        let y = textStartY;
        filteredKeys.forEach((key) => {
          
            let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
          if (key === "mrp") {
            fullText = `${labelHeadings[key]}: Rs. ${labelData[key] || ""}.00`; // Add Rupee symbol for MRP
          } else if (key === "title") {
            fullText = this.ellipsize(fullText, 128);
          } else if (key === "oneSetContains" || key === "productDimension") {
            fullText = this.ellipsize(fullText, 52);
          }
  
          // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
          // if (
          //   key === "marketedBy" ||
          //   key === "manufacturedBy" ||
          //   key === "title" || 
          //   key === "material"
          // ) {
          //   doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
          // } else {
          //   doc.setFontSize(fontSize); // Restore default font size
          // }

          doc.setFontSize(fontSize); // Restore default font size
  
          // Add the text to the PDF
          doc.text(fullText, textStartX, y, {
            maxWidth: textContentWidth,
          });

          if(key === "mrp"){
            y+=0.15;
            doc.setFontSize(4);
            doc.text("(Incl. of all taxes)", textStartX, y, {
              maxWidth: textContentWidth,
            });            
          }

         
  
          // Adjust the y position based on content type
          if (key === "marketedBy") {
            y += cellHeight6Lines;
          }else if (key === "title") {
            y += cellHeight4Lines;
          } else if (
            key === "productDimension" ||
            key === "modelNumber" ||
            key === "oneSetContains" ||
            key === "styleId"
          ) {
            y += cellHeight2Line;
          } else {
            y += cellHeight1Line;
          }

         
          
          
        });
      }
  
      doc.addPage();
      doc.setFontSize(30); // Larger font size for better visibility
      doc.setTextColor(255, 0, 0); // Set text color to red for high visibility
      
      // Draw a rectangle as a border for the separator
      doc.setDrawColor(255, 0, 0); // Red border
      // doc.setLineWidth(0.1); // Border thickness
      // doc.rect(margin, margin, width - 2 * margin, height - 2 * margin);
      
      // Add separator text
      doc.text("*BREAK*", width / 2, height / 2 + 0.5, {
        align: "center",
      });
      doc.setFontSize(10);          
      doc.setTextColor(0, 0, 0); // Reset text color to black
    }
  
    // Save the PDF
    const pdfBytes = doc.output("arraybuffer");
  
    // Rotate the pages using PDF-Lib
    await this.rotatePDFPages(pdfBytes);
  };

  
                
}

export default CompanyPurchaseOrderLabelUtils;