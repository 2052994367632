import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/CompanyPurchaseOrderConsignmentDetails.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  ConsignmentDetailed,
  BulkInvoiceDataItemParent,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";

interface RouteParams {
  po_id: string;
}

function CompanyPurchaseOrderConsignmentsPendingInvoices() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { po_id } = useParams<RouteParams>();

  const [checkedCount, setCheckedCount] = useState<number>(0);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [createNewConsignment, setCreateNewConsignment] = useState(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);
  const [currentConsignments, setCurrentConsignments] = useState<
    ConsignmentDetailed[]
  >([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);

  const fetchConsignmentsWithPendingInvoices = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    // setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.fetchConsignmentsWithPendingInvoices(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          100
        );

      setCurrentConsignments(response.data);
      // setSearchParams((prev) => {
      //   return {
      //     ...prev,
      //     item_count: prev.item_count + response.data["curr_page_count"],
      //     expected_item_count: response.data["total"],
      //     current_page_num: response.data["current_page"],
      //   };
      // });

      // setCurrentConsignments((prevList) => [
      //   ...prevList,
      //   ...response.data["content"],
      // ]);

      // setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in creating new Consignment : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      // setShowLoadingPage(false);
      setShowBottomLoader(false);
    }
  };

  const handleCheckBoxChangeAtIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) setCheckedCount(checkedCount + 1);
    else {
      setCheckedCount(checkedCount - 1);
      if (checkedCount - 1 == 0 && isCheckedSelectAll) {
        setIsCheckedSelectAll(false);
      }
    }

    setCurrentConsignments((prevItems) =>
      prevItems.map((item, i) =>
        i == index ? { ...item, ["is_selected"]: !item.is_selected } : item
      )
    );
  };

  const handleSearch = (query: string) => {
    setCurrentConsignments([]);
    setShowLoadingPage(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    fetchConsignmentsWithPendingInvoices(query, 1, true);
  };

  const getClassNameForConsignment = (consignment_status: string) => {
    if (consignment_status == "OPEN") return "color-green";
    else if (consignment_status == "CLOSED") return "color-red";
    else if (consignment_status == "MARKED_RTD") return "color-pink";
    return "";
  };

  const getClassNameForInvoiceNumberStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    else if (invoice_number_status == "ASSIGNED") return "color-green";
    return "";
  };
  const getClassNameForInvoiceUploadStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-UPLOADED") return "color-red";
    else if (invoice_number_status == "UPLOADED") return "color-green";
    return "";
  };

  const handleCheckBoxChangeForAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedSelectAll(event.target.checked); // Update state based on the checkbox value
    if (event.target.checked) {
      setCheckedCount(currentConsignments.length);
    } else {
      setCheckedCount(0);
    }

    setCurrentConsignments((prevItems) =>
      prevItems.map((item, i) => ({
        ...item,
        ["is_selected"]: event.target.checked,
      }))
    );
  };

  const getBulkInvoiceData = async (consignmentIds: any[]) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getBulkDataForInvoices(
          consignmentIds
        );

      return response.data;
    } catch (error) {
      console.error("Error in getting bulk data for invoice. : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      return [];
    }
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const extractStateFromAddress = (address: string): string => {
    for (const state of indianStates) {
      if (address.toLowerCase().includes(state.toLowerCase())) {
        return state;
      }
    }
    return "";
  };

  const sanitizeShipmentMode = (shipment_mode: string) => {
    if (shipment_mode) {
      return shipment_mode.replace("ways", "");
    }
  };

  const prepareAndDownloadBulkInvoiceData = async () => {
    //check if invoice numbers were assigned for all
    for (let index = 0; index < currentConsignments.length; index++) {
      const item = currentConsignments[index];
      if (
        item.is_selected === true &&
        item.invoice_number_status === "NOT-ASSIGNED"
      ) {
        toast.error(
          "Invoice Number was not assigned to one of the selected items, unable to download data for such consignments.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
        setShowDownloadPage(false);
        return;
      }
    }

    const consignmentIds = currentConsignments
      .filter((item) => item.is_selected === true)
      .map((item) => item.consignment_id);

    const bulkInvoiceParentItems: BulkInvoiceDataItemParent[] =
      await getBulkInvoiceData(consignmentIds);

    const finalTransformedItems = bulkInvoiceParentItems.map(
      (bulkInvoiceParent, index) => {
        let detectedState = "";
        if (bulkInvoiceParent.items.length > 0) {
          detectedState = extractStateFromAddress(
            bulkInvoiceParent.items[0].delivery_address
          );
        } else {
          detectedState = "";
        }

        const transformedItems = bulkInvoiceParent.items.map((singleItem) => {
          return {
            invoice_date: DateTimeUtils.getCurrentDateOnly(),
            invoice_number: singleItem.invoice_number,
            po_number: singleItem.po_number,
            company_code_primary: singleItem.company_code_primary,
            po_secondary_sku: singleItem.po_secondary_sku,
            title: singleItem.title,
            quantity: singleItem.consignment_qty,
            item_price_excl_gst: singleItem.rate_without_tax,
            state: detectedState,
            tax_rate: singleItem.tax_rate,
            igst:
              Math.round(
                ((singleItem.consignment_qty *
                  singleItem.rate_without_tax *
                  singleItem.tax_rate) /
                  100) *
                  100
              ) / 100,
            sgst_cgst:
              Math.round(
                ((singleItem.consignment_qty *
                  singleItem.rate_without_tax *
                  singleItem.tax_rate) /
                  200) *
                  100
              ) / 100,
            taxable_amount:
              Math.round(
                singleItem.consignment_qty * singleItem.rate_without_tax * 100
              ) / 100,
            net_sale:
              Math.round(
                ((singleItem.consignment_qty *
                  singleItem.rate_without_tax *
                  (100 + singleItem.tax_rate)) /
                  100) *
                  100
              ) / 100,
            gstin: singleItem.buyer_gstin,
            company_name: singleItem.company_name,
            shipping_address: singleItem.delivery_address,
            pin_code: "",
            iat: "",
            total_box: "Total Box - " + singleItem.box_count,
            transporter_name: singleItem.transporter_name,
            export_sales: "",
            shipment_mode: sanitizeShipmentMode(singleItem.shipment_mode),
            transporter_legal_id: singleItem.legal_transporter_id,
            vehicle_docket_number:
              singleItem.shipment_mode == "Roadways"
                ? singleItem.vehicle_number
                : singleItem.docket_number,
            vehicle_type: "Regular",
            our_state_name: "Rajasthan",
            our_pin_code: "302026",
            distance: "",
            sold_via: singleItem.sold_via,
            invoice_id: singleItem.invoice_id,
            hsn: singleItem.hsn_code,
          };
        });
        transformedItems.push({
          invoice_date: "",
          invoice_number: "",
          po_number: "",
          company_code_primary: "",
          po_secondary_sku: "",
          title: "",
          quantity: 0, // Set as number instead of empty string
          item_price_excl_gst: 0, // Set as number instead of empty string
          state: "",
          tax_rate: 0, // Set as number instead of empty string
          igst: 0, // Set as number instead of empty string
          sgst_cgst: 0, // Set as number instead of empty string
          taxable_amount: 0, // Set as number instead of empty string
          net_sale: 0, // Set as number instead of empty string
          gstin: "",
          company_name: "",
          shipping_address: "",
          pin_code: "",
          iat: "",
          total_box: "",
          transporter_name: "",
          export_sales: "",
          shipment_mode: "",
          transporter_legal_id: "",
          vehicle_docket_number: "",
          vehicle_type: "",
          our_state_name: "",
          our_pin_code: "",
          distance: "",
          sold_via: "",
          invoice_id: 0,
          hsn: "",
        });

        return transformedItems;
      }
    );

    jsonexport(finalTransformedItems, (err: Error | null, csv: string) => {
      if (err) {
        console.error(err);
        return;
      }
      // Create a Blob object with the CSV data
      const blob = new Blob([csv], { type: "text/csv" });
      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "bulk_invoice_raw_data";

      // Simulate a click to trigger the download
      link.click();
    });

    setShowDownloadPage(false);
  };

  useEffect(() => {
    setShowBottomLoader(true);
    fetchConsignmentsWithPendingInvoices("", 1, false);
  }, []);

  return (
    <div className="consignments-data-container">
      {currentConsignments && (
        <div className="vendor-action-buttons-container">
          <div className="catalogues-search-bar-container">
            {checkedCount > 0 && (
              <div>
                <Button
                  className="mr-2 filters-button"
                  onClick={() => {
                    setShowDownloadPage(true);
                    setTimeout(() => {
                      prepareAndDownloadBulkInvoiceData();
                    }, 3000);
                  }}
                  variant="outline-primary"
                >
                  Download Invoice Data
                </Button>
              </div>
            )}
          </div>

          <div className="labels-master-data-table-container">
            <div className="all-labels-master-data-table">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>
                      {" "}
                      <input
                        type="checkbox"
                        checked={isCheckedSelectAll}
                        onChange={handleCheckBoxChangeForAll}
                      />
                    </th>
                    <th>Consignment ID</th>
                    <th>Company Name</th>
                    <th>Location</th>
                    <th>Sold Via</th>
                    <th>PO Number</th>
                    <th>PO Type</th>
                    <th>Consignment Status</th>
                    <th>Invoice Number Status</th>
                    <th>Invoice Number</th>
                    <th>Invoice Upload Status</th>
                    <th>Boxes Count</th>
                    <th>Sku Count</th>
                    <th>Total Quantity</th>
                    <th>Transporter Name</th>
                    <th>Vehicle Number/Docket Number</th>
                    <th>Consignment Created At</th>
                    <th>Consignment Marked RTD At</th>
                    <th>Marked RTD By</th>
                  </tr>
                </thead>
                <tbody>
                  {currentConsignments.map((Consignment, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td>
                          <input
                            type="checkbox"
                            checked={Consignment.is_selected}
                            onChange={(e) =>
                              handleCheckBoxChangeAtIndex(e, index)
                            }
                          />
                        </td>
                        <td className="po-id-link">
                          <a
                            href={`/incomingPos/info/consignmentDetails/${Consignment.po_number}/${Consignment.consignment_id}/documents`}
                          >
                            {Consignment.consignment_id}
                          </a>
                        </td>
                        <td>{Consignment.company_name}</td>
                        <td>{Consignment.delivery_city}</td>
                        <td>{Consignment.sold_via}</td>
                        <td>{Consignment.po_number}</td>
                        <td>{Consignment.po_type}</td>
                        <td
                          className={getClassNameForConsignment(
                            Consignment.consignment_status
                          )}
                        >
                          {Consignment.consignment_status}
                        </td>
                        <td
                          className={getClassNameForInvoiceNumberStatus(
                            Consignment.invoice_number_status
                          )}
                        >
                          {Consignment.invoice_number_status}
                        </td>
                        <td>{Consignment.invoice_number}</td>

                        <td
                          className={getClassNameForInvoiceUploadStatus(
                            Consignment.invoice_upload_status
                          )}
                        >
                          {Consignment.invoice_number_status == "NOT-ASSIGNED"
                            ? ""
                            : Consignment.invoice_upload_status}
                        </td>

                        <td>{Consignment.total_boxes}</td>
                        <td>{Consignment.total_skus}</td>
                        <td>{Consignment.total_quantity}</td>
                        <td>{Consignment.transporter_name}</td>
                        <td>{Consignment.vehicle_number}</td>
                        <td>
                          {DateTimeUtils.formatDateTime(Consignment.created_at)}
                        </td>

                        <td>
                          {DateTimeUtils.formatDateTime(
                            Consignment.marked_rtd_at
                          )}
                        </td>

                        <td>{Consignment.marked_rtd_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {!showBottomLoader && currentConsignments.length == 0 && (
                <div className="nothing-found-container">
                  No pending invoices were found.
                </div>
              )}
              {showBottomLoader && (
                <div className="bottom-progress-container">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* {currentConsignments.length == 0 && (
        <div className="purchase-orders-table-container no-item">
          <div>No Pending Consignments were found.</div>
        </div>
      )} */}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrderConsignmentsPendingInvoices;
