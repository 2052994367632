import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/PurchaseOrderGrnHome.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  Vendor,
  VendorPurchaseOrderGrn,
  VendorPurchaseOrderGrnDetailed,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";

import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download, XCircleFill } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";

interface RouteParams {
  operation: string;
  po_id: string;
}

function PurchaseOrderGrnHome() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const purchaseOrderNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const { operation, po_id } = useParams<RouteParams>();
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [currentConsignments, setCurrentConsignments] = useState<Consignment[]>(
    []
  );

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [showOpenGrnTab, setShowOpenGrnTab] = useState<boolean>(false);
  const [allVendorPurchaseOrderGrns, setAllVendorPurchaseOrderGrns] = useState<
    VendorPurchaseOrderGrnDetailed[]
  >([]);
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<PurchaseOrder>();

  const [currentVendor, setCurrentVendor] = useState<Vendor>();
  const [vendorInvoiceNumber, setVendorInvoiceNumber] = useState<string>("");
  const [boxCountInInvoice, setBoxCountInInvoice] = useState<number | null>();
  const [actualBoxCountRecieved, setActualBoxCountRecieved] = useState<
    number | null
  >();

  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      const fileArray = Array.from(uploadedFiles);
      if (fileArray.length + files.length > 2) {
        alert("You can upload a maximum of 2 files.");
      } else {
        setFiles([...files, ...fileArray]);
      }
    }
  };

  const handleFileRemove = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const fetchAllGrns = async (purchase_order_id: any) => {
    setShowLoadingPage(true);
    try {
      const response = await purchaseOrderNetworkUtil.fetchAllGrnsByPoId(
        purchase_order_id.toString()
      );

      setAllVendorPurchaseOrderGrns(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in fetching GRN details : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const getClassNameForGRN = (consignment_status: string) => {
    if (consignment_status == "OPEN") return "color-green";
    else if (consignment_status == "CLOSED") return "color-pink";
    return "";
  };

  const handleGRNCreationInit = () => {
    if (currentPurchaseOrder) {
      if (currentPurchaseOrder.status == "PENDING_PUBLISHED") {
        setShowOpenGrnTab(true);
      } else {
        toast.error("Cannot OPEN GRN unless the purchase order is Published.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  useEffect(() => {
    const fetchPurchaseOrderByPOId = async (poId: string) => {
      console.log(poId);
      try {
        const response = await purchaseOrderNetworkUtil.getPurchaseOrderById(
          poId
        );
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    fetchPurchaseOrderByPOId(po_id);
  }, [po_id]);

  useEffect(() => {
    const fetchVendorByVendorId = async (vendor_id: string) => {
      try {
        const response = await vendorsNetworkUtil.getVendorByVendorId(
          vendor_id
        );
        setCurrentVendor(response.data);
      } catch (error) {
        console.error("Error in fetching vendor by vendor_id :", error);
      }
    };

    if (currentPurchaseOrder) {
      fetchVendorByVendorId(currentPurchaseOrder.vendor_id.toString());
      fetchAllGrns(currentPurchaseOrder.po_id.toString());
    }
  }, [currentPurchaseOrder]);

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const openNewGRN = async () => {
    if (currentPurchaseOrder) {
      let err_count = 0;
      if (vendorInvoiceNumber.length == 0) {
        toast.error("Invoice Number cannot be left empty", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        err_count++;
      }

      if (boxCountInInvoice == null || boxCountInInvoice < 0) {
        toast.error("Box count in invoice cannot be left empty", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        err_count++;
        return;
      }

      if (!actualBoxCountRecieved || actualBoxCountRecieved == 0) {
        toast.error("Actual box recieved cannot be zero.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        err_count++;
        return;
      }

      const queryParams: Record<string, any> = {
        vendor_invoice_number: vendorInvoiceNumber, // New page value
        box_count_invoice: boxCountInInvoice, // New count value
        actual_box_count_recieved: actualBoxCountRecieved,
      };

      setShowOpenGrnTab(false);
      setShowLoadingPage(true);
      try {
        const response = await purchaseOrderNetworkUtil.openNewGrn(
          queryParams,
          userDetailsFromStore.user_details.userId,
          currentPurchaseOrder.po_id.toString()
        );
        setAllVendorPurchaseOrderGrns(response.data);
        toast.success("GRN was opened successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        setVendorInvoiceNumber("");
        setBoxCountInInvoice(0);
        setActualBoxCountRecieved(0);
        setShowLoadingPage(false);
      } catch (error) {
        console.error("Error in opening GRN : ", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 400) {
          console.log((err.response.data as ErrorMessageBody).message);
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Unexpected error", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
        setShowLoadingPage(false);
      }
    }
  };

  return (
    <div className="catalogues-container">
      {currentPurchaseOrder && currentVendor && (
        <div className="incoming-purchase-order-details-table-container mt-4">
          <Table bordered hover size="sm" className="table-auto-width">
            <tbody>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Number</td>
                <td>{currentPurchaseOrder.po_id}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Status</td>
                <td>
                  {" "}
                  {currentPurchaseOrder.status == "PENDING_PUBLISHED" && (
                    <Badge bg="primary" className="po_status_pill">
                      PUBLISHED
                    </Badge>
                  )}
                  {currentPurchaseOrder.status == "MARKED_CANCELLED" && (
                    <Badge bg="dark" className="po_status_pill">
                      MARKED CANCELLED
                    </Badge>
                  )}
                  {currentPurchaseOrder.status == "PENDING" && (
                    <Badge bg="dark" className="po_status_pill">
                      PENDING
                    </Badge>
                  )}
                  {currentPurchaseOrder.status == "MARKED_MODIFICATION" && (
                    <Badge bg="dark" className="po_status_pill">
                      MARKED FOR MODIFICATION
                    </Badge>
                  )}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">SKU Count</td>
                <td>{currentPurchaseOrder.sku_count}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Quantity in PO</td>
                <td>{currentPurchaseOrder.total_quantity}</td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Vendor Id</td>
                <td>{currentPurchaseOrder.vendor_id}</td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Vendor Name</td>
                <td>{currentVendor.vendor_name}</td>
              </tr>

              <tr>
                <td className="nowrap">Vendor Billing Address</td>
                <td>
                  {currentVendor.vendor_address_line},{" "}
                  {currentVendor.vendor_city}, {currentVendor.vendor_state},{" "}
                  {currentVendor.vendor_postal_code}, GSTIN :
                  {currentVendor.vendor_gstin}, Contact Number :
                  {currentVendor.vendor_contact_number}
                </td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Release Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.date_published
                  )}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Expiry Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.expected_date
                  )}
                </td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Created By</td>
                <td>{currentPurchaseOrder.created_by}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      <div className="create-pack-task-container pt-4">
        <Button
          variant="outline-primary"
          className="vendor-action-button"
          onClick={handleGRNCreationInit}
        >
          Open New GRN
        </Button>
      </div>
      {allVendorPurchaseOrderGrns && (
        <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-container">
          <div className="po-document-section-heading mt-4">All GRNs</div>
          {allVendorPurchaseOrderGrns.length === 0 ? (
            <div className="po-document-section-no-document mt-3">
              No GRN was found
            </div>
          ) : (
            <div className="incoming-purchase-order-file-details-table-container mt-3">
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>GRN ID</th>
                    <th>PO ID</th>
                    <th>Vendor Invoice Number</th>
                    <th>GRN Status</th>
                    <th>GRN Audit Status</th>
                    <th>Invoice Collection Status</th>
                    <th>GRN SKU Count</th>
                    <th>Accepted Quantity</th>
                    <th>Rejected Quantity</th>
                    <th>Shortage Quantity</th>
                    <th>Box Count In Invoice</th>
                    <th>Actual Recieved Box Count</th>
                    <th>Created At</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {allVendorPurchaseOrderGrns.map((grn, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td className="po-id-link">
                          <a
                            href={`/vendor-hub/all-purchase-orders/grn/details/${grn.grn_id}`}
                          >
                            {grn.grn_id}
                          </a>
                        </td>
                        <td>{grn.po_id}</td>
                        <td>{grn.vendor_invoice_number}</td>
                        <td className={getClassNameForGRN(grn.grn_status)}>
                          {grn.grn_status}
                        </td>
                        <td
                          className={getClassNameForGRN(grn.grn_audit_status)}
                        >
                          {grn.grn_audit_status == "OPEN" ||
                          grn.grn_audit_status == "CLOSED"
                            ? grn.grn_audit_status
                            : ""}
                        </td>
                        <td
                          className={getClassNameForGRN(
                            grn.grn_invoice_collection_status
                          )}
                        >
                          {grn.grn_invoice_collection_status == "OPEN" ||
                          grn.grn_invoice_collection_status == "CLOSED"
                            ? grn.grn_invoice_collection_status
                            : ""}
                        </td>
                        <td>{grn.grn_sku_count}</td>
                        <td>{grn.grn_accepted_quantity}</td>
                        <td>{grn.grn_rejected_quantity}</td>
                        <td>{grn.grn_shortage_quantity}</td>
                        <td>{grn.box_count_invoice}</td>
                        <td>{grn.actual_box_count_recieved}</td>
                        <td>{DateTimeUtils.formatDateTime(grn.created_at)}</td>
                        <td>{grn.created_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
        </div>
      )}

      <Offcanvas
        show={showOpenGrnTab}
        onHide={() => {
          setShowOpenGrnTab(false);
        }}
        placement="end"
        className="dispatch-offcanvas"
        backdrop="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="dispatch-offcanvas-title">Open New GRN</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="invoice-details-container">
            {currentPurchaseOrder && currentVendor && (
              <div className="open-grn-header mb-4">
                <label>
                  Vendor - {currentVendor.vendor_name} ({currentVendor.id})
                </label>
                <br></br>
                <label>PO Number - {currentPurchaseOrder.po_id}</label>
              </div>
            )}

            <div className="grn-field-container mt-4">
              <label>
                Vendor Invoice Number :
                <input
                  className="grn-input"
                  name="vendor_invoice_number"
                  value={vendorInvoiceNumber}
                  onChange={(e) =>
                    setVendorInvoiceNumber(e.target.value.trim())
                  }
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="grn-field-container mt-3">
              <label>
                Box Count in Invoice :
                <input
                  type="number"
                  className="grn-input"
                  name="box_count_in_invoice"
                  value={boxCountInInvoice ?? ""}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0)
                      setBoxCountInInvoice(Number(e.target.value));
                  }}
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="grn-field-container mt-3">
              <label>
                Actual Box Count Recieved :
                <input
                  type="number"
                  className="grn-input"
                  name="actual_box_count_recieved"
                  value={actualBoxCountRecieved ?? ""}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0)
                      setActualBoxCountRecieved(Number(e.target.value));
                  }}
                  autoComplete="off"
                />
              </label>
            </div>

            {/* <div className="grn-field-container mt-3">
              <label>Upload Recieved Invoice : </label>
              <div className="grn-invoice-upload-container-main">
                <div className="date-time-picker-selected-date-container ml-1 mb-3">
                  <span>
                    (Maximum 2 files are allowed. File size should not exceed
                    2MB. Please upload both pdf and spreadsheet version of the
                    PO.){" "}
                  </span>{" "}
                  &nbsp;
                </div>
                <input
                  type="file"
                  accept=".xls,.xlsx,.csv,.pdf"
                  onChange={handleFileChange}
                  multiple
                />

                {files.length > 0 && (
                  <div>
                    <div>Selected Files:</div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index} style={{ padding: "4px" }}>
                          {file.name}
                          <XCircleFill
                            size={15}
                            color="#007bff"
                            style={{ cursor: "pointer", marginLeft: "8px" }}
                            onClick={() => handleFileRemove(index)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div> */}
            <div
              className="offcanvas-button-container"
              onClick={() => {
                openNewGRN();
              }}
            >
              <button type="button">Submit</button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default PurchaseOrderGrnHome;
